@use '../../src/sass/config' as *;

// Import Component Base Styles 👇🏻
@use '../../src/sass/components/_1_feature-v11' as *;

// Custom Styles 👇🏻
.boxed-feature__image {
  border-radius: 60px 0 60px 0;
}

@include max-width(md) {
  .box-before {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 60px;
      height: 60px;
      background: var(--color-bg-dark);
      z-index: -1;
    }
  }
}