// Remove Basic Browser Styling ↓
@use 'global/reset';

// Set Global Variables ↓
@use 'config' as * with (
  $font-family: (
    'primary': 'sofia-pro, system-ui, sans-serif',
    'secondary': 'kallisto, system-ui, sans-serif'
  ),
  $font-size: (
    '@all': (
      'xs': '0.6875rem',
      'sm': '0.8125rem',
      'base': '1rem',
      'md': '1.1875rem',
      'lg': '1.4375rem',
      'xl': '1.75rem',
      '2xl': '2.0625rem',
      '3xl': '2rem',
      '4xl': '3rem'
    ),
    '@md': (
      'xs': '0.75rem',
      'sm': '0.9375rem',
      'base': '1.125rem',
      'md': '1.375rem',
      'lg': '1.625rem',
      'xl': '2rem',
      '2xl': '2.5rem',
      '3xl': '3rem',
      '4xl': '3.625rem'
    )
  ),
  $colors: (
    'default': (
      'primary': (
        'darker': '196, 100%, 9%',
        'dark': '196, 100%, 15%',
        'base': '196, 100%, 21%',
        'light': '196, 100%, 27%',
        'lighter': '196, 100%, 33%'
      ),
      'accent': (
        'darker': '196, 100%, 27%',
        'dark': '196, 100%, 33%',
        'base': '196, 100%, 39%',
        'light': '196, 100%, 45%',
        'lighter': '196, 100%, 51%'
      ),
      'dark': (
        'darker': '0, 0%, 15%',
        'dark': '0, 0%, 21%',
        'base': '0, 0%, 27%',
        'light': '0, 0%, 33%',
        'lighter': '0, 0%, 39%'
      ),
      'black': (
        'base': '230, 13%, 9%'
      ),
      'white': (
        'base': '0, 0%, 100%'
      ),
      'warning': (
        'darker': '35, 79%, 48%',
        'dark': '35, 79%, 56%',
        'base': '35, 79%, 66%',
        'light': '35, 79%, 74%',
        'lighter': '35, 79%, 82%'
      ),
      'success': (
        'darker': '170, 78%, 26%',
        'dark': '170, 78%, 31%',
        'base': '170, 78%, 36%',
        'light': '170, 78%, 42%',
        'lighter': '170, 78%, 47%'
      ),
      'error': (
        'darker': '342, 89%, 38%',
        'dark': '342, 89%, 43%',
        'base': '342, 89%, 48%',
        'light': '342, 89%, 56%',
        'lighter': '342, 89%, 62%'
      ),
      'bg': (
        'darker': '240, 4%, 90%',
        'dark': '240, 4%, 95%',
        'base': '0, 0%, 100%',
        'light': '0, 0%, 100%',
        'lighter': '0, 0%, 100%'
      ),
      'contrast': (
        'lower': '240, 4%, 85%',
        'low': '240, 4%, 65%',
        'medium': '225, 4%, 47%',
        'high': '230, 7%, 23%',
        'higher': '230, 13%, 9%'
      )
    ),
    'dark': (
      'primary': (
        'darker': '196, 100%, 9%',
        'dark': '196, 100%, 15%',
        'base': '196, 100%, 21%',
        'light': '196, 100%, 27%',
        'lighter': '196, 100%, 33%'
      ),
      'accent': (
        'darker': '196, 100%, 27%',
        'dark': '196, 100%, 33%',
        'base': '196, 100%, 39%',
        'light': '196, 100%, 45%',
        'lighter': '196, 100%, 51%'
      ),
      'dark': (
        'darker': '0, 0%, 15%',
        'dark': '0, 0%, 21%',
        'base': '0, 0%, 27%',
        'light': '0, 0%, 33%',
        'lighter': '0, 0%, 39%'
      ),
      'black': (
        'base': '230, 13%, 9%'
      ),
      'white': (
        'base': '0, 0%, 100%'
      ),
      'warning': (
        'darker': '35, 79%, 48%',
        'dark': '35, 79%, 56%',
        'base': '35, 79%, 66%',
        'light': '35, 79%, 74%',
        'lighter': '35, 79%, 82%'
      ),
      'success': (
        'darker': '170, 78%, 26%',
        'dark': '170, 78%, 31%',
        'base': '170, 78%, 36%',
        'light': '170, 78%, 42%',
        'lighter': '170, 78%, 47%'
      ),
      'error': (
        'darker': '342, 89%, 38%',
        'dark': '342, 89%, 43%',
        'base': '342, 89%, 48%',
        'light': '342, 89%, 56%',
        'lighter': '342, 89%, 62%'
      ),
      'bg': (
        'darker': '240, 1%, 20%',
        'dark': '0, 0%, 23%',
        'base': '0, 0%, 27%',
        'light': '0, 0%, 31%',
        'lighter': '240, 1%, 34%'
      ),
      'contrast': (
        'lower': '240, 1%, 37%',
        'low': '240, 0%, 50%',
        'medium': '240, 1%, 64%',
        'high': '240, 5%, 85%',
        'higher': '240, 100%, 99%'
      )
    ),
    'primary': (
      'primary': (
        'darker': '196, 100%, 27%',
        'dark': '196, 100%, 33%',
        'base': '196, 100%, 39%',
        'light': '196, 100%, 45%',
        'lighter': '196, 100%, 51%'
      ),
      'accent': (
        'darker': '196, 100%, 27%',
        'dark': '196, 100%, 33%',
        'base': '196, 100%, 39%',
        'light': '196, 100%, 45%',
        'lighter': '196, 100%, 51%'
      ),
      'dark': (
        'darker': '0, 0%, 15%',
        'dark': '0, 0%, 21%',
        'base': '0, 0%, 27%',
        'light': '0, 0%, 33%',
        'lighter': '0, 0%, 39%'
      ),
      'black': (
        'base': '230, 13%, 9%'
      ),
      'white': (
        'base': '0, 0%, 100%'
      ),
      'warning': (
        'darker': '35, 79%, 48%',
        'dark': '35, 79%, 56%',
        'base': '35, 79%, 66%',
        'light': '35, 79%, 74%',
        'lighter': '35, 79%, 82%'
      ),
      'success': (
        'darker': '170, 78%, 26%',
        'dark': '170, 78%, 31%',
        'base': '170, 78%, 36%',
        'light': '170, 78%, 42%',
        'lighter': '170, 78%, 47%'
      ),
      'error': (
        'darker': '342, 89%, 38%',
        'dark': '342, 89%, 43%',
        'base': '342, 89%, 48%',
        'light': '342, 89%, 56%',
        'lighter': '342, 89%, 62%'
      ),
      'bg': (
        'darker': '196, 100%, 9%',
        'dark': '196, 100%, 15%',
        'base': '196, 100%, 21%',
        'light': '196, 100%, 27%',
        'lighter': '196, 100%, 33%'
      ),
      'contrast': (
        'lower': '201, 40%, 36%',
        'low': '203, 21%, 51%',
        'medium': '206, 22%, 65%',
        'high': '212, 25%, 85%',
        'higher': '240, 100%, 99%'
      )
    ),
    'accent': (
      'primary': (
        'darker': '196, 100%, 9%',
        'dark': '196, 100%, 15%',
        'base': '196, 100%, 21%',
        'light': '196, 100%, 27%',
        'lighter': '196, 100%, 33%'
      ),
      'accent': (
        'darker': '196, 100%, 9%',
        'dark': '196, 100%, 15%',
        'base': '196, 100%, 21%',
        'light': '196, 100%, 27%',
        'lighter': '196, 100%, 33%'
      ),
      'dark': (
        'darker': '0, 0%, 15%',
        'dark': '0, 0%, 21%',
        'base': '0, 0%, 27%',
        'light': '0, 0%, 33%',
        'lighter': '0, 0%, 39%'
      ),
      'black': (
        'base': '230, 13%, 9%'
      ),
      'white': (
        'base': '0, 0%, 100%'
      ),
      'warning': (
        'darker': '35, 79%, 48%',
        'dark': '35, 79%, 56%',
        'base': '35, 79%, 66%',
        'light': '35, 79%, 74%',
        'lighter': '35, 79%, 82%'
      ),
      'success': (
        'darker': '170, 78%, 26%',
        'dark': '170, 78%, 31%',
        'base': '170, 78%, 36%',
        'light': '170, 78%, 42%',
        'lighter': '170, 78%, 47%'
      ),
      'error': (
        'darker': '342, 89%, 38%',
        'dark': '342, 89%, 43%',
        'base': '342, 89%, 48%',
        'light': '342, 89%, 56%',
        'lighter': '342, 89%, 62%'
      ),
      'bg': (
        'darker': '196, 100%, 27%',
        'dark': '196, 100%, 33%',
        'base': '196, 100%, 39%',
        'light': '196, 100%, 45%',
        'lighter': '196, 100%, 51%'
      ),
      'contrast': (
        'lower': '201, 40%, 36%',
        'low': '203, 21%, 51%',
        'medium': '206, 22%, 65%',
        'high': '212, 25%, 85%',
        'higher': '240, 100%, 99%'
      )
    ),
    'light': (
      'primary': (
        'darker': '196, 100%, 9%',
        'dark': '196, 100%, 15%',
        'base': '196, 100%, 21%',
        'light': '196, 100%, 27%',
        'lighter': '196, 100%, 33%'
      ),
      'accent': (
        'darker': '196, 100%, 27%',
        'dark': '196, 100%, 33%',
        'base': '196, 100%, 39%',
        'light': '196, 100%, 45%',
        'lighter': '196, 100%, 51%'
      ),
      'dark': (
        'darker': '0, 0%, 15%',
        'dark': '0, 0%, 21%',
        'base': '0, 0%, 27%',
        'light': '0, 0%, 33%',
        'lighter': '0, 0%, 39%'
      ),
      'black': (
        'base': '230, 13%, 9%'
      ),
      'white': (
        'base': '0, 0%, 100%'
      ),
      'warning': (
        'darker': '35, 79%, 48%',
        'dark': '35, 79%, 56%',
        'base': '35, 79%, 66%',
        'light': '35, 79%, 74%',
        'lighter': '35, 79%, 82%'
      ),
      'success': (
        'darker': '170, 78%, 26%',
        'dark': '170, 78%, 31%',
        'base': '170, 78%, 36%',
        'light': '170, 78%, 42%',
        'lighter': '170, 78%, 47%'
      ),
      'error': (
        'darker': '342, 89%, 38%',
        'dark': '342, 89%, 43%',
        'base': '342, 89%, 48%',
        'light': '342, 89%, 56%',
        'lighter': '342, 89%, 62%'
      ),
      'bg': (
        'darker': '240, 1%, 86%',
        'dark': '0, 0%, 90%',
        'base': '0, 0%, 96%',
        'light': '0, 0%, 100%',
        'lighter': '240, 1%, 100%'
      ),
      'contrast': (
        'lower': '240, 4%, 85%',
        'low': '240, 4%, 65%',
        'medium': '225, 4%, 47%',
        'high': '230, 7%, 23%',
        'higher': '230, 13%, 9%'
      )
    )
  )
);

// Load Global Styles ↓
@use 'global/typography';
@use 'global/icons';
//@use 'global/colors';
@use 'global/buttons';
@use 'global/forms';


// Basic Theme Setup ↓
@use 'components/_1_animated-headline.scss' as *;
@use 'components/1_details.scss' as *;
@use 'components/1_card.scss' as *;
@use 'components/1_custom-checkbox.scss' as *;
@use 'components/1_custom-select.scss' as *;
@use 'components/1_floating-action-button.scss' as *;
@use 'components/1_link-effects.scss' as *;
@use 'components/1_list.scss' as *;
@use 'components/1_modal-window.scss' as *;
@use 'components/1_pagination.scss' as *;
@use 'components/1_radios-checkboxes.scss' as *;
@use 'components/1_read-more.scss' as *;
@use 'components/1_reveal-effects.scss' as *;
@use 'components/1_tabs.scss' as *;
@use 'components/2_carousel.scss' as *;
@use 'components/2_comments.scss' as *;
@use 'components/2_dropdown.scss' as *;
@use 'components/2_slideshow.scss' as *;
@use 'components/1_scrolling-animations.scss' as *;
@use 'components/_1_rating.scss' as *;
@use 'components/_1_tooltip.scss' as *;


// Navigation ↓
@use 'components/1_anim-menu-btn.scss' as *;
@use 'components/1_drawer.scss' as *;
@use 'components/2_drawer-navigation.scss' as *;
@use 'components/2_flexi-header.scss' as *;
@use 'components/3_hiding-nav.scss' as *;
@use 'components/3_mega-site-navigation.scss' as *;
@use 'components/2_morphing-navigation.scss' as *;


// Footer ↓
@use 'components/1_main-footer.scss' as *;
@use 'components/1_main-footer-v3.scss' as *;


// Load Components ↓
@use 'includes/_1_components.scss' as *;
@use '../../components/accordion/_style.scss' as *;
@use '../../components/accordion-v2/_style.scss' as *;
@use '../../components/image-comparison/_style.scss' as *;
@use '../../components/contact-person/_style.scss' as *;
@use '../../components/download/_style.scss' as *;
@use '../../components/animated-gallery/_style.scss' as *;
@use '../../components/masonry-gallery/_style.scss' as *;
@use '../../components/logo-grid/_style.scss' as *;
@use '../../components/image-hotspots/_style.scss' as *;
@use '../../components/logo-ticker/_style.scss' as *;
@use '../../components/tabs/_style.scss' as *;
@use '../../components/number-counter/_style.scss' as *;
@use '../../components/hero/_style.scss' as *;
@use '../../components/list/_style.scss' as *;
@use '../../components/carousel/_style.scss' as *;
@use '../../components/boxed-feature/_style.scss' as *;
@use '../../components/testimonial-slider/_style.scss' as *;
@use '../../components/icon-box/_style.scss' as *;


// Load Plugins ↓
@use 'includes/_1_contact-form-7' as *;
// @use 'includes/_1_woocommerce' as *;


// Load Utility Classes ↓
@use 'global/util';

.acf-flexible-content .layout {
  max-width: 100%;
}

.acf-field-flexible-content[data-acfe-flexible-grid="1"]>.acf-input>.acf-flexible-content>.values>.layout>.acfe-fc-placeholder.acfe-fc-preview>.-preview {
  padding: 15px;
}

:root {
  --mega-nav-height: 70px;
}

.radius-max {
  border-radius: 500px;
}

.radius-max\@md {
  border-radius: 50px;
  @include min-width(md) {
    border-radius: 500px;
  }
}

.animation-shake:hover {

  svg {
    animation: shake .3s;
  }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}



.footer-small__nav-item  {
  padding: 0 var(--space-xs);
  margin: 0;
  position: relative;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    height: 0.2em;
    width: 0.2em;
    border-radius: 50%;
    right: 0;
    top: calc(50% - 0.1em);
    background-color: var(--color-contrast-higher);
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;

    &::after {
      display: none;
    }
  }
}

html {
  scroll-behavior: smooth;
}

.bg-gradient-accent-primary-right {
  background-image: linear-gradient(90deg,var(--color-accent),var(--color-primary));
}