@use '../../src/sass/config' as *;

// Import Component Base Styles 👇🏻
@use '../../src/sass/components/_1_tabs' as *;
@use '../../src/sass/components/_1_tabbed-navigation' as *;

// Custom Styles 👇🏻
:root {
  --s-tabs-border-bottom-width: 2px;
  --s-tabs-selected-item-border-bottom-width: 2px;
}

.tabs__control:focus {
  outline: none;
}

.tabs__control.s-tabs__link[aria-selected=true] {
  text-decoration: none;
  @extend .s-tabs__link--current;
}