@charset "UTF-8";
/*! purgecss start ignore */
*, *::after, *::before {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  background-color: var(--color-bg, white);
}

ol, ul, menu {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

button, textarea, select, .reset {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

a {
  color: var(--color-primary, hsl(250, 100%, 69%));
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  display: block;
  max-width: 100%;
  height: auto;
}

[data-theme] {
  color: var(--color-contrast-high, hsl(240, 5%, 82%));
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: var(--color-contrast-high);
  font-family: var(--font-primary);
  font-size: var(--text-base);
  line-height: 1.4;
}

h1, h2, h3, h4 {
  font-family: var(--font-secondary);
  color: var(--color-primary);
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
}

h1 {
  font-size: var(--text-xl);
}

h2 {
  font-size: var(--text-lg);
}

h3 {
  font-size: var(--text-md);
}

h4 {
  font-size: var(--text-md);
}

small {
  font-size: var(--text-sm);
}

.link {
  text-decoration: none;
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2) 50%);
  background-size: 200% 1px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  transition: background-position 0.2s;
}
.link.color-accent {
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2) 50%);
}
.link.color-contrast-higher {
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.2) 50%);
}
.link.color-bg {
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0.2) 50%);
}
.link.color-white {
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.2) 50%);
}
.link.color-black {
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2) 50%);
}
.link:hover {
  background-position: 0% 100%;
}

/* text-component */
.text-component,
.component-text-media {
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
  --spacing: 1rem;
  line-height: var(--body-line-height);
}
.text-component > *,
.component-text-media > * {
  margin-bottom: var(--spacing);
}
.text-component :where(h1, h2, h3, h4),
.component-text-media :where(h1, h2, h3, h4) {
  line-height: var(--heading-line-height);
}
.text-component :where(ul, ol),
.component-text-media :where(ul, ol) {
  padding-left: 1.25em;
}
.text-component ul :where(ul, ol),
.text-component ol :where(ul, ol),
.component-text-media ul :where(ul, ol),
.component-text-media ol :where(ul, ol) {
  padding-left: 1em;
}
.text-component ul,
.component-text-media ul {
  list-style-type: disc;
}
.text-component ol,
.component-text-media ol {
  list-style-type: decimal;
}
.text-component ul li::marker,
.text-component ol li::marker,
.component-text-media ul li::marker,
.component-text-media ol li::marker {
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.25);
}
.text-component img,
.component-text-media img {
  border-radius: var(--radius-md);
}
.text-component figcaption,
.component-text-media figcaption {
  margin-top: calc(var(--spacing) / 2);
  font-size: var(--text-sm);
  color: var(--color-contrast-low);
  text-align: center;
}
.text-component em,
.component-text-media em {
  font-style: italic;
}
.text-component strong,
.component-text-media strong {
  font-weight: bold;
}
.text-component s,
.component-text-media s {
  text-decoration: line-through;
}
.text-component u,
.component-text-media u {
  text-decoration: underline;
}
.text-component mark,
.component-text-media mark {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2);
  color: inherit;
  border-radius: var(--radius-md);
  padding: 0 0.25em;
}
.text-component blockquote,
.component-text-media blockquote {
  padding-left: 1em;
  border-left: 3px solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.25);
  font-style: italic;
}
.text-component hr,
.component-text-media hr {
  margin: calc(var(--spacing) * 1.6666666667) 0;
  background: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
  height: 1px;
}
.text-component > *:first-child,
.component-text-media > *:first-child {
  margin-top: 0;
}
.text-component > *:last-child,
.component-text-media > *:last-child {
  margin-bottom: 0;
}

/* overflow items */
:where(.text-component__item-full-width, .text-component__item-overflow, .text-component__item-overflow-left, .text-component__item-overflow-right, .text-component__item-left, .text-component__item-right) img {
  width: 100%;
}

.text-component__item-full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@media (min-width: 48rem) {
  .text-component__item-left,
  .text-component__item-right,
  .text-component__item-overflow-left,
  .text-component__item-overflow-right {
    width: 45%;
  }
  .text-component__item-left,
  .text-component__item-overflow-left {
    float: left;
    margin-right: var(--spacing);
  }
  .text-component__item-right,
  .text-component__item-overflow-right {
    float: right;
    margin-left: var(--spacing);
  }
}
@media (min-width: 80rem) {
  .text-component__item-overflow,
  .text-component__item-overflow-left,
  .text-component__item-overflow-right {
    --overflow-size: 8rem;
  }
  .text-component__item-overflow {
    width: calc(100% + var(--overflow-size) * 2);
    margin-left: calc(var(--overflow-size) * -1);
  }
  .text-component__item-overflow-left {
    margin-left: calc(var(--overflow-size) * -1);
  }
  .text-component__item-overflow-right {
    margin-right: calc(var(--overflow-size) * -1);
  }
}
/* spacing variations */
.text-component--tight {
  --heading-line-height: 1.1;
  --body-line-height: 1.2;
  --spacing: 0.625rem;
}

.text-component--relaxed {
  --heading-line-height: 1.25;
  --body-line-height: 1.625;
  --spacing: 1.25rem;
}
@media (min-width: 64rem) {
  .text-component--relaxed {
    --spacing: 1.5rem;
  }
}

.line-left {
  position: relative;
  padding-bottom: 20px;
}
.line-left:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 5px;
  background: var(--color-accent);
  border-radius: 4px;
  bottom: 10px;
  left: 0;
}

.line-center {
  position: relative;
  padding-bottom: 20px;
}
.line-center:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 5px;
  background: var(--color-accent);
  border-radius: 4px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.icon {
  --size: 1em;
  font-size: var(--size);
  height: 1em;
  width: 1em;
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.icon--3xs {
  --size: 8px;
}

.icon--2xs {
  --size: 12px;
}

.icon--xs {
  --size: 16px;
}

.icon--sm {
  --size: 20px;
}

.icon--md {
  --size: 24px;
}

.icon--lg {
  --size: 32px;
}

.icon--xl {
  --size: 48px;
}

.icon--2xl {
  --size: 64px;
}

.icon--3xl {
  --size: 96px;
}

/* rotate the icon infinitely */
.icon--is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* SVG symbols - enable icon color corrections */
.icon use {
  color: inherit;
  fill: currentColor;
}

.btn, .wpcf7-submit {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  background: var(--color-bg-dark);
  padding: var(--space-2xs) var(--space-sm);
  border-radius: var(--radius-md);
  font-size: 1em;
  font-weight: 500;
  color: var(--color-contrast-higher);
  text-decoration: none;
  line-height: 1.2;
  cursor: pointer;
  transition: 0.2s;
  will-change: transform;
}
.btn:focus-visible, .wpcf7-submit:focus-visible {
  outline: none;
}
.btn:active, .wpcf7-submit:active {
  transform: translateY(2px);
}

/* primary */
.btn--primary, .wpcf7-submit {
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: var(--color-white);
}
.btn--primary:hover, .wpcf7-submit:hover {
  background: var(--color-primary-light);
}

.btn--primary__outline {
  background: transparent;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
}
.btn--primary__outline:hover, .btn--primary__outline:focus-visible {
  background: var(--color-primary-light);
  border: 2px solid var(--color-primary-light);
  color: var(--color-bg);
}

/* subtle */
.btn--subtle {
  background: var(--color-bg-lighter);
  color: var(--color-contrast-higher);
}
.btn--subtle:hover {
  background: var(--color-bg-light);
  border: 2px solid var(--color-bg-light);
}

.btn--subtle__outline {
  background: transparent;
  border: 2px solid var(--color-bg-lighter);
  color: var(--color-bg-lighter);
}
.btn--subtle__outline:hover, .btn--subtle__outline:focus-visible {
  background: var(--color-bg-light);
  border: 2px solid var(--color-bg-lighter);
  color: var(--color-bg);
}

/* accent */
.btn--accent {
  background: var(--color-accent);
  border: 2px solid var(--color-accent);
  color: var(--color-white);
}
.btn--accent:hover {
  background: var(--color-accent-light);
  border: 2px solid var(--color-accent-light);
}

.btn--accent__outline {
  background: transparent;
  border: 2px solid var(--color-accent);
  color: var(--color-accent);
}
.btn--accent__outline:hover, .btn--accent__outline:focus-visible {
  background: var(--color-accent-light);
  border: 2px solid var(--color-accent-light);
  color: var(--color-bg);
}

/* contrast */
.btn--contrast {
  background: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.95);
  border: 2px solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.95);
  color: var(--color-bg);
}
.btn--contrast:hover {
  background: var(--color-contrast-higher);
}

.btn--contrast__outline {
  background: transparent;
  border: 2px solid var(--color-contrast-higher);
  color: var(--color-contrast-higher);
}
.btn--contrast__outline:hover, .btn--contrast__outline:focus-visible {
  background: var(--color-contrast-higher);
  border: 2px solid var(--color-contrast-higher);
  color: var(--color-bg);
}

/* primary subtle */
.btn--primary-subtle {
  background: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.15);
  color: var(--color-primary);
}
.btn--primary-subtle:hover {
  background: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.12);
}

/* accent subtle */
.btn--accent-subtle {
  background: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.15);
  color: var(--color-accent);
}
.btn--accent-subtle:hover {
  background: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.12);
}

/* feedback */
.btn--disabled, .btn[disabled], [disabled].wpcf7-submit, .btn[readonly], [readonly].wpcf7-submit {
  opacity: 0.6;
  cursor: not-allowed;
}

/* a button containing (only) an icon */
.btn--icon {
  padding: 0.5rem;
}

.form-control, input[type=text].wpcf7-form-control,
input[type=email].wpcf7-form-control,
input[type=url].wpcf7-form-control,
input[type=tel].wpcf7-form-control,
input[type=number].wpcf7-form-control,
input[type=date].wpcf7-form-control,
textarea.wpcf7-form-control, input[type=select],
select {
  background: var(--color-bg-dark);
  box-shadow: inset 0 0 0 1px var(--color-contrast-lower);
  padding: var(--space-2xs) var(--space-xs);
  border-radius: var(--radius-md);
  width: 100%;
  font-size: 1em;
  line-height: 1.2;
  transition: 0.2s;
}
.form-control::placeholder, input[type=text].wpcf7-form-control::placeholder,
input[type=email].wpcf7-form-control::placeholder,
input[type=url].wpcf7-form-control::placeholder,
input[type=tel].wpcf7-form-control::placeholder,
input[type=number].wpcf7-form-control::placeholder,
input[type=date].wpcf7-form-control::placeholder,
textarea.wpcf7-form-control::placeholder, input[type=select]::placeholder,
select::placeholder {
  opacity: 1;
  color: var(--color-contrast-low);
}
.form-control:focus, input[type=text].wpcf7-form-control:focus,
input[type=email].wpcf7-form-control:focus,
input[type=url].wpcf7-form-control:focus,
input[type=tel].wpcf7-form-control:focus,
input[type=number].wpcf7-form-control:focus,
input[type=date].wpcf7-form-control:focus,
textarea.wpcf7-form-control:focus, input[type=select]:focus,
select:focus, .form-control:focus-within, input[type=text].wpcf7-form-control:focus-within,
input[type=email].wpcf7-form-control:focus-within,
input[type=url].wpcf7-form-control:focus-within,
input[type=tel].wpcf7-form-control:focus-within,
input[type=number].wpcf7-form-control:focus-within,
input[type=date].wpcf7-form-control:focus-within,
textarea.wpcf7-form-control:focus-within, input[type=select]:focus-within,
select:focus-within {
  background: var(--color-bg);
  outline: none;
  box-shadow: inset 0 0 0 1px hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), 0), 0 0 0 2px var(--color-primary), var(--shadow-sm);
}

/* disabled */
.form-control--disabled,
.form-control[disabled],
input[disabled][type=text].wpcf7-form-control,
input[disabled][type=email].wpcf7-form-control,
input[disabled][type=url].wpcf7-form-control,
input[disabled][type=tel].wpcf7-form-control,
input[disabled][type=number].wpcf7-form-control,
input[disabled][type=date].wpcf7-form-control,
textarea[disabled].wpcf7-form-control,
input[disabled][type=select],
select[disabled],
.form-control[readonly],
input[readonly][type=text].wpcf7-form-control,
input[readonly][type=email].wpcf7-form-control,
input[readonly][type=url].wpcf7-form-control,
input[readonly][type=tel].wpcf7-form-control,
input[readonly][type=number].wpcf7-form-control,
input[readonly][type=date].wpcf7-form-control,
textarea[readonly].wpcf7-form-control,
input[readonly][type=select],
select[readonly] {
  opacity: 0.5;
  cursor: not-allowed;
}

/* error */
.form-control[aria-invalid=true], input[aria-invalid=true][type=text].wpcf7-form-control,
input[aria-invalid=true][type=email].wpcf7-form-control,
input[aria-invalid=true][type=url].wpcf7-form-control,
input[aria-invalid=true][type=tel].wpcf7-form-control,
input[aria-invalid=true][type=number].wpcf7-form-control,
input[aria-invalid=true][type=date].wpcf7-form-control,
textarea[aria-invalid=true].wpcf7-form-control, input[aria-invalid=true][type=select],
select[aria-invalid=true],
.form-control.form-control--error,
input.form-control--error[type=text].wpcf7-form-control,
input.form-control--error[type=email].wpcf7-form-control,
input.form-control--error[type=url].wpcf7-form-control,
input.form-control--error[type=tel].wpcf7-form-control,
input.form-control--error[type=number].wpcf7-form-control,
input.form-control--error[type=date].wpcf7-form-control,
textarea.form-control--error.wpcf7-form-control,
input.form-control--error[type=select],
select.form-control--error {
  box-shadow: inset 0 0 0 1px hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), 0), 0 0 0 2px var(--color-error);
}
.form-control[aria-invalid=true]:focus, input[aria-invalid=true][type=text].wpcf7-form-control:focus,
input[aria-invalid=true][type=email].wpcf7-form-control:focus,
input[aria-invalid=true][type=url].wpcf7-form-control:focus,
input[aria-invalid=true][type=tel].wpcf7-form-control:focus,
input[aria-invalid=true][type=number].wpcf7-form-control:focus,
input[aria-invalid=true][type=date].wpcf7-form-control:focus,
textarea[aria-invalid=true].wpcf7-form-control:focus, input[aria-invalid=true][type=select]:focus,
select[aria-invalid=true]:focus, .form-control[aria-invalid=true]:focus-within, input[aria-invalid=true][type=text].wpcf7-form-control:focus-within,
input[aria-invalid=true][type=email].wpcf7-form-control:focus-within,
input[aria-invalid=true][type=url].wpcf7-form-control:focus-within,
input[aria-invalid=true][type=tel].wpcf7-form-control:focus-within,
input[aria-invalid=true][type=number].wpcf7-form-control:focus-within,
input[aria-invalid=true][type=date].wpcf7-form-control:focus-within,
textarea[aria-invalid=true].wpcf7-form-control:focus-within, input[aria-invalid=true][type=select]:focus-within,
select[aria-invalid=true]:focus-within,
.form-control.form-control--error:focus,
input.form-control--error[type=text].wpcf7-form-control:focus,
input.form-control--error[type=email].wpcf7-form-control:focus,
input.form-control--error[type=url].wpcf7-form-control:focus,
input.form-control--error[type=tel].wpcf7-form-control:focus,
input.form-control--error[type=number].wpcf7-form-control:focus,
input.form-control--error[type=date].wpcf7-form-control:focus,
textarea.form-control--error.wpcf7-form-control:focus,
input.form-control--error[type=select]:focus,
select.form-control--error:focus,
.form-control.form-control--error:focus-within,
input.form-control--error[type=text].wpcf7-form-control:focus-within,
input.form-control--error[type=email].wpcf7-form-control:focus-within,
input.form-control--error[type=url].wpcf7-form-control:focus-within,
input.form-control--error[type=tel].wpcf7-form-control:focus-within,
input.form-control--error[type=number].wpcf7-form-control:focus-within,
input.form-control--error[type=date].wpcf7-form-control:focus-within,
textarea.form-control--error.wpcf7-form-control:focus-within,
input.form-control--error[type=select]:focus-within,
select.form-control--error:focus-within {
  box-shadow: inset 0 0 0 1px hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), 0), 0 0 0 2px var(--color-error), var(--shadow-sm);
}

/* legend */
.form-legend {
  font-weight: 700;
  color: var(--color-contrast-higher);
  line-height: 1.2;
  font-size: var(--text-md);
  margin-bottom: var(--space-md);
}

/* label */
.form-label, .wpcf7 label {
  display: inline-block;
  font-size: var(--text-sm);
  margin-bottom: var(--space-3xs);
}

/* -------------------------------- 

File#: _1_animated-headline
Title: Animated Headline
Descr: A collection of word replacement effects
Usage: codyhouse.co/license

-------------------------------- */
.text-anim__wrapper {
  position: relative;
  display: inline-block;
}

.text-anim__word {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: inline-block;
  white-space: nowrap;
}

.text-anim__word--in {
  position: relative;
  z-index: 2;
}

.text-anim--rotate {
  --text-anim-duration: 0.85s;
  --text-anim-pause: 2.5s;
}
.text-anim--rotate .text-anim__wrapper {
  perspective: 300px;
}
.text-anim--rotate .text-anim__word {
  opacity: 0;
  backface-visibility: hidden;
  transform-origin: 50% 100%;
  transform: rotateX(-180deg);
  transition: opacity calc(var(--text-anim-duration) / 3) var(--ease-out), transform var(--text-anim-duration) var(--ease-out-back);
}
.text-anim--rotate .text-anim__word--in {
  opacity: 1;
  transform: rotateX(0deg);
}
.text-anim--rotate .text-anim__word--out {
  opacity: 0;
  transform: rotateX(180deg);
  transition: opacity calc(var(--text-anim-duration) / 6) var(--ease-out), transform var(--text-anim-duration) var(--ease-out-back);
}

.text-anim--loader {
  --text-anim-duration: 2.5s;
  --text-anim-pause: 0s;
}
.text-anim--loader .text-anim__word {
  color: transparent;
  transition: color 0.2s;
}
.text-anim--loader .text-anim__word::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 100%;
  background-color: currentColor;
  transform-origin: left center;
  transform: translateY(100%) scaleX(0);
  transition: transform var(--text-anim-duration) var(--ease-out);
}
.text-anim--loader .text-anim__word--in {
  color: currentColor;
}
.text-anim--loader .text-anim__word--in::after {
  transform: translateY(100%) scaleX(1);
}
.text-anim--loader .text-anim__word--out {
  color: transparent;
}
.text-anim--loader .text-anim__word--out::after {
  transition: none;
  transform: translateY(100%) scaleX(0);
}

.text-anim--slide {
  --text-anim-duration: 0.5s;
  --text-anim-pause: 2.5s;
}
.text-anim--slide .text-anim__wrapper {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  padding: 0.1em 0;
}
.text-anim--slide .text-anim__word {
  height: 100%;
  transform: translateY(-100%);
}
.text-anim--slide .text-anim__word--in,
.text-anim--slide .text-anim__word--out {
  animation-duration: var(--text-anim-duration);
  animation-timing-function: var(--ease-out-back);
}
.text-anim--slide .text-anim__word--in {
  animation-name: text-anim-slide-in;
  animation-fill-mode: forwards;
}
.text-anim--slide .text-anim__word--out {
  animation-name: text-anim-slide-out;
}

@keyframes text-anim-slide-in {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes text-anim-slide-out {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
.text-anim--zoom {
  --text-anim-duration: 0.85s;
  --text-anim-pause: 2.5s;
}
.text-anim--zoom .text-anim__wrapper {
  perspective: 300px;
}
.text-anim--zoom .text-anim__word {
  opacity: 0;
  transform: translateZ(100px);
  transition: opacity var(--text-anim-duration), transform var(--text-anim-duration) var(--ease-out);
}
.text-anim--zoom .text-anim__word--in {
  opacity: 1;
  transform: translateZ(0);
}
.text-anim--zoom .text-anim__word--out {
  opacity: 0;
  transform: translateZ(-100px);
}

.text-anim--push {
  --text-anim-duration: 0.65s;
  --text-anim-pause: 2.5s;
}
.text-anim--push .text-anim__word {
  opacity: 0;
  transform: translateX(-100px);
  transition: none;
}
.text-anim--push .text-anim__word--in,
.text-anim--push .text-anim__word--out {
  transition: opacity var(--text-anim-duration), transform var(--text-anim-duration) var(--ease-out-back);
}
.text-anim--push .text-anim__word--in {
  opacity: 1;
  transform: translateX(0);
}
.text-anim--push .text-anim__word--out {
  opacity: 0;
  transform: translateX(100px);
}

.text-anim--clip {
  --text-anim-duration: 0.7s;
  --text-anim-pause: 2s;
  --text-anim-border-width: 2px;
}
.text-anim--clip .text-anim__wrapper {
  overflow: hidden;
  vertical-align: top;
}
.text-anim--clip .text-anim__wrapper::after {
  content: "";
  position: absolute;
  right: 0;
  top: calc(50% - 0.7em);
  height: 1.4em;
  width: var(--text-anim-border-width);
  background-color: var(--color-accent);
}
.text-anim--clip .text-anim__wrapper--pulse::after {
  animation: text-anim-pulse 0.8s infinite;
}
.text-anim--clip .text-anim__word {
  opacity: 0;
  padding-right: var(--text-anim-border-width);
}
.text-anim--clip .text-anim__word--in {
  opacity: 1;
}

@keyframes text-anim-pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
/* -------------------------------- 

File#: _1_details
Title: Details
Descr: A button that toggles the visibility of additional information
Usage: codyhouse.co/license

-------------------------------- */
.details__summary {
  display: inline-block;
  cursor: pointer;
  user-select: none;
}
.details__summary:hover {
  color: var(--color-primary);
}
.details__summary:focus {
  outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  outline-offset: 4px;
}
.details__summary .icon {
  transition: transform 0.2s;
  flex-shrink: 0;
}

.details__summary {
  list-style: none;
}

.details__summary::-webkit-details-marker {
  display: none;
}

.details__summary[aria-expanded=true] .icon {
  transform: rotate(90deg);
}

.details__content[aria-hidden=true] {
  display: none;
}

/* -------------------------------- 

File#: _1_card
Title: Card
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */
.card {
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-ring), var(--shadow-sm);
  border-radius: var(--radius-md);
  overflow: hidden;
}

.card__img-wrapper img {
  display: block;
  width: 100%;
}

.card--link {
  text-decoration: none;
  display: block;
  color: inherit;
  transition: 0.2s;
}
.card--link:hover {
  background-color: var(--color-bg-lighter);
  box-shadow: var(--inner-glow), var(--shadow-ring), var(--shadow-md);
}
.card--link:hover .card__img-wrapper::after {
  opacity: 1;
}
.card--link .card__img-wrapper {
  position: relative;
}
.card--link .card__img-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.6);
  opacity: 0;
  transition: opacity 0.2s;
}

.card__link-icon-wrapper {
  position: absolute;
  z-index: 1;
  width: 64px;
  height: 64px;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
  opacity: 0;
  transition: 0.2s;
}
.card__link-icon-wrapper .icon {
  display: block;
  width: 32px;
  height: 32px;
  color: var(--color-black);
}

.card--link:hover .card__link-icon-wrapper {
  opacity: 1;
  transform: scale(1);
}

/* -------------------------------- 

File#: _1_custom-checkbox
Title: Custom Checkbox
Descr: Replace the native checkbox button with a custom element (e.g., an icon)
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --custom-checkbox-size: 20px;
  --custom-checkbox-radius: 4px;
  --custom-checkbox-border-width: 1px;
  --custom-checkbox-marker-size: 18px;
}

.custom-checkbox {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: var(--custom-checkbox-size);
}

.custom-checkbox__input {
  position: relative;
  /* hide native input */
  margin: 0;
  padding: 0;
  opacity: 0;
  height: 1em;
  width: 1em;
  display: block;
  z-index: 1;
}

.custom-checkbox__control {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  transition: transform 0.2s;
  color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), 0.65); /* unchecked color */
}
.custom-checkbox__control::before, .custom-checkbox__control::after {
  content: "";
  position: absolute;
}
.custom-checkbox__control::before { /* focus circle */
  width: 160%;
  height: 160%;
  background-color: currentColor;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  border-radius: 50%;
  will-change: transform;
  transition: transform 0.2s;
}
.custom-checkbox__control::after { /* custom checkbox */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* custom checkbox style */
  background-color: var(--color-bg);
  border-radius: var(--custom-checkbox-radius);
  box-shadow: inset 0 0 0 var(--custom-checkbox-border-width) currentColor, var(--shadow-xs); /* border */
}

.custom-checkbox__input:checked ~ .custom-checkbox__control::after,
.custom-checkbox__input:indeterminate ~ .custom-checkbox__control::after {
  background-color: currentColor;
  background-repeat: no-repeat;
  background-position: center;
  background-size: var(--custom-checkbox-marker-size);
  box-shadow: none;
}

.custom-checkbox__input:checked ~ .custom-checkbox__control {
  color: var(--color-primary); /* checked color */
}
.custom-checkbox__input:checked ~ .custom-checkbox__control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpolyline points='2.5 8 6.5 12 13.5 3' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
}

.custom-checkbox__input:indeterminate ~ .custom-checkbox__control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cline x1='2' y1='8' x2='14' y2='8' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
}

.custom-checkbox__input:active ~ .custom-checkbox__control {
  transform: scale(0.9);
}

.custom-checkbox__input:checked:active ~ .custom-checkbox__control,
.custom-checkbox__input:indeterminate:active ~ .custom-checkbox__control {
  transform: scale(1);
}

.custom-checkbox__input:focus ~ .custom-checkbox__control::before {
  opacity: 0.2;
  transform: translate(-50%, -50%) scale(1);
}

/* --icon */
.custom-checkbox--icon {
  --custom-checkbox-size: 32px;
}
.custom-checkbox--icon .custom-checkbox__control::after {
  display: none;
}
.custom-checkbox--icon .icon {
  display: block;
  color: inherit;
  position: relative;
  z-index: 1;
}

/* -------------------------------- 

File#: _1_custom-select
Title: Custom Select
Descr: Custom Select Control
Usage: codyhouse.co/license

-------------------------------- */
:root {
  /* --default variation only 👇 */
  --select-icon-size: 16px;
  --select-icon-right-margin: var(--space-sm); /* icon margin right */
  --select-text-icon-gap: var(--space-3xs); /* gap between text and icon */
}

.select {
  position: relative;
}

.select__input {
  width: 100%;
  height: 100%;
  padding-right: calc(var(--select-icon-size) + var(--select-icon-right-margin) + var(--select-text-icon-gap)) !important;
  user-select: none;
}

.select__icon {
  width: var(--select-icon-size);
  height: var(--select-icon-size);
  pointer-events: none;
  position: absolute;
  right: var(--select-icon-right-margin);
  top: 50%;
  transform: translateY(-50%);
}

/* --custom-dropdown */
:root {
  --select-dropdown-gap: 4px; /* distance between select control and custom dropdown */
}

.select__button { /* created in JS - custom select control */
  width: 100%;
}

.select__button[aria-expanded=true] {
  /* custom select control if dropdown = visible */
}

.select__dropdown { /* created in JS - custom select dropdown */
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 200px;
  max-height: 1px; /* updated in JS */
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-md);
  padding: var(--space-3xs) 0;
  border-radius: var(--radius-md);
  z-index: var(--z-index-popover, 5);
  margin-top: var(--select-dropdown-gap);
  margin-bottom: var(--select-dropdown-gap);
  overflow: auto;
  font-size: 1rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s;
}

.select__dropdown--right { /* change dropdown position based on the available space */
  right: 0;
  left: auto;
}

.select__dropdown--up {
  bottom: 100%;
  top: auto;
}

.select__button[aria-expanded=true] + .select__dropdown {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s;
}

/* custom <optgroup> list - include all <option>s if no <optgroup> available  */
.select__list {
  list-style: none !important;
}

.select__list:not(:first-of-type) {
  padding-top: var(--space-2xs);
}

.select__list:not(:last-of-type) {
  border-bottom: 1px solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
  padding-bottom: var(--space-2xs);
}

.select__item { /* single item inside .select__list */
  display: flex;
  align-items: center;
  padding: var(--space-2xs) var(--space-sm);
  color: var(--color-contrast-high);
  width: 100%;
  text-align: left;
  /* truncate text */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__item--optgroup { /* custom <optgroup> label */
  font-size: 0.8125rem;
  color: var(--color-contrast-medium);
}

.select__item--option { /* custom <option> label */
  cursor: pointer;
  transition: 0.2s;
}
.select__item--option:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.075);
}
.select__item--option:focus {
  outline: none;
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.15);
}
.select__item--option[aria-selected=true] { /* selected option */
  background-color: var(--color-primary);
  color: var(--color-white);
  position: relative;
}
.select__item--option[aria-selected=true]::after { /* check icon next to the selected language */
  content: "";
  display: block;
  height: 1em;
  width: 1em;
  margin-left: auto;
  background-color: currentColor;
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpolyline stroke-width='2' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round' points='1,9 5,13 15,3 '/%3E%3C/svg%3E");
}
.select__item--option[aria-selected=true]:focus {
  box-shadow: inset 0 0 0 2px var(--color-primary-dark);
}
.select__item--option[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

/* --------------------------------

File#: _1_floating-action-button
Title: Floating Action Button
Descr: A floating button representing the primary page action
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --fab-transition-duration: .2s; /* transition duration */
  --fab-gap-y: var(--space-md); /* top/bottom gap between button and viewport - horizontal gap depends on the max-width utility class */
  --fab-btn-size: 48px; /* button size */
  --fab-btn-radius: calc(var(--fab-btn-size)/2); /* button border-radius - use px units */
  --fab-btn-icon-size: 24px; /* icon size */
  --fab-popover-radius: 8px; /* popover border-radius - use px units */
  --fab-popover-width: 250px; /* popover width - from the @xs breakpoint ↓ */
  --fab-popover-gap: 6px; /* gap between popover and button */
}
@media (min-width: 64rem) {
  :root {
    --fab-btn-size: 54px;
  }
}

.fab {
  position: fixed;
  z-index: var(--z-index-overlay, 15);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: background var(--fab-transition-duration), opacity var(--fab-transition-duration), visibility 0s var(--fab-transition-duration);
}

.fab--in {
  opacity: 1;
  visibility: visible;
  transition: background var(--fab-transition-duration), opacity var(--fab-transition-duration);
}

.fab--bottom-right .fab__btn, .fab--bottom-right .fab__popover,
.fab--bottom-left .fab__btn,
.fab--bottom-left .fab__popover {
  bottom: var(--fab-gap-y);
  bottom: calc(var(--fab-gap-y) + env(safe-area-inset-bottom)); /* fixes issue on iOS */
}

.fab--bottom-right .fab__popover-inner {
  clip-path: inset(calc(var(--fab-popover-height) - var(--fab-btn-size)) 0 0 calc(100% - var(--fab-btn-size)) round var(--fab-btn-radius));
}
@media (min-width: 32rem) {
  .fab--bottom-right .fab__popover-inner {
    clip-path: inset(calc(var(--fab-popover-height) - var(--fab-btn-size)) 0 0 calc(var(--fab-popover-width) - var(--fab-btn-size)) round var(--fab-btn-radius));
  }
}

.fab--bottom-left .fab__popover-inner {
  clip-path: inset(calc(var(--fab-popover-height) - var(--fab-btn-size)) calc(100% - var(--fab-btn-size)) 0 0 round var(--fab-btn-radius));
}
@media (min-width: 32rem) {
  .fab--bottom-left .fab__popover-inner {
    clip-path: inset(calc(var(--fab-popover-height) - var(--fab-btn-size)) calc(var(--fab-popover-width) - var(--fab-btn-size)) 0 0 round var(--fab-btn-radius));
  }
}

.fab--bottom-right .fab__btn, .fab--bottom-right .fab__popover,
.fab--top-right .fab__btn,
.fab--top-right .fab__popover {
  right: 0;
}

.fab--bottom-left .fab__btn, .fab--bottom-left .fab__popover,
.fab--top-left .fab__btn,
.fab--top-left .fab__popover {
  left: 0;
}

.fab--top-right .fab__btn, .fab--top-right .fab__popover,
.fab--top-left .fab__btn,
.fab--top-left .fab__popover {
  top: var(--fab-gap-y);
}

.fab--top-right .fab__popover-inner {
  clip-path: inset(0 0 calc(var(--fab-popover-height) - var(--fab-btn-size)) calc(100% - var(--fab-btn-size)) round var(--fab-btn-radius));
}
@media (min-width: 32rem) {
  .fab--top-right .fab__popover-inner {
    clip-path: inset(0 0 calc(var(--fab-popover-height) - var(--fab-btn-size)) calc(var(--fab-popover-width) - var(--fab-btn-size)) round var(--fab-btn-radius));
  }
}

.fab--top-left .fab__popover-inner {
  clip-path: inset(0 calc(100% - var(--fab-btn-size)) calc(var(--fab-popover-height) - var(--fab-btn-size)) 0 round var(--fab-btn-radius));
}
@media (min-width: 32rem) {
  .fab--top-left .fab__popover-inner {
    clip-path: inset(0 calc(var(--fab-popover-width) - var(--fab-btn-size)) calc(var(--fab-popover-height) - var(--fab-btn-size)) 0 round var(--fab-btn-radius));
  }
}

/* button */
.fab__btn {
  display: block;
  position: relative;
  pointer-events: auto;
  width: var(--fab-btn-size);
  height: var(--fab-btn-size);
  background-color: var(--color-primary);
  box-shadow: var(--inner-glow-top), var(--shadow-sm);
  border-radius: var(--fab-btn-radius);
  overflow: hidden;
  touch-action: manipulation;
  position: absolute;
  z-index: 2;
  transform: translateY(20px);
  transition: box-shadow var(--fab-transition-duration), background var(--fab-transition-duration), transform var(--fab-transition-duration);
}
.fab__btn:hover {
  background-color: var(--color-primary-light);
  box-shadow: var(--shadow-md);
}
.fab__btn:active {
  transform: translateY(2px);
}
.fab--in .fab__btn {
  transform: translateY(0);
}

.fab__icon-wrapper {
  display: flex;
  height: inherit;
  width: inherit;
  justify-content: center;
  align-items: center;
  transition: transform var(--fab-transition-duration) var(--ease-out);
}
.fab__icon-wrapper:last-child {
  position: absolute;
  top: 0;
  transform: translateY(100%);
}
.fab__icon-wrapper:last-child .fab__icon {
  transform: rotate(-45deg);
  opacity: 0;
}
.fab--active .fab__icon-wrapper {
  transform: translateY(-100%);
}
.fab--active .fab__icon-wrapper .fab__icon {
  opacity: 0;
}
.fab--active .fab__icon-wrapper:last-child {
  transform: translateY(0);
}
.fab--active .fab__icon-wrapper:last-child .fab__icon {
  transform: rotate(0);
  opacity: 1;
}

.fab__icon {
  display: block;
  height: var(--fab-btn-icon-size);
  width: var(--fab-btn-icon-size);
  margin: auto;
  color: var(--color-white); /* icon color */
  transition: transform var(--fab-transition-duration) var(--ease-in-out), opacity var(--fab-transition-duration);
}

/* popover */
.fab__popover {
  position: absolute;
  z-index: 1;
  filter: drop-shadow(0 2px 5px hsla(0, 0%, 0%, 0.2));
  width: 100%; /* full-width on smaller devices */
}
@media (min-width: 32rem) {
  .fab__popover {
    width: var(--fab-popover-width);
  }
}

.fab__popover-inner {
  max-height: calc(100vh - var(--fab-gap-y) * 2 - var(--fab-btn-size));
  overflow: auto;
  background-color: var(--color-bg);
  visibility: hidden;
  transform: translateY(0);
  transition: clip-path var(--fab-transition-duration) var(--ease-out), transform var(--fab-transition-duration) var(--ease-out), visibility 0s var(--fab-transition-duration);
}

.fab__content {
  opacity: 0;
  transition: opacity var(--fab-transition-duration), transform var(--fab-transition-duration);
}

/* --active */
.fab--active {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.9);
  pointer-events: auto;
}
.fab--active .fab__popover-inner {
  visibility: visible;
  transform: translateY(calc(-1 * var(--fab-btn-size) - var(--fab-popover-gap)));
  clip-path: inset(0 0 0 0 round var(--fab-popover-radius));
  transition: clip-path var(--fab-transition-duration) var(--ease-out), transform var(--fab-transition-duration) var(--ease-out), visibility 0s;
}
.fab--active.fab--top-left .fab__popover-inner, .fab--active.fab--top-right .fab__popover-inner {
  transform: translateY(calc(var(--fab-btn-size) + var(--fab-popover-gap)));
}
.fab--active .fab__content {
  opacity: 1;
}

/* menu example */
.fab--has-demo-menu {
  --fab-popover-width: 300px;
}

.fab__menu {
  list-style: none;
  padding: var(--space-3xs);
}

.fab__menu-btn {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--space-2xs);
  border-radius: var(--radius-md);
  cursor: pointer;
  line-height: 1;
  color: inherit;
  text-decoration: none;
}
.fab__menu-btn::before, .fab__menu-btn::after {
  content: "";
  position: absolute;
  z-index: 1;
}
.fab__menu-btn::before {
  inset: 0;
  border-radius: inherit;
  background-color: var(--color-bg-dark);
  opacity: 0;
  transform: scale(0.75);
  transition: 0.1s;
}
.fab__menu-btn::after {
  width: calc(100% - var(--space-2xs) * 2);
  height: 1px;
  left: var(--space-2xs);
  top: 100%;
  background: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.075);
}
.fab__menu-btn:hover::before {
  opacity: 1;
  transform: scale(1);
}
.fab__menu-btn:hover::after {
  display: none;
}
.fab__menu-btn:focus-visible {
  outline: none;
}
.fab__menu-btn:focus-visible::before {
  opacity: 1;
  transform: scale(1);
  box-shadow: inset 0 0 0 2px var(--color-primary);
  transition: none;
  background-color: var(--color-bg);
}
.fab__menu-item:nth-last-child(2) .fab__menu-btn::after {
  display: none;
}

.fab__menu-img {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
}

.fab__menu-title {
  font-weight: 600;
  color: var(--color-contrast-higher);
  font-size: var(--text-sm);
}

.fab__menu-time, .fab__menu-preview, .fab__menu-unread-counter {
  font-size: var(--text-xs);
}

.fab__menu-time, .fab__menu-preview {
  color: var(--color-contrast-medium);
}

.fab__menu-time {
  text-align: right;
}

.fab__menu-unread-counter {
  width: 18px;
  height: 18px;
  background-color: var(--color-accent);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  box-shadow: var(--inner-glow), 0 1px 3px hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), 0.35);
}

.fab__menu-cta {
  display: flex;
  align-items: center;
  gap: var(--space-2xs);
  justify-content: center;
  font-size: var(--text-sm);
  background: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  color: var(--color-primary);
  font-weight: 600;
  text-decoration: none;
  padding: var(--space-sm) 0;
  border-radius: var(--radius-md);
  line-height: 1;
}
.fab__menu-cta:hover {
  background: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.15);
}
.fab__menu-cta:focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--color-primary);
}

.fab__menu-item:last-child {
  margin-top: var(--space-3xs);
}

/* -------------------------------- 

File#: _1_link-effects
Title: Link Effects
Descr: A collection of link effects
Usage: codyhouse.co/license

-------------------------------- */
.link-fx-1 {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  padding: 0 6px;
  text-decoration: none;
}
.link-fx-1 .icon {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateX(100%) rotate(90deg);
  font-size: 32px;
}
.link-fx-1 .icon circle {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  transition: stroke-dashoffset 0.2s;
}
.link-fx-1 .icon line {
  transition: transform 0.4s;
  transform-origin: 13px 15px;
}
.link-fx-1 .icon line:last-child {
  transform-origin: 19px 15px;
}
.link-fx-1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-accent);
  transform-origin: right center;
  transition: transform 0.2s 0.1s;
}
.link-fx-1:hover .icon circle {
  stroke-dashoffset: 200;
  transition: stroke-dashoffset 0.2s 0.1s;
}
.link-fx-1:hover .icon line {
  transform: rotate(-180deg);
}
.link-fx-1:hover .icon line:last-child {
  transform: rotate(180deg);
}
.link-fx-1:hover::before {
  transform: translateX(17px) scaleX(0);
  transition: transform 0.2s;
}

.link-fx-2 {
  position: relative;
  display: inline-block;
  padding: 4px 6px;
  color: var(--color-contrast-higher);
  text-decoration: none;
  background-image: linear-gradient(var(--color-contrast-higher), var(--color-contrast-higher));
  background-size: 100% 1px;
  background-repeat: repeat-x;
  background-position: left bottom;
  will-change: background-size;
  transition: background-size 0.3s var(--ease-out);
}
.link-fx-2::after {
  content: attr(data-link-fx-clone);
  color: var(--color-bg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: inherit;
  will-change: clip-path, transform;
  clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  transform: translateY(4px);
  transition: clip-path 0.3s, transform 0.3s;
  transition-timing-function: var(--ease-out);
}
.link-fx-2:hover {
  background-size: 100% 100%;
}
.link-fx-2:hover::after {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  transform: translateY(0px);
}

.link-fx-3 {
  position: relative;
  display: inline-flex;
  padding: 4px 6px;
  text-decoration: none;
  align-items: center;
}
.link-fx-3 .icon {
  display: block;
  font-size: 12px;
  margin-left: 8px;
  position: relative;
  opacity: 0;
  visibility: hidden;
  will-change: transform;
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.3s var(--ease-out), visibility 0s 0.3s;
}
.link-fx-3 .icon line {
  transform-origin: 9px 6px;
  will-change: transform;
  transition: transform 0.3s var(--ease-out);
}
.link-fx-3 .icon line:first-child {
  transform: rotate(20deg);
}
.link-fx-3 .icon line:last-child {
  transform: rotate(-20deg);
}
.link-fx-3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 18px);
  height: 1px;
  background-color: currentColor;
  transition: 0.3s var(--ease-out);
}
.link-fx-3:hover .icon {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.3s var(--ease-out);
}
.link-fx-3:hover .icon line:first-child, .link-fx-3:hover .icon line:last-child {
  transform: rotate(0);
}
.link-fx-3:hover::before {
  width: 100%;
}

.link-fx-4 {
  position: relative;
  display: inline-block;
  padding: 6px 10px;
  background-color: var(--color-contrast-lower);
  color: var(--color-contrast-higher);
  text-decoration: none;
}
.link-fx-4::before {
  content: attr(data-link-fx-clone);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
  will-change: clip-path;
  clip-path: polygon(0% 0%, 1px 0%, 1px 100%, 0% 100%);
  transition: clip-path 0.3s var(--ease-out);
}
.link-fx-4:hover::before {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.link-fx-5 {
  display: inline-block;
  padding: 4px 0;
  text-decoration: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4' viewBox='0 0 16 4'%3E%3Cpath d='M0,3.5c4,0,4-3,8-3s4,3,8,3' fill='none' stroke='%232a6df4' opacity='0.5' stroke-miterlimit='10'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-position: 0px 100%;
  background-size: 0;
}
.link-fx-5:hover {
  background-size: 16px 4px;
  animation: link-fx-5-animation 0.4s linear infinite;
}

@keyframes link-fx-5-animation {
  from {
    background-position: 0px 100%;
  }
  to {
    background-position: 16px 100%;
  }
}
/* -------------------------------- 

File#: _1_list
Title: List
Descr: Custom list component
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --list-space-y: 0.375em;
  --list-offset: 1em;
  --list-line-height-multiplier: 1;
}

.list, .text-component .list {
  padding-left: 0;
  list-style: none;
}
.list ul, .list ol, .text-component .list ul, .text-component .list ol {
  list-style: none;
  margin: 0;
  margin-top: calc(var(--list-space-y) / 2);
  padding-top: calc(var(--list-space-y) / 2);
  padding-left: var(--list-offset);
}
.list li, .text-component .list li {
  padding-bottom: calc(var(--list-space-y) / 2);
  margin-bottom: calc(var(--list-space-y) / 2);
  line-height: calc(1.4 * var(--list-line-height-multiplier));
}
.list > li:last-child, .list ul > li:last-child, .list ol > li:last-child, .text-component .list > li:last-child, .text-component .list ul > li:last-child, .text-component .list ol > li:last-child {
  margin-bottom: 0;
}
.list:not(.list--border) > li:last-child, .list ul > li:last-child, .list ol > li:last-child, .text-component .list:not(.list--border) > li:last-child, .text-component .list ul > li:last-child, .text-component .list ol > li:last-child {
  padding-bottom: 0;
}

/* #region (ul + ol) */
.list--ul, .text-component .list--ul,
.list--ol, .text-component .list--ol {
  --list-offset: calc(var(--list-bullet-size) + var(--list-bullet-margin-right));
}
.list--ul ul, .list--ul ol, .text-component .list--ul ul, .text-component .list--ul ol,
.list--ol ul,
.list--ol ol, .text-component .list--ol ul, .text-component .list--ol ol {
  padding-left: 0;
}
@supports (--css: variables) {
  .list--ul li, .text-component .list--ul li,
  .list--ol li, .text-component .list--ol li {
    padding-left: var(--list-offset) !important;
  }
}
.list--ul li::before, .text-component .list--ul li::before,
.list--ol li::before, .text-component .list--ol li::before {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  position: relative;
  top: -0.1em;
}
@supports (--css: variables) {
  .list--ul li::before, .text-component .list--ul li::before,
  .list--ol li::before, .text-component .list--ol li::before {
    width: var(--list-bullet-size) !important;
    height: var(--list-bullet-size) !important;
    margin-left: calc(var(--list-bullet-size) * -1) !important;
    left: calc(var(--list-bullet-margin-right) * -1) !important;
  }
}

.list--ul, .text-component .list--ul {
  --list-bullet-size: 7px;
  --list-bullet-margin-right: 12px;
}
.list--ul > li, .text-component .list--ul > li {
  padding-left: 19px;
}
.list--ul > li::before, .text-component .list--ul > li::before {
  content: "";
  border-radius: 50%;
  color: var(--color-contrast-lower);
  background-color: currentColor;
  width: 7px;
  height: 7px;
  margin-left: -7px;
  left: -12px;
}
.list--ul ul li::before, .text-component .list--ul ul li::before {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px currentColor;
}

.list--ol, .text-component .list--ol {
  --list-bullet-size: 26px;
  --list-bullet-margin-right: 6px;
  --list-bullet-font-size: 14px;
  counter-reset: list-items;
}
.list--ol > li, .text-component .list--ol > li {
  counter-increment: list-items;
  padding-left: 32px;
}
.list--ol ol, .text-component .list--ol ol {
  counter-reset: list-items;
}
.list--ol > li::before, .text-component .list--ol > li::before {
  content: counter(list-items);
  font-size: var(--list-bullet-font-size, 14px);
  background-color: var(--color-contrast-lower);
  color: var(--color-contrast-high);
  line-height: 1;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin-left: -26px;
  left: -6px;
}
.list--ol ol > li::before, .text-component .list--ol ol > li::before {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--color-contrast-lower);
}

/* #endregion */
/* #region (border) */
.list--border li:not(:last-child), .text-component .list--border li:not(:last-child) {
  border-bottom: 1px solid var(--color-contrast-lower);
}
.list--border ul, .list--border ol, .text-component .list--border ul, .text-component .list--border ol {
  border-top: 1px solid var(--color-contrast-lower);
}

/* #endregion */
/* #region (icons) */
.list--icons, .text-component .list--icons {
  --list-bullet-size: 24px;
  --list-bullet-margin-right: 8px;
  --list-offset: calc(var(--list-bullet-size) + var(--list-bullet-margin-right));
}
.list--icons ul, .list--icons ol, .text-component .list--icons ul, .text-component .list--icons ol {
  padding-left: 32px;
}
@supports (--css: variables) {
  .list--icons ul, .list--icons ol, .text-component .list--icons ul, .text-component .list--icons ol {
    padding-left: var(--list-offset);
  }
}

.list__icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.list__icon:not(.top-0) {
  top: calc((1.4em - 24px) / 2);
}
@supports (--css: variables) {
  .list__icon {
    width: var(--list-bullet-size);
    height: var(--list-bullet-size);
    margin-right: var(--list-bullet-margin-right);
  }
  .list__icon:not(.top-0) {
    top: calc((1.4em * var(--list-line-height-multiplier) - var(--list-bullet-size)) / 2);
  }
}

/* #endregion */
/* -------------------------------- 

File#: _1_modal-window
Title: Modal Window
Descr: A modal dialog used to display critical information
Usage: codyhouse.co/license

-------------------------------- */
.modal {
  position: fixed;
  z-index: var(--z-index-overlay, 15);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.modal:not(.modal--is-visible) {
  pointer-events: none;
  background-color: transparent;
}

.modal--is-visible {
  opacity: 1;
  visibility: visible;
}

/* close buttons */
.modal__close-btn {
  display: flex;
  flex-shrink: 0;
  border-radius: 50%;
  transition: 0.2s;
}
.modal__close-btn .icon {
  display: block;
  margin: auto;
}

.modal__close-btn--outer { /* close button - outside the modal__content */
  --size: 48px;
  width: var(--size);
  height: var(--size);
  position: fixed;
  top: var(--space-sm);
  right: var(--space-sm);
  z-index: var(--z-index-fixed-element, 10);
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.9);
  transition: 0.2s;
}
.modal__close-btn--outer .icon {
  color: var(--color-white); /* icon color */
  transition: transform 0.3s var(--ease-out-back);
}
.modal__close-btn--outer:hover {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 1);
}
.modal__close-btn--outer:hover .icon {
  transform: scale(1.1);
}

.modal__close-btn--inner { /* close button - inside the modal__content */
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  transition: 0.2s;
}
.modal__close-btn--inner .icon {
  color: inherit; /* icon color */
}
.modal__close-btn--inner:hover {
  background-color: var(--color-bg-lighter);
  box-shadow: var(--inner-glow), var(--shadow-md);
}

/* animations */
:root {
  --modal-transition-duration: 0.2s; /* fallback (i.e., unless specified differently in the variations 👇) */
}

@media (prefers-reduced-motion: no-preference) {
  .modal--animate-fade {
    --modal-transition-duration: 0.2s;
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
  }
  .modal--animate-fade.modal--is-visible {
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s;
  }
  .modal--animate-scale,
  .modal--animate-translate-up,
  .modal--animate-translate-down,
  .modal--animate-translate-right,
  .modal--animate-translate-left {
    --modal-transition-duration: 0.2s;
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
  }
  .modal--animate-scale .modal__content,
  .modal--animate-translate-up .modal__content,
  .modal--animate-translate-down .modal__content,
  .modal--animate-translate-right .modal__content,
  .modal--animate-translate-left .modal__content {
    will-change: transform;
    transition: transform var(--modal-transition-duration) var(--ease-out);
  }
  .modal--animate-scale.modal--is-visible,
  .modal--animate-translate-up.modal--is-visible,
  .modal--animate-translate-down.modal--is-visible,
  .modal--animate-translate-right.modal--is-visible,
  .modal--animate-translate-left.modal--is-visible {
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s;
  }
  .modal--animate-scale.modal--is-visible .modal__content,
  .modal--animate-translate-up.modal--is-visible .modal__content,
  .modal--animate-translate-down.modal--is-visible .modal__content,
  .modal--animate-translate-right.modal--is-visible .modal__content,
  .modal--animate-translate-left.modal--is-visible .modal__content {
    transform: scale(1); /* reset all transformations */
  }
  .modal--animate-slide-up,
  .modal--animate-slide-down,
  .modal--animate-slide-right,
  .modal--animate-slide-left {
    --modal-transition-duration: 0.3s;
    transition: opacity 0s var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
  }
  .modal--animate-slide-up .modal__content,
  .modal--animate-slide-down .modal__content,
  .modal--animate-slide-right .modal__content,
  .modal--animate-slide-left .modal__content {
    will-change: transform;
    transition: transform var(--modal-transition-duration) var(--ease-out);
  }
  .modal--animate-slide-up.modal--is-visible,
  .modal--animate-slide-down.modal--is-visible,
  .modal--animate-slide-right.modal--is-visible,
  .modal--animate-slide-left.modal--is-visible {
    transition: background-color var(--modal-transition-duration), visibility 0s;
  }
  .modal--animate-slide-up.modal--is-visible .modal__content,
  .modal--animate-slide-down.modal--is-visible .modal__content,
  .modal--animate-slide-right.modal--is-visible .modal__content,
  .modal--animate-slide-left.modal--is-visible .modal__content {
    transform: scale(1); /* reset all transformations */
  }
  /* scale */
  .modal--animate-scale .modal__content {
    transform: scale(0.95);
  }
  /* translate */
  .modal--animate-translate-up .modal__content {
    transform: translateY(40px);
  }
  .modal--animate-translate-down .modal__content {
    transform: translateY(-40px);
  }
  .modal--animate-translate-right .modal__content {
    transform: translateX(-40px);
  }
  .modal--animate-translate-left .modal__content {
    transform: translateX(40px);
  }
  /* slide */
  .modal--animate-slide-up .modal__content {
    transform: translateY(100%);
  }
  .modal--animate-slide-down .modal__content {
    transform: translateY(-100%);
  }
  .modal--animate-slide-right .modal__content {
    transform: translateX(-100%);
  }
  .modal--animate-slide-left .modal__content {
    transform: translateX(100%);
  }
}
/* load content - optional */
.modal--is-loading .modal__content {
  visibility: hidden;
}
.modal--is-loading .modal__loader {
  display: flex;
}

.modal__loader { /* loader icon */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
  pointer-events: none;
}

/* --image */
.modal-img-btn {
  position: relative;
  cursor: pointer;
}
.modal-img-btn::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0);
  transition: background 0.2s;
}
.modal-img-btn:hover::after {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.7);
}
.modal-img-btn:hover .modal-img-btn__icon-wrapper {
  opacity: 1;
}

.modal-img-btn__icon-wrapper {
  position: absolute;
  z-index: 2;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.7);
  opacity: 0;
  transition: opacity 0.2s;
}
.modal-img-btn__icon-wrapper .icon {
  color: var(--color-white);
}

/* -------------------------------- 

File#: _1_pagination
Title: Pagination 
Descr: Component used to navigate through pages of related content
Usage: codyhouse.co/license

-------------------------------- */
.pagination__list > li {
  display: inline-block;
}

.pagination--split .pagination__list {
  width: 100%;
}
.pagination--split .pagination__list > *:first-child {
  margin-right: auto;
}
.pagination--split .pagination__list > *:last-child {
  margin-left: auto;
}

.pagination__item {
  display: inline-block;
  display: inline-flex;
  height: 100%;
  align-items: center;
  padding: var(--space-xs) calc(1.355 * var(--space-xs));
  white-space: nowrap;
  line-height: 1;
  border-radius: var(--radius-md);
  text-decoration: none;
  color: var(--color-contrast-high);
  will-change: transform;
  transition: 0.2s;
}
.pagination__item:hover:not(.pagination__item--selected):not(.pagination__item--ellipsis) {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
}
.pagination__item:active {
  transform: translateY(2px);
}

.pagination__item--selected {
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
  box-shadow: var(--shadow-sm);
}

.pagination__item--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.pagination__jumper .form-control {
  width: 3em;
  margin-right: var(--space-xs);
}
.pagination__jumper em {
  flex-shrink: 0;
  white-space: nowrap;
}

/* -------------------------------- 

File#: _1_radios-checkboxes
Title: Radios and Checkboxes
Descr: Custom radio and checkbox buttons
Usage: codyhouse.co/license

-------------------------------- */
:root {
  /* radios + checkboxes */
  --checkbox-radio-size: 18px;
  --checkbox-radio-gap: var(--space-2xs); /* gap between button and label */
  --checkbox-radio-border-width: 1px;
  --checkbox-radio-line-height: 1.4;
  /* radios */
  --radio-marker-size: 8px;
  /* checkboxes */
  --checkbox-marker-size: 12px;
  --checkbox-radius: 4px;
}

body:not(.woocommerce-page) {
  /* hide native buttons */
  /* label */
  /* custom inputs - basic style */
  /* :hover */
  /* radio only style */
  /* checkbox only style */
  /* :checked */
  /* :active */
  /* :checked:active */
  /* radio button icon */
  /* checkbox button icon */
  /* :focus */
  /* --radio--bg, --checkbox--bg -> variation with background color */
}
body:not(.woocommerce-page) input {
  appearance: none;
}
body:not(.woocommerce-page) .radio,
body:not(.woocommerce-page) .checkbox {
  position: absolute;
  padding: 0;
  margin: 0;
  margin-top: calc((1em * var(--checkbox-radio-line-height) - var(--checkbox-radio-size)) / 2);
  opacity: 0;
  height: var(--checkbox-radio-size);
  width: var(--checkbox-radio-size);
  pointer-events: none;
}
body:not(.woocommerce-page) .radio + label,
body:not(.woocommerce-page) .checkbox + label {
  display: inline-block;
  line-height: var(--checkbox-radio-line-height);
  user-select: none;
  cursor: pointer;
  padding-left: calc(var(--checkbox-radio-size) + var(--checkbox-radio-gap));
}
body:not(.woocommerce-page) .radio + label::before,
body:not(.woocommerce-page) .checkbox + label::before {
  content: "";
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  top: -0.1em;
  margin-left: calc(-1 * (var(--checkbox-radio-size) + var(--checkbox-radio-gap)));
  flex-shrink: 0;
  width: var(--checkbox-radio-size);
  height: var(--checkbox-radio-size);
  background-color: var(--color-bg);
  border-width: var(--checkbox-radio-border-width);
  border-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), 0.65);
  border-style: solid;
  box-shadow: var(--shadow-xs);
  background-repeat: no-repeat;
  background-position: center;
  margin-right: var(--checkbox-radio-gap);
  transition: transform 0.2s, border 0.2s;
}
body:not(.woocommerce-page) .radio:not(:checked):not(:focus) + label:hover::before,
body:not(.woocommerce-page) .checkbox:not(:checked):not(:focus) + label:hover::before {
  border-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), 1);
}
body:not(.woocommerce-page) .radio + label::before {
  border-radius: 50%;
}
body:not(.woocommerce-page) .checkbox + label::before {
  border-radius: var(--checkbox-radius);
}
body:not(.woocommerce-page) .radio:checked + label::before,
body:not(.woocommerce-page) .checkbox:checked + label::before {
  background-color: var(--color-primary);
  box-shadow: var(--shadow-xs);
  border-color: var(--color-primary);
  transition: transform 0.2s;
}
body:not(.woocommerce-page) .radio:active + label::before,
body:not(.woocommerce-page) .checkbox:active + label::before {
  transform: scale(0.8);
  transition: transform 0.2s;
}
body:not(.woocommerce-page) .radio:checked:active + label::before,
body:not(.woocommerce-page) .checkbox:checked:active + label::before {
  transform: none;
  transition: none;
}
body:not(.woocommerce-page) .radio:checked + label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23ffffff'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  background-size: var(--radio-marker-size);
}
body:not(.woocommerce-page) .checkbox:checked + label::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpolyline points='1 6.5 4 9.5 11 2.5' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
  background-size: var(--checkbox-marker-size);
}
body:not(.woocommerce-page) .radio:checked:active + label::before,
body:not(.woocommerce-page) .checkbox:checked:active + label::before,
body:not(.woocommerce-page) .radio:focus + label::before,
body:not(.woocommerce-page) .checkbox:focus + label::before {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 3px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}
body:not(.woocommerce-page) .radio--bg + label, body:not(.woocommerce-page) .checkbox--bg + label {
  padding: var(--space-4xs) var(--space-3xs);
  padding-left: calc(var(--checkbox-radio-size) + var(--checkbox-radio-gap) + var(--space-3xs));
  border-radius: var(--radius-md);
  transition: background 0.2s;
}
body:not(.woocommerce-page) .radio--bg + label:hover, body:not(.woocommerce-page) .checkbox--bg + label:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.075);
}
body:not(.woocommerce-page) .radio--bg:active + label,
body:not(.woocommerce-page) .checkbox--bg:active + label,
body:not(.woocommerce-page) .radio--bg:focus + label,
body:not(.woocommerce-page) .checkbox--bg:focus + label {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.1);
}

/* -------------------------------- 

File#: _1_read-more
Title: Read More
Descr: A truncated paragraph with the option of reading more content
Usage: codyhouse.co/license

-------------------------------- */
.read-more {
  opacity: 0;
}

.read-more--loaded {
  opacity: 1;
}

.read-more__btn {
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: underline;
}

/* -------------------------------- 

File#: _1_reveal-effects
Title: Reveal Effects
Descr: A collection of reveal effects targeting specific elements as they enter the viewport
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --reveal-fx-duration: 0.6s;
  --reveal-fx-timing-function: var(--ease-out);
}

.reveal-fx {
  opacity: 0;
  transition: opacity, transform var(--reveal-fx-timing-function);
  transition-duration: var(--reveal-fx-duration);
}
.reveal-fx::before {
  display: none;
  content: "reveal-fx";
}

.reveal-fx--translate, .reveal-fx--translate-up {
  transform: translateY(50px);
}

.reveal-fx--translate-right {
  transform: translateX(-50px);
}

.reveal-fx--translate-left {
  transform: translateX(50px);
}

.reveal-fx--translate-down {
  transform: translateY(-50px);
}

.reveal-fx--scale {
  transform: scale(0.8);
}

.reveal-fx--scale-up {
  transform: translateY(50px) scale(0.8);
}

.reveal-fx--scale-right {
  transform: translateX(-50px) scale(0.8);
}

.reveal-fx--scale-left {
  transform: translateX(50px) scale(0.8);
}

.reveal-fx--scale-down {
  transform: translateY(-50px) scale(0.8);
}

.reveal-fx--rotate, .reveal-fx--rotate-down, .reveal-fx--rotate-right, .reveal-fx--rotate-left, .reveal-fx--rotate-up {
  perspective: 1000px;
}
.reveal-fx--rotate > *, .reveal-fx--rotate-down > *, .reveal-fx--rotate-right > *, .reveal-fx--rotate-left > *, .reveal-fx--rotate-up > * {
  transition: transform var(--reveal-fx-duration) var(--reveal-fx-timing-function);
  backface-visibility: hidden;
}

.reveal-fx--rotate > *, .reveal-fx--rotate-down > * {
  transform-origin: top;
  transform: rotateX(-45deg);
}

.reveal-fx--rotate-right > * {
  transform-origin: left center;
  transform: rotateY(45deg);
}

.reveal-fx--rotate-left > * {
  transform-origin: right center;
  transform: rotateY(-45deg);
}

.reveal-fx--rotate-up > * {
  transform-origin: bottom;
  transform: rotateX(45deg);
}

.reveal-fx--text-mask {
  overflow: hidden;
}
.reveal-fx--text-mask > * {
  display: inline-block;
  transform: translateY(100%);
  transition: transform var(--reveal-fx-duration) var(--reveal-fx-timing-function);
  will-change: transform;
}

[class*=reveal-fx--translate], [class*=reveal-fx--scale] {
  will-change: opacity, transform;
}

.reveal-fx--text-mask > *, [class*=reveal-fx--rotate] > * {
  will-change: transform;
}

.reveal-fx--clip-x > *,
.reveal-fx--clip-y > * {
  transition: opacity, clip-path var(--ease-out);
  transition-duration: var(--reveal-fx-duration);
}

.reveal-fx--clip-x > * {
  clip-path: polygon(10% 0%, 90% 0%, 90% 100%, 10% 100%);
}

.reveal-fx--clip-y > * {
  clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
}

.reveal-fx--is-visible {
  opacity: 1;
}
.reveal-fx--is-visible[class*=reveal-fx--translate], .reveal-fx--is-visible[class*=reveal-fx--scale], .reveal-fx--is-visible[class*=reveal-fx--rotate] > *, .reveal-fx--is-visible.reveal-fx--text-mask > * {
  transform: translate(0);
}
.reveal-fx--is-visible.reveal-fx--clip-x > *, .reveal-fx--is-visible.reveal-fx--clip-y > * {
  opacity: 1;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

/* -------------------------------- 

File#: _1_tabs
Title: Tabs
Descr: A list of content sections (panels), accessible one at a time using control labels
Usage: codyhouse.co/license

-------------------------------- */
.tabs__control {
  text-decoration: none;
  color: var(--color-contrast-medium);
}
.tabs__control:focus {
  outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  outline-offset: 2px;
}
.tabs__control:hover {
  color: var(--color-contrast-high);
}

.tabs__control[aria-selected=true] {
  color: var(--color-contrast-high);
  text-decoration: underline;
}

/* -------------------------------- 

File#: _2_carousel
Title: Carousel
Descr: Display and cycle through a collection of items
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --carousel-grid-gap: var(--space-xs);
  --carousel-item-auto-size: 260px;
  --carousel-transition-duration: 0.5s;
}

.carousel {
  position: relative;
}

.carousel__list {
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.carousel__item {
  flex-shrink: 0;
  width: var(--carousel-item-auto-size);
  margin-right: var(--carousel-grid-gap);
  margin-bottom: var(--carousel-grid-gap);
}

.carousel__list--animating {
  transition-property: transform;
  transition-duration: var(--carousel-transition-duration);
  transition-timing-function: var(--ease-out);
}

.carousel__item {
  opacity: 0;
  margin-bottom: 0;
}

.carousel--loaded .carousel__item {
  opacity: 1;
}

.carousel:not(.carousel--is-dragging) .carousel__list:not(.carousel__list--animating) .carousel__item[tabindex="-1"] > * {
  visibility: hidden;
}

.carousel[data-drag=on] .carousel__item {
  user-select: none;
}
.carousel[data-drag=on] .carousel__item img {
  pointer-events: none;
}

.carousel__control {
  --size: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  background-color: var(--color-bg-light);
  border-radius: 50%;
  box-shadow: var(--inner-glow), var(--shadow-sm);
  z-index: 1;
  transition: 0.2s;
}
.carousel__control:active {
  transform: translateY(1px);
}
.carousel__control:hover {
  background-color: var(--color-bg-lighter);
  box-shadow: var(--inner-glow), var(--shadow-md);
}
.carousel__control[disabled] {
  pointer-events: none;
  opacity: 0.5;
  box-shadow: none;
}
.carousel__control .icon {
  --size: 20px;
  display: block;
  margin: auto;
}

.carousel__navigation {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 10px);
  gap: var(--space-xs);
  justify-content: center;
  align-items: center;
  margin-top: var(--space-sm);
}

.carousel__nav-item {
  display: inline-block;
  margin: 0 var(--space-3xs);
}
@supports (grid-area: auto) {
  .carousel__nav-item {
    margin: 0;
  }
}
.carousel__nav-item button {
  display: block;
  position: relative;
  font-size: 10px;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  background-color: var(--color-contrast-high);
  opacity: 0.4;
  cursor: pointer;
  transition: background 0.3s;
}
.carousel__nav-item button::before {
  content: "";
  position: absolute;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  font-size: 16px;
  height: 1em;
  width: 1em;
  border-radius: inherit;
  border: 1px solid var(--color-contrast-high);
  opacity: 0;
  transform: scale(0);
  transition: 3s;
}
.carousel__nav-item button:focus {
  outline: none;
}
.carousel__nav-item button:focus::before {
  opacity: 1;
  transform: scale(1);
}

.carousel__nav-item--selected button {
  opacity: 1;
}

.carousel__navigation--pagination {
  grid-template-columns: repeat(auto-fit, 24px);
}
.carousel__navigation--pagination .carousel__nav-item button {
  width: 24px;
  height: 24px;
  color: var(--color-bg);
  font-size: 12px;
  line-height: 24px;
  border-radius: var(--radius-md);
  text-align: center;
}
.carousel__navigation--pagination .carousel__nav-item button:focus {
  outline: 1px solid var(--color-primary);
  outline-offset: 2px;
}

.carousel--hide-controls .carousel__navigation, .carousel--hide-controls .carousel__control {
  display: none;
}

/* -------------------------------- 

File#: _2_comments
Title: Comments
Descr: Threaded comments template
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --comments-author-img-size: 2.6em;
  --comments-author-content-gap: var(--space-xs);
  --comments-gap: var(--space-md);
}

.comments__sorting-label {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;
}
.comments__sorting-label + label {
  color: var(--color-contrast-medium);
  cursor: pointer;
  user-select: none;
}
.comments__sorting-label + label:hover {
  color: var(--color-contrast-high);
}
.comments__sorting-label:checked + label {
  color: var(--color-contrast-higher);
  text-decoration: underline;
}
.comments__sorting-label:focus + label {
  outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  outline-offset: 2px;
}

.comments__comment:not(:last-child) {
  margin-bottom: var(--comments-gap);
}

.comments__author-img {
  display: block;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  margin-right: var(--comments-author-content-gap);
  transition: opacity 0.2s;
}
.comments__author-img img {
  display: block;
  width: var(--comments-author-img-size);
  height: var(--comments-author-img-size);
}
.comments__author-img:hover {
  opacity: 0.75;
}

.comments__author-name {
  font-weight: bold;
  text-decoration: none;
  color: var(--color-contrast-higher);
}
.comments__author-name:hover {
  text-decoration: underline;
}

.comments__readmore-btn {
  color: var(--color-contrast-medium);
}
.comments__readmore-btn:hover {
  cursor: pointer;
  color: var(--color-contrast-higher);
  text-decoration: underline;
}

.comments__vote-btn {
  display: inline-flex;
  align-items: center;
  color: var(--color-contrast-medium);
}
.comments__vote-btn:hover {
  color: var(--color-contrast-higher);
  cursor: pointer;
}

.comments__vote-btn--pressed {
  color: var(--color-accent);
}
.comments__vote-btn--pressed:hover {
  color: var(--color-accent);
}
.comments__vote-btn--pressed .comments__vote-icon-wrapper .icon {
  animation: comments-vote-icon 0.3s;
}
.comments__vote-btn--pressed .comments__vote-icon-wrapper::before {
  animation: comments-vote-icon-circle 0.6s;
}

.comments__vote-icon-wrapper {
  position: relative;
}
.comments__vote-icon-wrapper .icon {
  position: relative;
  z-index: 2;
  transition: transform 0.3s;
}
.comments__vote-icon-wrapper::before {
  content: "";
  width: 2em;
  height: 2em;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: calc(50% - 1em);
  left: calc(50% - 1em);
  background-color: currentColor;
  opacity: 1;
  transform: scale(0);
  pointer-events: none;
}

@keyframes comments-vote-icon {
  0%, 100% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.8);
  }
}
@keyframes comments-vote-icon-circle {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(1);
  }
}
.comments__label-btn {
  color: var(--color-contrast-medium);
}
.comments__label-btn:hover {
  color: var(--color-contrast-higher);
  cursor: pointer;
  text-decoration: underline;
}

.comments__time {
  color: var(--color-contrast-medium);
}

.comments__inline-divider {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--color-contrast-low);
}

.comments__details {
  margin-top: var(--space-sm);
  margin-left: calc(var(--comments-author-img-size) + var(--comments-author-content-gap));
  padding-left: var(--comments-author-content-gap);
  border-left: 3px solid var(--color-contrast-lower);
}
.comments__details .details__content > ul {
  margin-top: var(--space-sm);
}

.comments--no-profile-img {
  --comments-author-img-size: 0;
}
.comments--no-profile-img .comments__author-img {
  display: none;
}

/* -------------------------------- 

File#: _2_dropdown
Title: Dropdown
Descr: A hoverable link that toggles the visibility of a dropdown list
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --dropdown-item-padding: var(--space-2xs) var(--space-sm);
}

.dropdown {
  position: relative;
}

.dropdown__menu {
  width: 200px;
  border-radius: var(--radius-md);
  padding: var(--space-3xs) 0;
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  z-index: var(--z-index-popover, 5);
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

@media (pointer: fine) {
  .dropdown__wrapper:hover > .dropdown__menu,
  .dropdown__sub-wrapper:hover > .dropdown__menu {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s;
  }
  .dropdown__sub-wrapper:hover > .dropdown__menu {
    left: 100%;
  }
}
@media not all and (pointer: fine) {
  .dropdown__trigger-icon {
    display: none;
  }
}
.dropdown__item {
  display: block;
  text-decoration: none;
  color: var(--color-contrast-high);
  padding: var(--dropdown-item-padding);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.2s;
}
.dropdown__item:hover, .dropdown__item.dropdown__item--hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.075);
}

.dropdown__separator {
  height: 1px;
  background-color: var(--color-contrast-lower);
  margin: var(--dropdown-item-padding);
}

.dropdown__sub-wrapper {
  position: relative;
}
.dropdown__sub-wrapper > .dropdown__item {
  position: relative;
  padding-right: calc(var(--space-sm) + 12px);
}
.dropdown__sub-wrapper > .dropdown__item .icon {
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  right: var(--space-2xs);
  top: calc(50% - 6px);
}
.dropdown__sub-wrapper > .dropdown__menu {
  top: calc(var(--space-2xs) * -1);
  box-shadow: var(--inner-glow), var(--shadow-md);
}

.dropdown__menu {
  top: calc(100% + 4px);
}

.dropdown__sub-wrapper .dropdown__menu {
  top: calc(var(--space-2xs) * -1);
}

@media (pointer: fine) {
  .dropdown__menu--is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s;
  }
}
.dropdown__menu--hide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

.dropdown__sub-wrapper > .dropdown__menu--is-visible,
.dropdown__sub-wrapper > .dropdown__menu--hide {
  left: 100%;
}
.dropdown__sub-wrapper > .dropdown__menu--is-visible.dropdown__menu--left,
.dropdown__sub-wrapper > .dropdown__menu--hide.dropdown__menu--left {
  left: -100%;
}

/* -------------------------------- 

File#: _2_slideshow
Title: Slideshow
Descr: Show a collection of items one at a time
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --slideshow-height: 280px;
  --slideshow-fade-transition-duration: 0.25s;
  --slideshow-slide-transition-duration: 0.35s;
  --slideshow-prx-transition-duration: 0.5s;
  --slideshow-btn-width: 1.6em;
  --slideshow-btn-height: 3.2em;
  --slideshow-btn-icon-size: 1.6em;
  --slideshow-btn-offset: var(--space-xs);
}
@media (min-width: 48rem) {
  :root {
    --slideshow-height: 380px;
  }
}
@media (min-width: 64rem) {
  :root {
    --slideshow-height: 480px;
  }
}
@media (min-width: 80rem) {
  :root {
    --slideshow-height: 580px;
  }
}

.slideshow__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slideshow-height);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.slideshow__item:focus {
  outline: none;
}

.slideshow--ratio-16\:9 .slideshow__item {
  height: 0;
  padding-bottom: 56.25%;
}

.slideshow--ratio-4\:3 .slideshow__item {
  height: 0;
  padding-bottom: 75%;
}

.slideshow--ratio-1\:1 .slideshow__item {
  height: 0;
  padding-bottom: 100%;
}

.slideshow {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.slideshow__content {
  overflow: hidden;
}

.slideshow__item {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
}

.slideshow__item--selected {
  position: relative;
  z-index: 3;
  visibility: visible;
}

.slideshow--transition-fade .slideshow__item {
  opacity: 0;
  transition: opacity 0s var(--slideshow-fade-transition-duration), visibility 0s var(--slideshow-fade-transition-duration);
}

.slideshow--transition-fade .slideshow__item--selected {
  opacity: 1;
  transition: opacity var(--slideshow-fade-transition-duration);
}

.slideshow--transition-slide .slideshow__item {
  animation-duration: var(--slideshow-slide-transition-duration);
  animation-fill-mode: forwards;
  animation-timing-function: var(--ease-out);
}
.slideshow--transition-slide .slideshow__item > * {
  visibility: hidden;
}

.slideshow--transition-slide .slideshow__item--selected > * {
  visibility: visible;
}

.slideshow--transition-slide .slideshow__item--slide-in-left {
  animation-name: slide-in-left;
}

.slideshow--transition-slide .slideshow__item--slide-in-right {
  animation-name: slide-in-right;
}

.slideshow--transition-slide .slideshow__item--slide-out-left {
  animation-name: slide-out-left;
}

.slideshow--transition-slide .slideshow__item--slide-out-right {
  animation-name: slide-out-right;
}

.slideshow--transition-slide .slideshow__item--slide-out-left,
.slideshow--transition-slide .slideshow__item--slide-out-right {
  z-index: 2;
}
.slideshow--transition-slide .slideshow__item--slide-out-left.slideshow__item--selected,
.slideshow--transition-slide .slideshow__item--slide-out-right.slideshow__item--selected {
  z-index: 3;
}
.slideshow--transition-slide .slideshow__item--slide-out-left > *,
.slideshow--transition-slide .slideshow__item--slide-out-right > * {
  visibility: visible;
}

@keyframes slide-in-left {
  0% {
    visibility: visible;
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-in-right {
  0% {
    visibility: visible;
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-out-left {
  0% {
    visibility: visible;
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes slide-out-right {
  0% {
    visibility: visible;
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.slideshow--transition-prx .slideshow__item {
  animation-duration: var(--slideshow-prx-transition-duration);
  animation-fill-mode: forwards;
  animation-timing-function: var(--ease-out);
}
.slideshow--transition-prx .slideshow__item > * {
  visibility: hidden;
}

.slideshow--transition-prx .slideshow__item--selected > * {
  visibility: visible;
}

.slideshow--transition-prx .slideshow__item--prx-in-left {
  animation-name: prx-in-left;
}

.slideshow--transition-prx .slideshow__item--prx-in-right {
  animation-name: prx-in-right;
}

.slideshow--transition-prx .slideshow__item--prx-out-left {
  animation-name: prx-out-left;
}

.slideshow--transition-prx .slideshow__item--prx-out-right {
  animation-name: prx-out-right;
}

.slideshow--transition-prx .slideshow__item--prx-out-left,
.slideshow--transition-prx .slideshow__item--prx-out-right {
  z-index: 2;
}
.slideshow--transition-prx .slideshow__item--prx-out-left.slideshow__item--selected,
.slideshow--transition-prx .slideshow__item--prx-out-right.slideshow__item--selected {
  z-index: 3;
}
.slideshow--transition-prx .slideshow__item--prx-out-left > *,
.slideshow--transition-prx .slideshow__item--prx-out-right > * {
  visibility: visible;
}

@keyframes prx-in-left {
  0% {
    visibility: visible;
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes prx-in-right {
  0% {
    visibility: visible;
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes prx-out-left {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  100% {
    opacity: 0.3;
    transform: translateX(40%);
  }
}
@keyframes prx-out-right {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  100% {
    opacity: 0.3;
    transform: translateX(-40%);
  }
}
.slideshow[data-swipe=on] .slideshow__content {
  user-select: none;
}
.slideshow[data-swipe=on] .slideshow__content img {
  pointer-events: none;
}

.slideshow__control {
  display: none;
}

.slideshow[data-controls=hover] .slideshow__control {
  opacity: 0;
  transition: opacity 0.3s;
}

.slideshow[data-controls=hover]:hover .slideshow__control {
  opacity: 1;
}

.slideshow[data-swipe=on] .slideshow__control {
  display: none;
}

.slideshow__control {
  display: block;
  position: absolute;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
}
.slideshow__control:first-of-type {
  left: var(--slideshow-btn-offset);
}
.slideshow__control:last-of-type {
  right: var(--slideshow-btn-offset);
}

@media (min-width: 64rem) {
  .slideshow[data-swipe=on] .slideshow__control {
    display: block;
  }
}
.slideshow__btn {
  display: block;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.75);
  height: var(--slideshow-btn-height);
  width: var(--slideshow-btn-width);
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: background 0.2s, transform 0.2s;
}
.slideshow__btn:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.85);
}
.slideshow__btn:hover .icon {
  color: var(--color-bg);
}
.slideshow:not(.slideshow--is-animating) .slideshow__btn:active {
  transform: translateY(2px);
}
.slideshow__btn .icon {
  display: block;
  width: var(--slideshow-btn-icon-size);
  height: var(--slideshow-btn-icon-size);
  margin: 0 auto;
  transition: color 0.2s;
  color: var(--color-white);
}
@supports (grid-area: auto) {
  .slideshow__btn {
    background-color: transparent;
  }
  .slideshow__btn .icon {
    color: var(--color-contrast-higher);
  }
}

.slideshow__navigation {
  position: absolute;
  z-index: 4;
  bottom: 0;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.slideshow__nav-item {
  display: inline-block;
  margin: 0 var(--space-3xs);
}
.slideshow__nav-item button {
  display: block;
  position: relative;
  font-size: 8px;
  color: var(--color-contrast-high);
  height: 1em;
  width: 1em;
  border-radius: 50%;
  background-color: currentColor;
  opacity: 0.4;
  cursor: pointer;
  transition: background 0.3s;
}
.slideshow__nav-item button::before {
  content: "";
  position: absolute;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  height: 1em;
  width: 1em;
  font-size: 14px;
  border-radius: inherit;
  border: 1px solid var(--color-contrast-high);
  opacity: 0;
  transform: scale(0);
  transition: 0.3s;
}
.slideshow__nav-item button:focus {
  outline: none;
}
.slideshow__nav-item button:focus::before {
  opacity: 1;
  transform: scale(1);
}

.slideshow__nav-item--selected button {
  opacity: 1;
}

@media (min-width: 64rem) {
  .slideshow__navigation {
    height: 40px;
  }
  .slideshow__nav-item button {
    font-size: 10px;
  }
  .slideshow__nav-item button::before {
    font-size: 16px;
  }
}
/* -------------------------------- 

File#: _1_scrolling-animations
Title: Scrolling Animations
Descr: Scrolling animations plugin
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --scroll-fx-translate-x: 0;
  --scroll-fx-translate-y: 0;
  --scroll-fx-translate-z: 0;
  --scroll-fx-rotate-x: 0;
  --scroll-fx-rotate-y: 0;
  --scroll-fx-rotate-z: 0;
  --scroll-fx-skew-x: 0;
  --scroll-fx-skew-y: 0;
  --scroll-fx-scale-x: 1;
  --scroll-fx-scale-y: 1;
  --scroll-fx-opacity: 1;
  --scroll-fx-stroke-dashoffset: 0;
}

.scroll-fx {
  transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
  opacity: var(--scroll-fx-opacity) !important;
  stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
}

.scroll-fx.scroll-fx--theme-transition,
.scroll-fx.scroll-fx--theme-transition > * {
  transition: 0.3s;
}

@media (min-width: 32rem) {
  .scroll-fx\@xs {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
    stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
  }
  .scroll-fx\@xs.scroll-fx--theme-transition,
  .scroll-fx\@xs.scroll-fx--theme-transition > * {
    transition: 0.3s;
  }
}
@media (min-width: 48rem) {
  .scroll-fx\@sm {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
    stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
  }
  .scroll-fx\@sm.scroll-fx--theme-transition,
  .scroll-fx\@sm.scroll-fx--theme-transition > * {
    transition: 0.3s;
  }
}
@media (min-width: 64rem) {
  .scroll-fx\@md {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
    stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
  }
  .scroll-fx\@md.scroll-fx--theme-transition,
  .scroll-fx\@md.scroll-fx--theme-transition > * {
    transition: 0.3s;
  }
}
@media (min-width: 80rem) {
  .scroll-fx\@lg {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
    stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
  }
  .scroll-fx\@lg.scroll-fx--theme-transition,
  .scroll-fx\@lg.scroll-fx--theme-transition > * {
    transition: 0.3s;
  }
}
@media (min-width: 90rem) {
  .scroll-fx\@xl {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
    stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
  }
  .scroll-fx\@xl.scroll-fx--theme-transition,
  .scroll-fx\@xl.scroll-fx--theme-transition > * {
    transition: 0.3s;
  }
}
.demo-scroll-fx-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
}
.demo-scroll-fx-grid > *:nth-child(1) {
  grid-column: 1/4;
  grid-row: 1/-1;
}
.demo-scroll-fx-grid > *:nth-child(2) {
  grid-column: 3/7;
  grid-row: 2/5;
}

/* -------------------------------- 

File#: _1_rating
Title: Rating
Descr: A rating plugin that allows users to vote for your products
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --rating-icon-size: 1.75em;
}

.rating__control ul {
  display: inline-block;
  display: inline-flex;
  vertical-align: top;
}
.rating__control li {
  position: relative;
  height: var(--rating-icon-size);
  width: var(--rating-icon-size);
  float: left;
  cursor: pointer;
}
.rating__control li:focus {
  outline: none;
}
.rating__control li:focus::before {
  transform: scale(1);
  opacity: 0.2;
}
.rating__control li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
  transition: 0.2s;
}
.rating__control li.rating__item--zero {
  display: none;
}

.rating__control--hide {
  display: none;
}

.rating__icon,
.rating__control li::before,
.rating[data-animation=on] .rating__control ul:hover .rating__icon {
  color: var(--color-primary);
}

.rating__icon--inactive,
.rating[data-animation=on] .rating__control li:hover ~ li .rating__icon,
.rating__item--checked ~ li .rating__icon {
  color: var(--color-contrast-lower);
}

.rating__icon {
  overflow: hidden;
}
.rating__icon svg {
  display: block;
  height: var(--rating-icon-size);
  width: var(--rating-icon-size);
  max-width: var(--rating-icon-size);
  pointer-events: none;
}

.rating--read-only .rating__control li {
  cursor: default;
}

.rating__icon--inactive {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.rating__item--half .rating__icon:not(.rating__icon--inactive) {
  position: relative;
  z-index: 2;
  width: 50%;
}

.rating[data-animation=on] .rating__control li:active .rating__icon {
  transform: scale(0.8);
}
.rating[data-animation=on] .rating__icon {
  transition: 0.2s;
}

.rating__select {
  display: none;
}

.rating__link {
  display: inline-block;
  transition: opacity 0.2s;
}
.rating__link:hover {
  opacity: 0.85;
}

/* -------------------------------- 

File#: _1_tooltip
Title: Tooltip
Descr: A popup displaying additional text information
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --tooltip-triangle-size: 12px;
}

.tooltip-trigger { /* style inline-text tooltip trigger */
  white-space: nowrap;
  border-bottom: 1px dotted var(--color-contrast-high);
  cursor: help;
}

.tooltip { /* tooltip element - created using js */
  position: absolute;
  z-index: var(--z-index-popover, 5);
  display: inline-block;
  padding: var(--space-2xs) var(--space-xs);
  border-radius: var(--radius-md);
  max-width: 200px;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.98);
  box-shadow: var(--shadow-md);
  color: var(--color-bg);
  font-size: var(--text-sm);
  line-height: 1.4;
  transition: opacity 0.2s, visibility 0.2s;
}
.tooltip a {
  color: inherit;
  text-decoration: underline;
}
@supports (clip-path: inset(50%)) {
  .tooltip::before { /* tooltip triangle  */
    content: "";
    position: absolute;
    background-color: inherit;
    border: inherit;
    width: var(--tooltip-triangle-size);
    height: var(--tooltip-triangle-size);
    clip-path: polygon(0% 0%, 100% 100%, 100% 100%, 0% 100%);
  }
}

.tootip:not(.tooltip--sticky) {
  pointer-events: none;
}

/* size variations */
.tooltip--sm {
  max-width: 150px;
  font-size: var(--text-xs);
  padding: var(--space-3xs) var(--space-2xs);
}

.tooltip--md {
  max-width: 300px;
  padding: var(--space-xs) var(--space-sm);
}

.tooltip--lg {
  max-width: 350px;
  font-size: var(--text-base-size);
  padding: var(--space-xs) var(--space-sm);
}

/* tooltip position */
.tooltip {
  /* variable used in JS to proper place tooltip triangle */
  --tooltip-triangle-translate: 0px;
}

.tooltip--top::before, .tooltip--bottom::before {
  left: calc(50% - var(--tooltip-triangle-size) / 2);
}

.tooltip--top::before {
  bottom: calc(var(--tooltip-triangle-size) * -0.5);
  transform: translateX(var(--tooltip-triangle-translate)) rotate(-45deg);
}

.tooltip--bottom::before {
  top: calc(var(--tooltip-triangle-size) * -0.5);
  transform: translateX(var(--tooltip-triangle-translate)) rotate(135deg);
}

.tooltip--left::before, .tooltip--right::before {
  top: calc(50% - var(--tooltip-triangle-size) / 2);
}

.tooltip--left::before {
  right: calc(var(--tooltip-triangle-size) * -0.5);
  transform: translateX(var(--tooltip-triangle-translate)) rotate(-135deg);
}

.tooltip--right::before {
  left: calc(var(--tooltip-triangle-size) * -0.5);
  transform: translateX(var(--tooltip-triangle-translate)) rotate(45deg);
}

.tooltip--hide {
  /* class used in JS to hide the tooltip element before its top/left positions are set */
  visibility: hidden;
  opacity: 0;
}

/* -------------------------------- 

File#: _1_anim-menu-btn
Title: Animated Menu Button
Descr: A menu button w/ a morphing icon
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --anim-menu-btn-size: 48px;
  --anim-menu-btn-transition-duration: .2s;
  --anim-menu-btn-icon-size: 32px;
  --anim-menu-btn-icon-stroke: 2px;
}

.anim-menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--anim-menu-btn-size);
  height: var(--anim-menu-btn-size);
}

.anim-menu-btn__icon {
  position: relative;
  display: block;
  font-size: var(--anim-menu-btn-icon-size);
  width: 1em;
  height: var(--anim-menu-btn-icon-stroke);
  color: inherit;
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  transform: scale(1);
}
.anim-menu-btn__icon::before, .anim-menu-btn__icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: inherit;
  border-radius: inherit;
}

.anim-menu-btn__icon--close {
  background-size: 100% 100%;
  will-change: transform, background-size;
  transition-property: transform, background-size;
  transition-duration: var(--anim-menu-btn-transition-duration, 0.2s);
}
.anim-menu-btn:active .anim-menu-btn__icon--close {
  transform: scale(0.9);
}
.anim-menu-btn__icon--close::before, .anim-menu-btn__icon--close::after {
  will-change: inherit;
  transition: inherit;
}
.anim-menu-btn__icon--close::before {
  transform: translateY(-0.25em) rotate(0);
}
.anim-menu-btn__icon--close::after {
  transform: translateY(0.25em) rotate(0);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--close {
  background-size: 0% 100%;
}
.anim-menu-btn--state-b .anim-menu-btn__icon--close::before {
  transform: translateY(0) rotate(45deg);
}
.anim-menu-btn--state-b .anim-menu-btn__icon--close::after {
  transform: translateY(0) rotate(-45deg);
}

.anim-menu-btn__icon--arrow-left,
.anim-menu-btn__icon--arrow-right,
.anim-menu-btn__icon--arrow-up,
.anim-menu-btn__icon--arrow-down {
  border-radius: 50em;
  will-change: transform;
  transition-property: transform;
  transition-duration: var(--anim-menu-btn-transition-duration, 0.2s);
}
.anim-menu-btn:active .anim-menu-btn__icon--arrow-left,
.anim-menu-btn:active .anim-menu-btn__icon--arrow-right,
.anim-menu-btn:active .anim-menu-btn__icon--arrow-up,
.anim-menu-btn:active .anim-menu-btn__icon--arrow-down {
  transform: scale(0.9);
}
.anim-menu-btn__icon--arrow-left::before, .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn__icon--arrow-down::before,
.anim-menu-btn__icon--arrow-down::after {
  transform-origin: calc(var(--anim-menu-btn-icon-stroke) / 2) 50%;
  will-change: transform, width;
  transition-property: transform, width;
  transition-duration: var(--anim-menu-btn-transition-duration, 0.2s);
}
.anim-menu-btn__icon--arrow-left::before,
.anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn__icon--arrow-down::before {
  transform: translateY(-0.25em) rotate(0);
}
.anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn__icon--arrow-down::after {
  transform: translateY(0.25em) rotate(0);
}

.anim-menu-btn__icon--arrow-right {
  transform: rotate(180deg);
}
.anim-menu-btn:active .anim-menu-btn__icon--arrow-right {
  transform: rotate(180deg) scale(0.9);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::before, .anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::after {
  width: 50%;
}
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::before {
  transform: translateY(0) rotate(-45deg);
}
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::after {
  transform: translateY(0) rotate(45deg);
}
.anim-menu-btn--state-b:active .anim-menu-btn__icon--arrow-up {
  transform: rotate(90deg) scale(0.9);
}
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up {
  transform: rotate(90deg);
}
.anim-menu-btn--state-b:active .anim-menu-btn__icon--arrow-down {
  transform: rotate(-90deg) scale(0.9);
}
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down {
  transform: rotate(-90deg);
}

/* -------------------------------- 

File#: _1_drawer
Title: Drawer
Descr: A slide-in panel used to display critical content
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --drawer-width: 400px;
}

.drawer {
  position: fixed;
  z-index: var(--z-index-overlay, 15);
  height: 100%;
  width: 100%;
  max-width: var(--drawer-width);
  top: 0;
  right: 0;
  visibility: hidden;
  transition: visibility 0s 0.3s;
}
.drawer:focus {
  outline: none;
}

.drawer--is-visible {
  visibility: visible;
  transition: none;
}

.drawer--open-left {
  right: auto;
  left: 0;
}

.drawer__content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s;
  transition-timing-function: var(--ease-in-out);
}
.drawer--open-left .drawer__content {
  transform: translateX(-100%);
}
.drawer--is-visible .drawer__content {
  transform: translateX(0);
}

.drawer__body {
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

.drawer__close-btn {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), 0.95);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  transition: 0.2s;
}
.drawer__close-btn:hover {
  background-color: var(--color-bg-lighter);
  box-shadow: var(--inner-glow), var(--shadow-md);
}
.drawer__close-btn .icon {
  display: block;
  color: var(--color-contrast-high);
}

.drawer--modal {
  max-width: none;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0);
  transition: background-color 0.3s, visibility 0s 0.3s;
}
.drawer--modal.drawer--is-visible {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.75);
  transition: background-color 0.3s;
}
.drawer--modal.drawer--open-left .drawer__content {
  right: auto;
  left: 0;
}
.drawer--modal .drawer__content {
  max-width: var(--drawer-width);
}

/* -------------------------------- 

File#: _1_radio-switch
Title: Radio Switch
Descr: Custom radio toggle
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --radio-switch-width: 186px;
  --radio-switch-height: 46px;
  --radio-switch-padding: 3px;
  --radio-switch-radius: 50em;
  --radio-switch-animation-duration: 0.3s;
}

.radio-switch {
  position: relative;
  display: inline-block;
  display: inline-flex;
  padding: var(--radio-switch-padding);
  border-radius: calc(var(--radio-switch-radius) * 1.4);
  background-color: var(--color-bg-darker);
}
.radio-switch:focus-within, .radio-switch:active {
  box-shadow: 0 0 0 2px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.15);
}

.radio-switch__item {
  position: relative;
  display: inline-block;
  height: calc(var(--radio-switch-height) - 2 * var(--radio-switch-padding));
  width: calc(var(--radio-switch-width) * 0.5 - var(--radio-switch-padding));
}

.radio-switch__label {
  position: relative;
  z-index: 2;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: var(--radio-switch-radius);
  cursor: pointer;
  font-size: var(--text-sm);
  user-select: none;
  transition: all var(--radio-switch-animation-duration);
}
.radio-switch__input:checked ~ .radio-switch__label {
  color: var(--color-white);
}
.radio-switch__input:focus ~ .radio-switch__label {
  background-color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * 0.6));
}
.radio-switch__label :not(*):focus-within, .radio-switch__input:focus ~ .radio-switch__label {
  background-color: transparent;
}

.radio-switch__marker {
  position: absolute;
  z-index: 1;
  top: 0;
  left: -100%;
  border-radius: var(--radio-switch-radius);
  background-color: var(--color-primary);
  height: calc(var(--radio-switch-height) - 2 * var(--radio-switch-padding));
  width: calc(var(--radio-switch-width) * 0.5 - var(--radio-switch-padding));
  box-shadow: var(--shadow-md);
  transition: transform var(--radio-switch-animation-duration);
}
.radio-switch__input:checked ~ .radio-switch__marker {
  transform: translateX(100%);
}

/* -------------------------------- 

File#: _1_search-input
Title: Search input
Descr: Search input field with custom button
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --search-input-btn-width: 2.2em;
  --search-input-icon-size: 1em;
  --search-input-shortcut-margin: 0.325em; /* gap between the shortcut badge and the input edges */
}

.search-input {
  position: relative;
}

.search-input__input {
  width: 100%;
  height: 100%;
}
.search-input__input::-webkit-search-decoration, .search-input__input::-webkit-search-cancel-button, .search-input__input::-webkit-search-results-button, .search-input__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.search-input__input::-ms-clear, .search-input__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.search-input--icon-right .search-input__input {
  padding-right: var(--search-input-btn-width);
}
.search-input--icon-left .search-input__input {
  padding-left: var(--search-input-btn-width);
}

.search-input__btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: var(--search-input-btn-width);
}
.search-input__btn:active .icon {
  transform: translateY(2px);
}
.search-input__btn .icon {
  display: block;
  --size: var(--search-input-icon-size);
  margin-left: auto;
  margin-right: auto;
  color: var(--color-contrast-low); /* icon color */
  transition: 0.2s;
}
.search-input--icon-left .search-input__btn {
  left: 0;
  right: auto;
  pointer-events: none;
}

.search-input__btn:focus .icon,
.search-input .search-input__input:focus + .search-input__btn .icon {
  color: var(--color-primary); /* active icon color */
}

/* --shortcut */
.search-input__shortcut {
  position: absolute;
  right: var(--search-input-shortcut-margin);
  top: var(--search-input-shortcut-margin);
  height: calc(100% - var(--search-input-shortcut-margin) * 2);
  display: flex;
  align-items: center;
  background-color: var(--color-bg);
  border: 1px solid var(--color-contrast-lower);
  border-radius: var(--radius-sm);
  --space-unit: 1em;
  padding: 0 var(--space-3xs);
  line-height: 1;
  color: var(--color-contrast-medium);
}

.search-input:focus-within .search-input__shortcut {
  display: none;
}

/* -------------------------------- 

File#: _2_drawer-navigation
Title: Drawer Navigation
Descr: Header template with drawer navigation
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --dr-nav-header-height: 54px;
  --dr-nav-logo-size: 48px;
}

@media (min-width: 64rem) {
  :root {
    --dr-nav-header-height: 70px;
    --dr-nav-logo-size: 54px;
  }
}
.dr-nav-header {
  height: var(--dr-nav-header-height);
  position: relative;
  z-index: var(--z-index-header, 3);
}
.dr-nav-header .radio-switch {
  --radio-switch-width: 72px;
  --radio-switch-height: 32px;
  --radio-switch-padding: 3px;
  --radio-switch-radius: 50em;
  --radio-switch-animation-duration: 0.3s;
}
.dr-nav-header .radio-switch__marker {
  background-color: var(--color-contrast-high);
}
.dr-nav-header .radio-switch__input:checked ~ .radio-switch__label {
  color: var(--color-bg);
}

.dr-nav-header__logo {
  display: block;
  position: absolute;
  left: calc(50% - var(--dr-nav-logo-size) / 2);
  top: calc(50% - var(--dr-nav-logo-size) / 2);
  width: var(--dr-nav-logo-size);
  height: var(--dr-nav-logo-size);
  text-decoration: none;
  color: var(--color-contrast-high);
  transition: 0.3s;
}
.dr-nav-header__logo svg {
  display: block;
  width: inherit;
  height: inherit;
}
.dr-nav-header__logo:hover {
  color: var(--color-contrast-higher);
}

.dr-nav-control-wrapper {
  position: fixed;
  z-index: var(--z-index-overlay, 15);
  top: 0;
  left: 0;
  height: var(--dr-nav-header-height);
  width: 100%;
  pointer-events: none;
}

.dr-nav-control {
  --anim-menu-btn-size: 48px;
  --anim-menu-btn-icon-size: 24px;
  --anim-menu-btn-icon-stroke: 2px;
  pointer-events: auto;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.3s var(--ease-in-out);
  color: var(--color-contrast-high);
}
.dr-nav-control circle {
  display: none;
}
.dr-nav-control.anim-menu-btn--state-b {
  transform: rotate(180deg);
}
.dr-nav-control:hover .dr-nav-control__bg {
  fill: var(--color-contrast-lower);
  opacity: 1;
}

.dr-nav-control__bg {
  display: block;
  fill: var(--color-bg);
  stroke-width: 1.5;
  stroke: var(--color-contrast-low);
  opacity: 0.95;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.2s;
}

.dr-nav-control--ready-to-animate circle {
  display: block;
  transition: stroke-dashoffset 0.3s 0s;
}

.dr-nav-control.anim-menu-btn--state-b circle {
  stroke-dashoffset: 0;
  transition-delay: 0.3s;
}

.dr-nav-drawer-header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: var(--dr-nav-header-height);
}

.dr-nav__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: var(--space-3xs) 0;
  color: var(--color-contrast-medium);
  transition: 0.3s;
}
.dr-nav__link :nth-child(2)::before {
  content: "";
  display: inline-block;
  height: 1px;
  width: 32px;
  margin-right: var(--space-xs);
  background-color: currentColor;
  vertical-align: middle;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.3s var(--ease-out);
}
.dr-nav__link:hover {
  color: var(--color-contrast-high);
}
.dr-nav__link:hover :nth-child(2)::before {
  transform: scaleX(1);
}

/* -------------------------------- 

File#: _2_flexi-header
Title: Flexi Header
Descr: Customizable header template
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --f-header-height: 80px;
  --f-header-logo-width: 104px;
}
@media (min-width: 64rem) {
  :root {
    --f-header-height: 100px;
  }
}

.f-header {
  height: var(--f-header-height);
  width: 100%;
  z-index: var(--z-index-header, 3);
}
.f-header::before { /* used in JS to detect menu style */
  display: none;
  content: "mobile";
}

.f-header--expanded {
  /* class added when navigation is visible - small devices only */
}

.f-header__mobile-content { /* logo + menu button on small devices */
  position: relative;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.f-header__logo {
  display: block;
  width: var(--f-header-logo-width);
  flex-shrink: 0;
  text-decoration: none;
}
.f-header__logo svg, .f-header__logo img {
  display: block;
}

.f-header__nav-control { /* menu button */
  --anim-menu-btn-size: 40px;
  /* 🍔 icon */
  --anim-menu-btn-icon-size: 28px;
  --anim-menu-btn-icon-stroke: 2px;
}

.f-header__nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: calc(100vh - var(--f-header-offset, 0px));
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  padding: 0 0 var(--space-md);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1em);
}
.f-header__nav::before { /* top header bg + border */
  content: "";
  display: block;
  position: sticky;
  top: 0;
  height: var(--f-header-height);
  background-color: inherit;
  border-bottom: 1px solid var(--color-contrast-lower);
}

.f-header__nav--is-visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: visibility 0s, opacity 0.3s, transform 0.3s;
}

.f-header__nav-logo-wrapper {
  display: none; /* hide logo nav on small devices */
}

.f-header__item {
  flex-shrink: 0;
  border-bottom: 1px solid var(--color-contrast-lower);
}

.f-header__dropdown-icon {
  --size: 16px;
  flex-shrink: 0;
  margin: 0 10px 0 auto;
  display: none; /* hide on mobile if link */
}
.f-header__dropdown-control .f-header__dropdown-icon {
  display: block;
}

.f-header__link,
.f-header__dropdown-control,
.f-header__dropdown-link,
.f-header__btn,
.f-header__form-control {
  font-size: var(--text-md);
}

.f-header__link,
.f-header__dropdown-control,
.f-header__dropdown-link {
  display: flex;
  align-items: center;
  color: var(--color-contrast-high);
  text-decoration: none;
  padding: var(--space-xs) 0;
}
.f-header__link:hover, .f-header__link[aria-current=page],
.f-header__dropdown-control:hover,
.f-header__dropdown-control[aria-current=page],
.f-header__dropdown-link:hover,
.f-header__dropdown-link[aria-current=page] {
  color: var(--color-primary);
}

.f-header__btn,
.f-header__form-control {
  width: 100%;
  margin: var(--space-xs) 0;
}

.f-header__dropdown-control {
  width: 100%;
}

.f-header__dropdown { /* sub navigation */
  padding-left: var(--space-md); /* offset sub nav */
}

.f-header__dropdown-control + .f-header__dropdown {
  display: none;
}
.f-header__dropdown-control[aria-expanded=true] + .f-header__dropdown {
  display: block;
}

@media (min-width: 64rem) {
  .f-header::before {
    content: "desktop";
  }
  .f-header__mobile-content {
    display: none; /* hide logo + menu (mobile content) */
  }
  .f-header__nav {
    /* reset */
    position: static;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: none;
    max-height: none;
    overflow: visible;
    overscroll-behavior: auto;
    height: var(--f-header-height);
  }
  .f-header__nav::before { /* reset */
    display: none;
  }
  .f-header__nav-logo-wrapper {
    display: flex;
  }
  .f-header__nav-grid,
  .f-header__list {
    display: flex;
    align-items: center;
  }
  .f-header__nav-grid {
    height: 100%;
  }
  .f-header__item {
    position: relative;
    border-bottom: none;
    margin-right: var(--space-xs); /* margin between nav items */
  }
  .f-header__item:last-child {
    margin-right: 0;
  }
  .f-header__dropdown-icon {
    --size: 12px;
    display: block;
    margin: 0 0 0 var(--space-4xs);
  }
  .f-header__link,
  .f-header__dropdown-control,
  .f-header__dropdown-link,
  .f-header__btn,
  .f-header__form-control {
    font-size: 1rem;
  }
  .f-header__link {
    padding: var(--space-3xs) var(--space-2xs);
  }
  .f-header__link--icon span {
    display: none; /* hide icon label */
  }
  .f-header__btn,
  .f-header__form-control {
    margin: 0; /* reset */
  }
  .f-header__dropdown {
    position: absolute;
    top: 100%;
    width: 220px;
    left: calc(50% - 110px);
    padding: var(--space-3xs) 0;
    background-color: var(--color-bg-light);
    border-radius: var(--radius-md);
    box-shadow: var(--inner-glow), var(--shadow-md);
    z-index: var(--z-index-popover, 5);
    /* hide */
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s 0.2s, opacity 0.2s 0s;
  }
  .f-header__dropdown-control + .f-header__dropdown {
    display: block; /* reset style */
  }
  .f-header__item:hover .f-header__dropdown, .f-header__dropdown-control[aria-expanded=true] + .f-header__dropdown {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s 0s, opacity 0.2s 0s;
  }
  .f-header__dropdown-link {
    color: var(--color-contrast-high);
    padding: var(--space-xs) var(--space-sm);
    transition: 0.2s;
  }
  .f-header__dropdown-link:hover {
    background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.075);
    color: var(--color-contrast-higher);
  }
}
/* --------------------------------

File#: _1_sub-navigation
Title: Sub Navigation
Descr: Secondary navigation template
Usage: codyhouse.co/license

-------------------------------- */
.subnav__nav {
  position: relative;
  display: flex;
}
.subnav__nav::after {
  content: "";
  width: 1em;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(to right, hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), 0), hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), 1));
  pointer-events: none;
}

.subnav__list {
  display: flex;
  overflow: auto;
}

.subnav__item {
  display: inline-block;
  flex-shrink: 0;
}

.subnav__link {
  display: block;
  font-size: var(--text-sm);
  padding: 1.5625em;
  text-decoration: none;
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), 0.65);
  border: 3px solid transparent;
  border-right-width: 0;
  border-left-width: 0;
  transition: 0.2s;
}
.subnav__link:hover {
  color: var(--color-contrast-high);
}
.subnav__link[aria-current=page] {
  border-bottom-color: var(--color-primary);
  color: var(--color-contrast-high);
}

.subnav:not(.subnav--collapsed) .subnav__control, .subnav:not(.subnav--collapsed) .subnav__close-btn {
  display: none;
}

.subnav--collapsed {
  display: inline-block;
}
.subnav--collapsed .subnav__wrapper {
  position: fixed;
  display: block;
  z-index: var(--z-index-overlay, 15);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.9);
  visibility: hidden;
  opacity: 0;
}
.subnav--collapsed .subnav__wrapper--is-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}
.subnav--collapsed .subnav__wrapper--is-visible .subnav__nav {
  transform: translateY(0);
  transition: transform 0.3s;
}
.subnav--collapsed .subnav__nav {
  display: block;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  max-height: 100%;
  overflow: auto;
  transform: translateY(-1em);
}
.subnav--collapsed .subnav__nav::after {
  display: none;
}
.subnav--collapsed .subnav__list {
  flex-direction: column;
  overflow: visible;
  padding: 0 var(--space-md) var(--space-md);
}
.subnav--collapsed .subnav__link {
  border-width: 0;
  font-size: var(--text-md);
  padding: var(--space-xs) 0;
}
.subnav--collapsed .subnav__link[aria-current=page] {
  color: var(--color-primary);
}

.subnav__close-btn {
  --size: 2em;
  width: var(--size);
  height: var(--size);
  display: flex;
  margin: var(--space-xs) var(--space-xs) 0 auto;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  transition: 0.2s;
}
.subnav__close-btn:hover {
  background-color: var(--color-bg-lighter);
  box-shadow: var(--inner-glow), var(--shadow-md);
}
.subnav__close-btn .icon {
  display: block;
  color: var(--color-contrast-high);
}

[class*=subnav--expanded]::before {
  display: none;
  content: "collapsed";
}

@media (min-width: 32rem) {
  .subnav--expanded\@xs::before {
    content: "expanded";
  }
}
@media (min-width: 48rem) {
  .subnav--expanded\@sm::before {
    content: "expanded";
  }
}
@media (min-width: 64rem) {
  .subnav--expanded\@md::before {
    content: "expanded";
  }
}
@media (min-width: 80rem) {
  .subnav--expanded\@lg::before {
    content: "expanded";
  }
}
@media (min-width: 90rem) {
  .subnav--expanded\@xl::before {
    content: "expanded";
  }
}
/* -------------------------------- 

File#: _3_hiding-nav
Title: Auto Hiding Navigation
Descr: A Navigation container that auto-hides when the user scrolls down, and is revealed when they scrolls back up
Usage: codyhouse.co/license

-------------------------------- */
.hide-nav {
  --hide-nav-transition-duration: 0.3s;
  position: sticky !important;
  top: 0;
  will-change: transform;
  transition: transform var(--hide-nav-transition-duration), background-color var(--hide-nav-transition-duration);
}

.hide-nav--fixed {
  background-color: transparent;
}

.hide-nav--has-bg {
  background-color: var(--color-bg);
}

/* -------------------------------- 

File#: _3_mega-site-navigation
Title: Mega-Site Navigation
Descr: Navigation template for mega-sites
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --mega-nav-height: 50px;
  --mega-nav-content-max-width: var(--max-width-lg); /* set max-width for navigation content */
}
@media (min-width: 64rem) {
  :root {
    --mega-nav-height: 70px;
  }
}

.mega-nav {
  height: var(--mega-nav-height);
  width: 100%;
  z-index: var(--z-index-header, 3);
  box-shadow: inset 0px -1px 0px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1); /* border bottom */
}

/* change mega-nav style if menu = expanded */
.mega-nav--expanded {
  background-color: var(--color-bg);
}

.mega-nav__container {
  width: calc(100% - 2 * var(--space-md));
  height: 100%;
  max-width: var(--mega-nav-content-max-width);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* logo */
.mega-nav__logo {
  display: block;
  width: 104px; /* logo width */
  height: 30px; /* logo height */
}
.mega-nav__logo > * { /* logo SVG */
  width: inherit;
  height: inherit;
}

/* mobile buttons */
.mega-nav__icon-btns {
  display: flex;
  align-items: center;
}

.mega-nav__icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 24px; /* icon size */
  color: var(--color-contrast-high); /* icon color */
  cursor: pointer;
  transition: 0.2s;
}
.mega-nav__icon-btn .icon {
  display: block;
}
.mega-nav__icon-btn .icon__group {
  stroke-width: 1px; /* icon stroke width */
}
.mega-nav__icon-btn .icon__group > * {
  transition: transform 0.3s var(--ease-in-out), stroke-dashoffset 0.3s, opacity 0.3s;
}

/* animated menu button */
.mega-nav__icon-btn--menu .icon__group > * {
  stroke-dasharray: 24;
}
.mega-nav__icon-btn--menu .icon__group > *:nth-child(1) {
  transform-origin: 12px 6px;
}
.mega-nav__icon-btn--menu .icon__group > *:nth-child(2) {
  stroke-dashoffset: 0;
}
.mega-nav__icon-btn--menu .icon__group > *:nth-child(3) {
  transform-origin: 12px 18px;
}
.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > *:nth-child(1) {
  transform: translateY(6px) rotate(-45deg);
}
.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > *:nth-child(2) {
  stroke-dashoffset: 24;
}
.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > *:nth-child(3) {
  transform: translateY(-6px) rotate(45deg);
}

/* animated search button */
.mega-nav__icon-btn--search .icon__group {
  transform-origin: 12px 12px;
  transform: rotate(0deg);
  transition: transform 0.3s var(--ease-out);
}
.mega-nav__icon-btn--search .icon__group > *:nth-child(1) {
  stroke-dashoffset: 34;
  stroke-dasharray: 24;
}
.mega-nav__icon-btn--search .icon__group > *:nth-child(2) {
  stroke-dashoffset: 24;
  stroke-dasharray: 24;
}
.mega-nav__icon-btn--search .icon__group > *:nth-child(3) {
  transform-origin: 9.5px 9.5px;
  transform: rotate(45deg);
  stroke-dashoffset: 84;
  stroke-dasharray: 42;
  opacity: 1;
}
.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group {
  transform: rotate(-90deg);
}
.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > *:nth-child(1) {
  stroke-dashoffset: 48;
}
.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > *:nth-child(2) {
  stroke-dashoffset: 48;
}
.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > *:nth-child(3) {
  stroke-dashoffset: 42;
  opacity: 0;
}

/* animated arrow icon */
.mega-nav__arrow-icon {
  font-size: 16px; /* icon size */
  color: currentColor; /* icon color */
  transition: color 0.2s;
}
.mega-nav__arrow-icon .icon {
  display: block;
}
.mega-nav__arrow-icon .icon__group {
  stroke-width: 1px; /* icon stroke width */
  will-change: transform;
  transform-origin: 8px 8px;
  transition: transform 0.3s var(--ease-out);
}
.mega-nav__arrow-icon .icon__group > * {
  stroke-dasharray: 17;
  transform-origin: 8px 8px;
  transform: translateY(3px);
  transition: transform 0.3s, stroke-dashoffset 0.3s;
  transition-timing-function: var(--ease-out);
}
.mega-nav__arrow-icon .icon__group > *:first-child {
  stroke-dashoffset: 8.5;
}
.mega-nav__arrow-icon .icon__group > *:last-child {
  stroke-dashoffset: 8.5;
}

/* label/divider */
.mega-nav__label {
  color: var(--color-contrast-medium);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: var(--text-xs);
}

/* card */
.mega-nav__card img {
  transition: opacity 0.3s;
}
.mega-nav__card img:hover {
  opacity: 0.85;
}

.mega-nav__card-title {
  color: var(--color-contrast-higher);
  text-decoration: none;
}
.mega-nav__card-title:hover {
  text-decoration: underline;
}

/* -------------------------------- 

--mobile - style affecting only small screens 👇

-------------------------------- */
.mega-nav--mobile {
  /* sub navigation */
}
.mega-nav--mobile .mega-nav__icon-btns--desktop {
  display: none; /* hide --desktop icon buttons */
}
.mega-nav--mobile .mega-nav__nav, .mega-nav--mobile .mega-nav__search {
  display: none;
  position: absolute;
  top: var(--mega-nav-height);
  left: 0;
  width: 100%;
  height: calc(100vh - var(--mega-nav-height) - var(--mega-nav-offset-y, 0px)); /* set --mega-nav-offset-y in JS */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-lg);
}
.mega-nav--mobile .mega-nav__nav--is-visible, .mega-nav--mobile .mega-nav__search--is-visible {
  display: block;
}
.mega-nav--mobile .mega-nav__nav--is-visible > *, .mega-nav--mobile .mega-nav__search--is-visible > * {
  animation: mega-nav-entry-animation 0.5s var(--ease-out);
}
.mega-nav--mobile .mega-nav__nav-inner, .mega-nav--mobile .mega-nav__search-inner {
  padding: var(--space-md) 0;
  width: calc(100% - 2 * var(--space-md));
  max-width: var(--mega-nav-content-max-width);
  margin-left: auto;
  margin-right: auto;
}
.mega-nav--mobile .mega-nav__label { /* label/divider */
  margin: var(--space-lg) 0 var(--space-xs);
}
.mega-nav--mobile .mega-nav__item { /* main navigation items */
  border-bottom: 1px solid var(--color-contrast-lower);
}
.mega-nav--mobile .mega-nav__control { /* navigation main controls (buttons/links) */
  display: flex;
  align-items: center;
  width: 100%;
  font-size: var(--text-md);
  text-align: left;
  padding: var(--space-sm) 0;
  color: var(--color-contrast-higher);
  text-decoration: none;
  cursor: pointer;
}
.mega-nav--mobile a[aria-current=page] { /* current page */
  color: var(--color-primary);
}
.mega-nav--mobile .mega-nav__arrow-icon {
  margin-left: auto;
  margin-right: 12px;
}
.mega-nav--mobile .mega-nav__btn { /* button */
  width: 100%;
  margin: var(--space-sm) 0;
  font-size: var(--text-md);
}
.mega-nav--mobile .mega-nav__sub-nav-wrapper { /* sub navigation content */
  display: none;
  padding: 0 var(--space-md) var(--space-lg);
  overflow: hidden;
}
.mega-nav--mobile .mega-nav__sub-items { /* list of sub items */ }
.mega-nav--mobile .mega-nav__sub-items:not(:last-child) {
  margin-bottom: var(--space-lg);
}
.mega-nav--mobile .mega-nav__sub-item { /* sub item */
  border-bottom: 1px solid var(--color-contrast-lower);
}
.mega-nav--mobile .mega-nav__sub-link { /* sub link */
  display: block;
  color: var(--color-contrast-higher);
  text-decoration: none;
  padding: var(--space-2xs) 0;
}
.mega-nav--mobile .mega-nav__quick-link { /* search quick links */
  display: block;
  color: var(--color-contrast-higher);
  text-decoration: none;
  border-bottom: 1px solid var(--color-contrast-lower);
  padding: var(--space-xs) 0;
}
.mega-nav--mobile .mega-nav__sub-nav--layout-1 { /* layout 1 -> tabbed content */ }
.mega-nav--mobile .mega-nav__sub-nav--layout-1 .mega-nav__sub-items {
  margin-bottom: 0;
}
.mega-nav--mobile .mega-nav__sub-nav--layout-1 .mega-nav__tabs {
  display: none; /* hide tabbed content on smaller screens */
}
.mega-nav--mobile .mega-nav__sub-nav--layout-3 { /* layout 3 -> gallery */
  padding-top: var(--space-md);
  display: grid;
  gap: var(--space-md);
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr)); /* auto add new cols */
}
.mega-nav--mobile .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group { /* animated arrow icon */ }
.mega-nav--mobile .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > *:first-child {
  transform: translateY(-3px) rotate(-90deg);
}
.mega-nav--mobile .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > *:last-child {
  transform: translateY(-3px) rotate(90deg);
}
.mega-nav--mobile .mega-nav__item--expanded .mega-nav__sub-nav-wrapper {
  display: block;
}
.mega-nav--mobile .mega-nav__item--expanded .mega-nav__sub-nav-wrapper > * {
  animation: mega-nav-entry-animation 0.5s var(--ease-out);
}

/* -------------------------------- 

--desktop - style affecting only big screens 👇

-------------------------------- */
.mega-nav--desktop {
  /* tabs */
  /* icon animation on click */
  /* icon buttons */
}
.mega-nav--desktop .mega-nav__icon-btns--mobile, .mega-nav--desktop .mega-nav__sub-nav-wrapper, .mega-nav--desktop .mega-nav__search, .mega-nav--desktop .mega-nav__label {
  display: none;
}
.mega-nav--desktop .mega-nav__logo {
  flex-shrink: 0;
  margin-right: var(--space-sm);
}
.mega-nav--desktop .mega-nav__nav {
  flex-grow: 1;
  height: 100%;
}
.mega-nav--desktop .mega-nav__nav-inner {
  height: 100%;
  display: flex;
  justify-content: space-between; /* navigation layout - change to "flex-end" to push the navigation to the right */
}
.mega-nav--desktop .mega-nav__items {
  display: flex;
  height: 100%;
}
.mega-nav--desktop .mega-nav__item {
  display: flex;
  align-items: center;
}
.mega-nav--desktop .mega-nav__control {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 var(--space-md);
  height: 100%;
  font-size: var(--text-sm);
  color: var(--color-contrast-higher);
  text-decoration: none;
  transition: 0.2s;
}
.mega-nav--desktop .mega-nav__control::after { /* marker */
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-contrast-higher);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}
.mega-nav--desktop .mega-nav__control .mega-nav__arrow-icon {
  margin-left: var(--space-2xs);
}
.mega-nav--desktop .mega-nav__control:hover {
  cursor: pointer;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.05);
}
.mega-nav--desktop .mega-nav__sub-nav-wrapper, .mega-nav--desktop .mega-nav__search {
  position: absolute;
  top: var(--mega-nav-height);
  left: 0;
  width: 100%;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-lg);
  max-height: calc(100vh - var(--mega-nav-height) - var(--mega-nav-offset-y, 0px)); /* set --mega-nav-offset-y in JS */
  overflow: auto;
}
.mega-nav--desktop .mega-nav__sub-nav, .mega-nav--desktop .mega-nav__search-inner {
  width: calc(100% - 2 * var(--space-md));
  margin: 0 auto;
  padding: var(--space-xl) 0;
}
.mega-nav--desktop .mega-nav__sub-nav .mega-nav__label, .mega-nav--desktop .mega-nav__search-inner .mega-nav__label {
  display: block;
}
.mega-nav--desktop .mega-nav__sub-nav {
  max-width: var(--mega-nav-content-max-width);
}
.mega-nav--desktop .mega-nav__search-inner {
  max-width: var(--max-width-xs); /* reduce max-width for search content */
}
.mega-nav--desktop .mega-nav__label {
  margin-bottom: var(--space-md);
}
.mega-nav--desktop .mega-nav__sub-item:not(:last-child) {
  margin-bottom: var(--space-2xs);
}
.mega-nav--desktop .mega-nav__sub-link {
  font-size: var(--text-sm);
  color: var(--color-contrast-higher);
  text-decoration: none;
}
.mega-nav--desktop .mega-nav__sub-link:hover {
  text-decoration: underline;
}
.mega-nav--desktop .mega-nav__quick-link { /* search quick links */
  display: inline-block;
  margin-bottom: var(--space-2xs);
  font-size: var(--text-sm);
  color: var(--color-contrast-higher);
  text-decoration: none;
}
.mega-nav--desktop .mega-nav__quick-link:hover {
  text-decoration: underline;
}
.mega-nav--desktop .mega-nav__btn {
  font-size: var(--text-sm);
  margin-left: var(--space-sm);
}
.mega-nav--desktop .mega-nav__sub-nav--layout-1 { /* layout 1 -> tabbed content */ }
.mega-nav--desktop .mega-nav__sub-nav--layout-1 .mega-nav__sub-items {
  display: none; /* hide links */
}
.mega-nav--desktop .mega-nav__sub-nav--layout-1 .mega-nav__tabs {
  display: flex;
}
.mega-nav--desktop .mega-nav__sub-nav--layout-2 { /* layout 2 -> multi lists */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: var(--space-sm);
}
.mega-nav--desktop .mega-nav__sub-nav--layout-3 { /* layout 3 -> gallery */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--space-md);
}
.mega-nav--desktop .mega-nav__sub-nav--layout-4 { /* layout 4 -> single list */
  text-align: center;
}
.mega-nav--desktop .mega-nav__sub-nav--layout-4 .mega-nav__sub-link {
  font-size: var(--text-lg);
}
.mega-nav--desktop .mega-nav__tabs-controls > *:not(:last-child) {
  border-bottom: 1px solid var(--color-contrast-lower);
}
.mega-nav--desktop .mega-nav__tabs-control {
  display: block;
  width: 100%;
  padding: var(--space-xs);
  overflow: hidden;
  color: var(--color-contrast-higher);
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
}
.mega-nav--desktop .mega-nav__tabs-control .icon {
  opacity: 0;
  transform: translateX(-10px);
  transition: transform 0.5s var(--ease-out), opacity 0.5s;
}
.mega-nav--desktop .mega-nav__tabs-control:hover, .mega-nav--desktop .mega-nav__tabs-control[aria-selected=true] {
  color: var(--color-primary);
}
.mega-nav--desktop .mega-nav__tabs-control[aria-selected=true] .icon {
  opacity: 1;
  transform: translateX(0px);
}
.mega-nav--desktop .mega-nav__tabs-img {
  display: block;
  overflow: hidden;
  border-radius: var(--radius-lg);
  transition: opacity 0.3s;
}
.mega-nav--desktop .mega-nav__tabs-img:hover {
  opacity: 0.85;
}
.mega-nav--desktop .mega-nav__item--expanded .mega-nav__control {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.05);
}
.mega-nav--desktop .mega-nav__item--expanded .mega-nav__control::after { /* marker */
  opacity: 1;
}
.mega-nav--desktop .mega-nav__item--expanded .mega-nav__sub-nav-wrapper {
  display: block;
}
.mega-nav--desktop .mega-nav__item--expanded .mega-nav__sub-nav {
  animation: mega-nav-entry-animation 0.5s var(--ease-out);
}
.mega-nav--desktop:not([data-hover=on]) .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group {
  transform: rotate(-90deg);
}
.mega-nav--desktop:not([data-hover=on]) .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > *:first-child, .mega-nav--desktop:not([data-hover=on]) .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group *:last-child {
  stroke-dashoffset: 0;
  transform: translateY(0px);
}
.mega-nav--desktop .mega-nav__icon-btn {
  border-radius: 50%;
  margin-left: var(--space-4xs);
}
.mega-nav--desktop .mega-nav__icon-btn:hover,
.mega-nav--desktop .mega-nav__icon-btn--state-b {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.05);
}
.mega-nav--desktop .mega-nav__search--is-visible {
  display: block;
}
.mega-nav--desktop .mega-nav__search--is-visible .mega-nav__search-inner {
  animation: mega-nav-entry-animation 0.5s var(--ease-out);
}

/* animations */
@keyframes mega-nav-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
/* used in JS - detect when the menu needs to switch from --mobile to --desktop layout */
[class*=mega-nav--desktop]::before {
  display: none;
  content: "mobile";
}

@media (min-width: 32rem) {
  .mega-nav--desktop\@xs::before {
    content: "desktop";
  }
}
@media (min-width: 48rem) {
  .mega-nav--desktop\@sm::before {
    content: "desktop";
  }
}
@media (min-width: 64rem) {
  .mega-nav--desktop\@md::before {
    content: "desktop";
  }
}
@media (min-width: 80rem) {
  .mega-nav--desktop\@lg::before {
    content: "desktop";
  }
}
@media (min-width: 90rem) {
  .mega-nav--desktop\@xl::before {
    content: "desktop";
  }
}
/* -------------------------------- 

File#: _1_collapse
Title: Collapse
Descr: Control button to toggle the visibility of a panel element
Usage: codyhouse.co/license

-------------------------------- */
/* -------------------------------- 

File#: _2_morphing-navigation
Title: Morphing Navigation
Descr: Header Template with a morphing dropdown
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --morph-nav-height: 80px;
}
@media (min-width: 64rem) {
  :root {
    --morph-nav-height: 100px;
  }
}

.morph-nav {
  z-index: var(--z-index-header);
  height: var(--morph-nav-height);
}
.morph-nav > * {
  height: 100%;
}

.morph-nav__logo {
  display: block;
  width: auto;
  height: 65px;
}

.morph-nav__link {
  display: flex;
  align-items: center;
}

.morph-nav__link-figure {
  flex-shrink: 0;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--space-sm);
}

.morph-nav__link-title {
  color: var(--color-contrast-higher);
  font-weight: 500;
}
.morph-nav__link-title .icon {
  opacity: 0;
  transform: translateX(-0.5em);
  transition: opacity 0.2s, transform 0.2s;
}
.morph-nav__link:hover .morph-nav__link-title .icon {
  opacity: 1;
  transform: translateX(0);
}

.morph-nav__link-description {
  color: var(--color-contrast-low);
}

.morph-nav__social-link {
  display: flex;
  align-items: center;
  color: var(--color-contrast-higher);
  font-weight: 500;
  transition: color 0.2s;
}
.morph-nav__social-link .icon {
  margin-right: var(--space-xs);
}
.morph-nav__social-link:hover {
  color: var(--color-primary);
}

/* #region (mobile only) */
.morph-nav__sm-nav {
  position: relative;
  z-index: 2;
  height: 100%;
}

.morph-nav__sm-menu-toggle {
  color: var(--color-contrast-higher);
  --anim-menu-btn-size: 40px;
  /* 🍔 icon */
  --anim-menu-btn-icon-size: 28px;
  --anim-menu-btn-icon-stroke: 2px;
}

.morph-nav__sm-dropdown {
  position: absolute;
  z-index: 1;
  padding-top: var(--morph-nav-height);
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-bg-light);
  box-shadow: var(--shadow-sm);
  transform: scale(0.95);
  transform-origin: top center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s var(--ease-out);
}
.morph-nav__sm-dropdown a {
  color: inherit;
  text-decoration: none;
}

.morph-nav__sm-dropdown--is-visible {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.3s, transform 0.3s var(--ease-out);
}

.morph-nav__sm-dropdown-innner {
  max-height: calc(100vh - var(--morph-nav-height));
  overflow: auto;
}

.morph-nav__sm-label {
  font-size: var(--text-xs);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--color-contrast-low);
  margin-bottom: var(--space-md);
}

/* #endregion */
/* #region (desktop only) */
.morph-nav__lg-nav-list {
  display: flex;
  height: 100%;
}

.morph-nav__lg-nav-item {
  height: 100%;
  transition: opacity 0.3s;
}

.morph-nav__lg-nav-list:hover .morph-nav__lg-nav-item:not(:hover) {
  opacity: 0.5;
}

.morph-nav__lg-main-nav-link {
  display: block;
  height: 100%;
  line-height: var(--morph-nav-height);
  color: var(--color-contrast-higher);
  font-size: var(--text-sm);
  font-weight: 500;
  text-decoration: none;
  padding: 0 var(--space-md);
}

.morph-nav__lg-subnav {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(20px);
  pointer-events: none;
  transition: transform 0.3s var(--ease-out);
}

.morph-nav__lg-subnav--is-visible {
  transform: translateY(0px);
  pointer-events: auto;
}

.morph-nav__lg-dropdown-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  visibility: hidden;
  will-change: height, width, transform, visibility;
  transition: visibility 0.3s;
}

.morph-nav__lg-subnav--is-visible .morph-nav__lg-dropdown-wrapper {
  visibility: visible;
}

.morph-nav__lg-dropdown-list {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.morph-nav__lg-dropdown-item {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s var(--ease-out), visibility 0s 0.3s;
}

.morph-nav__lg-dropdown-item--is-visible {
  opacity: 1;
  visibility: visible;
  z-index: 2;
  transition: opacity 0.3s var(--ease-out);
}

.morph-nav__lg-dropdown-bg {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-bg-light);
  border-radius: var(--radius-md);
  box-shadow: var(--inner-glow), var(--shadow-md);
  will-change: visibility, opacity;
  /* hide */
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s var(--ease-out);
  /* triangle marker */
}
.morph-nav__lg-dropdown-bg::before {
  --size: 14px;
  content: "";
  display: block;
  height: var(--size);
  width: var(--size);
  background-color: inherit;
  border: inherit;
  position: absolute;
  z-index: 1;
  top: calc(-1 * var(--size) / 2);
  left: calc(50% - var(--size) / 2);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
  transform: translateY(1px) rotate(-45deg);
  border-radius: 0 4px 0 0;
  box-shadow: var(--inner-glow);
}

.morph-nav__lg-dropdown-bg--is-visible {
  opacity: 1;
  visibility: visible;
}

.morph-nav__lg-dropdown a {
  text-decoration: none;
}

.morph-nav__lg-dropdown--1 {
  width: 580px;
}

.morph-nav__lg-dropdown--2 {
  width: 280px;
}

.morph-nav__lg-dropdown--3 {
  width: 460px;
}

/* #endregion */
/* -------------------------------- 

File#: _1_main-footer
Title: Main Footer
Descr: Footer navigation
Usage: codyhouse.co/license

-------------------------------- */
.main-footer__logo {
  display: inline-block;
}
.main-footer__logo svg, .main-footer__logo img {
  display: block;
}

.main-footer__link {
  color: var(--color-contrast-medium);
  text-decoration: none;
}
.main-footer__link:hover {
  color: var(--color-contrast-high);
  text-decoration: underline;
}

.main-footer__social {
  text-decoration: none;
  display: inline-block;
  color: var(--color-contrast-medium);
}
.main-footer__social:hover {
  color: var(--color-contrast-high);
}
.main-footer__social .icon {
  font-size: 1.2em;
}
@media (min-width: 64rem) {
  .main-footer__social .icon {
    font-size: 1em;
  }
}

/* -------------------------------- 

File#: _1_main-footer-v3
Title: Main Footer v3
Descr: Footer navigation template
Usage: codyhouse.co/license

-------------------------------- */
.footer-v3 {
  position: relative;
  z-index: 1;
}

.footer-v3__logo a, .footer-v3__logo svg, .footer-v3__logo img {
  width: 104px;
  height: 30px;
  display: block;
}

.footer-v3__nav {
  margin: var(--space-lg) 0;
}

.footer-v3__nav-item {
  margin-bottom: var(--space-sm);
}
.footer-v3__nav-item a {
  color: var(--color-contrast-high);
  font-size: 1.25em;
}
.footer-v3__nav-item a:hover {
  color: var(--color-primary);
}

.footer-v3__socials {
  display: flex;
  align-items: center;
}
.footer-v3__socials a {
  text-decoration: none;
  display: inline-block;
  margin: 0 var(--space-xs) 0 0;
  color: var(--color-contrast-high);
}
.footer-v3__socials a:hover {
  color: var(--color-primary);
}
.footer-v3__socials a .icon {
  display: block;
  width: 1.25em;
  height: 1.25em;
}

@supports (display: grid) {
  @media (min-width: 64rem) {
    .footer-v3__container {
      display: grid;
      grid-template-columns: 130px 1fr 130px;
    }
    .footer-v3__nav {
      margin: 0;
    }
    .footer-v3__nav-list {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .footer-v3__nav-item {
      padding: 0 var(--space-xs);
      margin: 0;
      position: relative;
    }
    .footer-v3__nav-item a {
      font-size: 0.875em;
    }
    .footer-v3__nav-item::after {
      content: "";
      display: inline-block;
      position: absolute;
      height: 0.8em;
      width: 1px;
      right: 0;
      top: calc(50% - 0.4em);
      background-color: var(--color-contrast-lower);
    }
    .footer-v3__nav-item:first-child {
      padding-left: 0;
    }
    .footer-v3__nav-item:last-child {
      padding-right: 0;
    }
    .footer-v3__nav-item:last-child::after {
      display: none;
    }
    .footer-v3__socials a {
      margin: 0 0 0 var(--space-xs);
    }
    .footer-v3__socials a .icon {
      width: 1em;
      height: 1em;
    }
  }
}
#main-wrapper {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}
#main-wrapper.components-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}
#main-wrapper.components-wrapper .section:not([class*=padding-]) {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}
#main-wrapper.components-wrapper .section:first-child:not([class*=padding-top-]) {
  padding-top: 0;
}
.components-wrapper .container.max-width-none {
  width: unset;
}

.background,
[class*=background-] {
  position: relative;
}

[class*=background-] .background-image {
  opacity: 0.2;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
  pointer-events: none;
  z-index: -1;
}

/* -------------------------------- 

File#: _1_accordion
Title: Accordion
Descr: Create stacked sections of content and allow the user to expand/collapse them
Usage: codyhouse.co/license

-------------------------------- */
:root {
  /* general */
  --accordion-border-width: 1px;
  /* icon */
  --accordion-icon-size: 20px;
  --accordion-icon-stroke-width: 2px;
}

.accordion__item {
  border-style: solid;
  border-color: var(--color-contrast-lower);
  border-bottom-width: var(--accordion-border-width);
}
.accordion__item:first-child {
  border-top-width: var(--accordion-border-width);
}

.accordion__header { /* button */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
}
.accordion__header .icon {
  --size: var(--accordion-icon-size);
}
.accordion__header .icon .icon__group {
  stroke-width: var(--accordion-icon-stroke-width);
}

.accordion__icon-arrow .icon__group,
.accordion__icon-arrow-v2 .icon__group,
.accordion__icon-plus .icon__group {
  will-change: transform;
  transform-origin: 50% 50%;
}

.accordion__icon-arrow .icon__group > * {
  transform-origin: 10px 14px;
}

.accordion__icon-arrow-v2 .icon__group > * {
  transform-origin: 50% 50%;
  stroke-dasharray: 20;
  transform: translateY(4px);
}
.accordion__icon-arrow-v2 .icon__group > *:first-child {
  stroke-dashoffset: 10.15;
}
.accordion__icon-arrow-v2 .icon__group > *:last-child {
  stroke-dashoffset: 10.15;
}

.accordion__icon-plus .icon__group {
  transform: rotate(-90deg);
}
.accordion__icon-plus .icon__group > * {
  transform-origin: 50% 50%;
}
.accordion__icon-plus .icon__group > *:first-child {
  transform: rotate(-90deg);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-arrow .icon__group { /* animated arrow icon */ }
.accordion__item--is-open > .accordion__header > .accordion__icon-arrow .icon__group > *:first-child {
  transform: translateY(-8px) rotate(-90deg);
}
.accordion__item--is-open > .accordion__header > .accordion__icon-arrow .icon__group > *:last-child {
  transform: translateY(-8px) rotate(90deg);
}
.accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group { /* animated arrow icon v2 */
  transform: rotate(-90deg);
}
.accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group > *:first-child, .accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group *:last-child {
  stroke-dashoffset: 0;
  transform: translateY(0px);
}
.accordion__item--is-open > .accordion__header > .accordion__icon-plus .icon__group { /* animated plus icon */
  transform: rotate(0);
}
.accordion__item--is-open > .accordion__header > .accordion__icon-plus .icon__group > *:first-child {
  transform: rotate(0);
}

.accordion__panel {
  display: none;
  will-change: height;
  transform: translateZ(0px);
}

.accordion__item--is-open > .accordion__panel {
  display: block;
}

/* animations */
.accordion[data-animation=on] .accordion__item--is-open .accordion__panel > * {
  animation: accordion-entry-animation 0.4s var(--ease-out);
}
.accordion[data-animation=on] .accordion__icon-arrow .icon__group,
.accordion[data-animation=on] .accordion__icon-arrow-v2 .icon__group,
.accordion[data-animation=on] .accordion__icon-plus .icon__group {
  transition: transform 0.3s var(--ease-out);
}
.accordion[data-animation=on] .accordion__icon-arrow .icon__group > *,
.accordion[data-animation=on] .accordion__icon-arrow-v2 .icon__group > *,
.accordion[data-animation=on] .accordion__icon-plus .icon__group > * {
  transition: transform 0.3s, stroke-dashoffset 0.3s;
  transition-timing-function: var(--ease-out);
}

@keyframes accordion-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
/* -------------------------------- 

File#: _2_accordion-v2
Title: Accordion v2
Descr: Stacked, expandable content sections
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --accordion-v2-icon-size: 20px;
  --accordion-v2-icon-stroke-width: 2px;
}

.accordion-v2__item {
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow-top), var(--shadow-sm);
  border-radius: var(--radius-md);
  transition: 0.3s;
}
.accordion-v2__item:hover {
  box-shadow: var(--inner-glow-top), var(--shadow-md);
}

.accordion-v2__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
}
.accordion-v2__header .icon {
  --size: var(--accordion-v2-icon-size);
}
.accordion-v2__header .icon .icon__group {
  stroke-width: var(--accordion-v2-icon-stroke-width);
}

.accordion-v2__icon-arrow .icon__group {
  will-change: transform;
  transform-origin: 50% 50%;
}
.accordion-v2__icon-arrow .icon__group > * {
  transform-origin: 50% 50%;
  stroke-dasharray: 20;
  transform: translateY(4px);
}
.accordion-v2__icon-arrow .icon__group > *:first-child {
  stroke-dashoffset: 10.15;
}
.accordion-v2__icon-arrow .icon__group > *:last-child {
  stroke-dashoffset: 10.15;
}

.accordion-v2__item--is-open > .accordion-v2__header > .accordion-v2__icon-arrow .icon__group {
  transform: rotate(-90deg);
}
.accordion-v2__item--is-open > .accordion-v2__header > .accordion-v2__icon-arrow .icon__group > *:first-child, .accordion-v2__item--is-open > .accordion-v2__header > .accordion-v2__icon-arrow .icon__group *:last-child {
  stroke-dashoffset: 0;
  transform: translateY(0px);
}

.accordion-v2__panel {
  display: none;
  will-change: height;
  transform: translateZ(0px);
}

.accordion-v2__item--is-open > .accordion-v2__panel {
  display: block;
}

.accordion-v2[data-animation=on] .accordion-v2__item--is-open .accordion-v2__panel > * {
  animation: accordion-v2-entry-animation 0.4s var(--ease-out);
}
.accordion-v2[data-animation=on] .accordion-v2__icon-arrow .icon__group {
  transition: transform 0.3s var(--ease-out);
}
.accordion-v2[data-animation=on] .accordion-v2__icon-arrow .icon__group > * {
  transition: transform 0.3s, stroke-dashoffset 0.3s;
  transition-timing-function: var(--ease-out);
}

@keyframes accordion-v2-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
/* -------------------------------- 

File#: _2_image-comparison-slider
Title: Image Comparison Slider
Descr: A draggable image slider used to compare two versions of the same image
Usage: codyhouse.co/license

-------------------------------- */
.compare-slider {
  position: relative;
}
.compare-slider img {
  display: block;
  width: 100%;
  pointer-events: none;
}

.compare-slider__inner {
  position: relative;
}

.compare-slider__img {
  position: relative;
  user-select: none;
  overflow: hidden;
}

.compare-slider__caption {
  position: absolute;
  bottom: 0;
  font-size: var(--text-sm);
  padding: var(--space-sm);
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.75);
  color: var(--color-white);
  backdrop-filter: blur(10px);
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
}

.compare-slider__img--original .compare-slider__caption {
  right: 0;
}

.compare-slider__img--modified .compare-slider__caption {
  left: 0;
}

.compare-slider__handle {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  color: var(--color-contrast-higher);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
  cursor: grab;
  will-change: left;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%) translateX(-50%) scale(0);
  margin-left: -1px;
}

.compare-slider--is-dragging .compare-slider__handle {
  cursor: grabbing;
  box-shadow: var(--shadow-sm), 0 0 0 4px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.5);
}

.compare-slider__input-handle {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
}

.compare-slider__input-handle:focus + .compare-slider__handle {
  color: var(--color-primary);
  box-shadow: var(--shadow-sm), 0 0 0 4px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.5);
}

.compare-slider__img--modified {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  transform: translateZ(0);
  border-right: 2px solid var(--color-bg);
  will-change: width;
}
.compare-slider__img--modified img {
  height: 100%;
  width: auto;
  max-width: none;
}

.compare-slider--in-viewport .compare-slider__img--modified {
  width: 50%;
  animation: compare-slider-bounce-in 0.7s;
}

.compare-slider__caption {
  opacity: 0;
  transform: translateY(20px);
}

.compare-slider--in-viewport .compare-slider__caption {
  opacity: 1;
  transform: translateY(0);
}

.compare-slider--in-viewport .compare-slider__caption--hide {
  opacity: 0;
  visibility: hidden;
}

.compare-slider__img--original .compare-slider__caption--hide {
  transform: translateX(30px);
}

.compare-slider__img--modified .compare-slider__caption--hide {
  transform: translateX(-30px);
}

.compare-slider__handle {
  display: block;
  display: flex;
  opacity: 0;
}

.compare-slider--in-viewport .compare-slider__handle {
  transition: transform 0.3s 0.7s, opacity 0s 0.7s;
  transform: translateY(-50%) translateX(-50%) scale(1);
  opacity: 1;
}

.compare-slider__input-handle {
  display: block;
}

.compare-slider--reduced-motion .compare-slider__img--modified {
  animation: none;
}

.compare-slider--reduced-motion .compare-slider__handle,
.compare-slider--reduced-motion .compare-slider__caption {
  transition: none;
}

@keyframes compare-slider-bounce-in {
  0% {
    width: 0%;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
.cp-image {
  height: 350px;
  margin-top: -30px;
}

/* -------------------------------- 

File#: _1_download-list
Title: Download List
Descr: A list of downloadable files
Usage: codyhouse.co/license

-------------------------------- */
.dow-list {
  display: grid;
  gap: var(--space-xs);
}

.dow-list__item {
  border-radius: var(--radius-md);
  background: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-ring), var(--shadow-xs);
  padding: var(--space-xs);
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}
@media (min-width: 32rem) {
  .dow-list__item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.dow-list__title {
  font-size: var(--text-base-size);
  font-weight: 600;
  line-height: 1;
  margin-bottom: var(--space-2xs);
}

.dow-list__metadata-list {
  display: flex;
  align-items: center;
  font-size: var(--text-xs);
  color: var(--color-contrast-medium);
  line-height: 1;
}
.dow-list__metadata-list > * {
  display: flex;
  align-items: center;
}
.dow-list__metadata-list > *:not(:last-child)::after { /* dot separator */
  content: "";
  display: block;
  flex-shrink: 0;
  --size: 5px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: currentColor;
  margin: 0 var(--space-xs);
  opacity: 0.5;
}

/* animated button */
.dow-list__btn {
  --hover-transition-duration: .3s;
  overflow: hidden;
}
.dow-list__btn:hover .dow-list__btn-label, .dow-list__btn.dow-list__btn--is-loading .dow-list__btn-label {
  transform: scale(0.5);
  opacity: 0;
}
.dow-list__btn:hover .dow-list__icon-wrapper, .dow-list__btn.dow-list__btn--is-loading .dow-list__icon-wrapper {
  transform: translateY(0);
}

.dow-list__btn--reset .dow-list__btn-label, .dow-list__btn--reset:hover .dow-list__btn-label {
  transform: scale(1);
  opacity: 1;
}
.dow-list__btn--reset .dow-list__icon-wrapper, .dow-list__btn--reset:hover .dow-list__icon-wrapper {
  transform: translateY(100%);
}
.dow-list__btn--reset .dow-list__icon-group *, .dow-list__btn--reset:hover .dow-list__icon-group * {
  transition: none !important;
  animation: none !important;
}

.dow-list__btn-label {
  transition: opacity var(--hover-transition-duration), transform var(--hover-transition-duration);
}

.dow-list__icon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  transition: transform var(--hover-transition-duration);
}

.dow-list__icon {
  --size: 20px;
  display: block;
  width: 20px;
  height: 20px;
}
.dow-list__icon .dow-list__icon-group > *:nth-child(1) { /* arrow main leg */
  stroke-dashoffset: 0;
  stroke-dasharray: 16;
  transition: stroke-dashoffset 0.4s 0.3s var(--ease-in);
}
.dow-list__icon .dow-list__icon-group > *:nth-child(2), .dow-list__icon .dow-list__icon-group *:nth-child(3) { /* arrow smaller legs */
  opacity: 1;
  transform: rotate(0);
  transition: transform 0.3s var(--ease-out), opacity 0s 0.3s;
}
.dow-list__icon .dow-list__icon-group > *:nth-child(4) { /* loading circle */
  stroke-dashoffset: 50;
  stroke-dasharray: 50;
}
.dow-list__icon .dow-list__icon-group > *:nth-child(5) { /* checkmark final loading circle */
  stroke-dashoffset: 38;
  stroke-dasharray: 38;
  transition: stroke-dashoffset 0.7s;
}
.dow-list__icon .dow-list__icon-group > *:nth-child(6) { /* checkmark */
  stroke-dashoffset: 24;
  stroke-dasharray: 24;
  transition: stroke-dashoffset 0.5s 0.2s var(--ease-out);
}
.dow-list__icon .dow-list__icon-group > *:nth-child(7) { /* exclamation point final loading circle */
  stroke-dashoffset: 25;
  stroke-dasharray: 25;
  transition: stroke-dashoffset 0.45s;
}
.dow-list__icon .dow-list__icon-group > *:nth-child(8) { /* exclamation point leg */
  stroke-dashoffset: 10;
  stroke-dasharray: 10;
  transition: stroke-dashoffset 0.3s 0.2s var(--ease-out);
}
.dow-list__icon .dow-list__icon-group > *:nth-child(9) { /* exclamation point dot */
  transform-origin: 10px 17.5px;
  transform: scale(0);
  transition: transform 0.3s 0.5s var(--ease-out-back);
}

.dow-list__btn--is-loading .dow-list__icon-group > *:nth-child(1) {
  stroke-dashoffset: 16;
}
.dow-list__btn--is-loading .dow-list__icon-group > *:nth-child(2), .dow-list__btn--is-loading .dow-list__icon-group *:nth-child(3) {
  opacity: 0;
  transform-origin: 10px 18px;
}
.dow-list__btn--is-loading .dow-list__icon-group > *:nth-child(2) {
  transform: rotate(45deg);
}
.dow-list__btn--is-loading .dow-list__icon-group > *:nth-child(3) {
  transform: rotate(-45deg);
}
.dow-list__btn--is-loading .dow-list__icon-group > *:nth-child(4) {
  animation: dow-list-obj-4-anim 1s 0.6s infinite;
}

@media not all and (pointer: fine) { /* user hasn't pointing device (e.g., touch device) */
  .dow-list__icon .dow-list__icon-group { /* hide arrow icon */ }
  .dow-list__icon .dow-list__icon-group > *:nth-child(1), .dow-list__icon .dow-list__icon-group > *:nth-child(2), .dow-list__icon .dow-list__icon-group > *:nth-child(3) {
    display: none;
  }
  .dow-list__btn--is-loading .dow-list__icon-group > *:nth-child(4) {
    animation-delay: 0s;
  }
  .dow-list__btn:hover .dow-list__btn-label { /* keep btn label visible */
    transform: scale(1);
    opacity: 1;
  }
  .dow-list__btn.dow-list__btn--is-loading .dow-list__btn-label {
    opacity: 0;
  }
}
@keyframes dow-list-obj-4-anim {
  from {
    stroke-dashoffset: 50;
  }
  to {
    stroke-dashoffset: 150;
  }
}
.dow-list__btn--success .dow-list__icon-group > *:nth-child(4) {
  display: none; /* hide loading circle */
}
.dow-list__btn--success .dow-list__icon-group > *:nth-child(5) {
  stroke-dashoffset: 114;
}
.dow-list__btn--success .dow-list__icon-group > *:nth-child(6) {
  stroke-dashoffset: 0;
}

.dow-list__btn--error {
  animation: dow-list-btn-error-anim 0.4s 0.5s;
}
.dow-list__btn--error .dow-list__icon-group > *:nth-child(4) {
  display: none; /* hide loading circle */
}
.dow-list__btn--error .dow-list__icon-group > *:nth-child(7) {
  stroke-dashoffset: 75;
}
.dow-list__btn--error .dow-list__icon-group > *:nth-child(8) {
  stroke-dashoffset: 0;
}
.dow-list__btn--error .dow-list__icon-group > *:nth-child(9) {
  transform: scale(1);
}

@keyframes dow-list-btn-error-anim {
  0%, 100% {
    transform: translateX(0);
  }
  20%, 60% {
    transform: translateX(4px);
  }
  40%, 80% {
    transform: translateX(-4px);
  }
}
.dow-list__item {
  box-shadow: none;
}

/* -------------------------------- 

File#: _1_card-v2
Title: Card v2
Descr: Container of information used as teasers for further content, often displayed in a gallery of related items
Usage: codyhouse.co/license

-------------------------------- */
.card-v2 {
  position: relative;
  overflow: hidden;
}
.card-v2 img {
  display: block;
  width: 100%;
}

.card-v2__caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0), hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 1));
  background-blend-mode: multiply;
  color: var(--color-white);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
}

.card-v2--blur-fx .card-v2__caption {
  background: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.8);
  backdrop-filter: blur(10px);
}

a.card-v2 {
  display: block;
  text-decoration: none;
  transition: box-shadow 0.2s;
}
a.card-v2:hover {
  box-shadow: var(--shadow-md);
}

/* -------------------------------- 

File#: _2_anim-cards
Title: Animated Cards
Descr: A collection of cards that slides over time
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --anim-cards-width: 280px;
  --anim-cards-gap: var(--space-md);
}

@supports (--css: variables) {
  @media (min-width: 48rem) {
    :root {
      --anim-cards-width: 400px;
    }
  }
  @media (min-width: 64rem) {
    :root {
      --anim-cards-width: 550px;
    }
  }
}
.anim-cards {
  overflow: hidden;
  margin-top: var(--anim-cards-gap);
  margin-bottom: var(--anim-cards-gap);
}

.anim-cards__list {
  display: flex;
  overflow: auto;
}

.anim-cards__item {
  float: left;
  width: var(--anim-cards-width);
  flex-shrink: 0;
  margin-right: var(--anim-cards-gap);
}

.anim-cards-control {
  display: inline-flex;
  width: 40px;
  height: 40px;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.75);
  border-radius: 50%;
  transition: 0.2s;
}
.anim-cards-control i {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  margin: auto;
  color: var(--color-bg);
}
.anim-cards-control i::before, .anim-cards-control i::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: currentColor;
  transition: clip-path 0.2s var(--ease-out);
}
.anim-cards-control i::before {
  clip-path: polygon(1px 1px, 5px 1px, 5px calc(100% - 1px), 1px calc(100% - 1px));
}
.anim-cards-control i::after {
  clip-path: polygon(calc(100% - 5px) 1px, calc(100% - 1px) 1px, calc(100% - 1px) calc(100% - 1px), calc(100% - 5px) calc(100% - 1px));
}
.anim-cards-control:hover {
  cursor: pointer;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.95);
}
.anim-cards-control[aria-pressed=true] i::before {
  clip-path: polygon(2px 0%, 100% 50%, 100% 50%, 2px 100%);
}
.anim-cards-control[aria-pressed=true] i::after {
  clip-path: polygon(calc(100% - 5px) calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 5px) calc(100% - 1px));
}

.anim-cards:not(.anim-cards--anim-off) .anim-cards__list {
  overflow: visible;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.anim-cards--reverse:not(.anim-cards--anim-off) .anim-cards__list {
  flex-direction: row-reverse;
}

.anim-cards {
  position: relative;
}
.anim-cards:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 100px 0 var(--color-bg);
  z-index: 2;
}
.anim-cards:after {
  content: "";
  position: absolute;
  top: 0;
  right: -100px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 100px 0 var(--color-bg);
  z-index: 2;
}

/* -------------------------------- 

File#: _1_masonry
Title: Masonry
Descr: Gallery with elements laid out in optimal position based on available vertical space
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --masonry-grid-gap: var(--space-sm);
  --masonry-col-auto-size: 280px;
}

.masonry__loader {
  display: none;
}

.masonry__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-1 * var(--masonry-grid-gap));
  margin-bottom: calc(-1 * var(--masonry-grid-gap));
}

.masonry__item {
  display: inline-block;
  width: var(--masonry-col-auto-size);
  margin-right: var(--masonry-grid-gap);
  margin-bottom: var(--masonry-grid-gap);
}

.masonry {
  position: relative;
}

.masonry__loader {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.masonry__list {
  opacity: 0;
  transition: opacity 0.4s;
}

.masonry--loaded .masonry__loader {
  display: none;
}
.masonry--loaded .masonry__list {
  opacity: 1;
}

@supports (flex-basis: 0px) {
  .masonry__list {
    flex-direction: column;
  }
  .masonry__item {
    flex-basis: 0px;
  }
}
/* -------------------------------- 

File#: _1_auto-sized-grid
Title: Auto Sized Grid
Descr: A grid layout based on CSS Grid where the columns are automatically created according to a min-width value
Usage: codyhouse.co/license

-------------------------------- */
.grid-auto-xs, .grid-auto-sm, .grid-auto-md, .grid-auto-lg, .grid-auto-xl {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--col-min-width), 1fr));
}

.grid-auto-xs {
  --col-min-width: 8rem;
}

.grid-auto-sm {
  --col-min-width: 10rem;
}

.grid-auto-md {
  --col-min-width: 15rem;
}

.grid-auto-lg {
  --col-min-width: 20rem;
}

.grid-auto-xl {
  --col-min-width: 25rem;
}

@media (min-width: 32rem) {
  .grid-auto-xs\@xs {
    --col-min-width: 8rem;
  }
  .grid-auto-sm\@xs {
    --col-min-width: 10rem;
  }
  .grid-auto-md\@xs {
    --col-min-width: 15rem;
  }
  .grid-auto-lg\@xs {
    --col-min-width: 20rem;
  }
  .grid-auto-xl\@xs {
    --col-min-width: 25rem;
  }
}
@media (min-width: 48rem) {
  .grid-auto-xs\@sm {
    --col-min-width: 8rem;
  }
  .grid-auto-sm\@sm {
    --col-min-width: 10rem;
  }
  .grid-auto-md\@sm {
    --col-min-width: 15rem;
  }
  .grid-auto-lg\@sm {
    --col-min-width: 20rem;
  }
  .grid-auto-xl\@sm {
    --col-min-width: 25rem;
  }
}
@media (min-width: 64rem) {
  .grid-auto-xs\@md {
    --col-min-width: 8rem;
  }
  .grid-auto-sm\@md {
    --col-min-width: 10rem;
  }
  .grid-auto-md\@md {
    --col-min-width: 15rem;
  }
  .grid-auto-lg\@md {
    --col-min-width: 20rem;
  }
  .grid-auto-xl\@md {
    --col-min-width: 25rem;
  }
}
@media (min-width: 80rem) {
  .grid-auto-xs\@lg {
    --col-min-width: 8rem;
  }
  .grid-auto-sm\@lg {
    --col-min-width: 10rem;
  }
  .grid-auto-md\@lg {
    --col-min-width: 15rem;
  }
  .grid-auto-lg\@lg {
    --col-min-width: 20rem;
  }
  .grid-auto-xl\@lg {
    --col-min-width: 25rem;
  }
}
@media (min-width: 90rem) {
  .grid-auto-xs\@xl {
    --col-min-width: 8rem;
  }
  .grid-auto-sm\@xl {
    --col-min-width: 10rem;
  }
  .grid-auto-md\@xl {
    --col-min-width: 15rem;
  }
  .grid-auto-lg\@xl {
    --col-min-width: 20rem;
  }
  .grid-auto-xl\@xl {
    --col-min-width: 25rem;
  }
}
/* --------------------------------

File#: _2_points-of-interest
Title: Points Of Interest
Descr: Highlight the points of interest of an image and provide additional details for each one.
Usage: codyhouse.co/license

-------------------------------- */
.poi {
  pointer-events: none;
}

.poi__item {
  position: absolute;
  pointer-events: auto;
}

.poi__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  --size: 32px;
  height: var(--size);
  width: var(--size);
  border-radius: 50%;
  background-color: var(--color-accent);
  box-shadow: var(--inner-glow-top), var(--shadow-sm);
  cursor: pointer;
  transition: 0.2s;
}
.poi__btn::after { /* pulse ring */
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: inherit;
  pointer-events: none;
  box-shadow: inset 0 0 1px 1px var(--color-accent);
  animation: poi-pulse 2s infinite;
}
.poi__btn .icon {
  color: var(--color-white); /* icon color */
  --size: 12px;
  transition: transform 0.3s var(--ease-out-back);
}
.poi__btn:hover .icon {
  transform: scale(1.1);
}

.poi__item--visited button { /* style of clicked button */
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.95);
  backdrop-filter: blur(10px);
}
.poi__item--visited button::after {
  display: none;
}

@keyframes poi-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.poi__item {
  transform: translate(-50%, -50%);
}

/* -------------------------------- 

File#: _1_ticker
Title: Ticker
Descr: News-like Horizontal Scrolling List
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --ticker-animation-duration: 10s;
  --ticker-gap-x: var(--space-md); /* horizontal gap */
  --ticker-img-width: 280px;
}
@media (min-width: 48rem) {
  :root {
    --ticker-img-width: 400px;
  }
}
@media (min-width: 64rem) {
  :root {
    --ticker-img-width: 550px;
  }
}

.ticker {
  overflow: hidden;
}

.ticker__list {
  display: flex;
  overflow: auto;
  will-change: transform;
  transform: translateZ(0px);
  backface-visibility: hidden;
}

/* class added in JS to trigger the scrolling animation */
.ticker--animate .ticker__list {
  animation: ticker-animation var(--ticker-animation-duration) infinite;
  animation-timing-function: linear;
}

/* pause animation on hover or using an external control button */
.ticker[data-ticker-pause-hover=on] .ticker__list:hover,
.ticker--paused .ticker__list {
  animation-play-state: paused;
}

.ticker__item {
  flex-shrink: 0;
  margin-right: calc(var(--ticker-gap-x) / 2);
  margin-left: calc(var(--ticker-gap-x) / 2);
}

.ticker__img {
  display: block;
  width: var(--ticker-img-width);
}

.ticker-control { /* pause/play button */
  display: inline-flex;
  width: 40px;
  height: 40px;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.75);
  border-radius: 50%;
  transition: 0.2s;
}
.ticker-control i { /* icon */
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  margin: auto;
  color: var(--color-bg); /* icon color */
}
.ticker-control i::before, .ticker-control i::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: currentColor;
  transition: clip-path 0.2s var(--ease-out);
}
.ticker-control i::before {
  clip-path: polygon(1px 1px, 5px 1px, 5px calc(100% - 1px), 1px calc(100% - 1px));
}
.ticker-control i::after {
  clip-path: polygon(calc(100% - 5px) 1px, calc(100% - 1px) 1px, calc(100% - 1px) calc(100% - 1px), calc(100% - 5px) calc(100% - 1px));
}
.ticker-control:hover {
  cursor: pointer;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.95);
}
.ticker-control[aria-pressed=true] i::before {
  clip-path: polygon(2px 0%, 100% 50%, 100% 50%, 2px 100%);
}
.ticker-control[aria-pressed=true] i::after {
  clip-path: polygon(calc(100% - 5px) calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 5px) calc(100% - 1px));
}

/* ticker--anim-off class turns off translate animation - added in JS if reduced-motion option is on */
.ticker:not(.ticker--anim-off) .ticker__list {
  overflow: visible;
}

.ticker--reverse:not(.ticker--anim-off) .ticker__list {
  flex-direction: row-reverse;
}

.ticker--reverse.ticker--animate:not(.ticker--anim-off) .ticker__list {
  transform: translateX(calc(100vw - 100%));
  animation-name: ticker-animation-reverse;
}

/* intermediate steps are added to fix a bug on Safari */
@keyframes ticker-animation {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes ticker-animation-reverse {
  0% {
    transform: translateX(calc(100vw - 100%));
  }
  100% {
    transform: translateX(calc(100vw - 50%));
  }
}
:root {
  --ticker-animation-duration: 20s;
  --ticker-gap-x: var(--space-sm);
  --ticker-img-width: 200px;
}
@media (min-width: 48rem) {
  :root {
    --ticker-img-width: 200px;
  }
}
@media (min-width: 64rem) {
  :root {
    --ticker-img-width: 200px;
  }
}

/* -------------------------------- 

File#: _1_tabbed-navigation
Title: Tabbed Navigation
Descr: Tabbed secondary navigation
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --s-tabs-border-bottom-width: 1px;
  --s-tabs-selected-item-border-bottom-width: 1px;
}

.s-tabs {
  position: relative;
}
.s-tabs::after { /* gradient - truncate text */
  content: "";
  position: absolute;
  right: -1px;
  top: 0;
  height: calc(100% - var(--s-tabs-border-bottom-width));
  width: 2em;
  background: linear-gradient(90deg, hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0), var(--color-bg));
  pointer-events: none;
  z-index: 1;
}

.s-tabs__list {
  display: flex;
  overflow: auto;
  -webkit-overflow-scrolling: auto;
}
.s-tabs__list::after { /* border bottom */
  content: "";
  position: absolute;
  width: 100%;
  height: var(--s-tabs-border-bottom-width);
  left: 0;
  bottom: 0;
  background-color: var(--color-contrast-lower);
}

.s-tabs__link {
  color: var(--color-contrast-medium);
  text-decoration: none;
  display: inline-block;
  padding: var(--space-xs) var(--space-sm);
  white-space: nowrap;
  border-bottom: var(--s-tabs-selected-item-border-bottom-width) solid transparent;
  z-index: 1;
  transition: 0.2s;
}
.s-tabs__link:hover:not(.s-tabs__link--current):not(.tabs__control.s-tabs__link[aria-selected=true]) {
  color: var(--color-contrast-high);
}

.s-tabs__link--current, .tabs__control.s-tabs__link[aria-selected=true] {
  position: relative;
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

:root {
  --s-tabs-border-bottom-width: 2px;
  --s-tabs-selected-item-border-bottom-width: 2px;
}

.tabs__control:focus {
  outline: none;
}

.tabs__control.s-tabs__link[aria-selected=true] {
  text-decoration: none;
}

/* -------------------------------- 

File#: _1_countup
Title: CountUp
Descr: A widget to animate a numerical value by counting to it
Usage: codyhouse.co/license

-------------------------------- */
.countup {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  opacity: 0;
}

.countup--is-visible {
  opacity: 1;
}

/* -------------------------------- 

File#: _1_hero
Title: Hero
Descr: A full-width callout section
Usage: codyhouse.co/license

-------------------------------- */
.hero {
  /* if background image in CSS */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* --overlay-layer */
.hero--overlay-layer::after { /* add a layer in between the img/video background and the content */
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0.65);
  z-index: 1;
}

.hero {
  position: relative;
  min-height: 700px;
  display: flex;
  align-items: center;
}

.hero--overlay-layer .container {
  z-index: 2;
}

.hero-figure {
  transform: translate(-3px, -3px);
}
.hero-figure svg {
  max-height: 650px !important;
}

/* -------------------------------- 

File#: _1_text-points
Title: Text Points
Descr: A list of text components
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --text-points-bullet-size: 32px;
  --text-points-bullet-font-size: 14px;
  --text-points-bullet-margin-right: var(--space-xs);
}

.text-points--counter .text-points__text,
.text-points--letter .text-points__text,
.text-points--icon .text-points__text {
  padding-left: calc(var(--text-points-bullet-size) + var(--text-points-bullet-margin-right));
}
.text-points--counter .text-points__bullet,
.text-points--letter .text-points__bullet,
.text-points--icon .text-points__bullet {
  position: relative;
  margin-left: calc(-1 * var(--text-points-bullet-size));
  left: calc(-1 * var(--text-points-bullet-margin-right));
}
.text-points--counter .text-points__bullet::before,
.text-points--letter .text-points__bullet::before,
.text-points--icon .text-points__bullet::before {
  content: "X";
  display: inline-flex;
  width: var(--text-points-bullet-size);
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
}
.text-points--counter .text-points__bullet::after,
.text-points--letter .text-points__bullet::after,
.text-points--icon .text-points__bullet::after {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: var(--text-points-bullet-size);
  height: var(--text-points-bullet-size);
  background-color: var(--color-bg-dark);
  border-radius: 50%;
  font-size: var(--text-points-bullet-font-size);
  font-weight: 600;
  color: var(--color-contrast-medium);
}

.text-points--counter .text-points__item {
  counter-increment: text-points;
}
.text-points--counter .text-points__bullet::after {
  content: counter(text-points);
}

.text-points--letter .text-points__bullet::after {
  content: "?";
}

.text-points--icon .text-points__bullet::after {
  content: "";
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='%23000000'%3E%3Ccircle cx='16' cy='16' r='16' opacity='.15'%3E%3C/circle%3E%3Cpath d='M16 24a1 1 0 0 1-1-1v-9a1 1 0 0 1 2 0v9a1 1 0 0 1-1 1z'%3E%3C/path%3E%3Ccircle cx='16' cy='9.5' r='1.5'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  background: var(--color-primary);
}

:root {
  --carousel-grid-gap: var(--space-sm);
  --carousel-item-auto-size: 250px;
  --carousel-transition-duration: 0.5s;
}

/* -------------------------------- 

File#: _1_feature-v11
Title: Feature v11
Descr: Feature section containing a boxed content over a background image
Usage: codyhouse.co/license

-------------------------------- */
.feature-v11 {
  position: relative;
  z-index: 1;
}

.feature-v11__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
  color: var(--color-contrast-higher);
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}
.feature-v11__link:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.feature-v11__link:hover .feature-v11__link-icon > * {
  transition: transform 0.3s var(--ease-out);
}
.feature-v11__link:hover .feature-v11__link-icon > *:first-child {
  transform: rotate(-180deg);
}
.feature-v11__link:hover .feature-v11__link-icon > *:last-child {
  transform: rotate(180deg);
}

.feature-v11__link-icon { /* animated arrow */
  --size: 16px;
}
.feature-v11__link-icon > * {
  will-change: transform;
}
.feature-v11__link-icon > *:first-child {
  transform-origin: 8px 5px;
}
.feature-v11__link-icon > *:last-child {
  transform-origin: 8px 11px;
}

.boxed-feature__image {
  border-radius: 60px 0 60px 0;
}

@media not all and (min-width: 64rem) {
  .box-before {
    position: relative;
  }
  .box-before:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 60px;
    height: 60px;
    background: var(--color-bg-dark);
    z-index: -1;
  }
}
/* -------------------------------- 

File#: _3_testimonial-slideshow
Title: Testimonial Slideshow
Descr: A Slideshow to display a gallery of testimonials
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --testimonial-slideshow-profile-img-size: 48px;
}
@media (min-width: 64rem) {
  :root {
    --testimonial-slideshow-profile-img-size: 64px;
  }
}

.testimonial-slideshow {
  --slideshow-height: 320px;
}
@media (min-width: 64rem) {
  .testimonial-slideshow {
    --slideshow-height: 420px;
  }
}

.testimonial-slideshow__profile-img {
  width: var(--testimonial-slideshow-profile-img-size);
  height: var(--testimonial-slideshow-profile-img-size);
  overflow: hidden;
}
.testimonial-slideshow__profile-img img {
  display: block;
  width: 100%;
}

.icon-box__icon {
  max-width: 120px;
  max-height: 120px;
}

/*! purgecss end ignore */
/* variables */
:root {
  /* spacing */
  --space-4xs: 0.125rem;
  --space-3xs: 0.25rem;
  --space-2xs: 0.375rem;
  --space-xs: 0.5rem;
  --space-sm: 0.75rem;
  --space-md: 1.25rem;
  --space-lg: 2rem;
  --space-xl: 3.25rem;
  --space-2xl: 5.25rem;
  --space-3xl: 8.5rem;
  --space-4xl: 13.75rem;
  /* font-family */
  --font-primary: sofia-pro, system-ui, sans-serif;
  --font-secondary: kallisto, system-ui, sans-serif;
  /* font-size */
  --text-xs: 0.6875rem;
  --text-sm: 0.8125rem;
  --text-base: 1rem;
  --text-md: 1.1875rem;
  --text-lg: 1.4375rem;
  --text-xl: 1.75rem;
  --text-2xl: 2.0625rem;
  --text-3xl: 2rem;
  --text-4xl: 3rem;
  /* line-height */
  --line-height-xs: 1.1;
  --line-height-sm: 1.2;
  --line-height-md: 1.4;
  --line-height-lg: 1.58;
  --line-height-xl: 1.72;
  /* max-width */
  --max-width-3xs: 20rem;
  --max-width-2xs: 26rem;
  --max-width-xs: 32rem;
  --max-width-sm: 48rem;
  --max-width-md: 64rem;
  --max-width-lg: 80rem;
  --max-width-xl: 90rem;
  --container-margin-x: var(--space-md);
  /* box-shadow */
  --shadow-ring: 0 0 0 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.05);
  --shadow-xs: 0 0 0 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.02), 0 1px 3px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2);
  --shadow-sm: 0 0.3px 0.4px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.02), 0 0.9px 1.5px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.045), 0 3.5px 6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.09);
  --shadow-md: 0 0.9px 1.25px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.025), 0 3px 5px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.05), 0 12px 20px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.09);
  --shadow-lg: 0 1.2px 1.9px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.01), 0 3px 5px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.015), 0 8px 15px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.05), 0 28px 40px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.1);
  --shadow-xl: 0 1.5px 2.1px -6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.009), 0 3.6px 5.2px -6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.0115), 0 7.3px 10.6px -6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.0125), 0 16.2px 21.9px -6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.025), 0 46px 60px -6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.15);
  /* inner-glow */
  --inner-glow: inset 0 0 0.5px 1px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.075);
  --inner-glow-top: inset 0 1px 0.5px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.075);
  /* border-radius */
  --radius-sm: 0.1875em;
  --radius-md: 0.375em;
  --radius-lg: 0.75em;
  /* z-index */
  --z-index-header: 3;
  --z-index-popover: 5;
  --z-index-fixed-element: 10;
  --z-index-overlay: 15;
  /* timing functions */
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* colors */
:root, [data-theme=default] {
  --color-primary-darker-h: 196;
  --color-primary-darker-s: 100%;
  --color-primary-darker-l: 9%;
  --color-primary-darker: hsl(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l));
  --color-primary-dark-h: 196;
  --color-primary-dark-s: 100%;
  --color-primary-dark-l: 15%;
  --color-primary-dark: hsl(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l));
  --color-primary-h: 196;
  --color-primary-s: 100%;
  --color-primary-l: 21%;
  --color-primary: hsl(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l));
  --color-primary-light-h: 196;
  --color-primary-light-s: 100%;
  --color-primary-light-l: 27%;
  --color-primary-light: hsl(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l));
  --color-primary-lighter-h: 196;
  --color-primary-lighter-s: 100%;
  --color-primary-lighter-l: 33%;
  --color-primary-lighter: hsl(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l));
  --color-accent-darker-h: 196;
  --color-accent-darker-s: 100%;
  --color-accent-darker-l: 27%;
  --color-accent-darker: hsl(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l));
  --color-accent-dark-h: 196;
  --color-accent-dark-s: 100%;
  --color-accent-dark-l: 33%;
  --color-accent-dark: hsl(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l));
  --color-accent-h: 196;
  --color-accent-s: 100%;
  --color-accent-l: 39%;
  --color-accent: hsl(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l));
  --color-accent-light-h: 196;
  --color-accent-light-s: 100%;
  --color-accent-light-l: 45%;
  --color-accent-light: hsl(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l));
  --color-accent-lighter-h: 196;
  --color-accent-lighter-s: 100%;
  --color-accent-lighter-l: 51%;
  --color-accent-lighter: hsl(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l));
  --color-dark-darker-h: 0;
  --color-dark-darker-s: 0%;
  --color-dark-darker-l: 15%;
  --color-dark-darker: hsl(var(--color-dark-darker-h), var(--color-dark-darker-s), var(--color-dark-darker-l));
  --color-dark-dark-h: 0;
  --color-dark-dark-s: 0%;
  --color-dark-dark-l: 21%;
  --color-dark-dark: hsl(var(--color-dark-dark-h), var(--color-dark-dark-s), var(--color-dark-dark-l));
  --color-dark-h: 0;
  --color-dark-s: 0%;
  --color-dark-l: 27%;
  --color-dark: hsl(var(--color-dark-h), var(--color-dark-s), var(--color-dark-l));
  --color-dark-light-h: 0;
  --color-dark-light-s: 0%;
  --color-dark-light-l: 33%;
  --color-dark-light: hsl(var(--color-dark-light-h), var(--color-dark-light-s), var(--color-dark-light-l));
  --color-dark-lighter-h: 0;
  --color-dark-lighter-s: 0%;
  --color-dark-lighter-l: 39%;
  --color-dark-lighter: hsl(var(--color-dark-lighter-h), var(--color-dark-lighter-s), var(--color-dark-lighter-l));
  --color-black-h: 230;
  --color-black-s: 13%;
  --color-black-l: 9%;
  --color-black: hsl(var(--color-black-h), var(--color-black-s), var(--color-black-l));
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-white: hsl(var(--color-white-h), var(--color-white-s), var(--color-white-l));
  --color-warning-darker-h: 35;
  --color-warning-darker-s: 79%;
  --color-warning-darker-l: 48%;
  --color-warning-darker: hsl(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l));
  --color-warning-dark-h: 35;
  --color-warning-dark-s: 79%;
  --color-warning-dark-l: 56%;
  --color-warning-dark: hsl(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l));
  --color-warning-h: 35;
  --color-warning-s: 79%;
  --color-warning-l: 66%;
  --color-warning: hsl(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l));
  --color-warning-light-h: 35;
  --color-warning-light-s: 79%;
  --color-warning-light-l: 74%;
  --color-warning-light: hsl(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l));
  --color-warning-lighter-h: 35;
  --color-warning-lighter-s: 79%;
  --color-warning-lighter-l: 82%;
  --color-warning-lighter: hsl(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l));
  --color-success-darker-h: 170;
  --color-success-darker-s: 78%;
  --color-success-darker-l: 26%;
  --color-success-darker: hsl(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l));
  --color-success-dark-h: 170;
  --color-success-dark-s: 78%;
  --color-success-dark-l: 31%;
  --color-success-dark: hsl(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l));
  --color-success-h: 170;
  --color-success-s: 78%;
  --color-success-l: 36%;
  --color-success: hsl(var(--color-success-h), var(--color-success-s), var(--color-success-l));
  --color-success-light-h: 170;
  --color-success-light-s: 78%;
  --color-success-light-l: 42%;
  --color-success-light: hsl(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l));
  --color-success-lighter-h: 170;
  --color-success-lighter-s: 78%;
  --color-success-lighter-l: 47%;
  --color-success-lighter: hsl(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l));
  --color-error-darker-h: 342;
  --color-error-darker-s: 89%;
  --color-error-darker-l: 38%;
  --color-error-darker: hsl(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l));
  --color-error-dark-h: 342;
  --color-error-dark-s: 89%;
  --color-error-dark-l: 43%;
  --color-error-dark: hsl(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l));
  --color-error-h: 342;
  --color-error-s: 89%;
  --color-error-l: 48%;
  --color-error: hsl(var(--color-error-h), var(--color-error-s), var(--color-error-l));
  --color-error-light-h: 342;
  --color-error-light-s: 89%;
  --color-error-light-l: 56%;
  --color-error-light: hsl(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l));
  --color-error-lighter-h: 342;
  --color-error-lighter-s: 89%;
  --color-error-lighter-l: 62%;
  --color-error-lighter: hsl(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l));
  --color-bg-darker-h: 240;
  --color-bg-darker-s: 4%;
  --color-bg-darker-l: 90%;
  --color-bg-darker: hsl(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l));
  --color-bg-dark-h: 240;
  --color-bg-dark-s: 4%;
  --color-bg-dark-l: 95%;
  --color-bg-dark: hsl(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l));
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-bg: hsl(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l));
  --color-bg-light-h: 0;
  --color-bg-light-s: 0%;
  --color-bg-light-l: 100%;
  --color-bg-light: hsl(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l));
  --color-bg-lighter-h: 0;
  --color-bg-lighter-s: 0%;
  --color-bg-lighter-l: 100%;
  --color-bg-lighter: hsl(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l));
  --color-contrast-lower-h: 240;
  --color-contrast-lower-s: 4%;
  --color-contrast-lower-l: 85%;
  --color-contrast-lower: hsl(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l));
  --color-contrast-low-h: 240;
  --color-contrast-low-s: 4%;
  --color-contrast-low-l: 65%;
  --color-contrast-low: hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l));
  --color-contrast-medium-h: 225;
  --color-contrast-medium-s: 4%;
  --color-contrast-medium-l: 47%;
  --color-contrast-medium: hsl(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l));
  --color-contrast-high-h: 230;
  --color-contrast-high-s: 7%;
  --color-contrast-high-l: 23%;
  --color-contrast-high: hsl(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l));
  --color-contrast-higher-h: 230;
  --color-contrast-higher-s: 13%;
  --color-contrast-higher-l: 9%;
  --color-contrast-higher: hsl(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l));
}

[data-theme=dark] {
  --color-primary-darker-h: 196;
  --color-primary-darker-s: 100%;
  --color-primary-darker-l: 9%;
  --color-primary-darker: hsl(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l));
  --color-primary-dark-h: 196;
  --color-primary-dark-s: 100%;
  --color-primary-dark-l: 15%;
  --color-primary-dark: hsl(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l));
  --color-primary-h: 196;
  --color-primary-s: 100%;
  --color-primary-l: 21%;
  --color-primary: hsl(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l));
  --color-primary-light-h: 196;
  --color-primary-light-s: 100%;
  --color-primary-light-l: 27%;
  --color-primary-light: hsl(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l));
  --color-primary-lighter-h: 196;
  --color-primary-lighter-s: 100%;
  --color-primary-lighter-l: 33%;
  --color-primary-lighter: hsl(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l));
  --color-accent-darker-h: 196;
  --color-accent-darker-s: 100%;
  --color-accent-darker-l: 27%;
  --color-accent-darker: hsl(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l));
  --color-accent-dark-h: 196;
  --color-accent-dark-s: 100%;
  --color-accent-dark-l: 33%;
  --color-accent-dark: hsl(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l));
  --color-accent-h: 196;
  --color-accent-s: 100%;
  --color-accent-l: 39%;
  --color-accent: hsl(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l));
  --color-accent-light-h: 196;
  --color-accent-light-s: 100%;
  --color-accent-light-l: 45%;
  --color-accent-light: hsl(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l));
  --color-accent-lighter-h: 196;
  --color-accent-lighter-s: 100%;
  --color-accent-lighter-l: 51%;
  --color-accent-lighter: hsl(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l));
  --color-dark-darker-h: 0;
  --color-dark-darker-s: 0%;
  --color-dark-darker-l: 15%;
  --color-dark-darker: hsl(var(--color-dark-darker-h), var(--color-dark-darker-s), var(--color-dark-darker-l));
  --color-dark-dark-h: 0;
  --color-dark-dark-s: 0%;
  --color-dark-dark-l: 21%;
  --color-dark-dark: hsl(var(--color-dark-dark-h), var(--color-dark-dark-s), var(--color-dark-dark-l));
  --color-dark-h: 0;
  --color-dark-s: 0%;
  --color-dark-l: 27%;
  --color-dark: hsl(var(--color-dark-h), var(--color-dark-s), var(--color-dark-l));
  --color-dark-light-h: 0;
  --color-dark-light-s: 0%;
  --color-dark-light-l: 33%;
  --color-dark-light: hsl(var(--color-dark-light-h), var(--color-dark-light-s), var(--color-dark-light-l));
  --color-dark-lighter-h: 0;
  --color-dark-lighter-s: 0%;
  --color-dark-lighter-l: 39%;
  --color-dark-lighter: hsl(var(--color-dark-lighter-h), var(--color-dark-lighter-s), var(--color-dark-lighter-l));
  --color-black-h: 230;
  --color-black-s: 13%;
  --color-black-l: 9%;
  --color-black: hsl(var(--color-black-h), var(--color-black-s), var(--color-black-l));
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-white: hsl(var(--color-white-h), var(--color-white-s), var(--color-white-l));
  --color-warning-darker-h: 35;
  --color-warning-darker-s: 79%;
  --color-warning-darker-l: 48%;
  --color-warning-darker: hsl(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l));
  --color-warning-dark-h: 35;
  --color-warning-dark-s: 79%;
  --color-warning-dark-l: 56%;
  --color-warning-dark: hsl(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l));
  --color-warning-h: 35;
  --color-warning-s: 79%;
  --color-warning-l: 66%;
  --color-warning: hsl(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l));
  --color-warning-light-h: 35;
  --color-warning-light-s: 79%;
  --color-warning-light-l: 74%;
  --color-warning-light: hsl(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l));
  --color-warning-lighter-h: 35;
  --color-warning-lighter-s: 79%;
  --color-warning-lighter-l: 82%;
  --color-warning-lighter: hsl(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l));
  --color-success-darker-h: 170;
  --color-success-darker-s: 78%;
  --color-success-darker-l: 26%;
  --color-success-darker: hsl(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l));
  --color-success-dark-h: 170;
  --color-success-dark-s: 78%;
  --color-success-dark-l: 31%;
  --color-success-dark: hsl(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l));
  --color-success-h: 170;
  --color-success-s: 78%;
  --color-success-l: 36%;
  --color-success: hsl(var(--color-success-h), var(--color-success-s), var(--color-success-l));
  --color-success-light-h: 170;
  --color-success-light-s: 78%;
  --color-success-light-l: 42%;
  --color-success-light: hsl(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l));
  --color-success-lighter-h: 170;
  --color-success-lighter-s: 78%;
  --color-success-lighter-l: 47%;
  --color-success-lighter: hsl(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l));
  --color-error-darker-h: 342;
  --color-error-darker-s: 89%;
  --color-error-darker-l: 38%;
  --color-error-darker: hsl(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l));
  --color-error-dark-h: 342;
  --color-error-dark-s: 89%;
  --color-error-dark-l: 43%;
  --color-error-dark: hsl(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l));
  --color-error-h: 342;
  --color-error-s: 89%;
  --color-error-l: 48%;
  --color-error: hsl(var(--color-error-h), var(--color-error-s), var(--color-error-l));
  --color-error-light-h: 342;
  --color-error-light-s: 89%;
  --color-error-light-l: 56%;
  --color-error-light: hsl(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l));
  --color-error-lighter-h: 342;
  --color-error-lighter-s: 89%;
  --color-error-lighter-l: 62%;
  --color-error-lighter: hsl(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l));
  --color-bg-darker-h: 240;
  --color-bg-darker-s: 1%;
  --color-bg-darker-l: 20%;
  --color-bg-darker: hsl(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l));
  --color-bg-dark-h: 0;
  --color-bg-dark-s: 0%;
  --color-bg-dark-l: 23%;
  --color-bg-dark: hsl(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l));
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 27%;
  --color-bg: hsl(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l));
  --color-bg-light-h: 0;
  --color-bg-light-s: 0%;
  --color-bg-light-l: 31%;
  --color-bg-light: hsl(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l));
  --color-bg-lighter-h: 240;
  --color-bg-lighter-s: 1%;
  --color-bg-lighter-l: 34%;
  --color-bg-lighter: hsl(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l));
  --color-contrast-lower-h: 240;
  --color-contrast-lower-s: 1%;
  --color-contrast-lower-l: 37%;
  --color-contrast-lower: hsl(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l));
  --color-contrast-low-h: 240;
  --color-contrast-low-s: 0%;
  --color-contrast-low-l: 50%;
  --color-contrast-low: hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l));
  --color-contrast-medium-h: 240;
  --color-contrast-medium-s: 1%;
  --color-contrast-medium-l: 64%;
  --color-contrast-medium: hsl(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l));
  --color-contrast-high-h: 240;
  --color-contrast-high-s: 5%;
  --color-contrast-high-l: 85%;
  --color-contrast-high: hsl(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l));
  --color-contrast-higher-h: 240;
  --color-contrast-higher-s: 100%;
  --color-contrast-higher-l: 99%;
  --color-contrast-higher: hsl(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l));
}

[data-theme=primary] {
  --color-primary-darker-h: 196;
  --color-primary-darker-s: 100%;
  --color-primary-darker-l: 27%;
  --color-primary-darker: hsl(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l));
  --color-primary-dark-h: 196;
  --color-primary-dark-s: 100%;
  --color-primary-dark-l: 33%;
  --color-primary-dark: hsl(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l));
  --color-primary-h: 196;
  --color-primary-s: 100%;
  --color-primary-l: 39%;
  --color-primary: hsl(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l));
  --color-primary-light-h: 196;
  --color-primary-light-s: 100%;
  --color-primary-light-l: 45%;
  --color-primary-light: hsl(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l));
  --color-primary-lighter-h: 196;
  --color-primary-lighter-s: 100%;
  --color-primary-lighter-l: 51%;
  --color-primary-lighter: hsl(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l));
  --color-accent-darker-h: 196;
  --color-accent-darker-s: 100%;
  --color-accent-darker-l: 27%;
  --color-accent-darker: hsl(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l));
  --color-accent-dark-h: 196;
  --color-accent-dark-s: 100%;
  --color-accent-dark-l: 33%;
  --color-accent-dark: hsl(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l));
  --color-accent-h: 196;
  --color-accent-s: 100%;
  --color-accent-l: 39%;
  --color-accent: hsl(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l));
  --color-accent-light-h: 196;
  --color-accent-light-s: 100%;
  --color-accent-light-l: 45%;
  --color-accent-light: hsl(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l));
  --color-accent-lighter-h: 196;
  --color-accent-lighter-s: 100%;
  --color-accent-lighter-l: 51%;
  --color-accent-lighter: hsl(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l));
  --color-dark-darker-h: 0;
  --color-dark-darker-s: 0%;
  --color-dark-darker-l: 15%;
  --color-dark-darker: hsl(var(--color-dark-darker-h), var(--color-dark-darker-s), var(--color-dark-darker-l));
  --color-dark-dark-h: 0;
  --color-dark-dark-s: 0%;
  --color-dark-dark-l: 21%;
  --color-dark-dark: hsl(var(--color-dark-dark-h), var(--color-dark-dark-s), var(--color-dark-dark-l));
  --color-dark-h: 0;
  --color-dark-s: 0%;
  --color-dark-l: 27%;
  --color-dark: hsl(var(--color-dark-h), var(--color-dark-s), var(--color-dark-l));
  --color-dark-light-h: 0;
  --color-dark-light-s: 0%;
  --color-dark-light-l: 33%;
  --color-dark-light: hsl(var(--color-dark-light-h), var(--color-dark-light-s), var(--color-dark-light-l));
  --color-dark-lighter-h: 0;
  --color-dark-lighter-s: 0%;
  --color-dark-lighter-l: 39%;
  --color-dark-lighter: hsl(var(--color-dark-lighter-h), var(--color-dark-lighter-s), var(--color-dark-lighter-l));
  --color-black-h: 230;
  --color-black-s: 13%;
  --color-black-l: 9%;
  --color-black: hsl(var(--color-black-h), var(--color-black-s), var(--color-black-l));
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-white: hsl(var(--color-white-h), var(--color-white-s), var(--color-white-l));
  --color-warning-darker-h: 35;
  --color-warning-darker-s: 79%;
  --color-warning-darker-l: 48%;
  --color-warning-darker: hsl(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l));
  --color-warning-dark-h: 35;
  --color-warning-dark-s: 79%;
  --color-warning-dark-l: 56%;
  --color-warning-dark: hsl(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l));
  --color-warning-h: 35;
  --color-warning-s: 79%;
  --color-warning-l: 66%;
  --color-warning: hsl(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l));
  --color-warning-light-h: 35;
  --color-warning-light-s: 79%;
  --color-warning-light-l: 74%;
  --color-warning-light: hsl(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l));
  --color-warning-lighter-h: 35;
  --color-warning-lighter-s: 79%;
  --color-warning-lighter-l: 82%;
  --color-warning-lighter: hsl(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l));
  --color-success-darker-h: 170;
  --color-success-darker-s: 78%;
  --color-success-darker-l: 26%;
  --color-success-darker: hsl(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l));
  --color-success-dark-h: 170;
  --color-success-dark-s: 78%;
  --color-success-dark-l: 31%;
  --color-success-dark: hsl(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l));
  --color-success-h: 170;
  --color-success-s: 78%;
  --color-success-l: 36%;
  --color-success: hsl(var(--color-success-h), var(--color-success-s), var(--color-success-l));
  --color-success-light-h: 170;
  --color-success-light-s: 78%;
  --color-success-light-l: 42%;
  --color-success-light: hsl(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l));
  --color-success-lighter-h: 170;
  --color-success-lighter-s: 78%;
  --color-success-lighter-l: 47%;
  --color-success-lighter: hsl(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l));
  --color-error-darker-h: 342;
  --color-error-darker-s: 89%;
  --color-error-darker-l: 38%;
  --color-error-darker: hsl(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l));
  --color-error-dark-h: 342;
  --color-error-dark-s: 89%;
  --color-error-dark-l: 43%;
  --color-error-dark: hsl(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l));
  --color-error-h: 342;
  --color-error-s: 89%;
  --color-error-l: 48%;
  --color-error: hsl(var(--color-error-h), var(--color-error-s), var(--color-error-l));
  --color-error-light-h: 342;
  --color-error-light-s: 89%;
  --color-error-light-l: 56%;
  --color-error-light: hsl(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l));
  --color-error-lighter-h: 342;
  --color-error-lighter-s: 89%;
  --color-error-lighter-l: 62%;
  --color-error-lighter: hsl(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l));
  --color-bg-darker-h: 196;
  --color-bg-darker-s: 100%;
  --color-bg-darker-l: 9%;
  --color-bg-darker: hsl(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l));
  --color-bg-dark-h: 196;
  --color-bg-dark-s: 100%;
  --color-bg-dark-l: 15%;
  --color-bg-dark: hsl(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l));
  --color-bg-h: 196;
  --color-bg-s: 100%;
  --color-bg-l: 21%;
  --color-bg: hsl(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l));
  --color-bg-light-h: 196;
  --color-bg-light-s: 100%;
  --color-bg-light-l: 27%;
  --color-bg-light: hsl(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l));
  --color-bg-lighter-h: 196;
  --color-bg-lighter-s: 100%;
  --color-bg-lighter-l: 33%;
  --color-bg-lighter: hsl(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l));
  --color-contrast-lower-h: 201;
  --color-contrast-lower-s: 40%;
  --color-contrast-lower-l: 36%;
  --color-contrast-lower: hsl(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l));
  --color-contrast-low-h: 203;
  --color-contrast-low-s: 21%;
  --color-contrast-low-l: 51%;
  --color-contrast-low: hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l));
  --color-contrast-medium-h: 206;
  --color-contrast-medium-s: 22%;
  --color-contrast-medium-l: 65%;
  --color-contrast-medium: hsl(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l));
  --color-contrast-high-h: 212;
  --color-contrast-high-s: 25%;
  --color-contrast-high-l: 85%;
  --color-contrast-high: hsl(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l));
  --color-contrast-higher-h: 240;
  --color-contrast-higher-s: 100%;
  --color-contrast-higher-l: 99%;
  --color-contrast-higher: hsl(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l));
}

[data-theme=accent] {
  --color-primary-darker-h: 196;
  --color-primary-darker-s: 100%;
  --color-primary-darker-l: 9%;
  --color-primary-darker: hsl(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l));
  --color-primary-dark-h: 196;
  --color-primary-dark-s: 100%;
  --color-primary-dark-l: 15%;
  --color-primary-dark: hsl(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l));
  --color-primary-h: 196;
  --color-primary-s: 100%;
  --color-primary-l: 21%;
  --color-primary: hsl(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l));
  --color-primary-light-h: 196;
  --color-primary-light-s: 100%;
  --color-primary-light-l: 27%;
  --color-primary-light: hsl(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l));
  --color-primary-lighter-h: 196;
  --color-primary-lighter-s: 100%;
  --color-primary-lighter-l: 33%;
  --color-primary-lighter: hsl(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l));
  --color-accent-darker-h: 196;
  --color-accent-darker-s: 100%;
  --color-accent-darker-l: 9%;
  --color-accent-darker: hsl(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l));
  --color-accent-dark-h: 196;
  --color-accent-dark-s: 100%;
  --color-accent-dark-l: 15%;
  --color-accent-dark: hsl(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l));
  --color-accent-h: 196;
  --color-accent-s: 100%;
  --color-accent-l: 21%;
  --color-accent: hsl(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l));
  --color-accent-light-h: 196;
  --color-accent-light-s: 100%;
  --color-accent-light-l: 27%;
  --color-accent-light: hsl(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l));
  --color-accent-lighter-h: 196;
  --color-accent-lighter-s: 100%;
  --color-accent-lighter-l: 33%;
  --color-accent-lighter: hsl(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l));
  --color-dark-darker-h: 0;
  --color-dark-darker-s: 0%;
  --color-dark-darker-l: 15%;
  --color-dark-darker: hsl(var(--color-dark-darker-h), var(--color-dark-darker-s), var(--color-dark-darker-l));
  --color-dark-dark-h: 0;
  --color-dark-dark-s: 0%;
  --color-dark-dark-l: 21%;
  --color-dark-dark: hsl(var(--color-dark-dark-h), var(--color-dark-dark-s), var(--color-dark-dark-l));
  --color-dark-h: 0;
  --color-dark-s: 0%;
  --color-dark-l: 27%;
  --color-dark: hsl(var(--color-dark-h), var(--color-dark-s), var(--color-dark-l));
  --color-dark-light-h: 0;
  --color-dark-light-s: 0%;
  --color-dark-light-l: 33%;
  --color-dark-light: hsl(var(--color-dark-light-h), var(--color-dark-light-s), var(--color-dark-light-l));
  --color-dark-lighter-h: 0;
  --color-dark-lighter-s: 0%;
  --color-dark-lighter-l: 39%;
  --color-dark-lighter: hsl(var(--color-dark-lighter-h), var(--color-dark-lighter-s), var(--color-dark-lighter-l));
  --color-black-h: 230;
  --color-black-s: 13%;
  --color-black-l: 9%;
  --color-black: hsl(var(--color-black-h), var(--color-black-s), var(--color-black-l));
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-white: hsl(var(--color-white-h), var(--color-white-s), var(--color-white-l));
  --color-warning-darker-h: 35;
  --color-warning-darker-s: 79%;
  --color-warning-darker-l: 48%;
  --color-warning-darker: hsl(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l));
  --color-warning-dark-h: 35;
  --color-warning-dark-s: 79%;
  --color-warning-dark-l: 56%;
  --color-warning-dark: hsl(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l));
  --color-warning-h: 35;
  --color-warning-s: 79%;
  --color-warning-l: 66%;
  --color-warning: hsl(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l));
  --color-warning-light-h: 35;
  --color-warning-light-s: 79%;
  --color-warning-light-l: 74%;
  --color-warning-light: hsl(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l));
  --color-warning-lighter-h: 35;
  --color-warning-lighter-s: 79%;
  --color-warning-lighter-l: 82%;
  --color-warning-lighter: hsl(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l));
  --color-success-darker-h: 170;
  --color-success-darker-s: 78%;
  --color-success-darker-l: 26%;
  --color-success-darker: hsl(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l));
  --color-success-dark-h: 170;
  --color-success-dark-s: 78%;
  --color-success-dark-l: 31%;
  --color-success-dark: hsl(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l));
  --color-success-h: 170;
  --color-success-s: 78%;
  --color-success-l: 36%;
  --color-success: hsl(var(--color-success-h), var(--color-success-s), var(--color-success-l));
  --color-success-light-h: 170;
  --color-success-light-s: 78%;
  --color-success-light-l: 42%;
  --color-success-light: hsl(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l));
  --color-success-lighter-h: 170;
  --color-success-lighter-s: 78%;
  --color-success-lighter-l: 47%;
  --color-success-lighter: hsl(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l));
  --color-error-darker-h: 342;
  --color-error-darker-s: 89%;
  --color-error-darker-l: 38%;
  --color-error-darker: hsl(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l));
  --color-error-dark-h: 342;
  --color-error-dark-s: 89%;
  --color-error-dark-l: 43%;
  --color-error-dark: hsl(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l));
  --color-error-h: 342;
  --color-error-s: 89%;
  --color-error-l: 48%;
  --color-error: hsl(var(--color-error-h), var(--color-error-s), var(--color-error-l));
  --color-error-light-h: 342;
  --color-error-light-s: 89%;
  --color-error-light-l: 56%;
  --color-error-light: hsl(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l));
  --color-error-lighter-h: 342;
  --color-error-lighter-s: 89%;
  --color-error-lighter-l: 62%;
  --color-error-lighter: hsl(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l));
  --color-bg-darker-h: 196;
  --color-bg-darker-s: 100%;
  --color-bg-darker-l: 27%;
  --color-bg-darker: hsl(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l));
  --color-bg-dark-h: 196;
  --color-bg-dark-s: 100%;
  --color-bg-dark-l: 33%;
  --color-bg-dark: hsl(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l));
  --color-bg-h: 196;
  --color-bg-s: 100%;
  --color-bg-l: 39%;
  --color-bg: hsl(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l));
  --color-bg-light-h: 196;
  --color-bg-light-s: 100%;
  --color-bg-light-l: 45%;
  --color-bg-light: hsl(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l));
  --color-bg-lighter-h: 196;
  --color-bg-lighter-s: 100%;
  --color-bg-lighter-l: 51%;
  --color-bg-lighter: hsl(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l));
  --color-contrast-lower-h: 201;
  --color-contrast-lower-s: 40%;
  --color-contrast-lower-l: 36%;
  --color-contrast-lower: hsl(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l));
  --color-contrast-low-h: 203;
  --color-contrast-low-s: 21%;
  --color-contrast-low-l: 51%;
  --color-contrast-low: hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l));
  --color-contrast-medium-h: 206;
  --color-contrast-medium-s: 22%;
  --color-contrast-medium-l: 65%;
  --color-contrast-medium: hsl(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l));
  --color-contrast-high-h: 212;
  --color-contrast-high-s: 25%;
  --color-contrast-high-l: 85%;
  --color-contrast-high: hsl(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l));
  --color-contrast-higher-h: 240;
  --color-contrast-higher-s: 100%;
  --color-contrast-higher-l: 99%;
  --color-contrast-higher: hsl(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l));
}

[data-theme=light] {
  --color-primary-darker-h: 196;
  --color-primary-darker-s: 100%;
  --color-primary-darker-l: 9%;
  --color-primary-darker: hsl(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l));
  --color-primary-dark-h: 196;
  --color-primary-dark-s: 100%;
  --color-primary-dark-l: 15%;
  --color-primary-dark: hsl(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l));
  --color-primary-h: 196;
  --color-primary-s: 100%;
  --color-primary-l: 21%;
  --color-primary: hsl(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l));
  --color-primary-light-h: 196;
  --color-primary-light-s: 100%;
  --color-primary-light-l: 27%;
  --color-primary-light: hsl(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l));
  --color-primary-lighter-h: 196;
  --color-primary-lighter-s: 100%;
  --color-primary-lighter-l: 33%;
  --color-primary-lighter: hsl(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l));
  --color-accent-darker-h: 196;
  --color-accent-darker-s: 100%;
  --color-accent-darker-l: 27%;
  --color-accent-darker: hsl(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l));
  --color-accent-dark-h: 196;
  --color-accent-dark-s: 100%;
  --color-accent-dark-l: 33%;
  --color-accent-dark: hsl(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l));
  --color-accent-h: 196;
  --color-accent-s: 100%;
  --color-accent-l: 39%;
  --color-accent: hsl(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l));
  --color-accent-light-h: 196;
  --color-accent-light-s: 100%;
  --color-accent-light-l: 45%;
  --color-accent-light: hsl(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l));
  --color-accent-lighter-h: 196;
  --color-accent-lighter-s: 100%;
  --color-accent-lighter-l: 51%;
  --color-accent-lighter: hsl(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l));
  --color-dark-darker-h: 0;
  --color-dark-darker-s: 0%;
  --color-dark-darker-l: 15%;
  --color-dark-darker: hsl(var(--color-dark-darker-h), var(--color-dark-darker-s), var(--color-dark-darker-l));
  --color-dark-dark-h: 0;
  --color-dark-dark-s: 0%;
  --color-dark-dark-l: 21%;
  --color-dark-dark: hsl(var(--color-dark-dark-h), var(--color-dark-dark-s), var(--color-dark-dark-l));
  --color-dark-h: 0;
  --color-dark-s: 0%;
  --color-dark-l: 27%;
  --color-dark: hsl(var(--color-dark-h), var(--color-dark-s), var(--color-dark-l));
  --color-dark-light-h: 0;
  --color-dark-light-s: 0%;
  --color-dark-light-l: 33%;
  --color-dark-light: hsl(var(--color-dark-light-h), var(--color-dark-light-s), var(--color-dark-light-l));
  --color-dark-lighter-h: 0;
  --color-dark-lighter-s: 0%;
  --color-dark-lighter-l: 39%;
  --color-dark-lighter: hsl(var(--color-dark-lighter-h), var(--color-dark-lighter-s), var(--color-dark-lighter-l));
  --color-black-h: 230;
  --color-black-s: 13%;
  --color-black-l: 9%;
  --color-black: hsl(var(--color-black-h), var(--color-black-s), var(--color-black-l));
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-white: hsl(var(--color-white-h), var(--color-white-s), var(--color-white-l));
  --color-warning-darker-h: 35;
  --color-warning-darker-s: 79%;
  --color-warning-darker-l: 48%;
  --color-warning-darker: hsl(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l));
  --color-warning-dark-h: 35;
  --color-warning-dark-s: 79%;
  --color-warning-dark-l: 56%;
  --color-warning-dark: hsl(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l));
  --color-warning-h: 35;
  --color-warning-s: 79%;
  --color-warning-l: 66%;
  --color-warning: hsl(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l));
  --color-warning-light-h: 35;
  --color-warning-light-s: 79%;
  --color-warning-light-l: 74%;
  --color-warning-light: hsl(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l));
  --color-warning-lighter-h: 35;
  --color-warning-lighter-s: 79%;
  --color-warning-lighter-l: 82%;
  --color-warning-lighter: hsl(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l));
  --color-success-darker-h: 170;
  --color-success-darker-s: 78%;
  --color-success-darker-l: 26%;
  --color-success-darker: hsl(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l));
  --color-success-dark-h: 170;
  --color-success-dark-s: 78%;
  --color-success-dark-l: 31%;
  --color-success-dark: hsl(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l));
  --color-success-h: 170;
  --color-success-s: 78%;
  --color-success-l: 36%;
  --color-success: hsl(var(--color-success-h), var(--color-success-s), var(--color-success-l));
  --color-success-light-h: 170;
  --color-success-light-s: 78%;
  --color-success-light-l: 42%;
  --color-success-light: hsl(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l));
  --color-success-lighter-h: 170;
  --color-success-lighter-s: 78%;
  --color-success-lighter-l: 47%;
  --color-success-lighter: hsl(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l));
  --color-error-darker-h: 342;
  --color-error-darker-s: 89%;
  --color-error-darker-l: 38%;
  --color-error-darker: hsl(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l));
  --color-error-dark-h: 342;
  --color-error-dark-s: 89%;
  --color-error-dark-l: 43%;
  --color-error-dark: hsl(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l));
  --color-error-h: 342;
  --color-error-s: 89%;
  --color-error-l: 48%;
  --color-error: hsl(var(--color-error-h), var(--color-error-s), var(--color-error-l));
  --color-error-light-h: 342;
  --color-error-light-s: 89%;
  --color-error-light-l: 56%;
  --color-error-light: hsl(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l));
  --color-error-lighter-h: 342;
  --color-error-lighter-s: 89%;
  --color-error-lighter-l: 62%;
  --color-error-lighter: hsl(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l));
  --color-bg-darker-h: 240;
  --color-bg-darker-s: 1%;
  --color-bg-darker-l: 86%;
  --color-bg-darker: hsl(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l));
  --color-bg-dark-h: 0;
  --color-bg-dark-s: 0%;
  --color-bg-dark-l: 90%;
  --color-bg-dark: hsl(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l));
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 96%;
  --color-bg: hsl(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l));
  --color-bg-light-h: 0;
  --color-bg-light-s: 0%;
  --color-bg-light-l: 100%;
  --color-bg-light: hsl(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l));
  --color-bg-lighter-h: 240;
  --color-bg-lighter-s: 1%;
  --color-bg-lighter-l: 100%;
  --color-bg-lighter: hsl(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l));
  --color-contrast-lower-h: 240;
  --color-contrast-lower-s: 4%;
  --color-contrast-lower-l: 85%;
  --color-contrast-lower: hsl(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l));
  --color-contrast-low-h: 240;
  --color-contrast-low-s: 4%;
  --color-contrast-low-l: 65%;
  --color-contrast-low: hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l));
  --color-contrast-medium-h: 225;
  --color-contrast-medium-s: 4%;
  --color-contrast-medium-l: 47%;
  --color-contrast-medium: hsl(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l));
  --color-contrast-high-h: 230;
  --color-contrast-high-s: 7%;
  --color-contrast-high-l: 23%;
  --color-contrast-high: hsl(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l));
  --color-contrast-higher-h: 230;
  --color-contrast-higher-s: 13%;
  --color-contrast-higher-l: 9%;
  --color-contrast-higher: hsl(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l));
}

/* grid */
.grid {
  --grid-columns: 12;
  --column-gap: 0px;
  --row-gap: 0px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--row-gap) var(--column-gap);
}

.grid > * {
  width: 100%;
  min-width: 0;
}

.grid-col-1 {
  --grid-columns: 1;
}

.col-1 {
  --column-span: 1;
}

.grid-col-2 {
  --grid-columns: 2;
}

.col-2 {
  --column-span: 2;
}

.grid-col-3 {
  --grid-columns: 3;
}

.col-3 {
  --column-span: 3;
}

.grid-col-4 {
  --grid-columns: 4;
}

.col-4 {
  --column-span: 4;
}

.grid-col-5 {
  --grid-columns: 5;
}

.col-5 {
  --column-span: 5;
}

.grid-col-6 {
  --grid-columns: 6;
}

.col-6 {
  --column-span: 6;
}

.grid-col-7 {
  --grid-columns: 7;
}

.col-7 {
  --column-span: 7;
}

.grid-col-8 {
  --grid-columns: 8;
}

.col-8 {
  --column-span: 8;
}

.grid-col-9 {
  --grid-columns: 9;
}

.col-9 {
  --column-span: 9;
}

.grid-col-10 {
  --grid-columns: 10;
}

.col-10 {
  --column-span: 10;
}

.grid-col-11 {
  --grid-columns: 11;
}

.col-11 {
  --column-span: 11;
}

.grid-col-12 {
  --grid-columns: 12;
}

.col-12 {
  --column-span: 12;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
  flex: initial;
}

.col {
  width: auto;
  flex: 1 1 0;
}

.col-content {
  width: auto;
  flex: 0 1 auto;
}

.offset-1 {
  --column-offset: 1;
}

.offset-2 {
  --column-offset: 2;
}

.offset-3 {
  --column-offset: 3;
}

.offset-4 {
  --column-offset: 4;
}

.offset-5 {
  --column-offset: 5;
}

.offset-6 {
  --column-offset: 6;
}

.offset-7 {
  --column-offset: 7;
}

.offset-8 {
  --column-offset: 8;
}

.offset-9 {
  --column-offset: 9;
}

.offset-10 {
  --column-offset: 10;
}

.offset-11 {
  --column-offset: 11;
}

.offset-1, .offset-2, .offset-3, .offset-4, .offset-5, .offset-6, .offset-7, .offset-8, .offset-9, .offset-10, .offset-11 {
  margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
}

/* gap */
.gap-4xs {
  --row-gap: var(--space-4xs);
  --column-gap: var(--space-4xs);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-4xs {
  --column-gap: var(--space-4xs);
  column-gap: var(--column-gap);
}

.gap-y-4xs {
  --row-gap: var(--space-4xs);
  row-gap: var(--row-gap);
}

.gap-3xs {
  --row-gap: var(--space-3xs);
  --column-gap: var(--space-3xs);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-3xs {
  --column-gap: var(--space-3xs);
  column-gap: var(--column-gap);
}

.gap-y-3xs {
  --row-gap: var(--space-3xs);
  row-gap: var(--row-gap);
}

.gap-2xs {
  --row-gap: var(--space-2xs);
  --column-gap: var(--space-2xs);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-2xs {
  --column-gap: var(--space-2xs);
  column-gap: var(--column-gap);
}

.gap-y-2xs {
  --row-gap: var(--space-2xs);
  row-gap: var(--row-gap);
}

.gap-xs {
  --row-gap: var(--space-xs);
  --column-gap: var(--space-xs);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-xs {
  --column-gap: var(--space-xs);
  column-gap: var(--column-gap);
}

.gap-y-xs {
  --row-gap: var(--space-xs);
  row-gap: var(--row-gap);
}

.gap-sm {
  --row-gap: var(--space-sm);
  --column-gap: var(--space-sm);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-sm {
  --column-gap: var(--space-sm);
  column-gap: var(--column-gap);
}

.gap-y-sm {
  --row-gap: var(--space-sm);
  row-gap: var(--row-gap);
}

.gap-md {
  --row-gap: var(--space-md);
  --column-gap: var(--space-md);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-md {
  --column-gap: var(--space-md);
  column-gap: var(--column-gap);
}

.gap-y-md {
  --row-gap: var(--space-md);
  row-gap: var(--row-gap);
}

.gap-lg {
  --row-gap: var(--space-lg);
  --column-gap: var(--space-lg);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-lg {
  --column-gap: var(--space-lg);
  column-gap: var(--column-gap);
}

.gap-y-lg {
  --row-gap: var(--space-lg);
  row-gap: var(--row-gap);
}

.gap-xl {
  --row-gap: var(--space-xl);
  --column-gap: var(--space-xl);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-xl {
  --column-gap: var(--space-xl);
  column-gap: var(--column-gap);
}

.gap-y-xl {
  --row-gap: var(--space-xl);
  row-gap: var(--row-gap);
}

.gap-2xl {
  --row-gap: var(--space-2xl);
  --column-gap: var(--space-2xl);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-2xl {
  --column-gap: var(--space-2xl);
  column-gap: var(--column-gap);
}

.gap-y-2xl {
  --row-gap: var(--space-2xl);
  row-gap: var(--row-gap);
}

.gap-3xl {
  --row-gap: var(--space-3xl);
  --column-gap: var(--space-3xl);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-3xl {
  --column-gap: var(--space-3xl);
  column-gap: var(--column-gap);
}

.gap-y-3xl {
  --row-gap: var(--space-3xl);
  row-gap: var(--row-gap);
}

.gap-4xl {
  --row-gap: var(--space-4xl);
  --column-gap: var(--space-4xl);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-4xl {
  --column-gap: var(--space-4xl);
  column-gap: var(--column-gap);
}

.gap-y-4xl {
  --row-gap: var(--space-4xl);
  row-gap: var(--row-gap);
}

.gap-0 {
  --row-gap: 0px;
  --column-gap: 0px;
  gap: 0px;
}

.gap-x-0 {
  --column-gap: 0px;
  column-gap: 0px;
}

.gap-y-0 {
  --row-gap: 0px;
  row-gap: 0px;
}

/* flexbox */
.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-basis-0 {
  flex-basis: 0;
}

/* justify-content */
.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

/* align-items */
.items-center {
  align-items: center;
}

.items-start {
  align-items: start;
}

.items-end {
  align-items: end;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

/* align-content */
.content-start {
  align-content: start;
}

.content-end {
  align-content: end;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

/* order */
.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

/* aspect-ratio */
.aspect-ratio-16\:9 {
  aspect-ratio: 16/9;
}

.aspect-ratio-3\:2 {
  aspect-ratio: 3/2;
}

.aspect-ratio-4\:3 {
  aspect-ratio: 4/3;
}

.aspect-ratio-5\:4 {
  aspect-ratio: 5/4;
}

.aspect-ratio-1\:1 {
  aspect-ratio: 1/1;
}

.aspect-ratio-4\:5 {
  aspect-ratio: 4/5;
}

.aspect-ratio-3\:4 {
  aspect-ratio: 3/4;
}

.aspect-ratio-2\:3 {
  aspect-ratio: 2/3;
}

.aspect-ratio-9\:16 {
  aspect-ratio: 9/16;
}

/* media wrapper */
:where([class^=media-wrapper], [class*=" media-wrapper"]) {
  position: relative;
}

[class^=media-wrapper], [class*=" media-wrapper"] {
  height: 0;
}

[class^=media-wrapper] > *,
[class*=" media-wrapper"] > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

[class^=media-wrapper] > *:not(iframe),
[class*=" media-wrapper"] > *:not(iframe) {
  object-fit: cover;
}

.media-wrapper-16\:9 {
  padding-bottom: 56.25%;
}

.media-wrapper-3\:2 {
  padding-bottom: 66.6666666667%;
}

.media-wrapper-4\:3 {
  padding-bottom: 75%;
}

.media-wrapper-1\:1 {
  padding-bottom: 100%;
}

/* display */
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.contents {
  display: contents;
}

.css-grid {
  display: grid;
}

.css-inline-grid {
  display: inline-grid;
}

.hide {
  display: none;
}

.is-visible {
  display: var(--display, block) !important;
}

.is-hidden {
  display: none !important;
}

/* margin */
.margin-4xs {
  margin: var(--space-4xs);
}

.margin-top-4xs {
  margin-top: var(--space-4xs);
}

.margin-right-4xs {
  margin-right: var(--space-4xs);
}

.margin-bottom-4xs {
  margin-bottom: var(--space-4xs);
}

.margin-left-4xs {
  margin-left: var(--space-4xs);
}

.margin-y-4xs {
  margin-top: var(--space-4xs);
  margin-bottom: var(--space-4xs);
}

.margin-x-4xs {
  margin-right: var(--space-4xs);
  margin-left: var(--space-4xs);
}

.margin-3xs {
  margin: var(--space-3xs);
}

.margin-top-3xs {
  margin-top: var(--space-3xs);
}

.margin-right-3xs {
  margin-right: var(--space-3xs);
}

.margin-bottom-3xs {
  margin-bottom: var(--space-3xs);
}

.margin-left-3xs {
  margin-left: var(--space-3xs);
}

.margin-y-3xs {
  margin-top: var(--space-3xs);
  margin-bottom: var(--space-3xs);
}

.margin-x-3xs {
  margin-right: var(--space-3xs);
  margin-left: var(--space-3xs);
}

.margin-2xs {
  margin: var(--space-2xs);
}

.margin-top-2xs, .wpcf7 .wpcf7-not-valid-tip {
  margin-top: var(--space-2xs);
}

.margin-right-2xs {
  margin-right: var(--space-2xs);
}

.margin-bottom-2xs {
  margin-bottom: var(--space-2xs);
}

.margin-left-2xs {
  margin-left: var(--space-2xs);
}

.margin-y-2xs {
  margin-top: var(--space-2xs);
  margin-bottom: var(--space-2xs);
}

.margin-x-2xs {
  margin-right: var(--space-2xs);
  margin-left: var(--space-2xs);
}

.margin-xs {
  margin: var(--space-xs);
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-right-xs {
  margin-right: var(--space-xs);
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-y-xs {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.margin-x-xs {
  margin-right: var(--space-xs);
  margin-left: var(--space-xs);
}

.margin-sm {
  margin: var(--space-sm);
}

.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-y-sm {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.margin-x-sm {
  margin-right: var(--space-sm);
  margin-left: var(--space-sm);
}

.margin-md {
  margin: var(--space-md);
}

.margin-top-md {
  margin-top: var(--space-md);
}

.margin-right-md {
  margin-right: var(--space-md);
}

.margin-bottom-md {
  margin-bottom: var(--space-md);
}

.margin-left-md {
  margin-left: var(--space-md);
}

.margin-y-md {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}

.margin-x-md {
  margin-right: var(--space-md);
  margin-left: var(--space-md);
}

.margin-lg {
  margin: var(--space-lg);
}

.margin-top-lg {
  margin-top: var(--space-lg);
}

.margin-right-lg {
  margin-right: var(--space-lg);
}

.margin-bottom-lg {
  margin-bottom: var(--space-lg);
}

.margin-left-lg {
  margin-left: var(--space-lg);
}

.margin-y-lg {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.margin-x-lg {
  margin-right: var(--space-lg);
  margin-left: var(--space-lg);
}

.margin-xl {
  margin: var(--space-xl);
}

.margin-top-xl {
  margin-top: var(--space-xl);
}

.margin-right-xl {
  margin-right: var(--space-xl);
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}

.margin-left-xl {
  margin-left: var(--space-xl);
}

.margin-y-xl {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.margin-x-xl {
  margin-right: var(--space-xl);
  margin-left: var(--space-xl);
}

.margin-2xl {
  margin: var(--space-2xl);
}

.margin-top-2xl {
  margin-top: var(--space-2xl);
}

.margin-right-2xl {
  margin-right: var(--space-2xl);
}

.margin-bottom-2xl {
  margin-bottom: var(--space-2xl);
}

.margin-left-2xl {
  margin-left: var(--space-2xl);
}

.margin-y-2xl {
  margin-top: var(--space-2xl);
  margin-bottom: var(--space-2xl);
}

.margin-x-2xl {
  margin-right: var(--space-2xl);
  margin-left: var(--space-2xl);
}

.margin-3xl {
  margin: var(--space-3xl);
}

.margin-top-3xl {
  margin-top: var(--space-3xl);
}

.margin-right-3xl {
  margin-right: var(--space-3xl);
}

.margin-bottom-3xl {
  margin-bottom: var(--space-3xl);
}

.margin-left-3xl {
  margin-left: var(--space-3xl);
}

.margin-y-3xl {
  margin-top: var(--space-3xl);
  margin-bottom: var(--space-3xl);
}

.margin-x-3xl {
  margin-right: var(--space-3xl);
  margin-left: var(--space-3xl);
}

.margin-4xl {
  margin: var(--space-4xl);
}

.margin-top-4xl {
  margin-top: var(--space-4xl);
}

.margin-right-4xl {
  margin-right: var(--space-4xl);
}

.margin-bottom-4xl {
  margin-bottom: var(--space-4xl);
}

.margin-left-4xl {
  margin-left: var(--space-4xl);
}

.margin-y-4xl {
  margin-top: var(--space-4xl);
  margin-bottom: var(--space-4xl);
}

.margin-x-4xl {
  margin-right: var(--space-4xl);
  margin-left: var(--space-4xl);
}

.margin-auto {
  margin: auto;
}

.margin-0 {
  margin: 0;
}

.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.margin-top-auto {
  margin-top: auto;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-right-auto {
  margin-right: auto;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-bottom-auto {
  margin-bottom: auto;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-0 {
  margin-left: 0;
}

/* padding */
.padding-4xs {
  padding: var(--space-4xs);
}

.padding-top-4xs {
  padding-top: var(--space-4xs);
}

.padding-right-4xs {
  padding-right: var(--space-4xs);
}

.padding-bottom-4xs {
  padding-bottom: var(--space-4xs);
}

.padding-left-4xs {
  padding-left: var(--space-4xs);
}

.padding-y-4xs {
  padding-top: var(--space-4xs);
  padding-bottom: var(--space-4xs);
}

.padding-x-4xs {
  padding-right: var(--space-4xs);
  padding-left: var(--space-4xs);
}

.padding-3xs {
  padding: var(--space-3xs);
}

.padding-top-3xs {
  padding-top: var(--space-3xs);
}

.padding-right-3xs {
  padding-right: var(--space-3xs);
}

.padding-bottom-3xs {
  padding-bottom: var(--space-3xs);
}

.padding-left-3xs {
  padding-left: var(--space-3xs);
}

.padding-y-3xs {
  padding-top: var(--space-3xs);
  padding-bottom: var(--space-3xs);
}

.padding-x-3xs {
  padding-right: var(--space-3xs);
  padding-left: var(--space-3xs);
}

.padding-2xs {
  padding: var(--space-2xs);
}

.padding-top-2xs {
  padding-top: var(--space-2xs);
}

.padding-right-2xs {
  padding-right: var(--space-2xs);
}

.padding-bottom-2xs {
  padding-bottom: var(--space-2xs);
}

.padding-left-2xs {
  padding-left: var(--space-2xs);
}

.padding-y-2xs {
  padding-top: var(--space-2xs);
  padding-bottom: var(--space-2xs);
}

.padding-x-2xs {
  padding-right: var(--space-2xs);
  padding-left: var(--space-2xs);
}

.padding-xs, .wpcf7 .wpcf7-not-valid-tip {
  padding: var(--space-xs);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-y-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.padding-x-xs {
  padding-right: var(--space-xs);
  padding-left: var(--space-xs);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-y-sm {
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
}

.padding-x-sm {
  padding-right: var(--space-sm);
  padding-left: var(--space-sm);
}

.padding-md {
  padding: var(--space-md);
}

.padding-top-md {
  padding-top: var(--space-md);
}

.padding-right-md {
  padding-right: var(--space-md);
}

.padding-bottom-md {
  padding-bottom: var(--space-md);
}

.padding-left-md {
  padding-left: var(--space-md);
}

.padding-y-md {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);
}

.padding-x-md {
  padding-right: var(--space-md);
  padding-left: var(--space-md);
}

.padding-lg {
  padding: var(--space-lg);
}

.padding-top-lg {
  padding-top: var(--space-lg);
}

.padding-right-lg {
  padding-right: var(--space-lg);
}

.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}

.padding-left-lg {
  padding-left: var(--space-lg);
}

.padding-y-lg {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}

.padding-x-lg {
  padding-right: var(--space-lg);
  padding-left: var(--space-lg);
}

.padding-xl {
  padding: var(--space-xl);
}

.padding-top-xl {
  padding-top: var(--space-xl);
}

.padding-right-xl {
  padding-right: var(--space-xl);
}

.padding-bottom-xl {
  padding-bottom: var(--space-xl);
}

.padding-left-xl {
  padding-left: var(--space-xl);
}

.padding-y-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-x-xl {
  padding-right: var(--space-xl);
  padding-left: var(--space-xl);
}

.padding-2xl {
  padding: var(--space-2xl);
}

.padding-top-2xl {
  padding-top: var(--space-2xl);
}

.padding-right-2xl {
  padding-right: var(--space-2xl);
}

.padding-bottom-2xl {
  padding-bottom: var(--space-2xl);
}

.padding-left-2xl {
  padding-left: var(--space-2xl);
}

.padding-y-2xl {
  padding-top: var(--space-2xl);
  padding-bottom: var(--space-2xl);
}

.padding-x-2xl {
  padding-right: var(--space-2xl);
  padding-left: var(--space-2xl);
}

.padding-3xl {
  padding: var(--space-3xl);
}

.padding-top-3xl {
  padding-top: var(--space-3xl);
}

.padding-right-3xl {
  padding-right: var(--space-3xl);
}

.padding-bottom-3xl {
  padding-bottom: var(--space-3xl);
}

.padding-left-3xl {
  padding-left: var(--space-3xl);
}

.padding-y-3xl {
  padding-top: var(--space-3xl);
  padding-bottom: var(--space-3xl);
}

.padding-x-3xl {
  padding-right: var(--space-3xl);
  padding-left: var(--space-3xl);
}

.padding-4xl {
  padding: var(--space-4xl);
}

.padding-top-4xl {
  padding-top: var(--space-4xl);
}

.padding-right-4xl {
  padding-right: var(--space-4xl);
}

.padding-bottom-4xl {
  padding-bottom: var(--space-4xl);
}

.padding-left-4xl {
  padding-left: var(--space-4xl);
}

.padding-y-4xl {
  padding-top: var(--space-4xl);
  padding-bottom: var(--space-4xl);
}

.padding-x-4xl {
  padding-right: var(--space-4xl);
  padding-left: var(--space-4xl);
}

.padding-0 {
  padding: 0;
}

.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-left-0 {
  padding-left: 0;
}

/* vertical-align */
.align-baseline {
  vertical-align: baseline;
}

.align-sub {
  vertical-align: sub;
}

.align-super {
  vertical-align: super;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

/* typography */
.truncate, .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}

.break-word {
  overflow-wrap: break-word;
  min-width: 0;
}

/* font-size */
.text-xs {
  font-size: var(--text-xs);
}

.text-sm, .wpcf7 .wpcf7-not-valid-tip {
  font-size: var(--text-sm);
}

.text-base {
  font-size: var(--text-base);
}

.text-md {
  font-size: var(--text-md);
}

.text-lg {
  font-size: var(--text-lg);
}

.text-xl {
  font-size: var(--text-xl);
}

.text-2xl {
  font-size: var(--text-2xl);
}

.text-3xl {
  font-size: var(--text-3xl);
}

.text-4xl {
  font-size: var(--text-4xl);
}

/* text-transform */
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/* letter-spacing */
.letter-spacing-xs {
  letter-spacing: -0.1em;
}

.letter-spacing-sm {
  letter-spacing: -0.05em;
}

.letter-spacing-md {
  letter-spacing: 0.05em;
}

.letter-spacing-lg {
  letter-spacing: 0.1em;
}

.letter-spacing-xl {
  letter-spacing: 0.2em;
}

/* font-weight */
.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

/* font-style */
.font-italic {
  font-style: italic;
}

/* font-smooth */
.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: var(--font-primary);
}

.font-secondary {
  font-family: var(--font-secondary);
}

/* text-align */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* text-decoration */
.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

/* text-shadow */
.text-shadow-xs {
  text-shadow: 0 1px 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.15);
}

.text-shadow-sm {
  text-shadow: 0 1px 2px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.25);
}

.text-shadow-md {
  text-shadow: 0 1px 2px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.1), 0 2px 4px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2);
}

.text-shadow-lg {
  text-shadow: 0 1px 4px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.1), 0 2px 8px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.15), 0 4px 16px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2);
}

.text-shadow-xl {
  text-shadow: 0 1px 4px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.1), 0 2px 8px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.15), 0 4px 16px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2), 0 6px 24px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.25);
}

.text-shadow-none {
  text-shadow: none;
}

/* line-height */
.line-height-normal {
  line-height: normal;
}

.line-height-1 {
  line-height: 1;
}

.line-height-xs {
  line-height: var(--line-height-xs);
}

.line-height-sm {
  line-height: var(--line-height-sm);
}

.line-height-md {
  line-height: var(--line-height-md);
}

.line-height-lg {
  line-height: var(--line-height-lg);
}

.line-height-xl {
  line-height: var(--line-height-xl);
}

/* white-space */
.ws-nowrap {
  white-space: nowrap;
}

/* column-count */
.column-count-1 {
  column-count: 1;
}

.column-count-2 {
  column-count: 2;
}

.column-count-3 {
  column-count: 3;
}

.column-count-4 {
  column-count: 4;
}

/* cursor */
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

/* pointer-events */
.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

/* user-select */
.user-select-none {
  user-select: none;
}

.user-select-all {
  user-select: all;
}

/* color */
[class^=color-], [class*=" color-"] {
  --color-opacity: 1;
}

.color-inherit {
  color: inherit;
}

.color-primary-darker {
  color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--color-opacity, 1));
}

.color-primary-dark {
  color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--color-opacity, 1));
}

.color-primary {
  color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--color-opacity, 1));
}

.color-primary-light {
  color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--color-opacity, 1));
}

.color-primary-lighter {
  color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--color-opacity, 1));
}

.color-accent-darker {
  color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--color-opacity, 1));
}

.color-accent-dark {
  color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--color-opacity, 1));
}

.color-accent {
  color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--color-opacity, 1));
}

.color-accent-light {
  color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--color-opacity, 1));
}

.color-accent-lighter {
  color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--color-opacity, 1));
}

.color-dark-darker {
  color: hsla(var(--color-dark-darker-h), var(--color-dark-darker-s), var(--color-dark-darker-l), var(--color-opacity, 1));
}

.color-dark-dark {
  color: hsla(var(--color-dark-dark-h), var(--color-dark-dark-s), var(--color-dark-dark-l), var(--color-opacity, 1));
}

.color-dark {
  color: hsla(var(--color-dark-h), var(--color-dark-s), var(--color-dark-l), var(--color-opacity, 1));
}

.color-dark-light {
  color: hsla(var(--color-dark-light-h), var(--color-dark-light-s), var(--color-dark-light-l), var(--color-opacity, 1));
}

.color-dark-lighter {
  color: hsla(var(--color-dark-lighter-h), var(--color-dark-lighter-s), var(--color-dark-lighter-l), var(--color-opacity, 1));
}

.color-black {
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--color-opacity, 1));
}

.color-white {
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--color-opacity, 1));
}

.color-warning-darker {
  color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--color-opacity, 1));
}

.color-warning-dark {
  color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--color-opacity, 1));
}

.color-warning {
  color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--color-opacity, 1));
}

.color-warning-light {
  color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--color-opacity, 1));
}

.color-warning-lighter {
  color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--color-opacity, 1));
}

.color-success-darker {
  color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--color-opacity, 1));
}

.color-success-dark {
  color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--color-opacity, 1));
}

.color-success {
  color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--color-opacity, 1));
}

.color-success-light {
  color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--color-opacity, 1));
}

.color-success-lighter {
  color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--color-opacity, 1));
}

.color-error-darker {
  color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--color-opacity, 1));
}

.color-error-dark {
  color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--color-opacity, 1));
}

.color-error {
  color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--color-opacity, 1));
}

.color-error-light {
  color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--color-opacity, 1));
}

.color-error-lighter {
  color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--color-opacity, 1));
}

.color-bg-darker {
  color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--color-opacity, 1));
}

.color-bg-dark {
  color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--color-opacity, 1));
}

.color-bg {
  color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--color-opacity, 1));
}

.color-bg-light {
  color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--color-opacity, 1));
}

.color-bg-lighter {
  color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--color-opacity, 1));
}

.color-contrast-lower {
  color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--color-opacity, 1));
}

.color-contrast-low {
  color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--color-opacity, 1));
}

.color-contrast-medium {
  color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--color-opacity, 1));
}

.color-contrast-high {
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--color-opacity, 1));
}

.color-contrast-higher, .wpcf7 .wpcf7-not-valid-tip {
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--color-opacity, 1));
}

.color-opacity-0 {
  --color-opacity: 0;
}

.color-opacity-5\% {
  --color-opacity: 0.05;
}

.color-opacity-10\% {
  --color-opacity: 0.1;
}

.color-opacity-15\% {
  --color-opacity: 0.15;
}

.color-opacity-20\% {
  --color-opacity: 0.2;
}

.color-opacity-25\% {
  --color-opacity: 0.25;
}

.color-opacity-30\% {
  --color-opacity: 0.3;
}

.color-opacity-40\% {
  --color-opacity: 0.4;
}

.color-opacity-50\% {
  --color-opacity: 0.5;
}

.color-opacity-60\% {
  --color-opacity: 0.6;
}

.color-opacity-70\% {
  --color-opacity: 0.7;
}

.color-opacity-75\% {
  --color-opacity: 0.75;
}

.color-opacity-80\% {
  --color-opacity: 0.8;
}

.color-opacity-85\% {
  --color-opacity: 0.85;
}

.color-opacity-90\% {
  --color-opacity: 0.9;
}

.color-opacity-95\% {
  --color-opacity: 0.95;
}

/* gradient */
[class^=color-gradient], [class*=" color-gradient"] {
  color: transparent !important;
  background-clip: text;
}

/* width */
.width-4xs {
  width: 0.25rem;
}

.width-3xs {
  width: 0.5rem;
}

.width-2xs {
  width: 0.75rem;
}

.width-xs {
  width: 1rem;
}

.width-sm {
  width: 1.5rem;
}

.width-md {
  width: 2rem;
}

.width-lg {
  width: 3rem;
}

.width-xl {
  width: 4rem;
}

.width-2xl {
  width: 6rem;
}

.width-3xl {
  width: 8rem;
}

.width-4xl {
  width: 16rem;
}

.width-0 {
  width: 0;
}

.width-10\% {
  width: 10%;
}

.width-20\% {
  width: 20%;
}

.width-25\% {
  width: 25%;
}

.width-30\% {
  width: 30%;
}

.width-33\% {
  width: 33%;
}

.width-40\% {
  width: 40%;
}

.width-50\% {
  width: 50%;
}

.width-60\% {
  width: 60%;
}

.width-70\% {
  width: 70%;
}

.width-75\% {
  width: 75%;
}

.width-80\% {
  width: 80%;
}

.width-90\% {
  width: 90%;
}

.width-100\% {
  width: 100%;
}

.width-100vw {
  width: 100vw;
}

.width-auto {
  width: auto;
}

.width-inherit {
  width: inherit;
}

/* height */
.height-4xs {
  height: 0.25rem;
}

.height-3xs {
  height: 0.5rem;
}

.height-2xs {
  height: 0.75rem;
}

.height-xs {
  height: 1rem;
}

.height-sm {
  height: 1.5rem;
}

.height-md {
  height: 2rem;
}

.height-lg {
  height: 3rem;
}

.height-xl {
  height: 4rem;
}

.height-2xl {
  height: 6rem;
}

.height-3xl {
  height: 8rem;
}

.height-4xl {
  height: 16rem;
}

.height-0 {
  height: 0;
}

.height-10\% {
  height: 10%;
}

.height-20\% {
  height: 20%;
}

.height-25\% {
  height: 25%;
}

.height-30\% {
  height: 30%;
}

.height-33\% {
  height: 33%;
}

.height-40\% {
  height: 40%;
}

.height-50\% {
  height: 50%;
}

.height-60\% {
  height: 60%;
}

.height-70\% {
  height: 70%;
}

.height-75\% {
  height: 75%;
}

.height-80\% {
  height: 80%;
}

.height-90\% {
  height: 90%;
}

.height-100\% {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.height-auto {
  height: auto;
}

.height-inherit {
  height: inherit;
}

/* min-width */
.min-width-0 {
  min-width: 0;
}

.min-width-25\% {
  min-width: 25%;
}

.min-width-33\% {
  min-width: 33.3333333333%;
}

.min-width-50\% {
  min-width: 50%;
}

.min-width-66\% {
  min-width: 66.6666666667%;
}

.min-width-75\% {
  min-width: 75%;
}

.min-width-100\% {
  min-width: 100%;
}

.min-width-100vw {
  min-width: 100vw;
}

/* min-height */
.min-height-0 {
  min-height: 0;
}

.min-height-100\% {
  min-height: 100%;
}

.min-height-100vh {
  min-height: 100vh;
}

/* max-width */
.container {
  width: calc(100% - 2 * var(--container-margin-x));
  margin-left: auto;
  margin-right: auto;
}

.max-width-3xs {
  max-width: var(--max-width-3xs);
}

.max-width-2xs {
  max-width: var(--max-width-2xs);
}

.max-width-xs {
  max-width: var(--max-width-xs);
}

.max-width-sm {
  max-width: var(--max-width-sm);
}

.max-width-md {
  max-width: var(--max-width-md);
}

.max-width-lg {
  max-width: var(--max-width-lg);
}

.max-width-xl {
  max-width: var(--max-width-xl);
}

.max-width-100\% {
  max-width: 100%;
}

.max-width-none {
  max-width: none;
}

[class^=max-width-adaptive], [class*=" max-width-adaptive"] {
  max-width: 32rem;
}

@media (min-width: 48rem) {
  .max-width-adaptive-sm, .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl {
    max-width: 48rem;
  }
}
@media (min-width: 64rem) {
  .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl {
    max-width: 64rem;
  }
}
@media (min-width: 80rem) {
  .max-width-adaptive-lg, .max-width-adaptive-xl {
    max-width: 80rem;
  }
}
@media (min-width: 90rem) {
  .max-width-adaptive-xl {
    max-width: 90rem;
  }
}
/* max-height */
.max-height-100\% {
  max-height: 100%;
}

.max-height-100vh {
  max-height: 100vh;
}

/* box-shadow */
.shadow-ring {
  box-shadow: var(--shadow-ring);
}

.shadow-xs {
  box-shadow: var(--shadow-xs);
}

.shadow-xs.shadow-ring {
  box-shadow: var(--shadow-xs), var(--shadow-ring);
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow-sm.shadow-ring {
  box-shadow: var(--shadow-sm), var(--shadow-ring);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.shadow-md.shadow-ring {
  box-shadow: var(--shadow-md), var(--shadow-ring);
}

.shadow-lg {
  box-shadow: var(--shadow-lg);
}

.shadow-lg.shadow-ring {
  box-shadow: var(--shadow-lg), var(--shadow-ring);
}

.shadow-xl {
  box-shadow: var(--shadow-xl);
}

.shadow-xl.shadow-ring {
  box-shadow: var(--shadow-xl), var(--shadow-ring);
}

.shadow-none {
  box-shadow: none;
}

/* inner-glow */
:where(.inner-glow, .inner-glow-top) {
  position: relative;
}

.inner-glow::after, .inner-glow-top::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  pointer-events: none;
}

.inner-glow::after {
  box-shadow: var(--inner-glow);
}

.inner-glow-top::after {
  box-shadow: var(--inner-glow-top);
}

/* position */
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.position-static {
  position: static;
}

.inset-0 {
  inset: 0;
}

.top-4xs {
  top: var(--space-4xs);
}

.right-4xs {
  right: var(--space-4xs);
}

.bottom-4xs {
  bottom: var(--space-4xs);
}

.left-4xs {
  left: var(--space-4xs);
}

.top-3xs {
  top: var(--space-3xs);
}

.right-3xs {
  right: var(--space-3xs);
}

.bottom-3xs {
  bottom: var(--space-3xs);
}

.left-3xs {
  left: var(--space-3xs);
}

.top-2xs {
  top: var(--space-2xs);
}

.right-2xs {
  right: var(--space-2xs);
}

.bottom-2xs {
  bottom: var(--space-2xs);
}

.left-2xs {
  left: var(--space-2xs);
}

.top-xs {
  top: var(--space-xs);
}

.right-xs {
  right: var(--space-xs);
}

.bottom-xs {
  bottom: var(--space-xs);
}

.left-xs {
  left: var(--space-xs);
}

.top-sm {
  top: var(--space-sm);
}

.right-sm {
  right: var(--space-sm);
}

.bottom-sm {
  bottom: var(--space-sm);
}

.left-sm {
  left: var(--space-sm);
}

.top-md {
  top: var(--space-md);
}

.right-md {
  right: var(--space-md);
}

.bottom-md {
  bottom: var(--space-md);
}

.left-md {
  left: var(--space-md);
}

.top-lg {
  top: var(--space-lg);
}

.right-lg {
  right: var(--space-lg);
}

.bottom-lg {
  bottom: var(--space-lg);
}

.left-lg {
  left: var(--space-lg);
}

.top-xl {
  top: var(--space-xl);
}

.right-xl {
  right: var(--space-xl);
}

.bottom-xl {
  bottom: var(--space-xl);
}

.left-xl {
  left: var(--space-xl);
}

.top-2xl {
  top: var(--space-2xl);
}

.right-2xl {
  right: var(--space-2xl);
}

.bottom-2xl {
  bottom: var(--space-2xl);
}

.left-2xl {
  left: var(--space-2xl);
}

.top-3xl {
  top: var(--space-3xl);
}

.right-3xl {
  right: var(--space-3xl);
}

.bottom-3xl {
  bottom: var(--space-3xl);
}

.left-3xl {
  left: var(--space-3xl);
}

.top-4xl {
  top: var(--space-4xl);
}

.right-4xl {
  right: var(--space-4xl);
}

.bottom-4xl {
  bottom: var(--space-4xl);
}

.left-4xl {
  left: var(--space-4xl);
}

.top-50\% {
  top: 50%;
}

.top-auto {
  top: auto;
}

.top-0 {
  top: 0;
}

.right-50\% {
  right: 50%;
}

.right-auto {
  right: auto;
}

.right-0 {
  right: 0;
}

.bottom-50\% {
  bottom: 50%;
}

.bottom-auto {
  bottom: auto;
}

.bottom-0 {
  bottom: 0;
}

.left-50\% {
  left: 50%;
}

.left-auto {
  left: auto;
}

.left-0 {
  left: 0;
}

/* z-index */
.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-header {
  z-index: var(--z-index-header);
}

.z-index-popover {
  z-index: var(--z-index-popover);
}

.z-index-fixed-element {
  z-index: var(--z-index-fixed-element);
}

.z-index-overlay {
  z-index: var(--z-index-overlay);
}

/* overflow */
.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.momentum-scrolling {
  -webkit-overflow-scrolling: touch;
}

/* overscroll-behavior */
.overscroll-contain {
  overscroll-behavior: contain;
}

/* scroll behavior */
.scroll-smooth {
  scroll-behavior: smooth;
}

.scroll-padding-4xs {
  scroll-padding: var(--space-4xs);
}

.scroll-padding-3xs {
  scroll-padding: var(--space-3xs);
}

.scroll-padding-2xs {
  scroll-padding: var(--space-2xs);
}

.scroll-padding-xs {
  scroll-padding: var(--space-xs);
}

.scroll-padding-sm {
  scroll-padding: var(--space-sm);
}

.scroll-padding-md {
  scroll-padding: var(--space-md);
}

.scroll-padding-lg {
  scroll-padding: var(--space-lg);
}

.scroll-padding-xl {
  scroll-padding: var(--space-xl);
}

.scroll-padding-2xl {
  scroll-padding: var(--space-2xl);
}

.scroll-padding-3xl {
  scroll-padding: var(--space-3xl);
}

.scroll-padding-4xl {
  scroll-padding: var(--space-4xl);
}

/* accessibility */
.sr-only, .sr-only-focusable:not(:focus):not(:focus-within) {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

/* opacity */
.opacity-0 {
  opacity: 0;
}

.opacity-5\% {
  opacity: 0.05;
}

.opacity-10\% {
  opacity: 0.1;
}

.opacity-15\% {
  opacity: 0.15;
}

.opacity-20\% {
  opacity: 0.2;
}

.opacity-25\% {
  opacity: 0.25;
}

.opacity-30\% {
  opacity: 0.3;
}

.opacity-40\% {
  opacity: 0.4;
}

.opacity-50\% {
  opacity: 0.5;
}

.opacity-60\% {
  opacity: 0.6;
}

.opacity-70\% {
  opacity: 0.7;
}

.opacity-75\% {
  opacity: 0.75;
}

.opacity-80\% {
  opacity: 0.8;
}

.opacity-85\% {
  opacity: 0.85;
}

.opacity-90\% {
  opacity: 0.9;
}

.opacity-95\% {
  opacity: 0.95;
}

/* float */
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/* border */
[class^=border-], [class*=" border-"] {
  --border-opacity: 1;
  --border-width: 1px;
  --border-style: solid;
}

.border {
  border: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity-base, 0.1));
}

.border-top {
  border-top: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity-base, 0.1));
}

.border-bottom {
  border-bottom: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity-base, 0.1));
}

.border-left {
  border-left: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity-base, 0.1));
}

.border-right {
  border-right: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity-base, 0.1));
}

.border-2 {
  --border-width: 2px;
}

.border-3 {
  --border-width: 3px;
}

.border-4 {
  --border-width: 4px;
}

.border-dotted {
  --border-style: dotted;
}

.border-dashed {
  --border-style: dashed;
}

.border-primary-darker {
  border-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--border-opacity, 1));
}

.border-primary-dark {
  border-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--border-opacity, 1));
}

.border-primary {
  border-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--border-opacity, 1));
}

.border-primary-light {
  border-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--border-opacity, 1));
}

.border-primary-lighter {
  border-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--border-opacity, 1));
}

.border-accent-darker {
  border-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--border-opacity, 1));
}

.border-accent-dark {
  border-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--border-opacity, 1));
}

.border-accent {
  border-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--border-opacity, 1));
}

.border-accent-light {
  border-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--border-opacity, 1));
}

.border-accent-lighter {
  border-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--border-opacity, 1));
}

.border-dark-darker {
  border-color: hsla(var(--color-dark-darker-h), var(--color-dark-darker-s), var(--color-dark-darker-l), var(--border-opacity, 1));
}

.border-dark-dark {
  border-color: hsla(var(--color-dark-dark-h), var(--color-dark-dark-s), var(--color-dark-dark-l), var(--border-opacity, 1));
}

.border-dark {
  border-color: hsla(var(--color-dark-h), var(--color-dark-s), var(--color-dark-l), var(--border-opacity, 1));
}

.border-dark-light {
  border-color: hsla(var(--color-dark-light-h), var(--color-dark-light-s), var(--color-dark-light-l), var(--border-opacity, 1));
}

.border-dark-lighter {
  border-color: hsla(var(--color-dark-lighter-h), var(--color-dark-lighter-s), var(--color-dark-lighter-l), var(--border-opacity, 1));
}

.border-black {
  border-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--border-opacity, 1));
}

.border-white {
  border-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--border-opacity, 1));
}

.border-warning-darker {
  border-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--border-opacity, 1));
}

.border-warning-dark {
  border-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--border-opacity, 1));
}

.border-warning {
  border-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--border-opacity, 1));
}

.border-warning-light {
  border-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--border-opacity, 1));
}

.border-warning-lighter {
  border-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--border-opacity, 1));
}

.border-success-darker {
  border-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--border-opacity, 1));
}

.border-success-dark {
  border-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--border-opacity, 1));
}

.border-success {
  border-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--border-opacity, 1));
}

.border-success-light {
  border-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--border-opacity, 1));
}

.border-success-lighter {
  border-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--border-opacity, 1));
}

.border-error-darker {
  border-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--border-opacity, 1));
}

.border-error-dark {
  border-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--border-opacity, 1));
}

.border-error {
  border-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--border-opacity, 1));
}

.border-error-light {
  border-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--border-opacity, 1));
}

.border-error-lighter {
  border-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--border-opacity, 1));
}

.border-bg-darker {
  border-color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--border-opacity, 1));
}

.border-bg-dark {
  border-color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--border-opacity, 1));
}

.border-bg {
  border-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--border-opacity, 1));
}

.border-bg-light {
  border-color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--border-opacity, 1));
}

.border-bg-lighter {
  border-color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--border-opacity, 1));
}

.border-contrast-lower {
  border-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-opacity, 1));
}

.border-contrast-low {
  border-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-opacity, 1));
}

.border-contrast-medium {
  border-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--border-opacity, 1));
}

.border-contrast-high {
  border-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--border-opacity, 1));
}

.border-contrast-higher {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity, 1));
}

.border-opacity-0 {
  --border-opacity: 0;
}

.border-opacity-5\% {
  --border-opacity: 0.05;
}

.border-opacity-10\% {
  --border-opacity: 0.1;
}

.border-opacity-15\% {
  --border-opacity: 0.15;
}

.border-opacity-20\% {
  --border-opacity: 0.2;
}

.border-opacity-25\% {
  --border-opacity: 0.25;
}

.border-opacity-30\% {
  --border-opacity: 0.3;
}

.border-opacity-40\% {
  --border-opacity: 0.4;
}

.border-opacity-50\% {
  --border-opacity: 0.5;
}

.border-opacity-60\% {
  --border-opacity: 0.6;
}

.border-opacity-70\% {
  --border-opacity: 0.7;
}

.border-opacity-75\% {
  --border-opacity: 0.75;
}

.border-opacity-80\% {
  --border-opacity: 0.8;
}

.border-opacity-85\% {
  --border-opacity: 0.85;
}

.border-opacity-90\% {
  --border-opacity: 0.9;
}

.border-opacity-95\% {
  --border-opacity: 0.95;
}

/* border-radius */
.radius-sm {
  border-radius: var(--radius-sm);
}

.radius-md, .wpcf7 .wpcf7-not-valid-tip {
  border-radius: var(--radius-md);
}

.radius-lg {
  border-radius: var(--radius-lg);
}

.radius-50\% {
  border-radius: 50%;
}

.radius-full {
  border-radius: 50em;
}

.radius-0 {
  border-radius: 0;
}

.radius-inherit {
  border-radius: inherit;
}

.radius-top-left-0 {
  border-top-left-radius: 0;
}

.radius-top-right-0 {
  border-top-right-radius: 0;
}

.radius-bottom-right-0 {
  border-bottom-right-radius: 0;
}

.radius-bottom-left-0 {
  border-bottom-left-radius: 0;
}

/* background */
.bg, [class^=bg-], [class*=" bg-"] {
  --bg-opacity: 1;
}

.bg-transparent {
  background-color: transparent;
}

.bg-inherit {
  background-color: inherit;
}

.bg-primary-darker {
  background-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--bg-opacity, 1));
}

.bg-primary-dark {
  background-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--bg-opacity, 1));
}

.bg-primary {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--bg-opacity, 1));
}

.bg-primary-light {
  background-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--bg-opacity, 1));
}

.bg-primary-lighter {
  background-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--bg-opacity, 1));
}

.bg-accent-darker {
  background-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--bg-opacity, 1));
}

.bg-accent-dark {
  background-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--bg-opacity, 1));
}

.bg-accent {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--bg-opacity, 1));
}

.bg-accent-light {
  background-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--bg-opacity, 1));
}

.bg-accent-lighter {
  background-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--bg-opacity, 1));
}

.bg-dark-darker {
  background-color: hsla(var(--color-dark-darker-h), var(--color-dark-darker-s), var(--color-dark-darker-l), var(--bg-opacity, 1));
}

.bg-dark-dark {
  background-color: hsla(var(--color-dark-dark-h), var(--color-dark-dark-s), var(--color-dark-dark-l), var(--bg-opacity, 1));
}

.bg-dark {
  background-color: hsla(var(--color-dark-h), var(--color-dark-s), var(--color-dark-l), var(--bg-opacity, 1));
}

.bg-dark-light {
  background-color: hsla(var(--color-dark-light-h), var(--color-dark-light-s), var(--color-dark-light-l), var(--bg-opacity, 1));
}

.bg-dark-lighter {
  background-color: hsla(var(--color-dark-lighter-h), var(--color-dark-lighter-s), var(--color-dark-lighter-l), var(--bg-opacity, 1));
}

.bg-black {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--bg-opacity, 1));
}

.bg-white {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--bg-opacity, 1));
}

.bg-warning-darker {
  background-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--bg-opacity, 1));
}

.bg-warning-dark {
  background-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--bg-opacity, 1));
}

.bg-warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--bg-opacity, 1));
}

.bg-warning-light {
  background-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--bg-opacity, 1));
}

.bg-warning-lighter {
  background-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--bg-opacity, 1));
}

.bg-success-darker {
  background-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--bg-opacity, 1));
}

.bg-success-dark {
  background-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--bg-opacity, 1));
}

.bg-success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--bg-opacity, 1));
}

.bg-success-light {
  background-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--bg-opacity, 1));
}

.bg-success-lighter {
  background-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--bg-opacity, 1));
}

.bg-error-darker {
  background-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--bg-opacity, 1));
}

.bg-error-dark {
  background-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--bg-opacity, 1));
}

.bg-error, .wpcf7 .wpcf7-not-valid-tip {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--bg-opacity, 1));
}

.bg-error-light {
  background-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--bg-opacity, 1));
}

.bg-error-lighter {
  background-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--bg-opacity, 1));
}

.bg-darker {
  background-color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--bg-opacity, 1));
}

.bg-dark {
  background-color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--bg-opacity, 1));
}

.bg {
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--bg-opacity, 1));
}

.bg-light {
  background-color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--bg-opacity, 1));
}

.bg-lighter {
  background-color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--bg-opacity, 1));
}

.bg-contrast-lower {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--bg-opacity, 1));
}

.bg-contrast-low {
  background-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--bg-opacity, 1));
}

.bg-contrast-medium {
  background-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--bg-opacity, 1));
}

.bg-contrast-high {
  background-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--bg-opacity, 1));
}

.bg-contrast-higher {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--bg-opacity, 1));
}

.bg-opacity-0 {
  --bg-opacity: 0;
}

.bg-opacity-5\% {
  --bg-opacity: 0.05;
}

.bg-opacity-10\% {
  --bg-opacity: 0.1;
}

.bg-opacity-15\% {
  --bg-opacity: 0.15;
}

.bg-opacity-20\%, .wpcf7 .wpcf7-not-valid-tip {
  --bg-opacity: 0.2;
}

.bg-opacity-25\% {
  --bg-opacity: 0.25;
}

.bg-opacity-30\% {
  --bg-opacity: 0.3;
}

.bg-opacity-40\% {
  --bg-opacity: 0.4;
}

.bg-opacity-50\% {
  --bg-opacity: 0.5;
}

.bg-opacity-60\% {
  --bg-opacity: 0.6;
}

.bg-opacity-70\% {
  --bg-opacity: 0.7;
}

.bg-opacity-75\% {
  --bg-opacity: 0.75;
}

.bg-opacity-80\% {
  --bg-opacity: 0.8;
}

.bg-opacity-85\% {
  --bg-opacity: 0.85;
}

.bg-opacity-90\% {
  --bg-opacity: 0.9;
}

.bg-opacity-95\% {
  --bg-opacity: 0.95;
}

.bg-center {
  background-position: center;
}

.bg-top {
  background-position: center top;
}

.bg-right {
  background-position: right center;
}

.bg-bottom {
  background-position: center bottom;
}

.bg-left {
  background-position: left center;
}

.bg-top-left {
  background-position: left top;
}

.bg-top-right {
  background-position: right top;
}

.bg-bottom-left {
  background-position: left bottom;
}

.bg-bottom-right {
  background-position: right bottom;
}

.bg-cover {
  background-size: cover;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

/* backdrop-filter */
.backdrop-blur-10 {
  backdrop-filter: blur(10px);
}

.backdrop-blur-20 {
  backdrop-filter: blur(20px);
}

/* mix-blend-mode */
.isolate {
  isolation: isolate;
}

.blend-multiply {
  mix-blend-mode: multiply;
}

.blend-overlay {
  mix-blend-mode: overlay;
}

.blend-difference {
  mix-blend-mode: difference;
}

/* object-fit */
.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

/* perspective */
.perspective-xs {
  perspective: 250px;
}

.perspective-sm {
  perspective: 500px;
}

.perspective-md {
  perspective: 1000px;
}

.perspective-lg {
  perspective: 1500px;
}

.perspective-xl {
  perspective: 3000px;
}

/* transform */
[class^=flip], [class*=" flip"],
[class^=-rotate], [class*=" -rotate"],
[class^=rotate], [class*=" rotate"],
[class^=-translate], [class*=" -translate"],
[class^=translate], [class*=" translate"],
[class^=-scale], [class*=" -scale"],
[class^=scale], [class*=" scale"],
[class^=-skew], [class*=" -skew"] [class^=skew],
[class*=" skew"] {
  --translate: 0;
  --rotate: 0;
  --skew: 0;
  --scale: 1;
  transform: translate3d(var(--translate-x, var(--translate)), var(--translate-y, var(--translate)), var(--translate-z, 0)) rotateX(var(--rotate-x, 0)) rotateY(var(--rotate-y, 0)) rotateZ(var(--rotate-z, var(--rotate))) skewX(var(--skew-x, var(--skew))) skewY(var(--skew-y, 0)) scaleX(var(--scale-x, var(--scale))) scaleY(var(--scale-y, var(--scale)));
}

.flip {
  --scale: -1;
}

.flip-x {
  --scale-x: -1;
}

.flip-y {
  --scale-y: -1;
}

.rotate-90 {
  --rotate: 90deg;
}

.rotate-180 {
  --rotate: 180deg;
}

.rotate-270 {
  --rotate: 270deg;
}

.-translate-50\% {
  --translate: -50%;
}

.-translate-x-50\% {
  --translate-x: -50%;
}

.-translate-y-50\% {
  --translate-y: -50%;
}

.-translate-y-slight {
  --translate-y: -0.1em;
}

.translate-50\% {
  --translate: 50%;
}

.translate-x-50\% {
  --translate-x: 50%;
}

.translate-y-50\% {
  --translate-y: 50%;
}

/* transform-origin */
.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: center top;
}

.origin-right {
  transform-origin: right center;
}

.origin-bottom {
  transform-origin: center bottom;
}

.origin-left {
  transform-origin: left center;
}

.origin-top-left {
  transform-origin: left top;
}

.origin-top-right {
  transform-origin: right top;
}

.origin-bottom-left {
  transform-origin: left bottom;
}

.origin-bottom-right {
  transform-origin: right bottom;
}

/* transition */
.transition {
  transition-property: var(--transition-property, all);
  transition-duration: var(--transition-duration, 0.2s);
  transition-delay: var(--transition-delay, 0s);
}

/* SVG */
.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-1 {
  stroke-width: 1px;
}

.stroke-2 {
  stroke-width: 2px;
}

.stroke-3 {
  stroke-width: 3px;
}

.stroke-4 {
  stroke-width: 4px;
}

/* visibility */
.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

/* print */
@media print {
  .print\:hide, .print\:is-hidden {
    display: none !important;
  }
  .print\:shadow-none {
    box-shadow: none;
  }
}
/* appearance */
.appearance-none {
  appearance: none;
}

.appearance-auto {
  appearance: auto;
}

@media (min-width: 32rem) {
  /* grid */
  .grid-col-1\@xs {
    --grid-columns: 1;
  }
  .col-1\@xs {
    --column-span: 1;
  }
  .grid-col-2\@xs {
    --grid-columns: 2;
  }
  .col-2\@xs {
    --column-span: 2;
  }
  .grid-col-3\@xs {
    --grid-columns: 3;
  }
  .col-3\@xs {
    --column-span: 3;
  }
  .grid-col-4\@xs {
    --grid-columns: 4;
  }
  .col-4\@xs {
    --column-span: 4;
  }
  .grid-col-5\@xs {
    --grid-columns: 5;
  }
  .col-5\@xs {
    --column-span: 5;
  }
  .grid-col-6\@xs {
    --grid-columns: 6;
  }
  .col-6\@xs {
    --column-span: 6;
  }
  .grid-col-7\@xs {
    --grid-columns: 7;
  }
  .col-7\@xs {
    --column-span: 7;
  }
  .grid-col-8\@xs {
    --grid-columns: 8;
  }
  .col-8\@xs {
    --column-span: 8;
  }
  .grid-col-9\@xs {
    --grid-columns: 9;
  }
  .col-9\@xs {
    --column-span: 9;
  }
  .grid-col-10\@xs {
    --grid-columns: 10;
  }
  .col-10\@xs {
    --column-span: 10;
  }
  .grid-col-11\@xs {
    --grid-columns: 11;
  }
  .col-11\@xs {
    --column-span: 11;
  }
  .grid-col-12\@xs {
    --grid-columns: 12;
  }
  .col-12\@xs {
    --column-span: 12;
  }
  .col-1\@xs, .col-2\@xs, .col-3\@xs, .col-4\@xs, .col-5\@xs, .col-6\@xs, .col-7\@xs, .col-8\@xs, .col-9\@xs, .col-10\@xs, .col-11\@xs, .col-12\@xs {
    width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
    flex: initial;
  }
  .col\@xs {
    width: auto;
    flex: 1 1 0;
  }
  .col-content\@xs {
    width: auto;
    flex: 0 1 auto;
  }
  .offset-1\@xs {
    --column-offset: 1;
  }
  .offset-2\@xs {
    --column-offset: 2;
  }
  .offset-3\@xs {
    --column-offset: 3;
  }
  .offset-4\@xs {
    --column-offset: 4;
  }
  .offset-5\@xs {
    --column-offset: 5;
  }
  .offset-6\@xs {
    --column-offset: 6;
  }
  .offset-7\@xs {
    --column-offset: 7;
  }
  .offset-8\@xs {
    --column-offset: 8;
  }
  .offset-9\@xs {
    --column-offset: 9;
  }
  .offset-10\@xs {
    --column-offset: 10;
  }
  .offset-11\@xs {
    --column-offset: 11;
  }
  .offset-1\@xs, .offset-2\@xs, .offset-3\@xs, .offset-4\@xs, .offset-5\@xs, .offset-6\@xs, .offset-7\@xs, .offset-8\@xs, .offset-9\@xs, .offset-10\@xs, .offset-11\@xs {
    margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
  }
  .offset-0\@xs {
    margin-left: 0;
  }
  /* gap */
  .gap-4xs\@xs {
    --row-gap: var(--space-4xs);
    --column-gap: var(--space-4xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xs\@xs {
    --column-gap: var(--space-4xs);
    column-gap: var(--column-gap);
  }
  .gap-y-4xs\@xs {
    --row-gap: var(--space-4xs);
    row-gap: var(--row-gap);
  }
  .gap-3xs\@xs {
    --row-gap: var(--space-3xs);
    --column-gap: var(--space-3xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xs\@xs {
    --column-gap: var(--space-3xs);
    column-gap: var(--column-gap);
  }
  .gap-y-3xs\@xs {
    --row-gap: var(--space-3xs);
    row-gap: var(--row-gap);
  }
  .gap-2xs\@xs {
    --row-gap: var(--space-2xs);
    --column-gap: var(--space-2xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xs\@xs {
    --column-gap: var(--space-2xs);
    column-gap: var(--column-gap);
  }
  .gap-y-2xs\@xs {
    --row-gap: var(--space-2xs);
    row-gap: var(--row-gap);
  }
  .gap-xs\@xs {
    --row-gap: var(--space-xs);
    --column-gap: var(--space-xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xs\@xs {
    --column-gap: var(--space-xs);
    column-gap: var(--column-gap);
  }
  .gap-y-xs\@xs {
    --row-gap: var(--space-xs);
    row-gap: var(--row-gap);
  }
  .gap-sm\@xs {
    --row-gap: var(--space-sm);
    --column-gap: var(--space-sm);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-sm\@xs {
    --column-gap: var(--space-sm);
    column-gap: var(--column-gap);
  }
  .gap-y-sm\@xs {
    --row-gap: var(--space-sm);
    row-gap: var(--row-gap);
  }
  .gap-md\@xs {
    --row-gap: var(--space-md);
    --column-gap: var(--space-md);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-md\@xs {
    --column-gap: var(--space-md);
    column-gap: var(--column-gap);
  }
  .gap-y-md\@xs {
    --row-gap: var(--space-md);
    row-gap: var(--row-gap);
  }
  .gap-lg\@xs {
    --row-gap: var(--space-lg);
    --column-gap: var(--space-lg);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-lg\@xs {
    --column-gap: var(--space-lg);
    column-gap: var(--column-gap);
  }
  .gap-y-lg\@xs {
    --row-gap: var(--space-lg);
    row-gap: var(--row-gap);
  }
  .gap-xl\@xs {
    --row-gap: var(--space-xl);
    --column-gap: var(--space-xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xl\@xs {
    --column-gap: var(--space-xl);
    column-gap: var(--column-gap);
  }
  .gap-y-xl\@xs {
    --row-gap: var(--space-xl);
    row-gap: var(--row-gap);
  }
  .gap-2xl\@xs {
    --row-gap: var(--space-2xl);
    --column-gap: var(--space-2xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xl\@xs {
    --column-gap: var(--space-2xl);
    column-gap: var(--column-gap);
  }
  .gap-y-2xl\@xs {
    --row-gap: var(--space-2xl);
    row-gap: var(--row-gap);
  }
  .gap-3xl\@xs {
    --row-gap: var(--space-3xl);
    --column-gap: var(--space-3xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xl\@xs {
    --column-gap: var(--space-3xl);
    column-gap: var(--column-gap);
  }
  .gap-y-3xl\@xs {
    --row-gap: var(--space-3xl);
    row-gap: var(--row-gap);
  }
  .gap-4xl\@xs {
    --row-gap: var(--space-4xl);
    --column-gap: var(--space-4xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xl\@xs {
    --column-gap: var(--space-4xl);
    column-gap: var(--column-gap);
  }
  .gap-y-4xl\@xs {
    --row-gap: var(--space-4xl);
    row-gap: var(--row-gap);
  }
  .gap-0\@xs {
    --row-gap: 0px;
    --column-gap: 0px;
    gap: 0px;
  }
  .gap-x-0\@xs {
    --column-gap: 0px;
    column-gap: 0px;
  }
  .gap-y-0\@xs {
    --row-gap: 0px;
    row-gap: 0px;
  }
  /* flexbox */
  .flex\@xs {
    display: flex;
  }
  .inline-flex\@xs {
    display: inline-flex;
  }
  .flex-wrap\@xs {
    flex-wrap: wrap;
  }
  .flex-nowrap\@xs {
    flex-wrap: nowrap;
  }
  .flex-column\@xs {
    flex-direction: column;
  }
  .flex-column-reverse\@xs {
    flex-direction: column-reverse;
  }
  .flex-row\@xs {
    flex-direction: row;
  }
  .flex-row-reverse\@xs {
    flex-direction: row-reverse;
  }
  .flex-center\@xs {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@xs {
    flex-grow: 1;
  }
  .flex-grow-0\@xs {
    flex-grow: 0;
  }
  .flex-shrink\@xs {
    flex-shrink: 1;
  }
  .flex-shrink-0\@xs {
    flex-shrink: 0;
  }
  .flex-basis-0\@xs {
    flex-basis: 0;
  }
  /* justify-content */
  .justify-start\@xs {
    justify-content: start;
  }
  .justify-end\@xs {
    justify-content: end;
  }
  .justify-center\@xs {
    justify-content: center;
  }
  .justify-between\@xs {
    justify-content: space-between;
  }
  /* align-items */
  .items-center\@xs {
    align-items: center;
  }
  .items-start\@xs {
    align-items: start;
  }
  .items-end\@xs {
    align-items: end;
  }
  .items-baseline\@xs {
    align-items: baseline;
  }
  .items-stretch\@xs {
    align-items: stretch;
  }
  /* align-content */
  .content-start\@xs {
    align-content: start;
  }
  .content-end\@xs {
    align-content: end;
  }
  .content-center\@xs {
    align-content: center;
  }
  .content-between\@xs {
    align-content: space-between;
  }
  /* order */
  .order-1\@xs {
    order: 1;
  }
  .order-2\@xs {
    order: 2;
  }
  .order-3\@xs {
    order: 3;
  }
  /* display */
  .block\@xs {
    display: block;
  }
  .inline-block\@xs {
    display: inline-block;
  }
  .inline\@xs {
    display: inline;
  }
  .contents\@xs {
    display: contents;
  }
  .css-grid\@xs {
    display: grid;
  }
  .css-inline-grid\@xs {
    display: inline-grid;
  }
  .hide\@xs {
    display: none;
  }
  /* margin */
  .margin-4xs\@xs {
    margin: var(--space-4xs);
  }
  .margin-top-4xs\@xs {
    margin-top: var(--space-4xs);
  }
  .margin-right-4xs\@xs {
    margin-right: var(--space-4xs);
  }
  .margin-bottom-4xs\@xs {
    margin-bottom: var(--space-4xs);
  }
  .margin-left-4xs\@xs {
    margin-left: var(--space-4xs);
  }
  .margin-y-4xs\@xs {
    margin-top: var(--space-4xs);
    margin-bottom: var(--space-4xs);
  }
  .margin-x-4xs\@xs {
    margin-right: var(--space-4xs);
    margin-left: var(--space-4xs);
  }
  .margin-3xs\@xs {
    margin: var(--space-3xs);
  }
  .margin-top-3xs\@xs {
    margin-top: var(--space-3xs);
  }
  .margin-right-3xs\@xs {
    margin-right: var(--space-3xs);
  }
  .margin-bottom-3xs\@xs {
    margin-bottom: var(--space-3xs);
  }
  .margin-left-3xs\@xs {
    margin-left: var(--space-3xs);
  }
  .margin-y-3xs\@xs {
    margin-top: var(--space-3xs);
    margin-bottom: var(--space-3xs);
  }
  .margin-x-3xs\@xs {
    margin-right: var(--space-3xs);
    margin-left: var(--space-3xs);
  }
  .margin-2xs\@xs {
    margin: var(--space-2xs);
  }
  .margin-top-2xs\@xs {
    margin-top: var(--space-2xs);
  }
  .margin-right-2xs\@xs {
    margin-right: var(--space-2xs);
  }
  .margin-bottom-2xs\@xs {
    margin-bottom: var(--space-2xs);
  }
  .margin-left-2xs\@xs {
    margin-left: var(--space-2xs);
  }
  .margin-y-2xs\@xs {
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-2xs);
  }
  .margin-x-2xs\@xs {
    margin-right: var(--space-2xs);
    margin-left: var(--space-2xs);
  }
  .margin-xs\@xs {
    margin: var(--space-xs);
  }
  .margin-top-xs\@xs {
    margin-top: var(--space-xs);
  }
  .margin-right-xs\@xs {
    margin-right: var(--space-xs);
  }
  .margin-bottom-xs\@xs {
    margin-bottom: var(--space-xs);
  }
  .margin-left-xs\@xs {
    margin-left: var(--space-xs);
  }
  .margin-y-xs\@xs {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
  .margin-x-xs\@xs {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }
  .margin-sm\@xs {
    margin: var(--space-sm);
  }
  .margin-top-sm\@xs {
    margin-top: var(--space-sm);
  }
  .margin-right-sm\@xs {
    margin-right: var(--space-sm);
  }
  .margin-bottom-sm\@xs {
    margin-bottom: var(--space-sm);
  }
  .margin-left-sm\@xs {
    margin-left: var(--space-sm);
  }
  .margin-y-sm\@xs {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }
  .margin-x-sm\@xs {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }
  .margin-md\@xs {
    margin: var(--space-md);
  }
  .margin-top-md\@xs {
    margin-top: var(--space-md);
  }
  .margin-right-md\@xs {
    margin-right: var(--space-md);
  }
  .margin-bottom-md\@xs {
    margin-bottom: var(--space-md);
  }
  .margin-left-md\@xs {
    margin-left: var(--space-md);
  }
  .margin-y-md\@xs {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }
  .margin-x-md\@xs {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }
  .margin-lg\@xs {
    margin: var(--space-lg);
  }
  .margin-top-lg\@xs {
    margin-top: var(--space-lg);
  }
  .margin-right-lg\@xs {
    margin-right: var(--space-lg);
  }
  .margin-bottom-lg\@xs {
    margin-bottom: var(--space-lg);
  }
  .margin-left-lg\@xs {
    margin-left: var(--space-lg);
  }
  .margin-y-lg\@xs {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }
  .margin-x-lg\@xs {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }
  .margin-xl\@xs {
    margin: var(--space-xl);
  }
  .margin-top-xl\@xs {
    margin-top: var(--space-xl);
  }
  .margin-right-xl\@xs {
    margin-right: var(--space-xl);
  }
  .margin-bottom-xl\@xs {
    margin-bottom: var(--space-xl);
  }
  .margin-left-xl\@xs {
    margin-left: var(--space-xl);
  }
  .margin-y-xl\@xs {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  .margin-x-xl\@xs {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }
  .margin-2xl\@xs {
    margin: var(--space-2xl);
  }
  .margin-top-2xl\@xs {
    margin-top: var(--space-2xl);
  }
  .margin-right-2xl\@xs {
    margin-right: var(--space-2xl);
  }
  .margin-bottom-2xl\@xs {
    margin-bottom: var(--space-2xl);
  }
  .margin-left-2xl\@xs {
    margin-left: var(--space-2xl);
  }
  .margin-y-2xl\@xs {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
  }
  .margin-x-2xl\@xs {
    margin-right: var(--space-2xl);
    margin-left: var(--space-2xl);
  }
  .margin-3xl\@xs {
    margin: var(--space-3xl);
  }
  .margin-top-3xl\@xs {
    margin-top: var(--space-3xl);
  }
  .margin-right-3xl\@xs {
    margin-right: var(--space-3xl);
  }
  .margin-bottom-3xl\@xs {
    margin-bottom: var(--space-3xl);
  }
  .margin-left-3xl\@xs {
    margin-left: var(--space-3xl);
  }
  .margin-y-3xl\@xs {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-3xl);
  }
  .margin-x-3xl\@xs {
    margin-right: var(--space-3xl);
    margin-left: var(--space-3xl);
  }
  .margin-4xl\@xs {
    margin: var(--space-4xl);
  }
  .margin-top-4xl\@xs {
    margin-top: var(--space-4xl);
  }
  .margin-right-4xl\@xs {
    margin-right: var(--space-4xl);
  }
  .margin-bottom-4xl\@xs {
    margin-bottom: var(--space-4xl);
  }
  .margin-left-4xl\@xs {
    margin-left: var(--space-4xl);
  }
  .margin-y-4xl\@xs {
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-4xl);
  }
  .margin-x-4xl\@xs {
    margin-right: var(--space-4xl);
    margin-left: var(--space-4xl);
  }
  .margin-auto\@xs {
    margin: auto;
  }
  .margin-0\@xs {
    margin: 0;
  }
  .margin-y-auto\@xs {
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-y-0\@xs {
    margin-top: 0;
    margin-bottom: 0;
  }
  .margin-x-auto\@xs {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-x-0\@xs {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-top-auto\@xs {
    margin-top: auto;
  }
  .margin-top-0\@xs {
    margin-top: 0;
  }
  .margin-right-auto\@xs {
    margin-right: auto;
  }
  .margin-right-0\@xs {
    margin-right: 0;
  }
  .margin-bottom-auto\@xs {
    margin-bottom: auto;
  }
  .margin-bottom-0\@xs {
    margin-bottom: 0;
  }
  .margin-left-auto\@xs {
    margin-left: auto;
  }
  .margin-left-0\@xs {
    margin-left: 0;
  }
  /* padding */
  .padding-4xs\@xs {
    padding: var(--space-4xs);
  }
  .padding-top-4xs\@xs {
    padding-top: var(--space-4xs);
  }
  .padding-right-4xs\@xs {
    padding-right: var(--space-4xs);
  }
  .padding-bottom-4xs\@xs {
    padding-bottom: var(--space-4xs);
  }
  .padding-left-4xs\@xs {
    padding-left: var(--space-4xs);
  }
  .padding-y-4xs\@xs {
    padding-top: var(--space-4xs);
    padding-bottom: var(--space-4xs);
  }
  .padding-x-4xs\@xs {
    padding-right: var(--space-4xs);
    padding-left: var(--space-4xs);
  }
  .padding-3xs\@xs {
    padding: var(--space-3xs);
  }
  .padding-top-3xs\@xs {
    padding-top: var(--space-3xs);
  }
  .padding-right-3xs\@xs {
    padding-right: var(--space-3xs);
  }
  .padding-bottom-3xs\@xs {
    padding-bottom: var(--space-3xs);
  }
  .padding-left-3xs\@xs {
    padding-left: var(--space-3xs);
  }
  .padding-y-3xs\@xs {
    padding-top: var(--space-3xs);
    padding-bottom: var(--space-3xs);
  }
  .padding-x-3xs\@xs {
    padding-right: var(--space-3xs);
    padding-left: var(--space-3xs);
  }
  .padding-2xs\@xs {
    padding: var(--space-2xs);
  }
  .padding-top-2xs\@xs {
    padding-top: var(--space-2xs);
  }
  .padding-right-2xs\@xs {
    padding-right: var(--space-2xs);
  }
  .padding-bottom-2xs\@xs {
    padding-bottom: var(--space-2xs);
  }
  .padding-left-2xs\@xs {
    padding-left: var(--space-2xs);
  }
  .padding-y-2xs\@xs {
    padding-top: var(--space-2xs);
    padding-bottom: var(--space-2xs);
  }
  .padding-x-2xs\@xs {
    padding-right: var(--space-2xs);
    padding-left: var(--space-2xs);
  }
  .padding-xs\@xs {
    padding: var(--space-xs);
  }
  .padding-top-xs\@xs {
    padding-top: var(--space-xs);
  }
  .padding-right-xs\@xs {
    padding-right: var(--space-xs);
  }
  .padding-bottom-xs\@xs {
    padding-bottom: var(--space-xs);
  }
  .padding-left-xs\@xs {
    padding-left: var(--space-xs);
  }
  .padding-y-xs\@xs {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }
  .padding-x-xs\@xs {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }
  .padding-sm\@xs {
    padding: var(--space-sm);
  }
  .padding-top-sm\@xs {
    padding-top: var(--space-sm);
  }
  .padding-right-sm\@xs {
    padding-right: var(--space-sm);
  }
  .padding-bottom-sm\@xs {
    padding-bottom: var(--space-sm);
  }
  .padding-left-sm\@xs {
    padding-left: var(--space-sm);
  }
  .padding-y-sm\@xs {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
  .padding-x-sm\@xs {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }
  .padding-md\@xs {
    padding: var(--space-md);
  }
  .padding-top-md\@xs {
    padding-top: var(--space-md);
  }
  .padding-right-md\@xs {
    padding-right: var(--space-md);
  }
  .padding-bottom-md\@xs {
    padding-bottom: var(--space-md);
  }
  .padding-left-md\@xs {
    padding-left: var(--space-md);
  }
  .padding-y-md\@xs {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }
  .padding-x-md\@xs {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }
  .padding-lg\@xs {
    padding: var(--space-lg);
  }
  .padding-top-lg\@xs {
    padding-top: var(--space-lg);
  }
  .padding-right-lg\@xs {
    padding-right: var(--space-lg);
  }
  .padding-bottom-lg\@xs {
    padding-bottom: var(--space-lg);
  }
  .padding-left-lg\@xs {
    padding-left: var(--space-lg);
  }
  .padding-y-lg\@xs {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
  .padding-x-lg\@xs {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }
  .padding-xl\@xs {
    padding: var(--space-xl);
  }
  .padding-top-xl\@xs {
    padding-top: var(--space-xl);
  }
  .padding-right-xl\@xs {
    padding-right: var(--space-xl);
  }
  .padding-bottom-xl\@xs {
    padding-bottom: var(--space-xl);
  }
  .padding-left-xl\@xs {
    padding-left: var(--space-xl);
  }
  .padding-y-xl\@xs {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  .padding-x-xl\@xs {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }
  .padding-2xl\@xs {
    padding: var(--space-2xl);
  }
  .padding-top-2xl\@xs {
    padding-top: var(--space-2xl);
  }
  .padding-right-2xl\@xs {
    padding-right: var(--space-2xl);
  }
  .padding-bottom-2xl\@xs {
    padding-bottom: var(--space-2xl);
  }
  .padding-left-2xl\@xs {
    padding-left: var(--space-2xl);
  }
  .padding-y-2xl\@xs {
    padding-top: var(--space-2xl);
    padding-bottom: var(--space-2xl);
  }
  .padding-x-2xl\@xs {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
  .padding-3xl\@xs {
    padding: var(--space-3xl);
  }
  .padding-top-3xl\@xs {
    padding-top: var(--space-3xl);
  }
  .padding-right-3xl\@xs {
    padding-right: var(--space-3xl);
  }
  .padding-bottom-3xl\@xs {
    padding-bottom: var(--space-3xl);
  }
  .padding-left-3xl\@xs {
    padding-left: var(--space-3xl);
  }
  .padding-y-3xl\@xs {
    padding-top: var(--space-3xl);
    padding-bottom: var(--space-3xl);
  }
  .padding-x-3xl\@xs {
    padding-right: var(--space-3xl);
    padding-left: var(--space-3xl);
  }
  .padding-4xl\@xs {
    padding: var(--space-4xl);
  }
  .padding-top-4xl\@xs {
    padding-top: var(--space-4xl);
  }
  .padding-right-4xl\@xs {
    padding-right: var(--space-4xl);
  }
  .padding-bottom-4xl\@xs {
    padding-bottom: var(--space-4xl);
  }
  .padding-left-4xl\@xs {
    padding-left: var(--space-4xl);
  }
  .padding-y-4xl\@xs {
    padding-top: var(--space-4xl);
    padding-bottom: var(--space-4xl);
  }
  .padding-x-4xl\@xs {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
  .padding-0\@xs {
    padding: 0;
  }
  .padding-y-0\@xs {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-x-0\@xs {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-top-0\@xs {
    padding-top: 0;
  }
  .padding-right-0\@xs {
    padding-right: 0;
  }
  .padding-bottom-0\@xs {
    padding-bottom: 0;
  }
  .padding-left-0\@xs {
    padding-left: 0;
  }
  /* text-align */
  .text-center\@xs {
    text-align: center;
  }
  .text-left\@xs {
    text-align: left;
  }
  .text-right\@xs {
    text-align: right;
  }
  .text-justify\@xs {
    text-align: justify;
  }
  /* font-size */
  .text-xs\@xs {
    font-size: var(--text-xs);
  }
  .text-sm\@xs {
    font-size: var(--text-sm);
  }
  .text-base\@xs {
    font-size: var(--text-base);
  }
  .text-md\@xs {
    font-size: var(--text-md);
  }
  .text-lg\@xs {
    font-size: var(--text-lg);
  }
  .text-xl\@xs {
    font-size: var(--text-xl);
  }
  .text-2xl\@xs {
    font-size: var(--text-2xl);
  }
  .text-3xl\@xs {
    font-size: var(--text-3xl);
  }
  .text-4xl\@xs {
    font-size: var(--text-4xl);
  }
  /* column-count */
  .column-count-1\@xs {
    column-count: 1;
  }
  .column-count-2\@xs {
    column-count: 2;
  }
  .column-count-3\@xs {
    column-count: 3;
  }
  .column-count-4\@xs {
    column-count: 4;
  }
  /* width */
  .width-4xs\@xs {
    width: 0.25rem;
  }
  .width-3xs\@xs {
    width: 0.5rem;
  }
  .width-2xs\@xs {
    width: 0.75rem;
  }
  .width-xs\@xs {
    width: 1rem;
  }
  .width-sm\@xs {
    width: 1.5rem;
  }
  .width-md\@xs {
    width: 2rem;
  }
  .width-lg\@xs {
    width: 3rem;
  }
  .width-xl\@xs {
    width: 4rem;
  }
  .width-2xl\@xs {
    width: 6rem;
  }
  .width-3xl\@xs {
    width: 8rem;
  }
  .width-4xl\@xs {
    width: 16rem;
  }
  .width-0\@xs {
    width: 0;
  }
  .width-10\%\@xs {
    width: 10%;
  }
  .width-20\%\@xs {
    width: 20%;
  }
  .width-25\%\@xs {
    width: 25%;
  }
  .width-30\%\@xs {
    width: 30%;
  }
  .width-33\%\@xs {
    width: 33%;
  }
  .width-40\%\@xs {
    width: 40%;
  }
  .width-50\%\@xs {
    width: 50%;
  }
  .width-60\%\@xs {
    width: 60%;
  }
  .width-70\%\@xs {
    width: 70%;
  }
  .width-75\%\@xs {
    width: 75%;
  }
  .width-80\%\@xs {
    width: 80%;
  }
  .width-90\%\@xs {
    width: 90%;
  }
  .width-100\%\@xs {
    width: 100%;
  }
  .width-100\%\@xs {
    width: 100%;
  }
  .width-100vw\@xs {
    width: 100vw;
  }
  .width-auto\@xs {
    width: auto;
  }
  .width-inherit\@xs {
    width: inherit;
  }
  .width-0\@xs {
    width: 0;
  }
  /* height */
  .height-4xs\@xs {
    height: 0.25rem;
  }
  .height-3xs\@xs {
    height: 0.5rem;
  }
  .height-2xs\@xs {
    height: 0.75rem;
  }
  .height-xs\@xs {
    height: 1rem;
  }
  .height-sm\@xs {
    height: 1.5rem;
  }
  .height-md\@xs {
    height: 2rem;
  }
  .height-lg\@xs {
    height: 3rem;
  }
  .height-xl\@xs {
    height: 4rem;
  }
  .height-2xl\@xs {
    height: 6rem;
  }
  .height-3xl\@xs {
    height: 8rem;
  }
  .height-4xl\@xs {
    height: 16rem;
  }
  .height-0\@xs {
    height: 0;
  }
  .height-10\%\@xs {
    height: 10%;
  }
  .height-20\%\@xs {
    height: 20%;
  }
  .height-25\%\@xs {
    height: 25%;
  }
  .height-30\%\@xs {
    height: 30%;
  }
  .height-33\%\@xs {
    height: 33%;
  }
  .height-40\%\@xs {
    height: 40%;
  }
  .height-50\%\@xs {
    height: 50%;
  }
  .height-60\%\@xs {
    height: 60%;
  }
  .height-70\%\@xs {
    height: 70%;
  }
  .height-75\%\@xs {
    height: 75%;
  }
  .height-80\%\@xs {
    height: 80%;
  }
  .height-90\%\@xs {
    height: 90%;
  }
  .height-100\%\@xs {
    height: 100%;
  }
  .height-100\%\@xs {
    height: 100%;
  }
  .height-100vh\@xs {
    height: 100vh;
  }
  .height-auto\@xs {
    height: auto;
  }
  .height-inherit\@xs {
    height: inherit;
  }
  .height-0\@xs {
    height: 0;
  }
  /* max-width */
  .max-width-3xs\@xs {
    max-width: var(--max-width-3xs);
  }
  .max-width-2xs\@xs {
    max-width: var(--max-width-2xs);
  }
  .max-width-xs\@xs {
    max-width: var(--max-width-xs);
  }
  .max-width-sm\@xs {
    max-width: var(--max-width-sm);
  }
  .max-width-md\@xs {
    max-width: var(--max-width-md);
  }
  .max-width-lg\@xs {
    max-width: var(--max-width-lg);
  }
  .max-width-xl\@xs {
    max-width: var(--max-width-xl);
  }
  .max-width-100\%\@xs {
    max-width: 100%;
  }
  .max-width-none\@xs {
    max-width: none;
  }
  /* position */
  .position-relative\@xs {
    position: relative;
  }
  .position-absolute\@xs {
    position: absolute;
  }
  .position-fixed\@xs {
    position: fixed;
  }
  .position-sticky\@xs {
    position: sticky;
  }
  .position-static\@xs {
    position: static;
  }
  .inset-0\@xs {
    inset: 0;
  }
  .top-4xs\@xs {
    top: var(--space-4xs);
  }
  .right-4xs\@xs {
    right: var(--space-4xs);
  }
  .bottom-4xs\@xs {
    bottom: var(--space-4xs);
  }
  .left-4xs\@xs {
    left: var(--space-4xs);
  }
  .top-3xs\@xs {
    top: var(--space-3xs);
  }
  .right-3xs\@xs {
    right: var(--space-3xs);
  }
  .bottom-3xs\@xs {
    bottom: var(--space-3xs);
  }
  .left-3xs\@xs {
    left: var(--space-3xs);
  }
  .top-2xs\@xs {
    top: var(--space-2xs);
  }
  .right-2xs\@xs {
    right: var(--space-2xs);
  }
  .bottom-2xs\@xs {
    bottom: var(--space-2xs);
  }
  .left-2xs\@xs {
    left: var(--space-2xs);
  }
  .top-xs\@xs {
    top: var(--space-xs);
  }
  .right-xs\@xs {
    right: var(--space-xs);
  }
  .bottom-xs\@xs {
    bottom: var(--space-xs);
  }
  .left-xs\@xs {
    left: var(--space-xs);
  }
  .top-sm\@xs {
    top: var(--space-sm);
  }
  .right-sm\@xs {
    right: var(--space-sm);
  }
  .bottom-sm\@xs {
    bottom: var(--space-sm);
  }
  .left-sm\@xs {
    left: var(--space-sm);
  }
  .top-md\@xs {
    top: var(--space-md);
  }
  .right-md\@xs {
    right: var(--space-md);
  }
  .bottom-md\@xs {
    bottom: var(--space-md);
  }
  .left-md\@xs {
    left: var(--space-md);
  }
  .top-lg\@xs {
    top: var(--space-lg);
  }
  .right-lg\@xs {
    right: var(--space-lg);
  }
  .bottom-lg\@xs {
    bottom: var(--space-lg);
  }
  .left-lg\@xs {
    left: var(--space-lg);
  }
  .top-xl\@xs {
    top: var(--space-xl);
  }
  .right-xl\@xs {
    right: var(--space-xl);
  }
  .bottom-xl\@xs {
    bottom: var(--space-xl);
  }
  .left-xl\@xs {
    left: var(--space-xl);
  }
  .top-2xl\@xs {
    top: var(--space-2xl);
  }
  .right-2xl\@xs {
    right: var(--space-2xl);
  }
  .bottom-2xl\@xs {
    bottom: var(--space-2xl);
  }
  .left-2xl\@xs {
    left: var(--space-2xl);
  }
  .top-3xl\@xs {
    top: var(--space-3xl);
  }
  .right-3xl\@xs {
    right: var(--space-3xl);
  }
  .bottom-3xl\@xs {
    bottom: var(--space-3xl);
  }
  .left-3xl\@xs {
    left: var(--space-3xl);
  }
  .top-4xl\@xs {
    top: var(--space-4xl);
  }
  .right-4xl\@xs {
    right: var(--space-4xl);
  }
  .bottom-4xl\@xs {
    bottom: var(--space-4xl);
  }
  .left-4xl\@xs {
    left: var(--space-4xl);
  }
  .top-50\%\@xs {
    top: 50%;
  }
  .top-auto\@xs {
    top: auto;
  }
  .top-0\@xs {
    top: 0;
  }
  .right-50\%\@xs {
    right: 50%;
  }
  .right-auto\@xs {
    right: auto;
  }
  .right-0\@xs {
    right: 0;
  }
  .bottom-50\%\@xs {
    bottom: 50%;
  }
  .bottom-auto\@xs {
    bottom: auto;
  }
  .bottom-0\@xs {
    bottom: 0;
  }
  .left-50\%\@xs {
    left: 50%;
  }
  .left-auto\@xs {
    left: auto;
  }
  .left-0\@xs {
    left: 0;
  }
  /* overflow */
  .overflow-visible\@xs {
    overflow: visible;
  }
  .overflow-hidden\@xs {
    overflow: hidden;
  }
  .overflow-auto\@xs {
    overflow: auto;
  }
  .momentum-scrolling\@xs {
    -webkit-overflow-scrolling: touch;
  }
  /* visibility */
  .visible\@xs {
    visibility: visible;
  }
  .invisible\@xs {
    visibility: hidden;
  }
}
@media not all and (min-width: 32rem) {
  .display\@xs {
    display: none !important;
  }
}
@media (min-width: 48rem) {
  /* grid */
  .grid-col-1\@sm {
    --grid-columns: 1;
  }
  .col-1\@sm {
    --column-span: 1;
  }
  .grid-col-2\@sm {
    --grid-columns: 2;
  }
  .col-2\@sm {
    --column-span: 2;
  }
  .grid-col-3\@sm {
    --grid-columns: 3;
  }
  .col-3\@sm {
    --column-span: 3;
  }
  .grid-col-4\@sm {
    --grid-columns: 4;
  }
  .col-4\@sm {
    --column-span: 4;
  }
  .grid-col-5\@sm {
    --grid-columns: 5;
  }
  .col-5\@sm {
    --column-span: 5;
  }
  .grid-col-6\@sm {
    --grid-columns: 6;
  }
  .col-6\@sm {
    --column-span: 6;
  }
  .grid-col-7\@sm {
    --grid-columns: 7;
  }
  .col-7\@sm {
    --column-span: 7;
  }
  .grid-col-8\@sm {
    --grid-columns: 8;
  }
  .col-8\@sm {
    --column-span: 8;
  }
  .grid-col-9\@sm {
    --grid-columns: 9;
  }
  .col-9\@sm {
    --column-span: 9;
  }
  .grid-col-10\@sm {
    --grid-columns: 10;
  }
  .col-10\@sm {
    --column-span: 10;
  }
  .grid-col-11\@sm {
    --grid-columns: 11;
  }
  .col-11\@sm {
    --column-span: 11;
  }
  .grid-col-12\@sm {
    --grid-columns: 12;
  }
  .col-12\@sm {
    --column-span: 12;
  }
  .col-1\@sm, .col-2\@sm, .col-3\@sm, .col-4\@sm, .col-5\@sm, .col-6\@sm, .col-7\@sm, .col-8\@sm, .col-9\@sm, .col-10\@sm, .col-11\@sm, .col-12\@sm {
    width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
    flex: initial;
  }
  .col\@sm {
    width: auto;
    flex: 1 1 0;
  }
  .col-content\@sm {
    width: auto;
    flex: 0 1 auto;
  }
  .offset-1\@sm {
    --column-offset: 1;
  }
  .offset-2\@sm {
    --column-offset: 2;
  }
  .offset-3\@sm {
    --column-offset: 3;
  }
  .offset-4\@sm {
    --column-offset: 4;
  }
  .offset-5\@sm {
    --column-offset: 5;
  }
  .offset-6\@sm {
    --column-offset: 6;
  }
  .offset-7\@sm {
    --column-offset: 7;
  }
  .offset-8\@sm {
    --column-offset: 8;
  }
  .offset-9\@sm {
    --column-offset: 9;
  }
  .offset-10\@sm {
    --column-offset: 10;
  }
  .offset-11\@sm {
    --column-offset: 11;
  }
  .offset-1\@sm, .offset-2\@sm, .offset-3\@sm, .offset-4\@sm, .offset-5\@sm, .offset-6\@sm, .offset-7\@sm, .offset-8\@sm, .offset-9\@sm, .offset-10\@sm, .offset-11\@sm {
    margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
  }
  .offset-0\@sm {
    margin-left: 0;
  }
  /* gap */
  .gap-4xs\@sm {
    --row-gap: var(--space-4xs);
    --column-gap: var(--space-4xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xs\@sm {
    --column-gap: var(--space-4xs);
    column-gap: var(--column-gap);
  }
  .gap-y-4xs\@sm {
    --row-gap: var(--space-4xs);
    row-gap: var(--row-gap);
  }
  .gap-3xs\@sm {
    --row-gap: var(--space-3xs);
    --column-gap: var(--space-3xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xs\@sm {
    --column-gap: var(--space-3xs);
    column-gap: var(--column-gap);
  }
  .gap-y-3xs\@sm {
    --row-gap: var(--space-3xs);
    row-gap: var(--row-gap);
  }
  .gap-2xs\@sm {
    --row-gap: var(--space-2xs);
    --column-gap: var(--space-2xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xs\@sm {
    --column-gap: var(--space-2xs);
    column-gap: var(--column-gap);
  }
  .gap-y-2xs\@sm {
    --row-gap: var(--space-2xs);
    row-gap: var(--row-gap);
  }
  .gap-xs\@sm {
    --row-gap: var(--space-xs);
    --column-gap: var(--space-xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xs\@sm {
    --column-gap: var(--space-xs);
    column-gap: var(--column-gap);
  }
  .gap-y-xs\@sm {
    --row-gap: var(--space-xs);
    row-gap: var(--row-gap);
  }
  .gap-sm\@sm {
    --row-gap: var(--space-sm);
    --column-gap: var(--space-sm);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-sm\@sm {
    --column-gap: var(--space-sm);
    column-gap: var(--column-gap);
  }
  .gap-y-sm\@sm {
    --row-gap: var(--space-sm);
    row-gap: var(--row-gap);
  }
  .gap-md\@sm {
    --row-gap: var(--space-md);
    --column-gap: var(--space-md);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-md\@sm {
    --column-gap: var(--space-md);
    column-gap: var(--column-gap);
  }
  .gap-y-md\@sm {
    --row-gap: var(--space-md);
    row-gap: var(--row-gap);
  }
  .gap-lg\@sm {
    --row-gap: var(--space-lg);
    --column-gap: var(--space-lg);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-lg\@sm {
    --column-gap: var(--space-lg);
    column-gap: var(--column-gap);
  }
  .gap-y-lg\@sm {
    --row-gap: var(--space-lg);
    row-gap: var(--row-gap);
  }
  .gap-xl\@sm {
    --row-gap: var(--space-xl);
    --column-gap: var(--space-xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xl\@sm {
    --column-gap: var(--space-xl);
    column-gap: var(--column-gap);
  }
  .gap-y-xl\@sm {
    --row-gap: var(--space-xl);
    row-gap: var(--row-gap);
  }
  .gap-2xl\@sm {
    --row-gap: var(--space-2xl);
    --column-gap: var(--space-2xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xl\@sm {
    --column-gap: var(--space-2xl);
    column-gap: var(--column-gap);
  }
  .gap-y-2xl\@sm {
    --row-gap: var(--space-2xl);
    row-gap: var(--row-gap);
  }
  .gap-3xl\@sm {
    --row-gap: var(--space-3xl);
    --column-gap: var(--space-3xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xl\@sm {
    --column-gap: var(--space-3xl);
    column-gap: var(--column-gap);
  }
  .gap-y-3xl\@sm {
    --row-gap: var(--space-3xl);
    row-gap: var(--row-gap);
  }
  .gap-4xl\@sm {
    --row-gap: var(--space-4xl);
    --column-gap: var(--space-4xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xl\@sm {
    --column-gap: var(--space-4xl);
    column-gap: var(--column-gap);
  }
  .gap-y-4xl\@sm {
    --row-gap: var(--space-4xl);
    row-gap: var(--row-gap);
  }
  .gap-0\@sm {
    --row-gap: 0px;
    --column-gap: 0px;
    gap: 0px;
  }
  .gap-x-0\@sm {
    --column-gap: 0px;
    column-gap: 0px;
  }
  .gap-y-0\@sm {
    --row-gap: 0px;
    row-gap: 0px;
  }
  /* flexbox */
  .flex\@sm {
    display: flex;
  }
  .inline-flex\@sm {
    display: inline-flex;
  }
  .flex-wrap\@sm {
    flex-wrap: wrap;
  }
  .flex-nowrap\@sm {
    flex-wrap: nowrap;
  }
  .flex-column\@sm {
    flex-direction: column;
  }
  .flex-column-reverse\@sm {
    flex-direction: column-reverse;
  }
  .flex-row\@sm {
    flex-direction: row;
  }
  .flex-row-reverse\@sm {
    flex-direction: row-reverse;
  }
  .flex-center\@sm {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@sm {
    flex-grow: 1;
  }
  .flex-grow-0\@sm {
    flex-grow: 0;
  }
  .flex-shrink\@sm {
    flex-shrink: 1;
  }
  .flex-shrink-0\@sm {
    flex-shrink: 0;
  }
  .flex-basis-0\@sm {
    flex-basis: 0;
  }
  /* justify-content */
  .justify-start\@sm {
    justify-content: start;
  }
  .justify-end\@sm {
    justify-content: end;
  }
  .justify-center\@sm {
    justify-content: center;
  }
  .justify-between\@sm {
    justify-content: space-between;
  }
  /* align-items */
  .items-center\@sm {
    align-items: center;
  }
  .items-start\@sm {
    align-items: start;
  }
  .items-end\@sm {
    align-items: end;
  }
  .items-baseline\@sm {
    align-items: baseline;
  }
  .items-stretch\@sm {
    align-items: stretch;
  }
  /* align-content */
  .content-start\@sm {
    align-content: start;
  }
  .content-end\@sm {
    align-content: end;
  }
  .content-center\@sm {
    align-content: center;
  }
  .content-between\@sm {
    align-content: space-between;
  }
  /* order */
  .order-1\@sm {
    order: 1;
  }
  .order-2\@sm {
    order: 2;
  }
  .order-3\@sm {
    order: 3;
  }
  /* display */
  .block\@sm {
    display: block;
  }
  .inline-block\@sm {
    display: inline-block;
  }
  .inline\@sm {
    display: inline;
  }
  .contents\@sm {
    display: contents;
  }
  .css-grid\@sm {
    display: grid;
  }
  .css-inline-grid\@sm {
    display: inline-grid;
  }
  .hide\@sm {
    display: none;
  }
  /* margin */
  .margin-4xs\@sm {
    margin: var(--space-4xs);
  }
  .margin-top-4xs\@sm {
    margin-top: var(--space-4xs);
  }
  .margin-right-4xs\@sm {
    margin-right: var(--space-4xs);
  }
  .margin-bottom-4xs\@sm {
    margin-bottom: var(--space-4xs);
  }
  .margin-left-4xs\@sm {
    margin-left: var(--space-4xs);
  }
  .margin-y-4xs\@sm {
    margin-top: var(--space-4xs);
    margin-bottom: var(--space-4xs);
  }
  .margin-x-4xs\@sm {
    margin-right: var(--space-4xs);
    margin-left: var(--space-4xs);
  }
  .margin-3xs\@sm {
    margin: var(--space-3xs);
  }
  .margin-top-3xs\@sm {
    margin-top: var(--space-3xs);
  }
  .margin-right-3xs\@sm {
    margin-right: var(--space-3xs);
  }
  .margin-bottom-3xs\@sm {
    margin-bottom: var(--space-3xs);
  }
  .margin-left-3xs\@sm {
    margin-left: var(--space-3xs);
  }
  .margin-y-3xs\@sm {
    margin-top: var(--space-3xs);
    margin-bottom: var(--space-3xs);
  }
  .margin-x-3xs\@sm {
    margin-right: var(--space-3xs);
    margin-left: var(--space-3xs);
  }
  .margin-2xs\@sm {
    margin: var(--space-2xs);
  }
  .margin-top-2xs\@sm {
    margin-top: var(--space-2xs);
  }
  .margin-right-2xs\@sm {
    margin-right: var(--space-2xs);
  }
  .margin-bottom-2xs\@sm {
    margin-bottom: var(--space-2xs);
  }
  .margin-left-2xs\@sm {
    margin-left: var(--space-2xs);
  }
  .margin-y-2xs\@sm {
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-2xs);
  }
  .margin-x-2xs\@sm {
    margin-right: var(--space-2xs);
    margin-left: var(--space-2xs);
  }
  .margin-xs\@sm {
    margin: var(--space-xs);
  }
  .margin-top-xs\@sm {
    margin-top: var(--space-xs);
  }
  .margin-right-xs\@sm {
    margin-right: var(--space-xs);
  }
  .margin-bottom-xs\@sm {
    margin-bottom: var(--space-xs);
  }
  .margin-left-xs\@sm {
    margin-left: var(--space-xs);
  }
  .margin-y-xs\@sm {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
  .margin-x-xs\@sm {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }
  .margin-sm\@sm {
    margin: var(--space-sm);
  }
  .margin-top-sm\@sm {
    margin-top: var(--space-sm);
  }
  .margin-right-sm\@sm {
    margin-right: var(--space-sm);
  }
  .margin-bottom-sm\@sm {
    margin-bottom: var(--space-sm);
  }
  .margin-left-sm\@sm {
    margin-left: var(--space-sm);
  }
  .margin-y-sm\@sm {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }
  .margin-x-sm\@sm {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }
  .margin-md\@sm {
    margin: var(--space-md);
  }
  .margin-top-md\@sm {
    margin-top: var(--space-md);
  }
  .margin-right-md\@sm {
    margin-right: var(--space-md);
  }
  .margin-bottom-md\@sm {
    margin-bottom: var(--space-md);
  }
  .margin-left-md\@sm {
    margin-left: var(--space-md);
  }
  .margin-y-md\@sm {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }
  .margin-x-md\@sm {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }
  .margin-lg\@sm {
    margin: var(--space-lg);
  }
  .margin-top-lg\@sm {
    margin-top: var(--space-lg);
  }
  .margin-right-lg\@sm {
    margin-right: var(--space-lg);
  }
  .margin-bottom-lg\@sm {
    margin-bottom: var(--space-lg);
  }
  .margin-left-lg\@sm {
    margin-left: var(--space-lg);
  }
  .margin-y-lg\@sm {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }
  .margin-x-lg\@sm {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }
  .margin-xl\@sm {
    margin: var(--space-xl);
  }
  .margin-top-xl\@sm {
    margin-top: var(--space-xl);
  }
  .margin-right-xl\@sm {
    margin-right: var(--space-xl);
  }
  .margin-bottom-xl\@sm {
    margin-bottom: var(--space-xl);
  }
  .margin-left-xl\@sm {
    margin-left: var(--space-xl);
  }
  .margin-y-xl\@sm {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  .margin-x-xl\@sm {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }
  .margin-2xl\@sm {
    margin: var(--space-2xl);
  }
  .margin-top-2xl\@sm {
    margin-top: var(--space-2xl);
  }
  .margin-right-2xl\@sm {
    margin-right: var(--space-2xl);
  }
  .margin-bottom-2xl\@sm {
    margin-bottom: var(--space-2xl);
  }
  .margin-left-2xl\@sm {
    margin-left: var(--space-2xl);
  }
  .margin-y-2xl\@sm {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
  }
  .margin-x-2xl\@sm {
    margin-right: var(--space-2xl);
    margin-left: var(--space-2xl);
  }
  .margin-3xl\@sm {
    margin: var(--space-3xl);
  }
  .margin-top-3xl\@sm {
    margin-top: var(--space-3xl);
  }
  .margin-right-3xl\@sm {
    margin-right: var(--space-3xl);
  }
  .margin-bottom-3xl\@sm {
    margin-bottom: var(--space-3xl);
  }
  .margin-left-3xl\@sm {
    margin-left: var(--space-3xl);
  }
  .margin-y-3xl\@sm {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-3xl);
  }
  .margin-x-3xl\@sm {
    margin-right: var(--space-3xl);
    margin-left: var(--space-3xl);
  }
  .margin-4xl\@sm {
    margin: var(--space-4xl);
  }
  .margin-top-4xl\@sm {
    margin-top: var(--space-4xl);
  }
  .margin-right-4xl\@sm {
    margin-right: var(--space-4xl);
  }
  .margin-bottom-4xl\@sm {
    margin-bottom: var(--space-4xl);
  }
  .margin-left-4xl\@sm {
    margin-left: var(--space-4xl);
  }
  .margin-y-4xl\@sm {
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-4xl);
  }
  .margin-x-4xl\@sm {
    margin-right: var(--space-4xl);
    margin-left: var(--space-4xl);
  }
  .margin-auto\@sm {
    margin: auto;
  }
  .margin-0\@sm {
    margin: 0;
  }
  .margin-y-auto\@sm {
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-y-0\@sm {
    margin-top: 0;
    margin-bottom: 0;
  }
  .margin-x-auto\@sm {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-x-0\@sm {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-top-auto\@sm {
    margin-top: auto;
  }
  .margin-top-0\@sm {
    margin-top: 0;
  }
  .margin-right-auto\@sm {
    margin-right: auto;
  }
  .margin-right-0\@sm {
    margin-right: 0;
  }
  .margin-bottom-auto\@sm {
    margin-bottom: auto;
  }
  .margin-bottom-0\@sm {
    margin-bottom: 0;
  }
  .margin-left-auto\@sm {
    margin-left: auto;
  }
  .margin-left-0\@sm {
    margin-left: 0;
  }
  /* padding */
  .padding-4xs\@sm {
    padding: var(--space-4xs);
  }
  .padding-top-4xs\@sm {
    padding-top: var(--space-4xs);
  }
  .padding-right-4xs\@sm {
    padding-right: var(--space-4xs);
  }
  .padding-bottom-4xs\@sm {
    padding-bottom: var(--space-4xs);
  }
  .padding-left-4xs\@sm {
    padding-left: var(--space-4xs);
  }
  .padding-y-4xs\@sm {
    padding-top: var(--space-4xs);
    padding-bottom: var(--space-4xs);
  }
  .padding-x-4xs\@sm {
    padding-right: var(--space-4xs);
    padding-left: var(--space-4xs);
  }
  .padding-3xs\@sm {
    padding: var(--space-3xs);
  }
  .padding-top-3xs\@sm {
    padding-top: var(--space-3xs);
  }
  .padding-right-3xs\@sm {
    padding-right: var(--space-3xs);
  }
  .padding-bottom-3xs\@sm {
    padding-bottom: var(--space-3xs);
  }
  .padding-left-3xs\@sm {
    padding-left: var(--space-3xs);
  }
  .padding-y-3xs\@sm {
    padding-top: var(--space-3xs);
    padding-bottom: var(--space-3xs);
  }
  .padding-x-3xs\@sm {
    padding-right: var(--space-3xs);
    padding-left: var(--space-3xs);
  }
  .padding-2xs\@sm {
    padding: var(--space-2xs);
  }
  .padding-top-2xs\@sm {
    padding-top: var(--space-2xs);
  }
  .padding-right-2xs\@sm {
    padding-right: var(--space-2xs);
  }
  .padding-bottom-2xs\@sm {
    padding-bottom: var(--space-2xs);
  }
  .padding-left-2xs\@sm {
    padding-left: var(--space-2xs);
  }
  .padding-y-2xs\@sm {
    padding-top: var(--space-2xs);
    padding-bottom: var(--space-2xs);
  }
  .padding-x-2xs\@sm {
    padding-right: var(--space-2xs);
    padding-left: var(--space-2xs);
  }
  .padding-xs\@sm {
    padding: var(--space-xs);
  }
  .padding-top-xs\@sm {
    padding-top: var(--space-xs);
  }
  .padding-right-xs\@sm {
    padding-right: var(--space-xs);
  }
  .padding-bottom-xs\@sm {
    padding-bottom: var(--space-xs);
  }
  .padding-left-xs\@sm {
    padding-left: var(--space-xs);
  }
  .padding-y-xs\@sm {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }
  .padding-x-xs\@sm {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }
  .padding-sm\@sm {
    padding: var(--space-sm);
  }
  .padding-top-sm\@sm {
    padding-top: var(--space-sm);
  }
  .padding-right-sm\@sm {
    padding-right: var(--space-sm);
  }
  .padding-bottom-sm\@sm {
    padding-bottom: var(--space-sm);
  }
  .padding-left-sm\@sm {
    padding-left: var(--space-sm);
  }
  .padding-y-sm\@sm {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
  .padding-x-sm\@sm {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }
  .padding-md\@sm {
    padding: var(--space-md);
  }
  .padding-top-md\@sm {
    padding-top: var(--space-md);
  }
  .padding-right-md\@sm {
    padding-right: var(--space-md);
  }
  .padding-bottom-md\@sm {
    padding-bottom: var(--space-md);
  }
  .padding-left-md\@sm {
    padding-left: var(--space-md);
  }
  .padding-y-md\@sm {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }
  .padding-x-md\@sm {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }
  .padding-lg\@sm {
    padding: var(--space-lg);
  }
  .padding-top-lg\@sm {
    padding-top: var(--space-lg);
  }
  .padding-right-lg\@sm {
    padding-right: var(--space-lg);
  }
  .padding-bottom-lg\@sm {
    padding-bottom: var(--space-lg);
  }
  .padding-left-lg\@sm {
    padding-left: var(--space-lg);
  }
  .padding-y-lg\@sm {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
  .padding-x-lg\@sm {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }
  .padding-xl\@sm {
    padding: var(--space-xl);
  }
  .padding-top-xl\@sm {
    padding-top: var(--space-xl);
  }
  .padding-right-xl\@sm {
    padding-right: var(--space-xl);
  }
  .padding-bottom-xl\@sm {
    padding-bottom: var(--space-xl);
  }
  .padding-left-xl\@sm {
    padding-left: var(--space-xl);
  }
  .padding-y-xl\@sm {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  .padding-x-xl\@sm {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }
  .padding-2xl\@sm {
    padding: var(--space-2xl);
  }
  .padding-top-2xl\@sm {
    padding-top: var(--space-2xl);
  }
  .padding-right-2xl\@sm {
    padding-right: var(--space-2xl);
  }
  .padding-bottom-2xl\@sm {
    padding-bottom: var(--space-2xl);
  }
  .padding-left-2xl\@sm {
    padding-left: var(--space-2xl);
  }
  .padding-y-2xl\@sm {
    padding-top: var(--space-2xl);
    padding-bottom: var(--space-2xl);
  }
  .padding-x-2xl\@sm {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
  .padding-3xl\@sm {
    padding: var(--space-3xl);
  }
  .padding-top-3xl\@sm {
    padding-top: var(--space-3xl);
  }
  .padding-right-3xl\@sm {
    padding-right: var(--space-3xl);
  }
  .padding-bottom-3xl\@sm {
    padding-bottom: var(--space-3xl);
  }
  .padding-left-3xl\@sm {
    padding-left: var(--space-3xl);
  }
  .padding-y-3xl\@sm {
    padding-top: var(--space-3xl);
    padding-bottom: var(--space-3xl);
  }
  .padding-x-3xl\@sm {
    padding-right: var(--space-3xl);
    padding-left: var(--space-3xl);
  }
  .padding-4xl\@sm {
    padding: var(--space-4xl);
  }
  .padding-top-4xl\@sm {
    padding-top: var(--space-4xl);
  }
  .padding-right-4xl\@sm {
    padding-right: var(--space-4xl);
  }
  .padding-bottom-4xl\@sm {
    padding-bottom: var(--space-4xl);
  }
  .padding-left-4xl\@sm {
    padding-left: var(--space-4xl);
  }
  .padding-y-4xl\@sm {
    padding-top: var(--space-4xl);
    padding-bottom: var(--space-4xl);
  }
  .padding-x-4xl\@sm {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
  .padding-0\@sm {
    padding: 0;
  }
  .padding-y-0\@sm {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-x-0\@sm {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-top-0\@sm {
    padding-top: 0;
  }
  .padding-right-0\@sm {
    padding-right: 0;
  }
  .padding-bottom-0\@sm {
    padding-bottom: 0;
  }
  .padding-left-0\@sm {
    padding-left: 0;
  }
  /* text-align */
  .text-center\@sm {
    text-align: center;
  }
  .text-left\@sm {
    text-align: left;
  }
  .text-right\@sm {
    text-align: right;
  }
  .text-justify\@sm {
    text-align: justify;
  }
  /* font-size */
  .text-xs\@sm {
    font-size: var(--text-xs);
  }
  .text-sm\@sm {
    font-size: var(--text-sm);
  }
  .text-base\@sm {
    font-size: var(--text-base);
  }
  .text-md\@sm {
    font-size: var(--text-md);
  }
  .text-lg\@sm {
    font-size: var(--text-lg);
  }
  .text-xl\@sm {
    font-size: var(--text-xl);
  }
  .text-2xl\@sm {
    font-size: var(--text-2xl);
  }
  .text-3xl\@sm {
    font-size: var(--text-3xl);
  }
  .text-4xl\@sm {
    font-size: var(--text-4xl);
  }
  /* column-count */
  .column-count-1\@sm {
    column-count: 1;
  }
  .column-count-2\@sm {
    column-count: 2;
  }
  .column-count-3\@sm {
    column-count: 3;
  }
  .column-count-4\@sm {
    column-count: 4;
  }
  /* width */
  .width-4xs\@sm {
    width: 0.25rem;
  }
  .width-3xs\@sm {
    width: 0.5rem;
  }
  .width-2xs\@sm {
    width: 0.75rem;
  }
  .width-xs\@sm {
    width: 1rem;
  }
  .width-sm\@sm {
    width: 1.5rem;
  }
  .width-md\@sm {
    width: 2rem;
  }
  .width-lg\@sm {
    width: 3rem;
  }
  .width-xl\@sm {
    width: 4rem;
  }
  .width-2xl\@sm {
    width: 6rem;
  }
  .width-3xl\@sm {
    width: 8rem;
  }
  .width-4xl\@sm {
    width: 16rem;
  }
  .width-0\@sm {
    width: 0;
  }
  .width-10\%\@sm {
    width: 10%;
  }
  .width-20\%\@sm {
    width: 20%;
  }
  .width-25\%\@sm {
    width: 25%;
  }
  .width-30\%\@sm {
    width: 30%;
  }
  .width-33\%\@sm {
    width: 33%;
  }
  .width-40\%\@sm {
    width: 40%;
  }
  .width-50\%\@sm {
    width: 50%;
  }
  .width-60\%\@sm {
    width: 60%;
  }
  .width-70\%\@sm {
    width: 70%;
  }
  .width-75\%\@sm {
    width: 75%;
  }
  .width-80\%\@sm {
    width: 80%;
  }
  .width-90\%\@sm {
    width: 90%;
  }
  .width-100\%\@sm {
    width: 100%;
  }
  .width-100\%\@sm {
    width: 100%;
  }
  .width-100vw\@sm {
    width: 100vw;
  }
  .width-auto\@sm {
    width: auto;
  }
  .width-inherit\@sm {
    width: inherit;
  }
  .width-0\@sm {
    width: 0;
  }
  /* height */
  .height-4xs\@sm {
    height: 0.25rem;
  }
  .height-3xs\@sm {
    height: 0.5rem;
  }
  .height-2xs\@sm {
    height: 0.75rem;
  }
  .height-xs\@sm {
    height: 1rem;
  }
  .height-sm\@sm {
    height: 1.5rem;
  }
  .height-md\@sm {
    height: 2rem;
  }
  .height-lg\@sm {
    height: 3rem;
  }
  .height-xl\@sm {
    height: 4rem;
  }
  .height-2xl\@sm {
    height: 6rem;
  }
  .height-3xl\@sm {
    height: 8rem;
  }
  .height-4xl\@sm {
    height: 16rem;
  }
  .height-0\@sm {
    height: 0;
  }
  .height-10\%\@sm {
    height: 10%;
  }
  .height-20\%\@sm {
    height: 20%;
  }
  .height-25\%\@sm {
    height: 25%;
  }
  .height-30\%\@sm {
    height: 30%;
  }
  .height-33\%\@sm {
    height: 33%;
  }
  .height-40\%\@sm {
    height: 40%;
  }
  .height-50\%\@sm {
    height: 50%;
  }
  .height-60\%\@sm {
    height: 60%;
  }
  .height-70\%\@sm {
    height: 70%;
  }
  .height-75\%\@sm {
    height: 75%;
  }
  .height-80\%\@sm {
    height: 80%;
  }
  .height-90\%\@sm {
    height: 90%;
  }
  .height-100\%\@sm {
    height: 100%;
  }
  .height-100\%\@sm {
    height: 100%;
  }
  .height-100vh\@sm {
    height: 100vh;
  }
  .height-auto\@sm {
    height: auto;
  }
  .height-inherit\@sm {
    height: inherit;
  }
  .height-0\@sm {
    height: 0;
  }
  /* max-width */
  .max-width-3xs\@sm {
    max-width: var(--max-width-3xs);
  }
  .max-width-2xs\@sm {
    max-width: var(--max-width-2xs);
  }
  .max-width-xs\@sm {
    max-width: var(--max-width-xs);
  }
  .max-width-sm\@sm {
    max-width: var(--max-width-sm);
  }
  .max-width-md\@sm {
    max-width: var(--max-width-md);
  }
  .max-width-lg\@sm {
    max-width: var(--max-width-lg);
  }
  .max-width-xl\@sm {
    max-width: var(--max-width-xl);
  }
  .max-width-100\%\@sm {
    max-width: 100%;
  }
  .max-width-none\@sm {
    max-width: none;
  }
  /* position */
  .position-relative\@sm {
    position: relative;
  }
  .position-absolute\@sm {
    position: absolute;
  }
  .position-fixed\@sm {
    position: fixed;
  }
  .position-sticky\@sm {
    position: sticky;
  }
  .position-static\@sm {
    position: static;
  }
  .inset-0\@sm {
    inset: 0;
  }
  .top-4xs\@sm {
    top: var(--space-4xs);
  }
  .right-4xs\@sm {
    right: var(--space-4xs);
  }
  .bottom-4xs\@sm {
    bottom: var(--space-4xs);
  }
  .left-4xs\@sm {
    left: var(--space-4xs);
  }
  .top-3xs\@sm {
    top: var(--space-3xs);
  }
  .right-3xs\@sm {
    right: var(--space-3xs);
  }
  .bottom-3xs\@sm {
    bottom: var(--space-3xs);
  }
  .left-3xs\@sm {
    left: var(--space-3xs);
  }
  .top-2xs\@sm {
    top: var(--space-2xs);
  }
  .right-2xs\@sm {
    right: var(--space-2xs);
  }
  .bottom-2xs\@sm {
    bottom: var(--space-2xs);
  }
  .left-2xs\@sm {
    left: var(--space-2xs);
  }
  .top-xs\@sm {
    top: var(--space-xs);
  }
  .right-xs\@sm {
    right: var(--space-xs);
  }
  .bottom-xs\@sm {
    bottom: var(--space-xs);
  }
  .left-xs\@sm {
    left: var(--space-xs);
  }
  .top-sm\@sm {
    top: var(--space-sm);
  }
  .right-sm\@sm {
    right: var(--space-sm);
  }
  .bottom-sm\@sm {
    bottom: var(--space-sm);
  }
  .left-sm\@sm {
    left: var(--space-sm);
  }
  .top-md\@sm {
    top: var(--space-md);
  }
  .right-md\@sm {
    right: var(--space-md);
  }
  .bottom-md\@sm {
    bottom: var(--space-md);
  }
  .left-md\@sm {
    left: var(--space-md);
  }
  .top-lg\@sm {
    top: var(--space-lg);
  }
  .right-lg\@sm {
    right: var(--space-lg);
  }
  .bottom-lg\@sm {
    bottom: var(--space-lg);
  }
  .left-lg\@sm {
    left: var(--space-lg);
  }
  .top-xl\@sm {
    top: var(--space-xl);
  }
  .right-xl\@sm {
    right: var(--space-xl);
  }
  .bottom-xl\@sm {
    bottom: var(--space-xl);
  }
  .left-xl\@sm {
    left: var(--space-xl);
  }
  .top-2xl\@sm {
    top: var(--space-2xl);
  }
  .right-2xl\@sm {
    right: var(--space-2xl);
  }
  .bottom-2xl\@sm {
    bottom: var(--space-2xl);
  }
  .left-2xl\@sm {
    left: var(--space-2xl);
  }
  .top-3xl\@sm {
    top: var(--space-3xl);
  }
  .right-3xl\@sm {
    right: var(--space-3xl);
  }
  .bottom-3xl\@sm {
    bottom: var(--space-3xl);
  }
  .left-3xl\@sm {
    left: var(--space-3xl);
  }
  .top-4xl\@sm {
    top: var(--space-4xl);
  }
  .right-4xl\@sm {
    right: var(--space-4xl);
  }
  .bottom-4xl\@sm {
    bottom: var(--space-4xl);
  }
  .left-4xl\@sm {
    left: var(--space-4xl);
  }
  .top-50\%\@sm {
    top: 50%;
  }
  .top-auto\@sm {
    top: auto;
  }
  .top-0\@sm {
    top: 0;
  }
  .right-50\%\@sm {
    right: 50%;
  }
  .right-auto\@sm {
    right: auto;
  }
  .right-0\@sm {
    right: 0;
  }
  .bottom-50\%\@sm {
    bottom: 50%;
  }
  .bottom-auto\@sm {
    bottom: auto;
  }
  .bottom-0\@sm {
    bottom: 0;
  }
  .left-50\%\@sm {
    left: 50%;
  }
  .left-auto\@sm {
    left: auto;
  }
  .left-0\@sm {
    left: 0;
  }
  /* overflow */
  .overflow-visible\@sm {
    overflow: visible;
  }
  .overflow-hidden\@sm {
    overflow: hidden;
  }
  .overflow-auto\@sm {
    overflow: auto;
  }
  .momentum-scrolling\@sm {
    -webkit-overflow-scrolling: touch;
  }
  /* visibility */
  .visible\@sm {
    visibility: visible;
  }
  .invisible\@sm {
    visibility: hidden;
  }
}
@media not all and (min-width: 48rem) {
  .display\@sm {
    display: none !important;
  }
}
@media (min-width: 64rem) {
  :root {
    /* spacing */
    --space-4xs: 0.1875rem;
    --space-3xs: 0.375rem;
    --space-2xs: 0.5625rem;
    --space-xs: 0.75rem;
    --space-sm: 1.125rem;
    --space-md: 2rem;
    --space-lg: 3.125rem;
    --space-xl: 5.125rem;
    --space-2xl: 8.25rem;
    --space-3xl: 13.25rem;
    --space-4xl: 21.5rem;
    /* font-size */
    --text-xs: 0.75rem;
    --text-sm: 0.9375rem;
    --text-base: 1.125rem;
    --text-md: 1.375rem;
    --text-lg: 1.625rem;
    --text-xl: 2rem;
    --text-2xl: 2.5rem;
    --text-3xl: 3rem;
    --text-4xl: 3.625rem;
  }
  /* grid */
  .grid-col-1\@md {
    --grid-columns: 1;
  }
  .col-1\@md {
    --column-span: 1;
  }
  .grid-col-2\@md {
    --grid-columns: 2;
  }
  .col-2\@md {
    --column-span: 2;
  }
  .grid-col-3\@md {
    --grid-columns: 3;
  }
  .col-3\@md {
    --column-span: 3;
  }
  .grid-col-4\@md {
    --grid-columns: 4;
  }
  .col-4\@md {
    --column-span: 4;
  }
  .grid-col-5\@md {
    --grid-columns: 5;
  }
  .col-5\@md {
    --column-span: 5;
  }
  .grid-col-6\@md {
    --grid-columns: 6;
  }
  .col-6\@md {
    --column-span: 6;
  }
  .grid-col-7\@md {
    --grid-columns: 7;
  }
  .col-7\@md {
    --column-span: 7;
  }
  .grid-col-8\@md {
    --grid-columns: 8;
  }
  .col-8\@md {
    --column-span: 8;
  }
  .grid-col-9\@md {
    --grid-columns: 9;
  }
  .col-9\@md {
    --column-span: 9;
  }
  .grid-col-10\@md {
    --grid-columns: 10;
  }
  .col-10\@md {
    --column-span: 10;
  }
  .grid-col-11\@md {
    --grid-columns: 11;
  }
  .col-11\@md {
    --column-span: 11;
  }
  .grid-col-12\@md {
    --grid-columns: 12;
  }
  .col-12\@md {
    --column-span: 12;
  }
  .col-1\@md, .col-2\@md, .col-3\@md, .col-4\@md, .col-5\@md, .col-6\@md, .col-7\@md, .col-8\@md, .col-9\@md, .col-10\@md, .col-11\@md, .col-12\@md {
    width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
    flex: initial;
  }
  .col\@md {
    width: auto;
    flex: 1 1 0;
  }
  .col-content\@md {
    width: auto;
    flex: 0 1 auto;
  }
  .offset-1\@md {
    --column-offset: 1;
  }
  .offset-2\@md {
    --column-offset: 2;
  }
  .offset-3\@md {
    --column-offset: 3;
  }
  .offset-4\@md {
    --column-offset: 4;
  }
  .offset-5\@md {
    --column-offset: 5;
  }
  .offset-6\@md {
    --column-offset: 6;
  }
  .offset-7\@md {
    --column-offset: 7;
  }
  .offset-8\@md {
    --column-offset: 8;
  }
  .offset-9\@md {
    --column-offset: 9;
  }
  .offset-10\@md {
    --column-offset: 10;
  }
  .offset-11\@md {
    --column-offset: 11;
  }
  .offset-1\@md, .offset-2\@md, .offset-3\@md, .offset-4\@md, .offset-5\@md, .offset-6\@md, .offset-7\@md, .offset-8\@md, .offset-9\@md, .offset-10\@md, .offset-11\@md {
    margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
  }
  .offset-0\@md {
    margin-left: 0;
  }
  /* gap */
  .gap-4xs\@md {
    --row-gap: var(--space-4xs);
    --column-gap: var(--space-4xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xs\@md {
    --column-gap: var(--space-4xs);
    column-gap: var(--column-gap);
  }
  .gap-y-4xs\@md {
    --row-gap: var(--space-4xs);
    row-gap: var(--row-gap);
  }
  .gap-3xs\@md {
    --row-gap: var(--space-3xs);
    --column-gap: var(--space-3xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xs\@md {
    --column-gap: var(--space-3xs);
    column-gap: var(--column-gap);
  }
  .gap-y-3xs\@md {
    --row-gap: var(--space-3xs);
    row-gap: var(--row-gap);
  }
  .gap-2xs\@md {
    --row-gap: var(--space-2xs);
    --column-gap: var(--space-2xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xs\@md {
    --column-gap: var(--space-2xs);
    column-gap: var(--column-gap);
  }
  .gap-y-2xs\@md {
    --row-gap: var(--space-2xs);
    row-gap: var(--row-gap);
  }
  .gap-xs\@md {
    --row-gap: var(--space-xs);
    --column-gap: var(--space-xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xs\@md {
    --column-gap: var(--space-xs);
    column-gap: var(--column-gap);
  }
  .gap-y-xs\@md {
    --row-gap: var(--space-xs);
    row-gap: var(--row-gap);
  }
  .gap-sm\@md {
    --row-gap: var(--space-sm);
    --column-gap: var(--space-sm);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-sm\@md {
    --column-gap: var(--space-sm);
    column-gap: var(--column-gap);
  }
  .gap-y-sm\@md {
    --row-gap: var(--space-sm);
    row-gap: var(--row-gap);
  }
  .gap-md\@md {
    --row-gap: var(--space-md);
    --column-gap: var(--space-md);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-md\@md {
    --column-gap: var(--space-md);
    column-gap: var(--column-gap);
  }
  .gap-y-md\@md {
    --row-gap: var(--space-md);
    row-gap: var(--row-gap);
  }
  .gap-lg\@md {
    --row-gap: var(--space-lg);
    --column-gap: var(--space-lg);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-lg\@md {
    --column-gap: var(--space-lg);
    column-gap: var(--column-gap);
  }
  .gap-y-lg\@md {
    --row-gap: var(--space-lg);
    row-gap: var(--row-gap);
  }
  .gap-xl\@md {
    --row-gap: var(--space-xl);
    --column-gap: var(--space-xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xl\@md {
    --column-gap: var(--space-xl);
    column-gap: var(--column-gap);
  }
  .gap-y-xl\@md {
    --row-gap: var(--space-xl);
    row-gap: var(--row-gap);
  }
  .gap-2xl\@md {
    --row-gap: var(--space-2xl);
    --column-gap: var(--space-2xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xl\@md {
    --column-gap: var(--space-2xl);
    column-gap: var(--column-gap);
  }
  .gap-y-2xl\@md {
    --row-gap: var(--space-2xl);
    row-gap: var(--row-gap);
  }
  .gap-3xl\@md {
    --row-gap: var(--space-3xl);
    --column-gap: var(--space-3xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xl\@md {
    --column-gap: var(--space-3xl);
    column-gap: var(--column-gap);
  }
  .gap-y-3xl\@md {
    --row-gap: var(--space-3xl);
    row-gap: var(--row-gap);
  }
  .gap-4xl\@md {
    --row-gap: var(--space-4xl);
    --column-gap: var(--space-4xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xl\@md {
    --column-gap: var(--space-4xl);
    column-gap: var(--column-gap);
  }
  .gap-y-4xl\@md {
    --row-gap: var(--space-4xl);
    row-gap: var(--row-gap);
  }
  .gap-0\@md {
    --row-gap: 0px;
    --column-gap: 0px;
    gap: 0px;
  }
  .gap-x-0\@md {
    --column-gap: 0px;
    column-gap: 0px;
  }
  .gap-y-0\@md {
    --row-gap: 0px;
    row-gap: 0px;
  }
  /* flexbox */
  .flex\@md {
    display: flex;
  }
  .inline-flex\@md {
    display: inline-flex;
  }
  .flex-wrap\@md {
    flex-wrap: wrap;
  }
  .flex-nowrap\@md {
    flex-wrap: nowrap;
  }
  .flex-column\@md {
    flex-direction: column;
  }
  .flex-column-reverse\@md {
    flex-direction: column-reverse;
  }
  .flex-row\@md {
    flex-direction: row;
  }
  .flex-row-reverse\@md {
    flex-direction: row-reverse;
  }
  .flex-center\@md {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@md {
    flex-grow: 1;
  }
  .flex-grow-0\@md {
    flex-grow: 0;
  }
  .flex-shrink\@md {
    flex-shrink: 1;
  }
  .flex-shrink-0\@md {
    flex-shrink: 0;
  }
  .flex-basis-0\@md {
    flex-basis: 0;
  }
  /* justify-content */
  .justify-start\@md {
    justify-content: start;
  }
  .justify-end\@md {
    justify-content: end;
  }
  .justify-center\@md {
    justify-content: center;
  }
  .justify-between\@md {
    justify-content: space-between;
  }
  /* align-items */
  .items-center\@md {
    align-items: center;
  }
  .items-start\@md {
    align-items: start;
  }
  .items-end\@md {
    align-items: end;
  }
  .items-baseline\@md {
    align-items: baseline;
  }
  .items-stretch\@md {
    align-items: stretch;
  }
  /* align-content */
  .content-start\@md {
    align-content: start;
  }
  .content-end\@md {
    align-content: end;
  }
  .content-center\@md {
    align-content: center;
  }
  .content-between\@md {
    align-content: space-between;
  }
  /* order */
  .order-1\@md {
    order: 1;
  }
  .order-2\@md {
    order: 2;
  }
  .order-3\@md {
    order: 3;
  }
  /* display */
  .block\@md {
    display: block;
  }
  .inline-block\@md {
    display: inline-block;
  }
  .inline\@md {
    display: inline;
  }
  .contents\@md {
    display: contents;
  }
  .css-grid\@md {
    display: grid;
  }
  .css-inline-grid\@md {
    display: inline-grid;
  }
  .hide\@md {
    display: none;
  }
  /* margin */
  .margin-4xs\@md {
    margin: var(--space-4xs);
  }
  .margin-top-4xs\@md {
    margin-top: var(--space-4xs);
  }
  .margin-right-4xs\@md {
    margin-right: var(--space-4xs);
  }
  .margin-bottom-4xs\@md {
    margin-bottom: var(--space-4xs);
  }
  .margin-left-4xs\@md {
    margin-left: var(--space-4xs);
  }
  .margin-y-4xs\@md {
    margin-top: var(--space-4xs);
    margin-bottom: var(--space-4xs);
  }
  .margin-x-4xs\@md {
    margin-right: var(--space-4xs);
    margin-left: var(--space-4xs);
  }
  .margin-3xs\@md {
    margin: var(--space-3xs);
  }
  .margin-top-3xs\@md {
    margin-top: var(--space-3xs);
  }
  .margin-right-3xs\@md {
    margin-right: var(--space-3xs);
  }
  .margin-bottom-3xs\@md {
    margin-bottom: var(--space-3xs);
  }
  .margin-left-3xs\@md {
    margin-left: var(--space-3xs);
  }
  .margin-y-3xs\@md {
    margin-top: var(--space-3xs);
    margin-bottom: var(--space-3xs);
  }
  .margin-x-3xs\@md {
    margin-right: var(--space-3xs);
    margin-left: var(--space-3xs);
  }
  .margin-2xs\@md {
    margin: var(--space-2xs);
  }
  .margin-top-2xs\@md {
    margin-top: var(--space-2xs);
  }
  .margin-right-2xs\@md {
    margin-right: var(--space-2xs);
  }
  .margin-bottom-2xs\@md {
    margin-bottom: var(--space-2xs);
  }
  .margin-left-2xs\@md {
    margin-left: var(--space-2xs);
  }
  .margin-y-2xs\@md {
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-2xs);
  }
  .margin-x-2xs\@md {
    margin-right: var(--space-2xs);
    margin-left: var(--space-2xs);
  }
  .margin-xs\@md {
    margin: var(--space-xs);
  }
  .margin-top-xs\@md {
    margin-top: var(--space-xs);
  }
  .margin-right-xs\@md {
    margin-right: var(--space-xs);
  }
  .margin-bottom-xs\@md {
    margin-bottom: var(--space-xs);
  }
  .margin-left-xs\@md {
    margin-left: var(--space-xs);
  }
  .margin-y-xs\@md {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
  .margin-x-xs\@md {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }
  .margin-sm\@md {
    margin: var(--space-sm);
  }
  .margin-top-sm\@md {
    margin-top: var(--space-sm);
  }
  .margin-right-sm\@md {
    margin-right: var(--space-sm);
  }
  .margin-bottom-sm\@md {
    margin-bottom: var(--space-sm);
  }
  .margin-left-sm\@md {
    margin-left: var(--space-sm);
  }
  .margin-y-sm\@md {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }
  .margin-x-sm\@md {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }
  .margin-md\@md {
    margin: var(--space-md);
  }
  .margin-top-md\@md {
    margin-top: var(--space-md);
  }
  .margin-right-md\@md {
    margin-right: var(--space-md);
  }
  .margin-bottom-md\@md {
    margin-bottom: var(--space-md);
  }
  .margin-left-md\@md {
    margin-left: var(--space-md);
  }
  .margin-y-md\@md {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }
  .margin-x-md\@md {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }
  .margin-lg\@md {
    margin: var(--space-lg);
  }
  .margin-top-lg\@md {
    margin-top: var(--space-lg);
  }
  .margin-right-lg\@md {
    margin-right: var(--space-lg);
  }
  .margin-bottom-lg\@md {
    margin-bottom: var(--space-lg);
  }
  .margin-left-lg\@md {
    margin-left: var(--space-lg);
  }
  .margin-y-lg\@md {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }
  .margin-x-lg\@md {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }
  .margin-xl\@md {
    margin: var(--space-xl);
  }
  .margin-top-xl\@md {
    margin-top: var(--space-xl);
  }
  .margin-right-xl\@md {
    margin-right: var(--space-xl);
  }
  .margin-bottom-xl\@md {
    margin-bottom: var(--space-xl);
  }
  .margin-left-xl\@md {
    margin-left: var(--space-xl);
  }
  .margin-y-xl\@md {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  .margin-x-xl\@md {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }
  .margin-2xl\@md {
    margin: var(--space-2xl);
  }
  .margin-top-2xl\@md {
    margin-top: var(--space-2xl);
  }
  .margin-right-2xl\@md {
    margin-right: var(--space-2xl);
  }
  .margin-bottom-2xl\@md {
    margin-bottom: var(--space-2xl);
  }
  .margin-left-2xl\@md {
    margin-left: var(--space-2xl);
  }
  .margin-y-2xl\@md {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
  }
  .margin-x-2xl\@md {
    margin-right: var(--space-2xl);
    margin-left: var(--space-2xl);
  }
  .margin-3xl\@md {
    margin: var(--space-3xl);
  }
  .margin-top-3xl\@md {
    margin-top: var(--space-3xl);
  }
  .margin-right-3xl\@md {
    margin-right: var(--space-3xl);
  }
  .margin-bottom-3xl\@md {
    margin-bottom: var(--space-3xl);
  }
  .margin-left-3xl\@md {
    margin-left: var(--space-3xl);
  }
  .margin-y-3xl\@md {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-3xl);
  }
  .margin-x-3xl\@md {
    margin-right: var(--space-3xl);
    margin-left: var(--space-3xl);
  }
  .margin-4xl\@md {
    margin: var(--space-4xl);
  }
  .margin-top-4xl\@md {
    margin-top: var(--space-4xl);
  }
  .margin-right-4xl\@md {
    margin-right: var(--space-4xl);
  }
  .margin-bottom-4xl\@md {
    margin-bottom: var(--space-4xl);
  }
  .margin-left-4xl\@md {
    margin-left: var(--space-4xl);
  }
  .margin-y-4xl\@md {
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-4xl);
  }
  .margin-x-4xl\@md {
    margin-right: var(--space-4xl);
    margin-left: var(--space-4xl);
  }
  .margin-auto\@md {
    margin: auto;
  }
  .margin-0\@md {
    margin: 0;
  }
  .margin-y-auto\@md {
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-y-0\@md {
    margin-top: 0;
    margin-bottom: 0;
  }
  .margin-x-auto\@md {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-x-0\@md {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-top-auto\@md {
    margin-top: auto;
  }
  .margin-top-0\@md {
    margin-top: 0;
  }
  .margin-right-auto\@md {
    margin-right: auto;
  }
  .margin-right-0\@md {
    margin-right: 0;
  }
  .margin-bottom-auto\@md {
    margin-bottom: auto;
  }
  .margin-bottom-0\@md {
    margin-bottom: 0;
  }
  .margin-left-auto\@md {
    margin-left: auto;
  }
  .margin-left-0\@md {
    margin-left: 0;
  }
  /* padding */
  .padding-4xs\@md {
    padding: var(--space-4xs);
  }
  .padding-top-4xs\@md {
    padding-top: var(--space-4xs);
  }
  .padding-right-4xs\@md {
    padding-right: var(--space-4xs);
  }
  .padding-bottom-4xs\@md {
    padding-bottom: var(--space-4xs);
  }
  .padding-left-4xs\@md {
    padding-left: var(--space-4xs);
  }
  .padding-y-4xs\@md {
    padding-top: var(--space-4xs);
    padding-bottom: var(--space-4xs);
  }
  .padding-x-4xs\@md {
    padding-right: var(--space-4xs);
    padding-left: var(--space-4xs);
  }
  .padding-3xs\@md {
    padding: var(--space-3xs);
  }
  .padding-top-3xs\@md {
    padding-top: var(--space-3xs);
  }
  .padding-right-3xs\@md {
    padding-right: var(--space-3xs);
  }
  .padding-bottom-3xs\@md {
    padding-bottom: var(--space-3xs);
  }
  .padding-left-3xs\@md {
    padding-left: var(--space-3xs);
  }
  .padding-y-3xs\@md {
    padding-top: var(--space-3xs);
    padding-bottom: var(--space-3xs);
  }
  .padding-x-3xs\@md {
    padding-right: var(--space-3xs);
    padding-left: var(--space-3xs);
  }
  .padding-2xs\@md {
    padding: var(--space-2xs);
  }
  .padding-top-2xs\@md {
    padding-top: var(--space-2xs);
  }
  .padding-right-2xs\@md {
    padding-right: var(--space-2xs);
  }
  .padding-bottom-2xs\@md {
    padding-bottom: var(--space-2xs);
  }
  .padding-left-2xs\@md {
    padding-left: var(--space-2xs);
  }
  .padding-y-2xs\@md {
    padding-top: var(--space-2xs);
    padding-bottom: var(--space-2xs);
  }
  .padding-x-2xs\@md {
    padding-right: var(--space-2xs);
    padding-left: var(--space-2xs);
  }
  .padding-xs\@md {
    padding: var(--space-xs);
  }
  .padding-top-xs\@md {
    padding-top: var(--space-xs);
  }
  .padding-right-xs\@md {
    padding-right: var(--space-xs);
  }
  .padding-bottom-xs\@md {
    padding-bottom: var(--space-xs);
  }
  .padding-left-xs\@md {
    padding-left: var(--space-xs);
  }
  .padding-y-xs\@md {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }
  .padding-x-xs\@md {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }
  .padding-sm\@md {
    padding: var(--space-sm);
  }
  .padding-top-sm\@md {
    padding-top: var(--space-sm);
  }
  .padding-right-sm\@md {
    padding-right: var(--space-sm);
  }
  .padding-bottom-sm\@md {
    padding-bottom: var(--space-sm);
  }
  .padding-left-sm\@md {
    padding-left: var(--space-sm);
  }
  .padding-y-sm\@md {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
  .padding-x-sm\@md {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }
  .padding-md\@md {
    padding: var(--space-md);
  }
  .padding-top-md\@md {
    padding-top: var(--space-md);
  }
  .padding-right-md\@md {
    padding-right: var(--space-md);
  }
  .padding-bottom-md\@md {
    padding-bottom: var(--space-md);
  }
  .padding-left-md\@md {
    padding-left: var(--space-md);
  }
  .padding-y-md\@md {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }
  .padding-x-md\@md {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }
  .padding-lg\@md {
    padding: var(--space-lg);
  }
  .padding-top-lg\@md {
    padding-top: var(--space-lg);
  }
  .padding-right-lg\@md {
    padding-right: var(--space-lg);
  }
  .padding-bottom-lg\@md {
    padding-bottom: var(--space-lg);
  }
  .padding-left-lg\@md {
    padding-left: var(--space-lg);
  }
  .padding-y-lg\@md {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
  .padding-x-lg\@md {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }
  .padding-xl\@md {
    padding: var(--space-xl);
  }
  .padding-top-xl\@md {
    padding-top: var(--space-xl);
  }
  .padding-right-xl\@md {
    padding-right: var(--space-xl);
  }
  .padding-bottom-xl\@md {
    padding-bottom: var(--space-xl);
  }
  .padding-left-xl\@md {
    padding-left: var(--space-xl);
  }
  .padding-y-xl\@md {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  .padding-x-xl\@md {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }
  .padding-2xl\@md {
    padding: var(--space-2xl);
  }
  .padding-top-2xl\@md {
    padding-top: var(--space-2xl);
  }
  .padding-right-2xl\@md {
    padding-right: var(--space-2xl);
  }
  .padding-bottom-2xl\@md {
    padding-bottom: var(--space-2xl);
  }
  .padding-left-2xl\@md {
    padding-left: var(--space-2xl);
  }
  .padding-y-2xl\@md {
    padding-top: var(--space-2xl);
    padding-bottom: var(--space-2xl);
  }
  .padding-x-2xl\@md {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
  .padding-3xl\@md {
    padding: var(--space-3xl);
  }
  .padding-top-3xl\@md {
    padding-top: var(--space-3xl);
  }
  .padding-right-3xl\@md {
    padding-right: var(--space-3xl);
  }
  .padding-bottom-3xl\@md {
    padding-bottom: var(--space-3xl);
  }
  .padding-left-3xl\@md {
    padding-left: var(--space-3xl);
  }
  .padding-y-3xl\@md {
    padding-top: var(--space-3xl);
    padding-bottom: var(--space-3xl);
  }
  .padding-x-3xl\@md {
    padding-right: var(--space-3xl);
    padding-left: var(--space-3xl);
  }
  .padding-4xl\@md {
    padding: var(--space-4xl);
  }
  .padding-top-4xl\@md {
    padding-top: var(--space-4xl);
  }
  .padding-right-4xl\@md {
    padding-right: var(--space-4xl);
  }
  .padding-bottom-4xl\@md {
    padding-bottom: var(--space-4xl);
  }
  .padding-left-4xl\@md {
    padding-left: var(--space-4xl);
  }
  .padding-y-4xl\@md {
    padding-top: var(--space-4xl);
    padding-bottom: var(--space-4xl);
  }
  .padding-x-4xl\@md {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
  .padding-0\@md {
    padding: 0;
  }
  .padding-y-0\@md {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-x-0\@md {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-top-0\@md {
    padding-top: 0;
  }
  .padding-right-0\@md {
    padding-right: 0;
  }
  .padding-bottom-0\@md {
    padding-bottom: 0;
  }
  .padding-left-0\@md {
    padding-left: 0;
  }
  /* text-align */
  .text-center\@md {
    text-align: center;
  }
  .text-left\@md {
    text-align: left;
  }
  .text-right\@md {
    text-align: right;
  }
  .text-justify\@md {
    text-align: justify;
  }
  /* font-size */
  .text-xs\@md {
    font-size: var(--text-xs);
  }
  .text-sm\@md {
    font-size: var(--text-sm);
  }
  .text-base\@md {
    font-size: var(--text-base);
  }
  .text-md\@md {
    font-size: var(--text-md);
  }
  .text-lg\@md {
    font-size: var(--text-lg);
  }
  .text-xl\@md {
    font-size: var(--text-xl);
  }
  .text-2xl\@md {
    font-size: var(--text-2xl);
  }
  .text-3xl\@md {
    font-size: var(--text-3xl);
  }
  .text-4xl\@md {
    font-size: var(--text-4xl);
  }
  /* column-count */
  .column-count-1\@md {
    column-count: 1;
  }
  .column-count-2\@md {
    column-count: 2;
  }
  .column-count-3\@md {
    column-count: 3;
  }
  .column-count-4\@md {
    column-count: 4;
  }
  /* width */
  .width-4xs\@md {
    width: 0.25rem;
  }
  .width-3xs\@md {
    width: 0.5rem;
  }
  .width-2xs\@md {
    width: 0.75rem;
  }
  .width-xs\@md {
    width: 1rem;
  }
  .width-sm\@md {
    width: 1.5rem;
  }
  .width-md\@md {
    width: 2rem;
  }
  .width-lg\@md {
    width: 3rem;
  }
  .width-xl\@md {
    width: 4rem;
  }
  .width-2xl\@md {
    width: 6rem;
  }
  .width-3xl\@md {
    width: 8rem;
  }
  .width-4xl\@md {
    width: 16rem;
  }
  .width-0\@md {
    width: 0;
  }
  .width-10\%\@md {
    width: 10%;
  }
  .width-20\%\@md {
    width: 20%;
  }
  .width-25\%\@md {
    width: 25%;
  }
  .width-30\%\@md {
    width: 30%;
  }
  .width-33\%\@md {
    width: 33%;
  }
  .width-40\%\@md {
    width: 40%;
  }
  .width-50\%\@md {
    width: 50%;
  }
  .width-60\%\@md {
    width: 60%;
  }
  .width-70\%\@md {
    width: 70%;
  }
  .width-75\%\@md {
    width: 75%;
  }
  .width-80\%\@md {
    width: 80%;
  }
  .width-90\%\@md {
    width: 90%;
  }
  .width-100\%\@md {
    width: 100%;
  }
  .width-100\%\@md {
    width: 100%;
  }
  .width-100vw\@md {
    width: 100vw;
  }
  .width-auto\@md {
    width: auto;
  }
  .width-inherit\@md {
    width: inherit;
  }
  .width-0\@md {
    width: 0;
  }
  /* height */
  .height-4xs\@md {
    height: 0.25rem;
  }
  .height-3xs\@md {
    height: 0.5rem;
  }
  .height-2xs\@md {
    height: 0.75rem;
  }
  .height-xs\@md {
    height: 1rem;
  }
  .height-sm\@md {
    height: 1.5rem;
  }
  .height-md\@md {
    height: 2rem;
  }
  .height-lg\@md {
    height: 3rem;
  }
  .height-xl\@md {
    height: 4rem;
  }
  .height-2xl\@md {
    height: 6rem;
  }
  .height-3xl\@md {
    height: 8rem;
  }
  .height-4xl\@md {
    height: 16rem;
  }
  .height-0\@md {
    height: 0;
  }
  .height-10\%\@md {
    height: 10%;
  }
  .height-20\%\@md {
    height: 20%;
  }
  .height-25\%\@md {
    height: 25%;
  }
  .height-30\%\@md {
    height: 30%;
  }
  .height-33\%\@md {
    height: 33%;
  }
  .height-40\%\@md {
    height: 40%;
  }
  .height-50\%\@md {
    height: 50%;
  }
  .height-60\%\@md {
    height: 60%;
  }
  .height-70\%\@md {
    height: 70%;
  }
  .height-75\%\@md {
    height: 75%;
  }
  .height-80\%\@md {
    height: 80%;
  }
  .height-90\%\@md {
    height: 90%;
  }
  .height-100\%\@md {
    height: 100%;
  }
  .height-100\%\@md {
    height: 100%;
  }
  .height-100vh\@md {
    height: 100vh;
  }
  .height-auto\@md {
    height: auto;
  }
  .height-inherit\@md {
    height: inherit;
  }
  .height-0\@md {
    height: 0;
  }
  /* max-width */
  .max-width-3xs\@md {
    max-width: var(--max-width-3xs);
  }
  .max-width-2xs\@md {
    max-width: var(--max-width-2xs);
  }
  .max-width-xs\@md {
    max-width: var(--max-width-xs);
  }
  .max-width-sm\@md {
    max-width: var(--max-width-sm);
  }
  .max-width-md\@md {
    max-width: var(--max-width-md);
  }
  .max-width-lg\@md {
    max-width: var(--max-width-lg);
  }
  .max-width-xl\@md {
    max-width: var(--max-width-xl);
  }
  .max-width-100\%\@md {
    max-width: 100%;
  }
  .max-width-none\@md {
    max-width: none;
  }
  /* position */
  .position-relative\@md {
    position: relative;
  }
  .position-absolute\@md {
    position: absolute;
  }
  .position-fixed\@md {
    position: fixed;
  }
  .position-sticky\@md {
    position: sticky;
  }
  .position-static\@md {
    position: static;
  }
  .inset-0\@md {
    inset: 0;
  }
  .top-4xs\@md {
    top: var(--space-4xs);
  }
  .right-4xs\@md {
    right: var(--space-4xs);
  }
  .bottom-4xs\@md {
    bottom: var(--space-4xs);
  }
  .left-4xs\@md {
    left: var(--space-4xs);
  }
  .top-3xs\@md {
    top: var(--space-3xs);
  }
  .right-3xs\@md {
    right: var(--space-3xs);
  }
  .bottom-3xs\@md {
    bottom: var(--space-3xs);
  }
  .left-3xs\@md {
    left: var(--space-3xs);
  }
  .top-2xs\@md {
    top: var(--space-2xs);
  }
  .right-2xs\@md {
    right: var(--space-2xs);
  }
  .bottom-2xs\@md {
    bottom: var(--space-2xs);
  }
  .left-2xs\@md {
    left: var(--space-2xs);
  }
  .top-xs\@md {
    top: var(--space-xs);
  }
  .right-xs\@md {
    right: var(--space-xs);
  }
  .bottom-xs\@md {
    bottom: var(--space-xs);
  }
  .left-xs\@md {
    left: var(--space-xs);
  }
  .top-sm\@md {
    top: var(--space-sm);
  }
  .right-sm\@md {
    right: var(--space-sm);
  }
  .bottom-sm\@md {
    bottom: var(--space-sm);
  }
  .left-sm\@md {
    left: var(--space-sm);
  }
  .top-md\@md {
    top: var(--space-md);
  }
  .right-md\@md {
    right: var(--space-md);
  }
  .bottom-md\@md {
    bottom: var(--space-md);
  }
  .left-md\@md {
    left: var(--space-md);
  }
  .top-lg\@md {
    top: var(--space-lg);
  }
  .right-lg\@md {
    right: var(--space-lg);
  }
  .bottom-lg\@md {
    bottom: var(--space-lg);
  }
  .left-lg\@md {
    left: var(--space-lg);
  }
  .top-xl\@md {
    top: var(--space-xl);
  }
  .right-xl\@md {
    right: var(--space-xl);
  }
  .bottom-xl\@md {
    bottom: var(--space-xl);
  }
  .left-xl\@md {
    left: var(--space-xl);
  }
  .top-2xl\@md {
    top: var(--space-2xl);
  }
  .right-2xl\@md {
    right: var(--space-2xl);
  }
  .bottom-2xl\@md {
    bottom: var(--space-2xl);
  }
  .left-2xl\@md {
    left: var(--space-2xl);
  }
  .top-3xl\@md {
    top: var(--space-3xl);
  }
  .right-3xl\@md {
    right: var(--space-3xl);
  }
  .bottom-3xl\@md {
    bottom: var(--space-3xl);
  }
  .left-3xl\@md {
    left: var(--space-3xl);
  }
  .top-4xl\@md {
    top: var(--space-4xl);
  }
  .right-4xl\@md {
    right: var(--space-4xl);
  }
  .bottom-4xl\@md {
    bottom: var(--space-4xl);
  }
  .left-4xl\@md {
    left: var(--space-4xl);
  }
  .top-50\%\@md {
    top: 50%;
  }
  .top-auto\@md {
    top: auto;
  }
  .top-0\@md {
    top: 0;
  }
  .right-50\%\@md {
    right: 50%;
  }
  .right-auto\@md {
    right: auto;
  }
  .right-0\@md {
    right: 0;
  }
  .bottom-50\%\@md {
    bottom: 50%;
  }
  .bottom-auto\@md {
    bottom: auto;
  }
  .bottom-0\@md {
    bottom: 0;
  }
  .left-50\%\@md {
    left: 50%;
  }
  .left-auto\@md {
    left: auto;
  }
  .left-0\@md {
    left: 0;
  }
  /* overflow */
  .overflow-visible\@md {
    overflow: visible;
  }
  .overflow-hidden\@md {
    overflow: hidden;
  }
  .overflow-auto\@md {
    overflow: auto;
  }
  .momentum-scrolling\@md {
    -webkit-overflow-scrolling: touch;
  }
  /* visibility */
  .visible\@md {
    visibility: visible;
  }
  .invisible\@md {
    visibility: hidden;
  }
}
@media not all and (min-width: 64rem) {
  .display\@md {
    display: none !important;
  }
}
@media (min-width: 80rem) {
  /* grid */
  .grid-col-1\@lg {
    --grid-columns: 1;
  }
  .col-1\@lg {
    --column-span: 1;
  }
  .grid-col-2\@lg {
    --grid-columns: 2;
  }
  .col-2\@lg {
    --column-span: 2;
  }
  .grid-col-3\@lg {
    --grid-columns: 3;
  }
  .col-3\@lg {
    --column-span: 3;
  }
  .grid-col-4\@lg {
    --grid-columns: 4;
  }
  .col-4\@lg {
    --column-span: 4;
  }
  .grid-col-5\@lg {
    --grid-columns: 5;
  }
  .col-5\@lg {
    --column-span: 5;
  }
  .grid-col-6\@lg {
    --grid-columns: 6;
  }
  .col-6\@lg {
    --column-span: 6;
  }
  .grid-col-7\@lg {
    --grid-columns: 7;
  }
  .col-7\@lg {
    --column-span: 7;
  }
  .grid-col-8\@lg {
    --grid-columns: 8;
  }
  .col-8\@lg {
    --column-span: 8;
  }
  .grid-col-9\@lg {
    --grid-columns: 9;
  }
  .col-9\@lg {
    --column-span: 9;
  }
  .grid-col-10\@lg {
    --grid-columns: 10;
  }
  .col-10\@lg {
    --column-span: 10;
  }
  .grid-col-11\@lg {
    --grid-columns: 11;
  }
  .col-11\@lg {
    --column-span: 11;
  }
  .grid-col-12\@lg {
    --grid-columns: 12;
  }
  .col-12\@lg {
    --column-span: 12;
  }
  .col-1\@lg, .col-2\@lg, .col-3\@lg, .col-4\@lg, .col-5\@lg, .col-6\@lg, .col-7\@lg, .col-8\@lg, .col-9\@lg, .col-10\@lg, .col-11\@lg, .col-12\@lg {
    width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
    flex: initial;
  }
  .col\@lg {
    width: auto;
    flex: 1 1 0;
  }
  .col-content\@lg {
    width: auto;
    flex: 0 1 auto;
  }
  .offset-1\@lg {
    --column-offset: 1;
  }
  .offset-2\@lg {
    --column-offset: 2;
  }
  .offset-3\@lg {
    --column-offset: 3;
  }
  .offset-4\@lg {
    --column-offset: 4;
  }
  .offset-5\@lg {
    --column-offset: 5;
  }
  .offset-6\@lg {
    --column-offset: 6;
  }
  .offset-7\@lg {
    --column-offset: 7;
  }
  .offset-8\@lg {
    --column-offset: 8;
  }
  .offset-9\@lg {
    --column-offset: 9;
  }
  .offset-10\@lg {
    --column-offset: 10;
  }
  .offset-11\@lg {
    --column-offset: 11;
  }
  .offset-1\@lg, .offset-2\@lg, .offset-3\@lg, .offset-4\@lg, .offset-5\@lg, .offset-6\@lg, .offset-7\@lg, .offset-8\@lg, .offset-9\@lg, .offset-10\@lg, .offset-11\@lg {
    margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
  }
  .offset-0\@lg {
    margin-left: 0;
  }
  /* gap */
  .gap-4xs\@lg {
    --row-gap: var(--space-4xs);
    --column-gap: var(--space-4xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xs\@lg {
    --column-gap: var(--space-4xs);
    column-gap: var(--column-gap);
  }
  .gap-y-4xs\@lg {
    --row-gap: var(--space-4xs);
    row-gap: var(--row-gap);
  }
  .gap-3xs\@lg {
    --row-gap: var(--space-3xs);
    --column-gap: var(--space-3xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xs\@lg {
    --column-gap: var(--space-3xs);
    column-gap: var(--column-gap);
  }
  .gap-y-3xs\@lg {
    --row-gap: var(--space-3xs);
    row-gap: var(--row-gap);
  }
  .gap-2xs\@lg {
    --row-gap: var(--space-2xs);
    --column-gap: var(--space-2xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xs\@lg {
    --column-gap: var(--space-2xs);
    column-gap: var(--column-gap);
  }
  .gap-y-2xs\@lg {
    --row-gap: var(--space-2xs);
    row-gap: var(--row-gap);
  }
  .gap-xs\@lg {
    --row-gap: var(--space-xs);
    --column-gap: var(--space-xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xs\@lg {
    --column-gap: var(--space-xs);
    column-gap: var(--column-gap);
  }
  .gap-y-xs\@lg {
    --row-gap: var(--space-xs);
    row-gap: var(--row-gap);
  }
  .gap-sm\@lg {
    --row-gap: var(--space-sm);
    --column-gap: var(--space-sm);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-sm\@lg {
    --column-gap: var(--space-sm);
    column-gap: var(--column-gap);
  }
  .gap-y-sm\@lg {
    --row-gap: var(--space-sm);
    row-gap: var(--row-gap);
  }
  .gap-md\@lg {
    --row-gap: var(--space-md);
    --column-gap: var(--space-md);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-md\@lg {
    --column-gap: var(--space-md);
    column-gap: var(--column-gap);
  }
  .gap-y-md\@lg {
    --row-gap: var(--space-md);
    row-gap: var(--row-gap);
  }
  .gap-lg\@lg {
    --row-gap: var(--space-lg);
    --column-gap: var(--space-lg);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-lg\@lg {
    --column-gap: var(--space-lg);
    column-gap: var(--column-gap);
  }
  .gap-y-lg\@lg {
    --row-gap: var(--space-lg);
    row-gap: var(--row-gap);
  }
  .gap-xl\@lg {
    --row-gap: var(--space-xl);
    --column-gap: var(--space-xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xl\@lg {
    --column-gap: var(--space-xl);
    column-gap: var(--column-gap);
  }
  .gap-y-xl\@lg {
    --row-gap: var(--space-xl);
    row-gap: var(--row-gap);
  }
  .gap-2xl\@lg {
    --row-gap: var(--space-2xl);
    --column-gap: var(--space-2xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xl\@lg {
    --column-gap: var(--space-2xl);
    column-gap: var(--column-gap);
  }
  .gap-y-2xl\@lg {
    --row-gap: var(--space-2xl);
    row-gap: var(--row-gap);
  }
  .gap-3xl\@lg {
    --row-gap: var(--space-3xl);
    --column-gap: var(--space-3xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xl\@lg {
    --column-gap: var(--space-3xl);
    column-gap: var(--column-gap);
  }
  .gap-y-3xl\@lg {
    --row-gap: var(--space-3xl);
    row-gap: var(--row-gap);
  }
  .gap-4xl\@lg {
    --row-gap: var(--space-4xl);
    --column-gap: var(--space-4xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xl\@lg {
    --column-gap: var(--space-4xl);
    column-gap: var(--column-gap);
  }
  .gap-y-4xl\@lg {
    --row-gap: var(--space-4xl);
    row-gap: var(--row-gap);
  }
  .gap-0\@lg {
    --row-gap: 0px;
    --column-gap: 0px;
    gap: 0px;
  }
  .gap-x-0\@lg {
    --column-gap: 0px;
    column-gap: 0px;
  }
  .gap-y-0\@lg {
    --row-gap: 0px;
    row-gap: 0px;
  }
  /* flexbox */
  .flex\@lg {
    display: flex;
  }
  .inline-flex\@lg {
    display: inline-flex;
  }
  .flex-wrap\@lg {
    flex-wrap: wrap;
  }
  .flex-nowrap\@lg {
    flex-wrap: nowrap;
  }
  .flex-column\@lg {
    flex-direction: column;
  }
  .flex-column-reverse\@lg {
    flex-direction: column-reverse;
  }
  .flex-row\@lg {
    flex-direction: row;
  }
  .flex-row-reverse\@lg {
    flex-direction: row-reverse;
  }
  .flex-center\@lg {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@lg {
    flex-grow: 1;
  }
  .flex-grow-0\@lg {
    flex-grow: 0;
  }
  .flex-shrink\@lg {
    flex-shrink: 1;
  }
  .flex-shrink-0\@lg {
    flex-shrink: 0;
  }
  .flex-basis-0\@lg {
    flex-basis: 0;
  }
  /* justify-content */
  .justify-start\@lg {
    justify-content: start;
  }
  .justify-end\@lg {
    justify-content: end;
  }
  .justify-center\@lg {
    justify-content: center;
  }
  .justify-between\@lg {
    justify-content: space-between;
  }
  /* align-items */
  .items-center\@lg {
    align-items: center;
  }
  .items-start\@lg {
    align-items: start;
  }
  .items-end\@lg {
    align-items: end;
  }
  .items-baseline\@lg {
    align-items: baseline;
  }
  .items-stretch\@lg {
    align-items: stretch;
  }
  /* align-content */
  .content-start\@lg {
    align-content: start;
  }
  .content-end\@lg {
    align-content: end;
  }
  .content-center\@lg {
    align-content: center;
  }
  .content-between\@lg {
    align-content: space-between;
  }
  /* order */
  .order-1\@lg {
    order: 1;
  }
  .order-2\@lg {
    order: 2;
  }
  .order-3\@lg {
    order: 3;
  }
  /* display */
  .block\@lg {
    display: block;
  }
  .inline-block\@lg {
    display: inline-block;
  }
  .inline\@lg {
    display: inline;
  }
  .contents\@lg {
    display: contents;
  }
  .css-grid\@lg {
    display: grid;
  }
  .css-inline-grid\@lg {
    display: inline-grid;
  }
  .hide\@lg {
    display: none;
  }
  /* margin */
  .margin-4xs\@lg {
    margin: var(--space-4xs);
  }
  .margin-top-4xs\@lg {
    margin-top: var(--space-4xs);
  }
  .margin-right-4xs\@lg {
    margin-right: var(--space-4xs);
  }
  .margin-bottom-4xs\@lg {
    margin-bottom: var(--space-4xs);
  }
  .margin-left-4xs\@lg {
    margin-left: var(--space-4xs);
  }
  .margin-y-4xs\@lg {
    margin-top: var(--space-4xs);
    margin-bottom: var(--space-4xs);
  }
  .margin-x-4xs\@lg {
    margin-right: var(--space-4xs);
    margin-left: var(--space-4xs);
  }
  .margin-3xs\@lg {
    margin: var(--space-3xs);
  }
  .margin-top-3xs\@lg {
    margin-top: var(--space-3xs);
  }
  .margin-right-3xs\@lg {
    margin-right: var(--space-3xs);
  }
  .margin-bottom-3xs\@lg {
    margin-bottom: var(--space-3xs);
  }
  .margin-left-3xs\@lg {
    margin-left: var(--space-3xs);
  }
  .margin-y-3xs\@lg {
    margin-top: var(--space-3xs);
    margin-bottom: var(--space-3xs);
  }
  .margin-x-3xs\@lg {
    margin-right: var(--space-3xs);
    margin-left: var(--space-3xs);
  }
  .margin-2xs\@lg {
    margin: var(--space-2xs);
  }
  .margin-top-2xs\@lg {
    margin-top: var(--space-2xs);
  }
  .margin-right-2xs\@lg {
    margin-right: var(--space-2xs);
  }
  .margin-bottom-2xs\@lg {
    margin-bottom: var(--space-2xs);
  }
  .margin-left-2xs\@lg {
    margin-left: var(--space-2xs);
  }
  .margin-y-2xs\@lg {
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-2xs);
  }
  .margin-x-2xs\@lg {
    margin-right: var(--space-2xs);
    margin-left: var(--space-2xs);
  }
  .margin-xs\@lg {
    margin: var(--space-xs);
  }
  .margin-top-xs\@lg {
    margin-top: var(--space-xs);
  }
  .margin-right-xs\@lg {
    margin-right: var(--space-xs);
  }
  .margin-bottom-xs\@lg {
    margin-bottom: var(--space-xs);
  }
  .margin-left-xs\@lg {
    margin-left: var(--space-xs);
  }
  .margin-y-xs\@lg {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
  .margin-x-xs\@lg {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }
  .margin-sm\@lg {
    margin: var(--space-sm);
  }
  .margin-top-sm\@lg {
    margin-top: var(--space-sm);
  }
  .margin-right-sm\@lg {
    margin-right: var(--space-sm);
  }
  .margin-bottom-sm\@lg {
    margin-bottom: var(--space-sm);
  }
  .margin-left-sm\@lg {
    margin-left: var(--space-sm);
  }
  .margin-y-sm\@lg {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }
  .margin-x-sm\@lg {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }
  .margin-md\@lg {
    margin: var(--space-md);
  }
  .margin-top-md\@lg {
    margin-top: var(--space-md);
  }
  .margin-right-md\@lg {
    margin-right: var(--space-md);
  }
  .margin-bottom-md\@lg {
    margin-bottom: var(--space-md);
  }
  .margin-left-md\@lg {
    margin-left: var(--space-md);
  }
  .margin-y-md\@lg {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }
  .margin-x-md\@lg {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }
  .margin-lg\@lg {
    margin: var(--space-lg);
  }
  .margin-top-lg\@lg {
    margin-top: var(--space-lg);
  }
  .margin-right-lg\@lg {
    margin-right: var(--space-lg);
  }
  .margin-bottom-lg\@lg {
    margin-bottom: var(--space-lg);
  }
  .margin-left-lg\@lg {
    margin-left: var(--space-lg);
  }
  .margin-y-lg\@lg {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }
  .margin-x-lg\@lg {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }
  .margin-xl\@lg {
    margin: var(--space-xl);
  }
  .margin-top-xl\@lg {
    margin-top: var(--space-xl);
  }
  .margin-right-xl\@lg {
    margin-right: var(--space-xl);
  }
  .margin-bottom-xl\@lg {
    margin-bottom: var(--space-xl);
  }
  .margin-left-xl\@lg {
    margin-left: var(--space-xl);
  }
  .margin-y-xl\@lg {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  .margin-x-xl\@lg {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }
  .margin-2xl\@lg {
    margin: var(--space-2xl);
  }
  .margin-top-2xl\@lg {
    margin-top: var(--space-2xl);
  }
  .margin-right-2xl\@lg {
    margin-right: var(--space-2xl);
  }
  .margin-bottom-2xl\@lg {
    margin-bottom: var(--space-2xl);
  }
  .margin-left-2xl\@lg {
    margin-left: var(--space-2xl);
  }
  .margin-y-2xl\@lg {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
  }
  .margin-x-2xl\@lg {
    margin-right: var(--space-2xl);
    margin-left: var(--space-2xl);
  }
  .margin-3xl\@lg {
    margin: var(--space-3xl);
  }
  .margin-top-3xl\@lg {
    margin-top: var(--space-3xl);
  }
  .margin-right-3xl\@lg {
    margin-right: var(--space-3xl);
  }
  .margin-bottom-3xl\@lg {
    margin-bottom: var(--space-3xl);
  }
  .margin-left-3xl\@lg {
    margin-left: var(--space-3xl);
  }
  .margin-y-3xl\@lg {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-3xl);
  }
  .margin-x-3xl\@lg {
    margin-right: var(--space-3xl);
    margin-left: var(--space-3xl);
  }
  .margin-4xl\@lg {
    margin: var(--space-4xl);
  }
  .margin-top-4xl\@lg {
    margin-top: var(--space-4xl);
  }
  .margin-right-4xl\@lg {
    margin-right: var(--space-4xl);
  }
  .margin-bottom-4xl\@lg {
    margin-bottom: var(--space-4xl);
  }
  .margin-left-4xl\@lg {
    margin-left: var(--space-4xl);
  }
  .margin-y-4xl\@lg {
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-4xl);
  }
  .margin-x-4xl\@lg {
    margin-right: var(--space-4xl);
    margin-left: var(--space-4xl);
  }
  .margin-auto\@lg {
    margin: auto;
  }
  .margin-0\@lg {
    margin: 0;
  }
  .margin-y-auto\@lg {
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-y-0\@lg {
    margin-top: 0;
    margin-bottom: 0;
  }
  .margin-x-auto\@lg {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-x-0\@lg {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-top-auto\@lg {
    margin-top: auto;
  }
  .margin-top-0\@lg {
    margin-top: 0;
  }
  .margin-right-auto\@lg {
    margin-right: auto;
  }
  .margin-right-0\@lg {
    margin-right: 0;
  }
  .margin-bottom-auto\@lg {
    margin-bottom: auto;
  }
  .margin-bottom-0\@lg {
    margin-bottom: 0;
  }
  .margin-left-auto\@lg {
    margin-left: auto;
  }
  .margin-left-0\@lg {
    margin-left: 0;
  }
  /* padding */
  .padding-4xs\@lg {
    padding: var(--space-4xs);
  }
  .padding-top-4xs\@lg {
    padding-top: var(--space-4xs);
  }
  .padding-right-4xs\@lg {
    padding-right: var(--space-4xs);
  }
  .padding-bottom-4xs\@lg {
    padding-bottom: var(--space-4xs);
  }
  .padding-left-4xs\@lg {
    padding-left: var(--space-4xs);
  }
  .padding-y-4xs\@lg {
    padding-top: var(--space-4xs);
    padding-bottom: var(--space-4xs);
  }
  .padding-x-4xs\@lg {
    padding-right: var(--space-4xs);
    padding-left: var(--space-4xs);
  }
  .padding-3xs\@lg {
    padding: var(--space-3xs);
  }
  .padding-top-3xs\@lg {
    padding-top: var(--space-3xs);
  }
  .padding-right-3xs\@lg {
    padding-right: var(--space-3xs);
  }
  .padding-bottom-3xs\@lg {
    padding-bottom: var(--space-3xs);
  }
  .padding-left-3xs\@lg {
    padding-left: var(--space-3xs);
  }
  .padding-y-3xs\@lg {
    padding-top: var(--space-3xs);
    padding-bottom: var(--space-3xs);
  }
  .padding-x-3xs\@lg {
    padding-right: var(--space-3xs);
    padding-left: var(--space-3xs);
  }
  .padding-2xs\@lg {
    padding: var(--space-2xs);
  }
  .padding-top-2xs\@lg {
    padding-top: var(--space-2xs);
  }
  .padding-right-2xs\@lg {
    padding-right: var(--space-2xs);
  }
  .padding-bottom-2xs\@lg {
    padding-bottom: var(--space-2xs);
  }
  .padding-left-2xs\@lg {
    padding-left: var(--space-2xs);
  }
  .padding-y-2xs\@lg {
    padding-top: var(--space-2xs);
    padding-bottom: var(--space-2xs);
  }
  .padding-x-2xs\@lg {
    padding-right: var(--space-2xs);
    padding-left: var(--space-2xs);
  }
  .padding-xs\@lg {
    padding: var(--space-xs);
  }
  .padding-top-xs\@lg {
    padding-top: var(--space-xs);
  }
  .padding-right-xs\@lg {
    padding-right: var(--space-xs);
  }
  .padding-bottom-xs\@lg {
    padding-bottom: var(--space-xs);
  }
  .padding-left-xs\@lg {
    padding-left: var(--space-xs);
  }
  .padding-y-xs\@lg {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }
  .padding-x-xs\@lg {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }
  .padding-sm\@lg {
    padding: var(--space-sm);
  }
  .padding-top-sm\@lg {
    padding-top: var(--space-sm);
  }
  .padding-right-sm\@lg {
    padding-right: var(--space-sm);
  }
  .padding-bottom-sm\@lg {
    padding-bottom: var(--space-sm);
  }
  .padding-left-sm\@lg {
    padding-left: var(--space-sm);
  }
  .padding-y-sm\@lg {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
  .padding-x-sm\@lg {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }
  .padding-md\@lg {
    padding: var(--space-md);
  }
  .padding-top-md\@lg {
    padding-top: var(--space-md);
  }
  .padding-right-md\@lg {
    padding-right: var(--space-md);
  }
  .padding-bottom-md\@lg {
    padding-bottom: var(--space-md);
  }
  .padding-left-md\@lg {
    padding-left: var(--space-md);
  }
  .padding-y-md\@lg {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }
  .padding-x-md\@lg {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }
  .padding-lg\@lg {
    padding: var(--space-lg);
  }
  .padding-top-lg\@lg {
    padding-top: var(--space-lg);
  }
  .padding-right-lg\@lg {
    padding-right: var(--space-lg);
  }
  .padding-bottom-lg\@lg {
    padding-bottom: var(--space-lg);
  }
  .padding-left-lg\@lg {
    padding-left: var(--space-lg);
  }
  .padding-y-lg\@lg {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
  .padding-x-lg\@lg {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }
  .padding-xl\@lg {
    padding: var(--space-xl);
  }
  .padding-top-xl\@lg {
    padding-top: var(--space-xl);
  }
  .padding-right-xl\@lg {
    padding-right: var(--space-xl);
  }
  .padding-bottom-xl\@lg {
    padding-bottom: var(--space-xl);
  }
  .padding-left-xl\@lg {
    padding-left: var(--space-xl);
  }
  .padding-y-xl\@lg {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  .padding-x-xl\@lg {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }
  .padding-2xl\@lg {
    padding: var(--space-2xl);
  }
  .padding-top-2xl\@lg {
    padding-top: var(--space-2xl);
  }
  .padding-right-2xl\@lg {
    padding-right: var(--space-2xl);
  }
  .padding-bottom-2xl\@lg {
    padding-bottom: var(--space-2xl);
  }
  .padding-left-2xl\@lg {
    padding-left: var(--space-2xl);
  }
  .padding-y-2xl\@lg {
    padding-top: var(--space-2xl);
    padding-bottom: var(--space-2xl);
  }
  .padding-x-2xl\@lg {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
  .padding-3xl\@lg {
    padding: var(--space-3xl);
  }
  .padding-top-3xl\@lg {
    padding-top: var(--space-3xl);
  }
  .padding-right-3xl\@lg {
    padding-right: var(--space-3xl);
  }
  .padding-bottom-3xl\@lg {
    padding-bottom: var(--space-3xl);
  }
  .padding-left-3xl\@lg {
    padding-left: var(--space-3xl);
  }
  .padding-y-3xl\@lg {
    padding-top: var(--space-3xl);
    padding-bottom: var(--space-3xl);
  }
  .padding-x-3xl\@lg {
    padding-right: var(--space-3xl);
    padding-left: var(--space-3xl);
  }
  .padding-4xl\@lg {
    padding: var(--space-4xl);
  }
  .padding-top-4xl\@lg {
    padding-top: var(--space-4xl);
  }
  .padding-right-4xl\@lg {
    padding-right: var(--space-4xl);
  }
  .padding-bottom-4xl\@lg {
    padding-bottom: var(--space-4xl);
  }
  .padding-left-4xl\@lg {
    padding-left: var(--space-4xl);
  }
  .padding-y-4xl\@lg {
    padding-top: var(--space-4xl);
    padding-bottom: var(--space-4xl);
  }
  .padding-x-4xl\@lg {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
  .padding-0\@lg {
    padding: 0;
  }
  .padding-y-0\@lg {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-x-0\@lg {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-top-0\@lg {
    padding-top: 0;
  }
  .padding-right-0\@lg {
    padding-right: 0;
  }
  .padding-bottom-0\@lg {
    padding-bottom: 0;
  }
  .padding-left-0\@lg {
    padding-left: 0;
  }
  /* text-align */
  .text-center\@lg {
    text-align: center;
  }
  .text-left\@lg {
    text-align: left;
  }
  .text-right\@lg {
    text-align: right;
  }
  .text-justify\@lg {
    text-align: justify;
  }
  /* font-size */
  .text-xs\@lg {
    font-size: var(--text-xs);
  }
  .text-sm\@lg {
    font-size: var(--text-sm);
  }
  .text-base\@lg {
    font-size: var(--text-base);
  }
  .text-md\@lg {
    font-size: var(--text-md);
  }
  .text-lg\@lg {
    font-size: var(--text-lg);
  }
  .text-xl\@lg {
    font-size: var(--text-xl);
  }
  .text-2xl\@lg {
    font-size: var(--text-2xl);
  }
  .text-3xl\@lg {
    font-size: var(--text-3xl);
  }
  .text-4xl\@lg {
    font-size: var(--text-4xl);
  }
  /* column-count */
  .column-count-1\@lg {
    column-count: 1;
  }
  .column-count-2\@lg {
    column-count: 2;
  }
  .column-count-3\@lg {
    column-count: 3;
  }
  .column-count-4\@lg {
    column-count: 4;
  }
  /* width */
  .width-4xs\@lg {
    width: 0.25rem;
  }
  .width-3xs\@lg {
    width: 0.5rem;
  }
  .width-2xs\@lg {
    width: 0.75rem;
  }
  .width-xs\@lg {
    width: 1rem;
  }
  .width-sm\@lg {
    width: 1.5rem;
  }
  .width-md\@lg {
    width: 2rem;
  }
  .width-lg\@lg {
    width: 3rem;
  }
  .width-xl\@lg {
    width: 4rem;
  }
  .width-2xl\@lg {
    width: 6rem;
  }
  .width-3xl\@lg {
    width: 8rem;
  }
  .width-4xl\@lg {
    width: 16rem;
  }
  .width-0\@lg {
    width: 0;
  }
  .width-10\%\@lg {
    width: 10%;
  }
  .width-20\%\@lg {
    width: 20%;
  }
  .width-25\%\@lg {
    width: 25%;
  }
  .width-30\%\@lg {
    width: 30%;
  }
  .width-33\%\@lg {
    width: 33%;
  }
  .width-40\%\@lg {
    width: 40%;
  }
  .width-50\%\@lg {
    width: 50%;
  }
  .width-60\%\@lg {
    width: 60%;
  }
  .width-70\%\@lg {
    width: 70%;
  }
  .width-75\%\@lg {
    width: 75%;
  }
  .width-80\%\@lg {
    width: 80%;
  }
  .width-90\%\@lg {
    width: 90%;
  }
  .width-100\%\@lg {
    width: 100%;
  }
  .width-100\%\@lg {
    width: 100%;
  }
  .width-100vw\@lg {
    width: 100vw;
  }
  .width-auto\@lg {
    width: auto;
  }
  .width-inherit\@lg {
    width: inherit;
  }
  .width-0\@lg {
    width: 0;
  }
  /* height */
  .height-4xs\@lg {
    height: 0.25rem;
  }
  .height-3xs\@lg {
    height: 0.5rem;
  }
  .height-2xs\@lg {
    height: 0.75rem;
  }
  .height-xs\@lg {
    height: 1rem;
  }
  .height-sm\@lg {
    height: 1.5rem;
  }
  .height-md\@lg {
    height: 2rem;
  }
  .height-lg\@lg {
    height: 3rem;
  }
  .height-xl\@lg {
    height: 4rem;
  }
  .height-2xl\@lg {
    height: 6rem;
  }
  .height-3xl\@lg {
    height: 8rem;
  }
  .height-4xl\@lg {
    height: 16rem;
  }
  .height-0\@lg {
    height: 0;
  }
  .height-10\%\@lg {
    height: 10%;
  }
  .height-20\%\@lg {
    height: 20%;
  }
  .height-25\%\@lg {
    height: 25%;
  }
  .height-30\%\@lg {
    height: 30%;
  }
  .height-33\%\@lg {
    height: 33%;
  }
  .height-40\%\@lg {
    height: 40%;
  }
  .height-50\%\@lg {
    height: 50%;
  }
  .height-60\%\@lg {
    height: 60%;
  }
  .height-70\%\@lg {
    height: 70%;
  }
  .height-75\%\@lg {
    height: 75%;
  }
  .height-80\%\@lg {
    height: 80%;
  }
  .height-90\%\@lg {
    height: 90%;
  }
  .height-100\%\@lg {
    height: 100%;
  }
  .height-100\%\@lg {
    height: 100%;
  }
  .height-100vh\@lg {
    height: 100vh;
  }
  .height-auto\@lg {
    height: auto;
  }
  .height-inherit\@lg {
    height: inherit;
  }
  .height-0\@lg {
    height: 0;
  }
  /* max-width */
  .max-width-3xs\@lg {
    max-width: var(--max-width-3xs);
  }
  .max-width-2xs\@lg {
    max-width: var(--max-width-2xs);
  }
  .max-width-xs\@lg {
    max-width: var(--max-width-xs);
  }
  .max-width-sm\@lg {
    max-width: var(--max-width-sm);
  }
  .max-width-md\@lg {
    max-width: var(--max-width-md);
  }
  .max-width-lg\@lg {
    max-width: var(--max-width-lg);
  }
  .max-width-xl\@lg {
    max-width: var(--max-width-xl);
  }
  .max-width-100\%\@lg {
    max-width: 100%;
  }
  .max-width-none\@lg {
    max-width: none;
  }
  /* position */
  .position-relative\@lg {
    position: relative;
  }
  .position-absolute\@lg {
    position: absolute;
  }
  .position-fixed\@lg {
    position: fixed;
  }
  .position-sticky\@lg {
    position: sticky;
  }
  .position-static\@lg {
    position: static;
  }
  .inset-0\@lg {
    inset: 0;
  }
  .top-4xs\@lg {
    top: var(--space-4xs);
  }
  .right-4xs\@lg {
    right: var(--space-4xs);
  }
  .bottom-4xs\@lg {
    bottom: var(--space-4xs);
  }
  .left-4xs\@lg {
    left: var(--space-4xs);
  }
  .top-3xs\@lg {
    top: var(--space-3xs);
  }
  .right-3xs\@lg {
    right: var(--space-3xs);
  }
  .bottom-3xs\@lg {
    bottom: var(--space-3xs);
  }
  .left-3xs\@lg {
    left: var(--space-3xs);
  }
  .top-2xs\@lg {
    top: var(--space-2xs);
  }
  .right-2xs\@lg {
    right: var(--space-2xs);
  }
  .bottom-2xs\@lg {
    bottom: var(--space-2xs);
  }
  .left-2xs\@lg {
    left: var(--space-2xs);
  }
  .top-xs\@lg {
    top: var(--space-xs);
  }
  .right-xs\@lg {
    right: var(--space-xs);
  }
  .bottom-xs\@lg {
    bottom: var(--space-xs);
  }
  .left-xs\@lg {
    left: var(--space-xs);
  }
  .top-sm\@lg {
    top: var(--space-sm);
  }
  .right-sm\@lg {
    right: var(--space-sm);
  }
  .bottom-sm\@lg {
    bottom: var(--space-sm);
  }
  .left-sm\@lg {
    left: var(--space-sm);
  }
  .top-md\@lg {
    top: var(--space-md);
  }
  .right-md\@lg {
    right: var(--space-md);
  }
  .bottom-md\@lg {
    bottom: var(--space-md);
  }
  .left-md\@lg {
    left: var(--space-md);
  }
  .top-lg\@lg {
    top: var(--space-lg);
  }
  .right-lg\@lg {
    right: var(--space-lg);
  }
  .bottom-lg\@lg {
    bottom: var(--space-lg);
  }
  .left-lg\@lg {
    left: var(--space-lg);
  }
  .top-xl\@lg {
    top: var(--space-xl);
  }
  .right-xl\@lg {
    right: var(--space-xl);
  }
  .bottom-xl\@lg {
    bottom: var(--space-xl);
  }
  .left-xl\@lg {
    left: var(--space-xl);
  }
  .top-2xl\@lg {
    top: var(--space-2xl);
  }
  .right-2xl\@lg {
    right: var(--space-2xl);
  }
  .bottom-2xl\@lg {
    bottom: var(--space-2xl);
  }
  .left-2xl\@lg {
    left: var(--space-2xl);
  }
  .top-3xl\@lg {
    top: var(--space-3xl);
  }
  .right-3xl\@lg {
    right: var(--space-3xl);
  }
  .bottom-3xl\@lg {
    bottom: var(--space-3xl);
  }
  .left-3xl\@lg {
    left: var(--space-3xl);
  }
  .top-4xl\@lg {
    top: var(--space-4xl);
  }
  .right-4xl\@lg {
    right: var(--space-4xl);
  }
  .bottom-4xl\@lg {
    bottom: var(--space-4xl);
  }
  .left-4xl\@lg {
    left: var(--space-4xl);
  }
  .top-50\%\@lg {
    top: 50%;
  }
  .top-auto\@lg {
    top: auto;
  }
  .top-0\@lg {
    top: 0;
  }
  .right-50\%\@lg {
    right: 50%;
  }
  .right-auto\@lg {
    right: auto;
  }
  .right-0\@lg {
    right: 0;
  }
  .bottom-50\%\@lg {
    bottom: 50%;
  }
  .bottom-auto\@lg {
    bottom: auto;
  }
  .bottom-0\@lg {
    bottom: 0;
  }
  .left-50\%\@lg {
    left: 50%;
  }
  .left-auto\@lg {
    left: auto;
  }
  .left-0\@lg {
    left: 0;
  }
  /* overflow */
  .overflow-visible\@lg {
    overflow: visible;
  }
  .overflow-hidden\@lg {
    overflow: hidden;
  }
  .overflow-auto\@lg {
    overflow: auto;
  }
  .momentum-scrolling\@lg {
    -webkit-overflow-scrolling: touch;
  }
  /* visibility */
  .visible\@lg {
    visibility: visible;
  }
  .invisible\@lg {
    visibility: hidden;
  }
}
@media not all and (min-width: 80rem) {
  .display\@lg {
    display: none !important;
  }
}
@media (min-width: 90rem) {
  /* grid */
  .grid-col-1\@xl {
    --grid-columns: 1;
  }
  .col-1\@xl {
    --column-span: 1;
  }
  .grid-col-2\@xl {
    --grid-columns: 2;
  }
  .col-2\@xl {
    --column-span: 2;
  }
  .grid-col-3\@xl {
    --grid-columns: 3;
  }
  .col-3\@xl {
    --column-span: 3;
  }
  .grid-col-4\@xl {
    --grid-columns: 4;
  }
  .col-4\@xl {
    --column-span: 4;
  }
  .grid-col-5\@xl {
    --grid-columns: 5;
  }
  .col-5\@xl {
    --column-span: 5;
  }
  .grid-col-6\@xl {
    --grid-columns: 6;
  }
  .col-6\@xl {
    --column-span: 6;
  }
  .grid-col-7\@xl {
    --grid-columns: 7;
  }
  .col-7\@xl {
    --column-span: 7;
  }
  .grid-col-8\@xl {
    --grid-columns: 8;
  }
  .col-8\@xl {
    --column-span: 8;
  }
  .grid-col-9\@xl {
    --grid-columns: 9;
  }
  .col-9\@xl {
    --column-span: 9;
  }
  .grid-col-10\@xl {
    --grid-columns: 10;
  }
  .col-10\@xl {
    --column-span: 10;
  }
  .grid-col-11\@xl {
    --grid-columns: 11;
  }
  .col-11\@xl {
    --column-span: 11;
  }
  .grid-col-12\@xl {
    --grid-columns: 12;
  }
  .col-12\@xl {
    --column-span: 12;
  }
  .col-1\@xl, .col-2\@xl, .col-3\@xl, .col-4\@xl, .col-5\@xl, .col-6\@xl, .col-7\@xl, .col-8\@xl, .col-9\@xl, .col-10\@xl, .col-11\@xl, .col-12\@xl {
    width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
    flex: initial;
  }
  .col\@xl {
    width: auto;
    flex: 1 1 0;
  }
  .col-content\@xl {
    width: auto;
    flex: 0 1 auto;
  }
  .offset-1\@xl {
    --column-offset: 1;
  }
  .offset-2\@xl {
    --column-offset: 2;
  }
  .offset-3\@xl {
    --column-offset: 3;
  }
  .offset-4\@xl {
    --column-offset: 4;
  }
  .offset-5\@xl {
    --column-offset: 5;
  }
  .offset-6\@xl {
    --column-offset: 6;
  }
  .offset-7\@xl {
    --column-offset: 7;
  }
  .offset-8\@xl {
    --column-offset: 8;
  }
  .offset-9\@xl {
    --column-offset: 9;
  }
  .offset-10\@xl {
    --column-offset: 10;
  }
  .offset-11\@xl {
    --column-offset: 11;
  }
  .offset-1\@xl, .offset-2\@xl, .offset-3\@xl, .offset-4\@xl, .offset-5\@xl, .offset-6\@xl, .offset-7\@xl, .offset-8\@xl, .offset-9\@xl, .offset-10\@xl, .offset-11\@xl {
    margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
  }
  .offset-0\@xl {
    margin-left: 0;
  }
  /* gap */
  .gap-4xs\@xl {
    --row-gap: var(--space-4xs);
    --column-gap: var(--space-4xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xs\@xl {
    --column-gap: var(--space-4xs);
    column-gap: var(--column-gap);
  }
  .gap-y-4xs\@xl {
    --row-gap: var(--space-4xs);
    row-gap: var(--row-gap);
  }
  .gap-3xs\@xl {
    --row-gap: var(--space-3xs);
    --column-gap: var(--space-3xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xs\@xl {
    --column-gap: var(--space-3xs);
    column-gap: var(--column-gap);
  }
  .gap-y-3xs\@xl {
    --row-gap: var(--space-3xs);
    row-gap: var(--row-gap);
  }
  .gap-2xs\@xl {
    --row-gap: var(--space-2xs);
    --column-gap: var(--space-2xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xs\@xl {
    --column-gap: var(--space-2xs);
    column-gap: var(--column-gap);
  }
  .gap-y-2xs\@xl {
    --row-gap: var(--space-2xs);
    row-gap: var(--row-gap);
  }
  .gap-xs\@xl {
    --row-gap: var(--space-xs);
    --column-gap: var(--space-xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xs\@xl {
    --column-gap: var(--space-xs);
    column-gap: var(--column-gap);
  }
  .gap-y-xs\@xl {
    --row-gap: var(--space-xs);
    row-gap: var(--row-gap);
  }
  .gap-sm\@xl {
    --row-gap: var(--space-sm);
    --column-gap: var(--space-sm);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-sm\@xl {
    --column-gap: var(--space-sm);
    column-gap: var(--column-gap);
  }
  .gap-y-sm\@xl {
    --row-gap: var(--space-sm);
    row-gap: var(--row-gap);
  }
  .gap-md\@xl {
    --row-gap: var(--space-md);
    --column-gap: var(--space-md);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-md\@xl {
    --column-gap: var(--space-md);
    column-gap: var(--column-gap);
  }
  .gap-y-md\@xl {
    --row-gap: var(--space-md);
    row-gap: var(--row-gap);
  }
  .gap-lg\@xl {
    --row-gap: var(--space-lg);
    --column-gap: var(--space-lg);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-lg\@xl {
    --column-gap: var(--space-lg);
    column-gap: var(--column-gap);
  }
  .gap-y-lg\@xl {
    --row-gap: var(--space-lg);
    row-gap: var(--row-gap);
  }
  .gap-xl\@xl {
    --row-gap: var(--space-xl);
    --column-gap: var(--space-xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xl\@xl {
    --column-gap: var(--space-xl);
    column-gap: var(--column-gap);
  }
  .gap-y-xl\@xl {
    --row-gap: var(--space-xl);
    row-gap: var(--row-gap);
  }
  .gap-2xl\@xl {
    --row-gap: var(--space-2xl);
    --column-gap: var(--space-2xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xl\@xl {
    --column-gap: var(--space-2xl);
    column-gap: var(--column-gap);
  }
  .gap-y-2xl\@xl {
    --row-gap: var(--space-2xl);
    row-gap: var(--row-gap);
  }
  .gap-3xl\@xl {
    --row-gap: var(--space-3xl);
    --column-gap: var(--space-3xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xl\@xl {
    --column-gap: var(--space-3xl);
    column-gap: var(--column-gap);
  }
  .gap-y-3xl\@xl {
    --row-gap: var(--space-3xl);
    row-gap: var(--row-gap);
  }
  .gap-4xl\@xl {
    --row-gap: var(--space-4xl);
    --column-gap: var(--space-4xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xl\@xl {
    --column-gap: var(--space-4xl);
    column-gap: var(--column-gap);
  }
  .gap-y-4xl\@xl {
    --row-gap: var(--space-4xl);
    row-gap: var(--row-gap);
  }
  .gap-0\@xl {
    --row-gap: 0px;
    --column-gap: 0px;
    gap: 0px;
  }
  .gap-x-0\@xl {
    --column-gap: 0px;
    column-gap: 0px;
  }
  .gap-y-0\@xl {
    --row-gap: 0px;
    row-gap: 0px;
  }
  /* flexbox */
  .flex\@xl {
    display: flex;
  }
  .inline-flex\@xl {
    display: inline-flex;
  }
  .flex-wrap\@xl {
    flex-wrap: wrap;
  }
  .flex-nowrap\@xl {
    flex-wrap: nowrap;
  }
  .flex-column\@xl {
    flex-direction: column;
  }
  .flex-column-reverse\@xl {
    flex-direction: column-reverse;
  }
  .flex-row\@xl {
    flex-direction: row;
  }
  .flex-row-reverse\@xl {
    flex-direction: row-reverse;
  }
  .flex-center\@xl {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@xl {
    flex-grow: 1;
  }
  .flex-grow-0\@xl {
    flex-grow: 0;
  }
  .flex-shrink\@xl {
    flex-shrink: 1;
  }
  .flex-shrink-0\@xl {
    flex-shrink: 0;
  }
  .flex-basis-0\@xl {
    flex-basis: 0;
  }
  /* justify-content */
  .justify-start\@xl {
    justify-content: start;
  }
  .justify-end\@xl {
    justify-content: end;
  }
  .justify-center\@xl {
    justify-content: center;
  }
  .justify-between\@xl {
    justify-content: space-between;
  }
  /* align-items */
  .items-center\@xl {
    align-items: center;
  }
  .items-start\@xl {
    align-items: start;
  }
  .items-end\@xl {
    align-items: end;
  }
  .items-baseline\@xl {
    align-items: baseline;
  }
  .items-stretch\@xl {
    align-items: stretch;
  }
  /* align-content */
  .content-start\@xl {
    align-content: start;
  }
  .content-end\@xl {
    align-content: end;
  }
  .content-center\@xl {
    align-content: center;
  }
  .content-between\@xl {
    align-content: space-between;
  }
  /* order */
  .order-1\@xl {
    order: 1;
  }
  .order-2\@xl {
    order: 2;
  }
  .order-3\@xl {
    order: 3;
  }
  /* display */
  .block\@xl {
    display: block;
  }
  .inline-block\@xl {
    display: inline-block;
  }
  .inline\@xl {
    display: inline;
  }
  .contents\@xl {
    display: contents;
  }
  .css-grid\@xl {
    display: grid;
  }
  .css-inline-grid\@xl {
    display: inline-grid;
  }
  .hide\@xl {
    display: none;
  }
  /* margin */
  .margin-4xs\@xl {
    margin: var(--space-4xs);
  }
  .margin-top-4xs\@xl {
    margin-top: var(--space-4xs);
  }
  .margin-right-4xs\@xl {
    margin-right: var(--space-4xs);
  }
  .margin-bottom-4xs\@xl {
    margin-bottom: var(--space-4xs);
  }
  .margin-left-4xs\@xl {
    margin-left: var(--space-4xs);
  }
  .margin-y-4xs\@xl {
    margin-top: var(--space-4xs);
    margin-bottom: var(--space-4xs);
  }
  .margin-x-4xs\@xl {
    margin-right: var(--space-4xs);
    margin-left: var(--space-4xs);
  }
  .margin-3xs\@xl {
    margin: var(--space-3xs);
  }
  .margin-top-3xs\@xl {
    margin-top: var(--space-3xs);
  }
  .margin-right-3xs\@xl {
    margin-right: var(--space-3xs);
  }
  .margin-bottom-3xs\@xl {
    margin-bottom: var(--space-3xs);
  }
  .margin-left-3xs\@xl {
    margin-left: var(--space-3xs);
  }
  .margin-y-3xs\@xl {
    margin-top: var(--space-3xs);
    margin-bottom: var(--space-3xs);
  }
  .margin-x-3xs\@xl {
    margin-right: var(--space-3xs);
    margin-left: var(--space-3xs);
  }
  .margin-2xs\@xl {
    margin: var(--space-2xs);
  }
  .margin-top-2xs\@xl {
    margin-top: var(--space-2xs);
  }
  .margin-right-2xs\@xl {
    margin-right: var(--space-2xs);
  }
  .margin-bottom-2xs\@xl {
    margin-bottom: var(--space-2xs);
  }
  .margin-left-2xs\@xl {
    margin-left: var(--space-2xs);
  }
  .margin-y-2xs\@xl {
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-2xs);
  }
  .margin-x-2xs\@xl {
    margin-right: var(--space-2xs);
    margin-left: var(--space-2xs);
  }
  .margin-xs\@xl {
    margin: var(--space-xs);
  }
  .margin-top-xs\@xl {
    margin-top: var(--space-xs);
  }
  .margin-right-xs\@xl {
    margin-right: var(--space-xs);
  }
  .margin-bottom-xs\@xl {
    margin-bottom: var(--space-xs);
  }
  .margin-left-xs\@xl {
    margin-left: var(--space-xs);
  }
  .margin-y-xs\@xl {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
  .margin-x-xs\@xl {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }
  .margin-sm\@xl {
    margin: var(--space-sm);
  }
  .margin-top-sm\@xl {
    margin-top: var(--space-sm);
  }
  .margin-right-sm\@xl {
    margin-right: var(--space-sm);
  }
  .margin-bottom-sm\@xl {
    margin-bottom: var(--space-sm);
  }
  .margin-left-sm\@xl {
    margin-left: var(--space-sm);
  }
  .margin-y-sm\@xl {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }
  .margin-x-sm\@xl {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }
  .margin-md\@xl {
    margin: var(--space-md);
  }
  .margin-top-md\@xl {
    margin-top: var(--space-md);
  }
  .margin-right-md\@xl {
    margin-right: var(--space-md);
  }
  .margin-bottom-md\@xl {
    margin-bottom: var(--space-md);
  }
  .margin-left-md\@xl {
    margin-left: var(--space-md);
  }
  .margin-y-md\@xl {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }
  .margin-x-md\@xl {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }
  .margin-lg\@xl {
    margin: var(--space-lg);
  }
  .margin-top-lg\@xl {
    margin-top: var(--space-lg);
  }
  .margin-right-lg\@xl {
    margin-right: var(--space-lg);
  }
  .margin-bottom-lg\@xl {
    margin-bottom: var(--space-lg);
  }
  .margin-left-lg\@xl {
    margin-left: var(--space-lg);
  }
  .margin-y-lg\@xl {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }
  .margin-x-lg\@xl {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }
  .margin-xl\@xl {
    margin: var(--space-xl);
  }
  .margin-top-xl\@xl {
    margin-top: var(--space-xl);
  }
  .margin-right-xl\@xl {
    margin-right: var(--space-xl);
  }
  .margin-bottom-xl\@xl {
    margin-bottom: var(--space-xl);
  }
  .margin-left-xl\@xl {
    margin-left: var(--space-xl);
  }
  .margin-y-xl\@xl {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  .margin-x-xl\@xl {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }
  .margin-2xl\@xl {
    margin: var(--space-2xl);
  }
  .margin-top-2xl\@xl {
    margin-top: var(--space-2xl);
  }
  .margin-right-2xl\@xl {
    margin-right: var(--space-2xl);
  }
  .margin-bottom-2xl\@xl {
    margin-bottom: var(--space-2xl);
  }
  .margin-left-2xl\@xl {
    margin-left: var(--space-2xl);
  }
  .margin-y-2xl\@xl {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
  }
  .margin-x-2xl\@xl {
    margin-right: var(--space-2xl);
    margin-left: var(--space-2xl);
  }
  .margin-3xl\@xl {
    margin: var(--space-3xl);
  }
  .margin-top-3xl\@xl {
    margin-top: var(--space-3xl);
  }
  .margin-right-3xl\@xl {
    margin-right: var(--space-3xl);
  }
  .margin-bottom-3xl\@xl {
    margin-bottom: var(--space-3xl);
  }
  .margin-left-3xl\@xl {
    margin-left: var(--space-3xl);
  }
  .margin-y-3xl\@xl {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-3xl);
  }
  .margin-x-3xl\@xl {
    margin-right: var(--space-3xl);
    margin-left: var(--space-3xl);
  }
  .margin-4xl\@xl {
    margin: var(--space-4xl);
  }
  .margin-top-4xl\@xl {
    margin-top: var(--space-4xl);
  }
  .margin-right-4xl\@xl {
    margin-right: var(--space-4xl);
  }
  .margin-bottom-4xl\@xl {
    margin-bottom: var(--space-4xl);
  }
  .margin-left-4xl\@xl {
    margin-left: var(--space-4xl);
  }
  .margin-y-4xl\@xl {
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-4xl);
  }
  .margin-x-4xl\@xl {
    margin-right: var(--space-4xl);
    margin-left: var(--space-4xl);
  }
  .margin-auto\@xl {
    margin: auto;
  }
  .margin-0\@xl {
    margin: 0;
  }
  .margin-y-auto\@xl {
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-y-0\@xl {
    margin-top: 0;
    margin-bottom: 0;
  }
  .margin-x-auto\@xl {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-x-0\@xl {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-top-auto\@xl {
    margin-top: auto;
  }
  .margin-top-0\@xl {
    margin-top: 0;
  }
  .margin-right-auto\@xl {
    margin-right: auto;
  }
  .margin-right-0\@xl {
    margin-right: 0;
  }
  .margin-bottom-auto\@xl {
    margin-bottom: auto;
  }
  .margin-bottom-0\@xl {
    margin-bottom: 0;
  }
  .margin-left-auto\@xl {
    margin-left: auto;
  }
  .margin-left-0\@xl {
    margin-left: 0;
  }
  /* padding */
  .padding-4xs\@xl {
    padding: var(--space-4xs);
  }
  .padding-top-4xs\@xl {
    padding-top: var(--space-4xs);
  }
  .padding-right-4xs\@xl {
    padding-right: var(--space-4xs);
  }
  .padding-bottom-4xs\@xl {
    padding-bottom: var(--space-4xs);
  }
  .padding-left-4xs\@xl {
    padding-left: var(--space-4xs);
  }
  .padding-y-4xs\@xl {
    padding-top: var(--space-4xs);
    padding-bottom: var(--space-4xs);
  }
  .padding-x-4xs\@xl {
    padding-right: var(--space-4xs);
    padding-left: var(--space-4xs);
  }
  .padding-3xs\@xl {
    padding: var(--space-3xs);
  }
  .padding-top-3xs\@xl {
    padding-top: var(--space-3xs);
  }
  .padding-right-3xs\@xl {
    padding-right: var(--space-3xs);
  }
  .padding-bottom-3xs\@xl {
    padding-bottom: var(--space-3xs);
  }
  .padding-left-3xs\@xl {
    padding-left: var(--space-3xs);
  }
  .padding-y-3xs\@xl {
    padding-top: var(--space-3xs);
    padding-bottom: var(--space-3xs);
  }
  .padding-x-3xs\@xl {
    padding-right: var(--space-3xs);
    padding-left: var(--space-3xs);
  }
  .padding-2xs\@xl {
    padding: var(--space-2xs);
  }
  .padding-top-2xs\@xl {
    padding-top: var(--space-2xs);
  }
  .padding-right-2xs\@xl {
    padding-right: var(--space-2xs);
  }
  .padding-bottom-2xs\@xl {
    padding-bottom: var(--space-2xs);
  }
  .padding-left-2xs\@xl {
    padding-left: var(--space-2xs);
  }
  .padding-y-2xs\@xl {
    padding-top: var(--space-2xs);
    padding-bottom: var(--space-2xs);
  }
  .padding-x-2xs\@xl {
    padding-right: var(--space-2xs);
    padding-left: var(--space-2xs);
  }
  .padding-xs\@xl {
    padding: var(--space-xs);
  }
  .padding-top-xs\@xl {
    padding-top: var(--space-xs);
  }
  .padding-right-xs\@xl {
    padding-right: var(--space-xs);
  }
  .padding-bottom-xs\@xl {
    padding-bottom: var(--space-xs);
  }
  .padding-left-xs\@xl {
    padding-left: var(--space-xs);
  }
  .padding-y-xs\@xl {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }
  .padding-x-xs\@xl {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }
  .padding-sm\@xl {
    padding: var(--space-sm);
  }
  .padding-top-sm\@xl {
    padding-top: var(--space-sm);
  }
  .padding-right-sm\@xl {
    padding-right: var(--space-sm);
  }
  .padding-bottom-sm\@xl {
    padding-bottom: var(--space-sm);
  }
  .padding-left-sm\@xl {
    padding-left: var(--space-sm);
  }
  .padding-y-sm\@xl {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
  .padding-x-sm\@xl {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }
  .padding-md\@xl {
    padding: var(--space-md);
  }
  .padding-top-md\@xl {
    padding-top: var(--space-md);
  }
  .padding-right-md\@xl {
    padding-right: var(--space-md);
  }
  .padding-bottom-md\@xl {
    padding-bottom: var(--space-md);
  }
  .padding-left-md\@xl {
    padding-left: var(--space-md);
  }
  .padding-y-md\@xl {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }
  .padding-x-md\@xl {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }
  .padding-lg\@xl {
    padding: var(--space-lg);
  }
  .padding-top-lg\@xl {
    padding-top: var(--space-lg);
  }
  .padding-right-lg\@xl {
    padding-right: var(--space-lg);
  }
  .padding-bottom-lg\@xl {
    padding-bottom: var(--space-lg);
  }
  .padding-left-lg\@xl {
    padding-left: var(--space-lg);
  }
  .padding-y-lg\@xl {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
  .padding-x-lg\@xl {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }
  .padding-xl\@xl {
    padding: var(--space-xl);
  }
  .padding-top-xl\@xl {
    padding-top: var(--space-xl);
  }
  .padding-right-xl\@xl {
    padding-right: var(--space-xl);
  }
  .padding-bottom-xl\@xl {
    padding-bottom: var(--space-xl);
  }
  .padding-left-xl\@xl {
    padding-left: var(--space-xl);
  }
  .padding-y-xl\@xl {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  .padding-x-xl\@xl {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }
  .padding-2xl\@xl {
    padding: var(--space-2xl);
  }
  .padding-top-2xl\@xl {
    padding-top: var(--space-2xl);
  }
  .padding-right-2xl\@xl {
    padding-right: var(--space-2xl);
  }
  .padding-bottom-2xl\@xl {
    padding-bottom: var(--space-2xl);
  }
  .padding-left-2xl\@xl {
    padding-left: var(--space-2xl);
  }
  .padding-y-2xl\@xl {
    padding-top: var(--space-2xl);
    padding-bottom: var(--space-2xl);
  }
  .padding-x-2xl\@xl {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
  .padding-3xl\@xl {
    padding: var(--space-3xl);
  }
  .padding-top-3xl\@xl {
    padding-top: var(--space-3xl);
  }
  .padding-right-3xl\@xl {
    padding-right: var(--space-3xl);
  }
  .padding-bottom-3xl\@xl {
    padding-bottom: var(--space-3xl);
  }
  .padding-left-3xl\@xl {
    padding-left: var(--space-3xl);
  }
  .padding-y-3xl\@xl {
    padding-top: var(--space-3xl);
    padding-bottom: var(--space-3xl);
  }
  .padding-x-3xl\@xl {
    padding-right: var(--space-3xl);
    padding-left: var(--space-3xl);
  }
  .padding-4xl\@xl {
    padding: var(--space-4xl);
  }
  .padding-top-4xl\@xl {
    padding-top: var(--space-4xl);
  }
  .padding-right-4xl\@xl {
    padding-right: var(--space-4xl);
  }
  .padding-bottom-4xl\@xl {
    padding-bottom: var(--space-4xl);
  }
  .padding-left-4xl\@xl {
    padding-left: var(--space-4xl);
  }
  .padding-y-4xl\@xl {
    padding-top: var(--space-4xl);
    padding-bottom: var(--space-4xl);
  }
  .padding-x-4xl\@xl {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
  .padding-0\@xl {
    padding: 0;
  }
  .padding-y-0\@xl {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-x-0\@xl {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-top-0\@xl {
    padding-top: 0;
  }
  .padding-right-0\@xl {
    padding-right: 0;
  }
  .padding-bottom-0\@xl {
    padding-bottom: 0;
  }
  .padding-left-0\@xl {
    padding-left: 0;
  }
  /* text-align */
  .text-center\@xl {
    text-align: center;
  }
  .text-left\@xl {
    text-align: left;
  }
  .text-right\@xl {
    text-align: right;
  }
  .text-justify\@xl {
    text-align: justify;
  }
  /* font-size */
  .text-xs\@xl {
    font-size: var(--text-xs);
  }
  .text-sm\@xl {
    font-size: var(--text-sm);
  }
  .text-base\@xl {
    font-size: var(--text-base);
  }
  .text-md\@xl {
    font-size: var(--text-md);
  }
  .text-lg\@xl {
    font-size: var(--text-lg);
  }
  .text-xl\@xl {
    font-size: var(--text-xl);
  }
  .text-2xl\@xl {
    font-size: var(--text-2xl);
  }
  .text-3xl\@xl {
    font-size: var(--text-3xl);
  }
  .text-4xl\@xl {
    font-size: var(--text-4xl);
  }
  /* column-count */
  .column-count-1\@xl {
    column-count: 1;
  }
  .column-count-2\@xl {
    column-count: 2;
  }
  .column-count-3\@xl {
    column-count: 3;
  }
  .column-count-4\@xl {
    column-count: 4;
  }
  /* width */
  .width-4xs\@xl {
    width: 0.25rem;
  }
  .width-3xs\@xl {
    width: 0.5rem;
  }
  .width-2xs\@xl {
    width: 0.75rem;
  }
  .width-xs\@xl {
    width: 1rem;
  }
  .width-sm\@xl {
    width: 1.5rem;
  }
  .width-md\@xl {
    width: 2rem;
  }
  .width-lg\@xl {
    width: 3rem;
  }
  .width-xl\@xl {
    width: 4rem;
  }
  .width-2xl\@xl {
    width: 6rem;
  }
  .width-3xl\@xl {
    width: 8rem;
  }
  .width-4xl\@xl {
    width: 16rem;
  }
  .width-0\@xl {
    width: 0;
  }
  .width-10\%\@xl {
    width: 10%;
  }
  .width-20\%\@xl {
    width: 20%;
  }
  .width-25\%\@xl {
    width: 25%;
  }
  .width-30\%\@xl {
    width: 30%;
  }
  .width-33\%\@xl {
    width: 33%;
  }
  .width-40\%\@xl {
    width: 40%;
  }
  .width-50\%\@xl {
    width: 50%;
  }
  .width-60\%\@xl {
    width: 60%;
  }
  .width-70\%\@xl {
    width: 70%;
  }
  .width-75\%\@xl {
    width: 75%;
  }
  .width-80\%\@xl {
    width: 80%;
  }
  .width-90\%\@xl {
    width: 90%;
  }
  .width-100\%\@xl {
    width: 100%;
  }
  .width-100\%\@xl {
    width: 100%;
  }
  .width-100vw\@xl {
    width: 100vw;
  }
  .width-auto\@xl {
    width: auto;
  }
  .width-inherit\@xl {
    width: inherit;
  }
  .width-0\@xl {
    width: 0;
  }
  /* height */
  .height-4xs\@xl {
    height: 0.25rem;
  }
  .height-3xs\@xl {
    height: 0.5rem;
  }
  .height-2xs\@xl {
    height: 0.75rem;
  }
  .height-xs\@xl {
    height: 1rem;
  }
  .height-sm\@xl {
    height: 1.5rem;
  }
  .height-md\@xl {
    height: 2rem;
  }
  .height-lg\@xl {
    height: 3rem;
  }
  .height-xl\@xl {
    height: 4rem;
  }
  .height-2xl\@xl {
    height: 6rem;
  }
  .height-3xl\@xl {
    height: 8rem;
  }
  .height-4xl\@xl {
    height: 16rem;
  }
  .height-0\@xl {
    height: 0;
  }
  .height-10\%\@xl {
    height: 10%;
  }
  .height-20\%\@xl {
    height: 20%;
  }
  .height-25\%\@xl {
    height: 25%;
  }
  .height-30\%\@xl {
    height: 30%;
  }
  .height-33\%\@xl {
    height: 33%;
  }
  .height-40\%\@xl {
    height: 40%;
  }
  .height-50\%\@xl {
    height: 50%;
  }
  .height-60\%\@xl {
    height: 60%;
  }
  .height-70\%\@xl {
    height: 70%;
  }
  .height-75\%\@xl {
    height: 75%;
  }
  .height-80\%\@xl {
    height: 80%;
  }
  .height-90\%\@xl {
    height: 90%;
  }
  .height-100\%\@xl {
    height: 100%;
  }
  .height-100\%\@xl {
    height: 100%;
  }
  .height-100vh\@xl {
    height: 100vh;
  }
  .height-auto\@xl {
    height: auto;
  }
  .height-inherit\@xl {
    height: inherit;
  }
  .height-0\@xl {
    height: 0;
  }
  /* max-width */
  .max-width-3xs\@xl {
    max-width: var(--max-width-3xs);
  }
  .max-width-2xs\@xl {
    max-width: var(--max-width-2xs);
  }
  .max-width-xs\@xl {
    max-width: var(--max-width-xs);
  }
  .max-width-sm\@xl {
    max-width: var(--max-width-sm);
  }
  .max-width-md\@xl {
    max-width: var(--max-width-md);
  }
  .max-width-lg\@xl {
    max-width: var(--max-width-lg);
  }
  .max-width-xl\@xl {
    max-width: var(--max-width-xl);
  }
  .max-width-100\%\@xl {
    max-width: 100%;
  }
  .max-width-none\@xl {
    max-width: none;
  }
  /* position */
  .position-relative\@xl {
    position: relative;
  }
  .position-absolute\@xl {
    position: absolute;
  }
  .position-fixed\@xl {
    position: fixed;
  }
  .position-sticky\@xl {
    position: sticky;
  }
  .position-static\@xl {
    position: static;
  }
  .inset-0\@xl {
    inset: 0;
  }
  .top-4xs\@xl {
    top: var(--space-4xs);
  }
  .right-4xs\@xl {
    right: var(--space-4xs);
  }
  .bottom-4xs\@xl {
    bottom: var(--space-4xs);
  }
  .left-4xs\@xl {
    left: var(--space-4xs);
  }
  .top-3xs\@xl {
    top: var(--space-3xs);
  }
  .right-3xs\@xl {
    right: var(--space-3xs);
  }
  .bottom-3xs\@xl {
    bottom: var(--space-3xs);
  }
  .left-3xs\@xl {
    left: var(--space-3xs);
  }
  .top-2xs\@xl {
    top: var(--space-2xs);
  }
  .right-2xs\@xl {
    right: var(--space-2xs);
  }
  .bottom-2xs\@xl {
    bottom: var(--space-2xs);
  }
  .left-2xs\@xl {
    left: var(--space-2xs);
  }
  .top-xs\@xl {
    top: var(--space-xs);
  }
  .right-xs\@xl {
    right: var(--space-xs);
  }
  .bottom-xs\@xl {
    bottom: var(--space-xs);
  }
  .left-xs\@xl {
    left: var(--space-xs);
  }
  .top-sm\@xl {
    top: var(--space-sm);
  }
  .right-sm\@xl {
    right: var(--space-sm);
  }
  .bottom-sm\@xl {
    bottom: var(--space-sm);
  }
  .left-sm\@xl {
    left: var(--space-sm);
  }
  .top-md\@xl {
    top: var(--space-md);
  }
  .right-md\@xl {
    right: var(--space-md);
  }
  .bottom-md\@xl {
    bottom: var(--space-md);
  }
  .left-md\@xl {
    left: var(--space-md);
  }
  .top-lg\@xl {
    top: var(--space-lg);
  }
  .right-lg\@xl {
    right: var(--space-lg);
  }
  .bottom-lg\@xl {
    bottom: var(--space-lg);
  }
  .left-lg\@xl {
    left: var(--space-lg);
  }
  .top-xl\@xl {
    top: var(--space-xl);
  }
  .right-xl\@xl {
    right: var(--space-xl);
  }
  .bottom-xl\@xl {
    bottom: var(--space-xl);
  }
  .left-xl\@xl {
    left: var(--space-xl);
  }
  .top-2xl\@xl {
    top: var(--space-2xl);
  }
  .right-2xl\@xl {
    right: var(--space-2xl);
  }
  .bottom-2xl\@xl {
    bottom: var(--space-2xl);
  }
  .left-2xl\@xl {
    left: var(--space-2xl);
  }
  .top-3xl\@xl {
    top: var(--space-3xl);
  }
  .right-3xl\@xl {
    right: var(--space-3xl);
  }
  .bottom-3xl\@xl {
    bottom: var(--space-3xl);
  }
  .left-3xl\@xl {
    left: var(--space-3xl);
  }
  .top-4xl\@xl {
    top: var(--space-4xl);
  }
  .right-4xl\@xl {
    right: var(--space-4xl);
  }
  .bottom-4xl\@xl {
    bottom: var(--space-4xl);
  }
  .left-4xl\@xl {
    left: var(--space-4xl);
  }
  .top-50\%\@xl {
    top: 50%;
  }
  .top-auto\@xl {
    top: auto;
  }
  .top-0\@xl {
    top: 0;
  }
  .right-50\%\@xl {
    right: 50%;
  }
  .right-auto\@xl {
    right: auto;
  }
  .right-0\@xl {
    right: 0;
  }
  .bottom-50\%\@xl {
    bottom: 50%;
  }
  .bottom-auto\@xl {
    bottom: auto;
  }
  .bottom-0\@xl {
    bottom: 0;
  }
  .left-50\%\@xl {
    left: 50%;
  }
  .left-auto\@xl {
    left: auto;
  }
  .left-0\@xl {
    left: 0;
  }
  /* overflow */
  .overflow-visible\@xl {
    overflow: visible;
  }
  .overflow-hidden\@xl {
    overflow: hidden;
  }
  .overflow-auto\@xl {
    overflow: auto;
  }
  .momentum-scrolling\@xl {
    -webkit-overflow-scrolling: touch;
  }
  /* visibility */
  .visible\@xl {
    visibility: visible;
  }
  .invisible\@xl {
    visibility: hidden;
  }
}
@media not all and (min-width: 90rem) {
  .display\@xl {
    display: none !important;
  }
}
.wpcf7 form .wpcf7-response-output {
  margin: var(--space-md) 0 0 0;
  border: none;
  border-radius: var(--radius-md);
  padding: var(--space-sm) var(--space-md);
}
.wpcf7 form.sent .wpcf7-response-output {
  background: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), 0.35);
  border: 2px solid var(--color-success);
}
.wpcf7 form.invalid .wpcf7-response-output {
  background: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), 0.35);
  border: 2px solid var(--color-warning);
}
.acf-flexible-content .layout {
  max-width: 100%;
}

.acf-field-flexible-content[data-acfe-flexible-grid="1"] > .acf-input > .acf-flexible-content > .values > .layout > .acfe-fc-placeholder.acfe-fc-preview > .-preview {
  padding: 15px;
}

:root {
  --mega-nav-height: 70px;
}

.radius-max {
  border-radius: 500px;
}

.radius-max\@md {
  border-radius: 50px;
}
@media (min-width: 64rem) {
  .radius-max\@md {
    border-radius: 500px;
  }
}

.animation-shake:hover svg {
  animation: shake 0.3s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.footer-small__nav-item {
  padding: 0 var(--space-xs);
  margin: 0;
  position: relative;
}
.footer-small__nav-item::after {
  content: "";
  display: inline-block;
  position: absolute;
  height: 0.2em;
  width: 0.2em;
  border-radius: 50%;
  right: 0;
  top: calc(50% - 0.1em);
  background-color: var(--color-contrast-higher);
}
.footer-small__nav-item:first-child {
  padding-left: 0;
}
.footer-small__nav-item:last-child {
  padding-right: 0;
}
.footer-small__nav-item:last-child::after {
  display: none;
}

html {
  scroll-behavior: smooth;
}

.bg-gradient-accent-primary-right {
  background-image: linear-gradient(90deg, var(--color-accent), var(--color-primary));
}