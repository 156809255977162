@use '../../src/sass/config' as *;

// Import Component Base Styles 👇🏻
@use '../../src/sass/components/_1_hero' as *;

// Custom Styles 👇🏻
.hero {
  position: relative;
  min-height: 700px;
  display: flex;
  align-items: center;
}

.hero--overlay-layer {
  .container {
    z-index: 2;
  }
}


.hero-figure {
  transform: translate(-3px, -3px);

  svg {
    max-height: 650px !important;
  }
}