@use '../../src/sass/config' as *;

// Import Component Base Styles 👇🏻
@use '../../src/sass/components/_2_carousel' as *;

// Custom Styles 👇🏻
:root {
  --carousel-grid-gap: var(--space-sm);
  --carousel-item-auto-size: 250px; // min-width value -> used in JS to auto update the carousel items width
  --carousel-transition-duration: 0.5s;
}