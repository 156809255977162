@use '../config' as *;

#main-wrapper {
  // Default Vertical Spacing
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);

  // Remove Default Page Content Spacing from all Component-Based-Pages
  &.components-wrapper {
    padding-top: 0;
    padding-bottom: 0;

    // Default Padding
    .section:not([class*="padding-"]) {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }

    // Remove Padding on Default from First/Last Section
    .section:first-child:not([class*="padding-top-"]) {
      padding-top: 0;
    }
    .section:last-child:not([class*="padding-bottom-"]) {
      //padding-bottom: 0;
    }
  }
}

// Remove Horizontal Padding from Fullwidth Sections
.components-wrapper .container.max-width-none {
  width: unset;
}

.background,
[class*='background-'] {
  position: relative;
}

[class*='background-'] {
  .background-image {
    opacity: .2;
  }
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
  pointer-events: none;
  z-index: -1;
}