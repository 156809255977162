@use '../config' as *;

/* -------------------------------- 

File#: _1_radios-checkboxes
Title: Radios and Checkboxes
Descr: Custom radio and checkbox buttons
Usage: codyhouse.co/license

-------------------------------- */

:root {
  /* radios + checkboxes */
  --checkbox-radio-size: 18px;
  --checkbox-radio-gap: var(--space-2xs); /* gap between button and label */
  --checkbox-radio-border-width: 1px;
  --checkbox-radio-line-height: 1.4;

  /* radios */
  --radio-marker-size: 8px;

  /* checkboxes */
  --checkbox-marker-size: 12px;
  --checkbox-radius: 4px;
}

body:not(.woocommerce-page) {
  input {
    appearance: none;
  }
  /* hide native buttons */
  .radio,
  .checkbox {
    position: absolute;
    padding: 0;
    margin: 0;
    margin-top: calc((1em * var(--checkbox-radio-line-height) - var(--checkbox-radio-size)) / 2);
    opacity: 0;
    height: var(--checkbox-radio-size);
    width: var(--checkbox-radio-size);
    pointer-events: none;
  }

  /* label */
  .radio + label,
  .checkbox + label {
    display: inline-block;
    line-height: var(--checkbox-radio-line-height);
    user-select: none;
    cursor: pointer;
    padding-left: calc(var(--checkbox-radio-size) + var(--checkbox-radio-gap));
  }

  /* custom inputs - basic style */
  .radio + label::before,
  .checkbox + label::before {
    content: '';
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    top: -0.1em;
    margin-left: calc(-1 * (var(--checkbox-radio-size) + var(--checkbox-radio-gap)));
    flex-shrink: 0;
    width: var(--checkbox-radio-size);
    height: var(--checkbox-radio-size);
    background-color: var(--color-bg);
    border-width: var(--checkbox-radio-border-width);
    border-color: alpha(var(--color-contrast-low), 0.65);
    border-style: solid;
    box-shadow: var(--shadow-xs);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: var(--checkbox-radio-gap);
    transition: transform .2s, border .2s;
  }

  /* :hover */
  .radio:not(:checked):not(:focus) + label:hover::before,
  .checkbox:not(:checked):not(:focus) + label:hover::before {
    border-color: alpha(var(--color-contrast-low), 1);
  }

  /* radio only style */
  .radio + label::before {
    border-radius: 50%;
  }

  /* checkbox only style */
  .checkbox + label::before {
    border-radius: var(--checkbox-radius);
  }

  /* :checked */
  .radio:checked + label::before,
  .checkbox:checked + label::before {
    background-color: var(--color-primary);
    box-shadow: var(--shadow-xs);
    border-color: var(--color-primary);
    transition: transform .2s;
  }

  /* :active */
  .radio:active + label::before,
  .checkbox:active + label::before {
    transform: scale(0.8);
    transition: transform .2s;
  }

  /* :checked:active */
  .radio:checked:active + label::before,
  .checkbox:checked:active + label::before {
    transform: none;
    transition: none;
  }

  /* radio button icon */
  .radio:checked + label::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23ffffff'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
    background-size: var(--radio-marker-size);
  }

  /* checkbox button icon */
  .checkbox:checked + label::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpolyline points='1 6.5 4 9.5 11 2.5' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
    background-size: var(--checkbox-marker-size);
  }

  /* :focus */
  .radio:checked:active + label::before,
  .checkbox:checked:active + label::before,
  .radio:focus + label::before,
  .checkbox:focus + label::before {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px alpha(var(--color-primary), 0.2);
  }

  /* --radio--bg, --checkbox--bg -> variation with background color */
  .radio--bg + label, .checkbox--bg + label {
    padding: var(--space-4xs) var(--space-3xs);
    padding-left: calc(var(--checkbox-radio-size) + var(--checkbox-radio-gap) + var(--space-3xs));
    border-radius: var(--radius-md);
    transition: background .2s;
  }

  .radio--bg + label:hover, .checkbox--bg + label:hover {
    background-color: alpha(var(--color-contrast-higher), 0.075);
  }

  .radio--bg:active + label,
  .checkbox--bg:active + label,
  .radio--bg:focus + label,
  .checkbox--bg:focus + label {
    background-color: alpha(var(--color-primary), 0.1);
  }
}