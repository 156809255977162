@use '../config' as *;
@use '_1_collapse.scss' as *;
@use '_1_anim-menu-btn.scss' as *;

/* -------------------------------- 

File#: _2_morphing-navigation
Title: Morphing Navigation
Descr: Header Template with a morphing dropdown
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --morph-nav-height: 80px;

  @include min-width(md) {
    --morph-nav-height: 100px;
  }
}

.morph-nav {
  z-index: var(--z-index-header);
  height: var(--morph-nav-height);

  > * {
    height: 100%;
  }
}

.morph-nav__logo {
  display: block;
  width: auto;
  height: 65px;
}

.morph-nav__link {
  display: flex;
  align-items: center;
}

.morph-nav__link-figure {
  flex-shrink: 0;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--space-sm);
}

.morph-nav__link-title {
  color: var(--color-contrast-higher);
  font-weight: 500;

  .icon {
    opacity: 0;
    transform: translateX(-0.5em);
    transition: opacity 0.2s, transform 0.2s;

    .morph-nav__link:hover & {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.morph-nav__link-description {
  color: var(--color-contrast-low);
}

.morph-nav__social-link {
  display: flex;
  align-items: center;
  color: var(--color-contrast-higher);
  font-weight: 500;

  transition: color 0.2s;

  .icon {
    margin-right: var(--space-xs);
  }

  &:hover {
    color: var(--color-primary);
  }
}

/* #region (mobile only) */
.morph-nav__sm-nav {
  position: relative;
  z-index: 2;
  height: 100%;
}

.morph-nav__sm-menu-toggle { // menu button
  color: var(--color-contrast-higher); // icon color

  --anim-menu-btn-size: 40px;
  /* 🍔 icon */
  --anim-menu-btn-icon-size: 28px;
  --anim-menu-btn-icon-stroke: 2px;
}

.morph-nav__sm-dropdown {
  position: absolute;
  z-index: 1;
  padding-top: var(--morph-nav-height);
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-bg-light);
  box-shadow: var(--shadow-sm);

  transform: scale(0.95);
  transform-origin: top center;
  visibility: hidden;
  opacity: 0;

  transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s var(--ease-out);

  a {
    color: inherit;
    text-decoration: none;
  }
}

.morph-nav__sm-dropdown--is-visible {
  transform: scale(1);
  visibility: visible;
  opacity: 1;

  transition: visibility 0s, opacity 0.3s, transform 0.3s var(--ease-out);
}

.morph-nav__sm-dropdown-innner {
  max-height: calc(100vh - var(--morph-nav-height));
  overflow: auto;
}

.morph-nav__sm-label {
  font-size: var(--text-xs);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--color-contrast-low);
  margin-bottom: var(--space-md);
}
/* #endregion */

/* #region (desktop only) */
.morph-nav__lg-nav-list {
  display: flex;
  height: 100%;
}

.morph-nav__lg-nav-item {
  height: 100%;
  transition: opacity 0.3s;
}

.morph-nav__lg-nav-list:hover .morph-nav__lg-nav-item:not(:hover) {
  opacity: 0.5;
}

.morph-nav__lg-main-nav-link {
  display: block;
  height: 100%;
  line-height: var(--morph-nav-height);
  color: var(--color-contrast-higher);
  font-size: var(--text-sm);
  font-weight: 500;
  text-decoration: none;
  padding: 0 var(--space-md);
}

.morph-nav__lg-subnav {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(20px);
  pointer-events: none;
  transition: transform 0.3s var(--ease-out);
}

.morph-nav__lg-subnav--is-visible {
  transform: translateY(0px);
  pointer-events: auto;
}

.morph-nav__lg-dropdown-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  visibility: hidden;
  will-change: height, width, transform, visibility;
  transition: visibility 0.3s;
}

.morph-nav__lg-subnav--is-visible .morph-nav__lg-dropdown-wrapper {
  visibility: visible;
}

.morph-nav__lg-dropdown-list {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.morph-nav__lg-dropdown-item {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s var(--ease-out), visibility 0s 0.3s;
}

.morph-nav__lg-dropdown-item--is-visible {
  opacity: 1;
	visibility: visible;
  z-index: 2;
  transition: opacity 0.3s var(--ease-out);
}

.morph-nav__lg-dropdown-bg {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-bg-light);
  border-radius: var(--radius-md);
  box-shadow: var(--inner-glow), var(--shadow-md);
  will-change: visibility, opacity;

  /* hide */
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s var(--ease-out);

  /* triangle marker */
  &::before {
    --size: 14px;
    content: '';
    display: block;
    height: var(--size);
    width: var(--size);
    background-color: inherit;
    border: inherit;
    position: absolute;
    z-index: 1;
    top: calc(-1 * var(--size)/2);
    left: calc(50% - var(--size)/2);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    transform: translateY(1px) rotate(-45deg);
    border-radius: 0 4px 0 0;
    box-shadow: var(--inner-glow);
  }
}

.morph-nav__lg-dropdown-bg--is-visible {
  opacity: 1;
  visibility: visible;
}

.morph-nav__lg-dropdown a {
  text-decoration: none;
}

.morph-nav__lg-dropdown--1 {
  width: 580px;
}

.morph-nav__lg-dropdown--2 {
  width: 280px;
}

.morph-nav__lg-dropdown--3 {
  width: 460px;
}
/* #endregion */